import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import Layout from '../elements/Layout';
import { getCustomerPayments } from '../utils/api/api';
import toast from 'react-hot-toast';
import Cookies from 'js-cookie';
import moment from 'moment';
import { Link } from 'react-router-dom';
import {
  FaEye,
  FaSearch,
  FaChevronLeft,
  FaChevronRight,
  FaFileAlt,
  FaFilter,
  FaCalendarAlt,
  FaSortAmountDown,
  FaSortAmountUp
} from 'react-icons/fa';
import { IoCalendarOutline, IoWalletOutline, IoTimeOutline, IoArrowForward, IoCardOutline, IoSearchOutline, IoFunnelOutline } from 'react-icons/io5';

const Payments = () => {
  const cu_id = Cookies.get('user_id');
  const [payments, setPayments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const [showFilters, setShowFilters] = useState(false);
  const [sortField, setSortField] = useState('createdAt');
  const [sortDirection, setSortDirection] = useState('desc');
  const [dateRange, setDateRange] = useState({ start: '', end: '' });
  const [filterMethod, setFilterMethod] = useState('');

  const getPayments = async (page, pageSize) => {
    setLoading(true);
    const payload = { customer_id: Number(cu_id), page, pageSize };
    try {
      const response = await getCustomerPayments(payload);
      setPayments(response.payments);
      setTotalPages(response.totalPages);
    } catch (error) {
      toast.error(error.message || "Failed to fetch payments", {
        style: {
          borderRadius: '12px',
          background: '#333',
          color: '#fff',
        }
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPayments(page, pageSize);
  }, [page, pageSize]);

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setPage(newPage);
    }
  };

  const handlePageSizeChange = (event) => {
    setPageSize(Number(event.target.value));
    setPage(1);
  };

  const handleSort = (field) => {
    if (sortField === field) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortDirection('asc');
    }
  };

  const formatCurrency = (amount) => {
    return `KES ${Number(amount).toLocaleString()}`;
  };

  const filteredPayments = payments.filter(payment => {
    let matchesSearch = true;
    let matchesMethod = true;
    let matchesDate = true;

    // Search filter
    if (searchQuery) {
      const query = searchQuery.toLowerCase();
      matchesSearch =
        payment.transaction_number?.toLowerCase().includes(query) ||
        payment.transaction_id?.toLowerCase().includes(query) ||
        payment.method?.toLowerCase().includes(query) ||
        payment.amount?.toString().includes(query);
    }

    // Method filter
    if (filterMethod) {
      matchesMethod = payment.method?.toLowerCase() === filterMethod.toLowerCase();
    }

    // Date range filter
    if (dateRange.start && dateRange.end) {
      const paymentDate = moment(payment.createdAt);
      const startDate = moment(dateRange.start);
      const endDate = moment(dateRange.end);
      matchesDate = paymentDate.isBetween(startDate, endDate, 'day', '[]');
    }

    return matchesSearch && matchesMethod && matchesDate;
  });

  // Sort the filtered payments
  const sortedPayments = [...filteredPayments].sort((a, b) => {
    if (sortField === 'amount') {
      return sortDirection === 'asc'
        ? parseFloat(a.amount) - parseFloat(b.amount)
        : parseFloat(b.amount) - parseFloat(a.amount);
    } else if (sortField === 'createdAt') {
      return sortDirection === 'asc'
        ? new Date(a.createdAt) - new Date(b.createdAt)
        : new Date(b.createdAt) - new Date(a.createdAt);
    }
    return 0;
  });

  // Animation variants
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.05
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        type: "spring",
        stiffness: 100,
        damping: 15
      }
    }
  };

  const tableRowVariants = {
    hidden: { opacity: 0, x: -20 },
    visible: i => ({
      opacity: 1,
      x: 0,
      transition: {
        delay: i * 0.05,
        duration: 0.3,
        ease: "easeOut"
      }
    }),
    hover: {
      backgroundColor: "#f9fafb",
      transition: { duration: 0.2 }
    }
  };

  const cardVariants = {
    hidden: { opacity: 0, y: 20, scale: 0.95 },
    visible: i => ({
      opacity: 1,
      y: 0,
      scale: 1,
      transition: {
        delay: i * 0.05,
        duration: 0.3,
        type: "spring",
        stiffness: 100,
        damping: 15
      }
    }),
    hover: {
      y: -5,
      boxShadow: "0 10px 25px rgba(0, 0, 0, 0.08)",
      transition: { type: "spring", stiffness: 400, damping: 10 }
    },
    tap: { scale: 0.98 }
  };

  const filterVariants = {
    hidden: { opacity: 0, height: 0 },
    visible: {
      opacity: 1,
      height: "auto",
      transition: {
        duration: 0.3,
        ease: "easeInOut"
      }
    },
    exit: {
      opacity: 0,
      height: 0,
      transition: {
        duration: 0.3,
        ease: "easeInOut"
      }
    }
  };

  // Skeleton loader for table rows
  const TableRowSkeleton = ({ count = 5 }) => (
    <>
      {[...Array(count)].map((_, index) => (
        <tr key={index} className="border-b border-gray-200">
          <td className="py-3 px-6">
            <div className="h-4 bg-gray-200 rounded animate-pulse"></div>
          </td>
          <td className="py-3 px-6">
            <div className="h-4 bg-gray-200 rounded animate-pulse"></div>
          </td>
          <td className="py-3 px-6">
            <div className="h-4 bg-gray-200 rounded animate-pulse"></div>
          </td>
          <td className="py-3 px-6">
            <div className="h-4 w-20 bg-gray-200 rounded animate-pulse"></div>
          </td>
          <td className="py-3 px-6">
            <div className="h-4 bg-gray-200 rounded animate-pulse"></div>
          </td>
          <td className="py-3 px-6">
            <div className="h-7 w-7 bg-gray-200 rounded animate-pulse"></div>
          </td>
        </tr>
      ))}
    </>
  );

  // Skeleton loader for mobile cards
  const CardSkeleton = ({ count = 5 }) => (
    <>
      {[...Array(count)].map((_, index) => (
        <motion.div
          key={index}
          className="bg-white rounded-xl p-4 mb-3 shadow-sm"
          initial={{ opacity: 0.7 }}
          animate={{ opacity: [0.7, 0.9, 0.7] }}
          transition={{ duration: 1.5, repeat: Infinity }}
        >
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-3">
              <div className="w-[40px] h-[40px] bg-gray-200 rounded-full"></div>
              <div>
                <div className="h-4 w-28 bg-gray-200 rounded mb-2"></div>
                <div className="h-3 w-20 bg-gray-200 rounded"></div>
              </div>
            </div>
            <div className="text-right">
              <div className="h-4 w-24 bg-gray-200 rounded mb-2"></div>
              <div className="h-3 w-32 bg-gray-200 rounded"></div>
            </div>
          </div>
        </motion.div>
      ))}
    </>
  );

  // Empty state component
  const EmptyState = () => (
    <motion.div
      className="flex flex-col items-center justify-center py-16"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <div className="w-20 h-20 bg-gray-100 rounded-full flex items-center justify-center mb-6">
        <IoWalletOutline className="text-gray-400 text-4xl" />
      </div>
      <h3 className="text-xl font-medium text-gray-700 mb-2">No payments found</h3>
      <p className="text-gray-500 text-center max-w-md mb-8">
        {searchQuery || filterMethod || (dateRange.start && dateRange.end)
          ? "No payments match your search criteria. Try adjusting your filters."
          : "You haven't made any payments yet. Your payment history will appear here."}
      </p>
      {(searchQuery || filterMethod || (dateRange.start && dateRange.end)) && (
        <motion.button
          className="px-6 py-2.5 bg-secondary text-white rounded-full font-medium"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={() => {
            setSearchQuery('');
            setFilterMethod('');
            setDateRange({ start: '', end: '' });
          }}
        >
          Clear Filters
        </motion.button>
      )}
    </motion.div>
  );

  return (
    <Layout text="Payment History">
      <motion.div
        className="max-w-7xl mx-auto"
        initial="hidden"
        animate="visible"
        variants={containerVariants}
      >
        <motion.div
          className="bg-white rounded-2xl shadow-sm border border-gray-100 overflow-hidden mb-6"
          variants={itemVariants}
        >
          <div className="p-6 border-b border-gray-100">
            <div className="flex flex-col lg:flex-row lg:items-center lg:justify-between gap-4">
              <div>
                <h2 className="text-xl font-semibold text-gray-800">Transaction History</h2>
                <p className="text-gray-500 text-sm mt-1">View and manage all your payment transactions</p>
              </div>

              <div className="flex flex-col sm:flex-row gap-3">
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <IoSearchOutline className="text-gray-400" />
                  </div>
                  <input
                    type="text"
                    placeholder="Search transactions..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    className="pl-10 pr-4 py-2 bg-gray-50 border border-gray-200 rounded-xl text-sm focus:outline-none focus:ring-2 focus:ring-secondary/20 focus:border-secondary w-full sm:w-64"
                  />
                </div>

                <motion.button
                  className="flex items-center justify-center gap-2 px-4 py-2 bg-gray-50 border border-gray-200 rounded-xl hover:bg-gray-100 transition-colors"
                  onClick={() => setShowFilters(!showFilters)}
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                >
                  <IoFunnelOutline className="text-gray-600" />
                  <span className="text-sm text-gray-700">Filters</span>
                </motion.button>
              </div>
            </div>

            <AnimatePresence>
              {showFilters && (
                <motion.div
                  className="mt-4 border-t border-gray-100 pt-4"
                  variants={filterVariants}
                  initial="hidden"
                  animate="visible"
                  exit="exit"
                >
                  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
                    <div>
                      <label className="block text-sm text-gray-600 mb-1">Payment Method</label>
                      <select
                        value={filterMethod}
                        onChange={(e) => setFilterMethod(e.target.value)}
                        className="w-full p-2 bg-gray-50 border border-gray-200 rounded-xl text-sm focus:outline-none focus:ring-2 focus:ring-secondary/20 focus:border-secondary"
                      >
                        <option value="">All Methods</option>
                        <option value="mpesa">M-Pesa</option>
                        <option value="card">Card</option>
                        <option value="bank">Bank Transfer</option>
                      </select>
                    </div>

                    <div>
                      <label className="block text-sm text-gray-600 mb-1">From Date</label>
                      <div className="relative">
                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                          <IoCalendarOutline className="text-gray-400" />
                        </div>
                        <input
                          type="date"
                          value={dateRange.start}
                          onChange={(e) => setDateRange({ ...dateRange, start: e.target.value })}
                          className="w-full pl-10 pr-4 py-2 bg-gray-50 border border-gray-200 rounded-xl text-sm focus:outline-none focus:ring-2 focus:ring-secondary/20 focus:border-secondary"
                        />
                      </div>
                    </div>

                    <div>
                      <label className="block text-sm text-gray-600 mb-1">To Date</label>
                      <div className="relative">
                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                          <IoCalendarOutline className="text-gray-400" />
                        </div>
                        <input
                          type="date"
                          value={dateRange.end}
                          onChange={(e) => setDateRange({ ...dateRange, end: e.target.value })}
                          className="w-full pl-10 pr-4 py-2 bg-gray-50 border border-gray-200 rounded-xl text-sm focus:outline-none focus:ring-2 focus:ring-secondary/20 focus:border-secondary"
                        />
                      </div>
                    </div>

                    <div>
                      <label className="block text-sm text-gray-600 mb-1">Items per page</label>
                      <select
                        value={pageSize}
                        onChange={handlePageSizeChange}
                        className="w-full p-2 bg-gray-50 border border-gray-200 rounded-xl text-sm focus:outline-none focus:ring-2 focus:ring-secondary/20 focus:border-secondary"
                      >
                        <option value={5}>5</option>
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                      </select>
                    </div>
                  </div>

                  <div className="flex justify-end mt-4">
                    <motion.button
                      className="px-4 py-2 text-sm text-gray-600 border border-gray-200 rounded-xl mr-2 hover:bg-gray-50"
                      onClick={() => {
                        setSearchQuery('');
                        setFilterMethod('');
                        setDateRange({ start: '', end: '' });
                      }}
                      whileHover={{ scale: 1.02 }}
                      whileTap={{ scale: 0.98 }}
                    >
                      Clear Filters
                    </motion.button>
                    <motion.button
                      className="px-4 py-2 text-sm text-white bg-secondary rounded-xl"
                      onClick={() => getPayments(1, pageSize)}
                      whileHover={{ scale: 1.02 }}
                      whileTap={{ scale: 0.98 }}
                    >
                      Apply Filters
                    </motion.button>
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          </div>

          {/* Desktop Table View */}
          <div className="hidden md:block overflow-x-auto">
            <table className="min-w-full table-fixed">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    className="py-3 px-6 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-100"
                    onClick={() => handleSort('transaction_number')}
                  >
                    <div className="flex items-center">
                      <span>TRX No.</span>
                      {sortField === 'transaction_number' && (
                        <span className="ml-1">
                          {sortDirection === 'asc' ? <FaSortAmountUp className="text-secondary" /> : <FaSortAmountDown className="text-secondary" />}
                        </span>
                      )}
                    </div>
                  </th>
                  <th
                    className="py-3 px-6 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-100"
                    onClick={() => handleSort('transaction_id')}
                  >
                    <div className="flex items-center">
                      <span>Transaction ID</span>
                      {sortField === 'transaction_id' && (
                        <span className="ml-1">
                          {sortDirection === 'asc' ? <FaSortAmountUp className="text-secondary" /> : <FaSortAmountDown className="text-secondary" />}
                        </span>
                      )}
                    </div>
                  </th>
                  <th
                    className="py-3 px-6 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-100"
                    onClick={() => handleSort('amount')}
                  >
                    <div className="flex items-center">
                      <span>Amount</span>
                      {sortField === 'amount' && (
                        <span className="ml-1">
                          {sortDirection === 'asc' ? <FaSortAmountUp className="text-secondary" /> : <FaSortAmountDown className="text-secondary" />}
                        </span>
                      )}
                    </div>
                  </th>
                  <th
                    className="py-3 px-6 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-100"
                    onClick={() => handleSort('method')}
                  >
                    <div className="flex items-center">
                      <span>Method</span>
                      {sortField === 'method' && (
                        <span className="ml-1">
                          {sortDirection === 'asc' ? <FaSortAmountUp className="text-secondary" /> : <FaSortAmountDown className="text-secondary" />}
                        </span>
                      )}
                    </div>
                  </th>
                  <th
                    className="py-3 px-6 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-100"
                    onClick={() => handleSort('createdAt')}
                  >
                    <div className="flex items-center">
                      <span>Date</span>
                      {sortField === 'createdAt' && (
                        <span className="ml-1">
                          {sortDirection === 'asc' ? <FaSortAmountUp className="text-secondary" /> : <FaSortAmountDown className="text-secondary" />}
                        </span>
                      )}
                    </div>
                  </th>
                  <th className="py-3 px-6 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <TableRowSkeleton count={pageSize} />
                ) : sortedPayments.length > 0 ? (
                  sortedPayments.map((payment, index) => (
                    <motion.tr
                      key={payment.id}
                      custom={index}
                      variants={tableRowVariants}
                      whileHover="hover"
                      className="border-b border-gray-100 hover:bg-gray-50 transition-colors"
                    >
                      <td className="py-4 px-6 text-sm text-gray-700">{payment.transaction_number}</td>
                      <td className="py-4 px-6 text-sm font-medium text-gray-800">{payment.transaction_id}</td>
                      <td className="py-4 px-6 text-sm font-semibold text-gray-900">
                        {formatCurrency(payment.amount)}
                      </td>
                      <td className="py-4 px-6">
                        <div className={`inline-flex items-center px-2.5 py-1 rounded-full text-xs font-medium ${payment.method.toLowerCase() === 'mpesa'
                            ? 'bg-green-100 text-green-800'
                            : payment.method.toLowerCase() === 'card'
                              ? 'bg-blue-100 text-blue-800'
                              : 'bg-gray-100 text-gray-800'
                          }`}>
                          {payment.method.toLowerCase() === 'mpesa' && <IoWalletOutline className="mr-1" />}
                          {payment.method.toLowerCase() === 'card' && <IoCardOutline className="mr-1" />}
                          {payment.method}
                        </div>
                      </td>
                      <td className="py-4 px-6 text-sm text-gray-600">
                        <div className="flex flex-col">
                          <span>{moment(payment.createdAt).format('MMM D, YYYY')}</span>
                          <span className="text-xs text-gray-500">{moment(payment.createdAt).format('h:mm A')}</span>
                        </div>
                      </td>
                      <td className="py-4 px-6">
                        <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
                          <Link
                            to={`/dashboard/payments/${payment.id}/view-details`}
                            className="flex items-center justify-center w-9 h-9 bg-secondary bg-opacity-10 text-secondary rounded-full hover:bg-opacity-20 transition-colors"
                          >
                            <FaEye />
                          </Link>
                        </motion.div>
                      </td>
                    </motion.tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="6" className="py-10">
                      <EmptyState />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          {/* Mobile Cards View */}
          <div className="md:hidden p-4">
            {loading ? (
              <CardSkeleton count={pageSize > 5 ? 5 : pageSize} />
            ) : sortedPayments.length > 0 ? (
              <div className="space-y-3">
                {sortedPayments.map((payment, index) => (
                  <motion.div
                    key={payment.id}
                    custom={index}
                    variants={cardVariants}
                    whileHover="hover"
                    whileTap="tap"
                  >
                    <Link
                      to={`/dashboard/payments/${payment.id}/view-details`}
                      className="block bg-white rounded-xl p-4 border border-gray-100 shadow-sm"
                    >
                      <div className="flex items-center justify-between">
                        <div className="flex items-center gap-3">
                          <div className={`w-10 h-10 rounded-full flex items-center justify-center ${payment.method.toLowerCase() === 'mpesa'
                              ? 'bg-green-100 text-green-600'
                              : payment.method.toLowerCase() === 'card'
                                ? 'bg-blue-100 text-blue-600'
                                : 'bg-gray-100 text-gray-600'
                            }`}>
                            {payment.method.toLowerCase() === 'mpesa' ? (
                              <IoWalletOutline className="text-lg" />
                            ) : payment.method.toLowerCase() === 'card' ? (
                              <IoCardOutline className="text-lg" />
                            ) : (
                              <FaFileAlt className="text-lg" />
                            )}
                          </div>
                          <div>
                            <p className="text-sm font-medium text-gray-800">
                              {payment.transaction_id.slice(0, 12)}...
                            </p>
                            <div className="flex items-center mt-1">
                              <span className={`inline-flex items-center px-2 py-0.5 rounded-full text-xs ${payment.method.toLowerCase() === 'mpesa'
                                  ? 'bg-green-100 text-green-700'
                                  : payment.method.toLowerCase() === 'card'
                                    ? 'bg-blue-100 text-blue-700'
                                    : 'bg-gray-100 text-gray-700'
                                }`}>
                                {payment.method}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="text-right">
                          <p className="font-semibold text-gray-900">
                            {formatCurrency(payment.amount)}
                          </p>
                          <div className="flex items-center justify-end mt-1 text-xs text-gray-500">
                            <IoTimeOutline className="mr-1" />
                            {moment(payment.createdAt).format('MMM D, h:mm A')}
                          </div>
                        </div>
                      </div>

                      <div className="flex items-center justify-between mt-3 pt-3 border-t border-gray-100">
                        <span className="text-xs text-gray-500">
                          TRX: {payment.transaction_number}
                        </span>
                        <div className="flex items-center text-secondary text-sm">
                          <span className="mr-1">View Details</span>
                          <IoArrowForward className="text-xs" />
                        </div>
                      </div>
                    </Link>
                  </motion.div>
                ))}
              </div>
            ) : (
              <EmptyState />
            )}
          </div>

          {/* Pagination Controls */}
          {!loading && sortedPayments.length > 0 && (
            <div className="flex flex-col sm:flex-row items-center justify-between px-6 py-4 border-t border-gray-100 bg-gray-50">
              <div className="text-sm text-gray-600 mb-3 sm:mb-0">
                Showing {sortedPayments.length} of {payments.length} payments
              </div>
              <div className="flex items-center space-x-2">
                <motion.button
                  className={`w-9 h-9 rounded-full flex items-center justify-center ${page === 1
                      ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                      : 'bg-white border border-gray-200 text-gray-600 hover:bg-gray-50'
                    }`}
                  onClick={() => handlePageChange(1)}
                  disabled={page === 1}
                  whileHover={page !== 1 ? { scale: 1.1 } : {}}
                  whileTap={page !== 1 ? { scale: 0.9 } : {}}
                >
                  <FaChevronLeft className="text-xs mr-1" />
                  <FaChevronLeft className="text-xs" />
                </motion.button>

                <motion.button
                  className={`w-9 h-9 rounded-full flex items-center justify-center ${page === 1
                      ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                      : 'bg-white border border-gray-200 text-gray-600 hover:bg-gray-50'
                    }`}
                  onClick={() => handlePageChange(page - 1)}
                  disabled={page === 1}
                  whileHover={page !== 1 ? { scale: 1.1 } : {}}
                  whileTap={page !== 1 ? { scale: 0.9 } : {}}
                >
                  <FaChevronLeft className="text-xs" />
                </motion.button>

                <div className="px-3 py-1 bg-secondary text-white rounded-full text-sm min-w-[40px] text-center font-medium">
                  {page}
                </div>

                <motion.button
                  className={`w-9 h-9 rounded-full flex items-center justify-center ${page === totalPages
                      ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                      : 'bg-white border border-gray-200 text-gray-600 hover:bg-gray-50'
                    }`}
                  onClick={() => handlePageChange(page + 1)}
                  disabled={page === totalPages}
                  whileHover={page !== totalPages ? { scale: 1.1 } : {}}
                  whileTap={page !== totalPages ? { scale: 0.9 } : {}}
                >
                  <FaChevronRight className="text-xs" />
                </motion.button>

                <motion.button
                  className={`w-9 h-9 rounded-full flex items-center justify-center ${page === totalPages
                      ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                      : 'bg-white border border-gray-200 text-gray-600 hover:bg-gray-50'
                    }`}
                  onClick={() => handlePageChange(totalPages)}
                  disabled={page === totalPages}
                  whileHover={page !== totalPages ? { scale: 1.1 } : {}}
                  whileTap={page !== totalPages ? { scale: 0.9 } : {}}
                >
                  <FaChevronRight className="text-xs" />
                  <FaChevronRight className="text-xs ml-1" />
                </motion.button>
              </div>
            </div>
          )}
        </motion.div>
      </motion.div>
    </Layout>
  );
};

export default Payments;