import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import Layout from '../elements/Layout';
import Cookies from 'js-cookie';
import { fetchCustomerOrders } from '../utils/api/api';
import toast from 'react-hot-toast';
import { FaEye, FaCreditCard } from 'react-icons/fa6';
import { IoMdSearch, IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import { IoFilterSharp } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';

const Orders = () => {
  const customer_id = Cookies.get('user_id');
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const [showFilter, setShowFilter] = useState(false);
  const navigate = useNavigate();

  const getOrders = async (page, pageSize) => {
    setLoading(true);
    const payload = {
      customer_id: customer_id,
      page: page,
      pageSize: pageSize,
    };
    try {
      const response = await fetchCustomerOrders(payload);
      setOrders(response.orders);
      setTotalPages(response.totalPages);
    } catch (error) {
      toast.error('Request failed', {
        style: {
          borderRadius: '12px',
          background: '#333',
          color: '#fff',
        },
        icon: '❌',
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getOrders(page, pageSize);
  }, [page, pageSize]);

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setPage(newPage);
    }
  };

  const handlePageSizeChange = (event) => {
    setPageSize(Number(event.target.value));
    setPage(1);
  };

  const handleMakePayment = (orderId) => {
    navigate(`/dashboard/orders/${orderId}/view`);
  };

  // Animation variants
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.05
      }
    }
  };

  const cardVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        type: "spring",
        stiffness: 100,
        damping: 15
      }
    },
    hover: {
      y: -5,
      boxShadow: "0 10px 25px rgba(0, 0, 0, 0.1)",
      transition: {
        type: "spring",
        stiffness: 400,
        damping: 15
      }
    },
    tap: { scale: 0.98 }
  };

  // Filter by search query
  const filteredOrders = orders.filter(order => {
    if (!searchQuery) return true;
    return order.id.toString().includes(searchQuery) ||
      order.amount.toString().includes(searchQuery) ||
      order.payment_status.toLowerCase().includes(searchQuery.toLowerCase());
  });

  const SkeletonLoader = () => (
    <motion.div
      className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6"
      initial={{ opacity: 0.6 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5, repeat: Infinity, repeatType: "reverse" }}
    >
      {[...Array(8)].map((_, index) => (
        <div key={index} className="bg-white rounded-2xl p-5 shadow-md">
          <div className="h-7 bg-gray-200 rounded-xl mb-4"></div>
          <div className="h-6 w-24 bg-gray-200 rounded-full mb-4"></div>
          <div className="h-9 w-28 bg-gray-200 rounded-full"></div>
        </div>
      ))}
    </motion.div>
  );

  const EmptyState = () => (
    <motion.div
      className="col-span-full flex flex-col items-center justify-center py-16 px-4"
      initial={{ opacity: 0, scale: 0.9 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ type: "spring", stiffness: 100, damping: 15 }}
    >
      <div className="h-24 w-24 bg-blue-50 rounded-full flex items-center justify-center mb-6">
        <FaCreditCard className="text-secondary text-3xl" />
      </div>
      <h3 className="text-xl font-semibold text-gray-800 mb-2">No payments found</h3>
      <p className="text-gray-500 text-center max-w-md mb-6">
        You don't have any payment requests at the moment. They'll appear here when you do.
      </p>
      <motion.button
        className="px-6 py-3 bg-gradient-to-r from-secondary to-secondary text-white rounded-full font-medium shadow-md"
        whileHover={{ scale: 1.05, boxShadow: "0 10px 25px rgba(79, 70, 229, 0.2)" }}
        whileTap={{ scale: 0.98 }}
      >
        Refresh
      </motion.button>
    </motion.div>
  );

  return (
    <Layout text="Pay now">
      <div className="w-full max-w-7xl mx-auto">
        <motion.div
          className="bg-white rounded-2xl p-6 shadow-md"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          {/* Header */}
          <div className="flex flex-col md:flex-row justify-between items-start md:items-center mb-6">
            <motion.h1
              className="text-2xl font-bold text-gray-800 mb-4 md:mb-0"
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 0.2, duration: 0.5 }}
            >
              Payment Requests
            </motion.h1>

            <motion.div
              className="flex flex-col sm:flex-row gap-4 w-full md:w-auto"
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 0.3, duration: 0.5 }}
            >
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <IoMdSearch className="text-gray-400 text-lg" />
                </div>
                <input
                  type="text"
                  placeholder="Search payments..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  className="w-full sm:w-64 pl-10 pr-4 py-2 bg-gray-50 border border-gray-100 rounded-xl text-sm text-gray-700 focus:outline-none focus:ring-2 focus:ring-secondary transition-all duration-200"
                />
              </div>

              <motion.button
                onClick={() => setShowFilter(!showFilter)}
                className="flex items-center justify-center px-4 py-2 bg-gray-50 border border-gray-100 rounded-xl text-sm text-gray-700"
                whileHover={{ backgroundColor: "#f1f5f9" }}
                whileTap={{ scale: 0.98 }}
              >
                <IoFilterSharp className="mr-2" />
                Filter
              </motion.button>
            </motion.div>
          </div>

          {/* Filter options */}
          <AnimatePresence>
            {showFilter && (
              <motion.div
                className="bg-gray-50 p-4 rounded-xl mb-6"
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: "auto" }}
                exit={{ opacity: 0, height: 0 }}
                transition={{ duration: 0.3 }}
              >
                <div className="flex flex-wrap gap-4 items-center">
                  <div>
                    <label htmlFor="pageSize" className="block text-sm text-gray-600 mb-1">
                      Items per page
                    </label>
                    <select
                      id="pageSize"
                      value={pageSize}
                      onChange={handlePageSizeChange}
                      className="border border-gray-200 rounded-xl text-sm py-2 px-3 bg-white focus:ring-2 focus:ring-secondary focus:outline-none shadow-sm"
                    >
                      <option value={5}>5</option>
                      <option value={10}>10</option>
                      <option value={20}>20</option>
                      <option value={50}>50</option>
                    </select>
                  </div>

                  <div>
                    <label htmlFor="status" className="block text-sm text-gray-600 mb-1">
                      Payment Status
                    </label>
                    <select
                      id="status"
                      className="border border-gray-200 rounded-xl text-sm py-2 px-3 bg-white focus:ring-2 focus:ring-secondary focus:outline-none shadow-sm"
                    >
                      <option value="all">All</option>
                      <option value="paid">Paid</option>
                      <option value="unpaid">Unpaid</option>
                    </select>
                  </div>
                </div>
              </motion.div>
            )}
          </AnimatePresence>

          {/* Content */}
          {loading ? (
            <SkeletonLoader />
          ) : filteredOrders.length > 0 ? (
            <motion.div
              className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6"
              variants={containerVariants}
              initial="hidden"
              animate="visible"
            >
              {filteredOrders.map((order, index) => (
                <motion.div
                  key={order.id}
                  className="bg-white rounded-2xl p-5 border border-gray-100 shadow-sm hover:shadow-lg transition-shadow duration-300"
                  variants={cardVariants}
                  whileHover="hover"
                  whileTap="tap"
                  custom={index}
                  onClick={() => handleMakePayment(order.id)}
                >
                  <div className="flex justify-between items-start mb-3">
                    <div className="bg-gradient-to-r from-blue-50 to-indigo-50 px-3 py-1 rounded-lg text-xs font-medium text-blue-700">
                      Order #{order.id.toString().slice(-4)}
                    </div>
                    <motion.div
                      className={`h-3 w-3 rounded-full ${order.payment_status === 'paid' ? 'bg-green-400' : 'bg-yellow-400'}`}
                      initial={{ scale: 0.8 }}
                      animate={{ scale: [0.8, 1.2, 1], opacity: [0.7, 1, 0.9] }}
                      transition={{ duration: 2, repeat: Infinity, repeatType: "reverse" }}
                    />
                  </div>

                  <div className="mb-4">
                    <div className="text-gray-500 text-xs mb-1">Amount</div>
                    <div className="text-xl font-semibold bg-gradient-to-r from-gray-800 to-gray-700 bg-clip-text text-transparent">
                      {Number(order.amount).toLocaleString('en-KE')} KES
                    </div>
                  </div>

                  <div className="flex justify-between items-center">
                    <div
                      className={`px-3 py-1 rounded-full text-xs font-medium ${order.payment_status === 'paid'
                        ? 'bg-green-100 text-green-600'
                        : 'bg-yellow-100 text-yellow-600'
                        }`}
                    >
                      {order.payment_status}
                    </div>

                    {order.payment_status !== 'paid' ? (
                      <motion.button
                        className="px-4 py-1.5 bg-gradient-to-r from-secondary to-secondary text-white rounded-full text-xs font-medium shadow-sm"
                        whileHover={{ scale: 1.05, boxShadow: "0 4px 12px rgba(79, 70, 229, 0.2)" }}
                        whileTap={{ scale: 0.95 }}
                      >
                        Pay Now
                      </motion.button>
                    ) : (
                      <motion.button
                        className="flex items-center gap-1.5 px-3 py-1.5 text-secondary text-xs font-medium border border-blue-100 rounded-full bg-blue-50"
                        whileHover={{ scale: 1.05, backgroundColor: "#e0e7ff" }}
                        whileTap={{ scale: 0.95 }}
                      >
                        Details
                        <FaEye className="text-secondary" size={12} />
                      </motion.button>
                    )}
                  </div>
                </motion.div>
              ))}
            </motion.div>
          ) : (
            <EmptyState />
          )}

          {/* Pagination */}
          {!loading && filteredOrders.length > 0 && (
            <motion.div
              className="flex flex-col sm:flex-row items-center justify-between mt-8 gap-4"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.4, duration: 0.5 }}
            >
              <div className="text-sm text-gray-500">
                Page {page} of {totalPages}
              </div>

              <div className="flex items-center">
                <motion.button
                  className={`w-10 h-10 rounded-full flex items-center justify-center mr-2 ${page === 1
                    ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                    : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                    }`}
                  onClick={() => handlePageChange(1)}
                  disabled={page === 1}
                  whileHover={page !== 1 ? { scale: 1.1, backgroundColor: "#e5e7eb" } : {}}
                  whileTap={page !== 1 ? { scale: 0.9 } : {}}
                >
                  <IoIosArrowBack className="text-lg" />
                  <IoIosArrowBack className="text-lg -ml-3" />
                </motion.button>

                <motion.button
                  className={`w-10 h-10 rounded-full flex items-center justify-center mr-2 ${page === 1
                    ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                    : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                    }`}
                  onClick={() => handlePageChange(page - 1)}
                  disabled={page === 1}
                  whileHover={page !== 1 ? { scale: 1.1, backgroundColor: "#e5e7eb" } : {}}
                  whileTap={page !== 1 ? { scale: 0.9 } : {}}
                >
                  <IoIosArrowBack className="text-lg" />
                </motion.button>

                <div className="mx-2 px-4 py-2 bg-secondary text-white rounded-full font-medium min-w-[40px] text-center">
                  {page}
                </div>

                <motion.button
                  className={`w-10 h-10 rounded-full flex items-center justify-center ml-2 ${page === totalPages
                    ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                    : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                    }`}
                  onClick={() => handlePageChange(page + 1)}
                  disabled={page === totalPages}
                  whileHover={page !== totalPages ? { scale: 1.1, backgroundColor: "#e5e7eb" } : {}}
                  whileTap={page !== totalPages ? { scale: 0.9 } : {}}
                >
                  <IoIosArrowForward className="text-lg" />
                </motion.button>

                <motion.button
                  className={`w-10 h-10 rounded-full flex items-center justify-center ml-2 ${page === totalPages
                    ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                    : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                    }`}
                  onClick={() => handlePageChange(totalPages)}
                  disabled={page === totalPages}
                  whileHover={page !== totalPages ? { scale: 1.1, backgroundColor: "#e5e7eb" } : {}}
                  whileTap={page !== totalPages ? { scale: 0.9 } : {}}
                >
                  <IoIosArrowForward className="text-lg" />
                  <IoIosArrowForward className="text-lg -ml-3" />
                </motion.button>
              </div>
            </motion.div>
          )}
        </motion.div>
      </div>
    </Layout>
  );
};

export default Orders;