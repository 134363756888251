import React, { useEffect, useState } from 'react';
import CustomerKYC from './CustomerKYC';
import { useNavigate } from 'react-router-dom';
import { fetchCustomerById, updateCustomer } from '../utils/api/api';
import { MdOutlineAttachment, MdEdit, MdClose } from 'react-icons/md';
import { FaRegUser } from 'react-icons/fa';
import { IoIosArrowForward } from 'react-icons/io';
import CustomerInfo from './CustomerInfo';
import toast from 'react-hot-toast';
import Loading from '../elements/Loading';
import Layout from '../elements/Layout';
import Cookies from 'js-cookie';

const Account = () => {
    const [activeTab, setActiveTab] = useState('Info');
    const [customer, setCustomer] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const customerId = Cookies.get('user_id');
    const [editCustomerData, setEditCustomerData] = useState({
        id: customer?.id || "",
        email: customer?.email || "",
        first_name: customer?.first_name || "",
        last_name: customer?.last_name || "",
        phone_number: customer?.phone_number || "",
        id_number: customer?.id_number || "",
        dob: customer?.dob || "",
        gender: customer?.gender || "",
        nationality: customer?.nationality || "",
        city: customer?.city || "",
        state: customer?.state || "",
        country: customer?.country || "",
        address: customer?.address || ""
    });

    useEffect(() => {
        if (customer) {
            setEditCustomerData({
                id: customer.id || "",
                email: customer.email || "",
                first_name: customer.first_name || "",
                last_name: customer.last_name || "",
                phone_number: customer.phone_number || "",
                id_number: customer.id_number || "",
                dob: customer.dob || "",
                gender: customer.gender || "",
                nationality: customer.nationality || "",
                city: customer.city || "",
                state: customer.state || "",
                country: customer.country || ""
            });
        }
    }, [customer]);

    const handleEditInputChange = (e) => {
        const { name, value } = e.target;
        setEditCustomerData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    useEffect(() => {
        const getCustomer = async () => {
            try {
                const data = await fetchCustomerById(customerId);
                setCustomer(data.customer);
                setLoading(false);
            } catch (err) {
                setError('Failed to fetch customer.');
                setLoading(false);
            }
        };

        getCustomer();
    }, [customerId]);

    const renderTabContent = () => {
        switch (activeTab) {
            case 'Info':
                return <CustomerInfo customer={customer} />;
            case 'Documents':
                return <CustomerKYC />;
            default:
                return <CustomerInfo />;
        }
    };

    return (
        <Layout>
            <div className="min-h-screen bg-gradient-to-br from-blue-50 to-purple-50">
                {loading && <Loading />}

                <div className="relative z-10 max-w-5xl mx-auto px-4 py-6">
                    <header className="flex items-center justify-between mb-8">
                        <div className="text-2xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-purple-600">Account</div>
                        <div className="relative">
                            <button
                                onClick={() => navigate('/dashboard/account/edit')}
                                className="w-10 h-10 rounded-full flex items-center justify-center bg-white/30 backdrop-blur-lg border border-white/50 shadow-lg"
                            >
                                <MdEdit size={18} className="text-blue-600" />
                            </button>
                        </div>
                    </header>

                    {/* Profile Card - Glass Effect */}
                    <div className="mb-8 relative overflow-hidden">
                        <div className="absolute inset-0 bg-gradient-to-r from-blue-400/10 to-purple-400/10 backdrop-blur-md rounded-3xl"></div>
                        <div className="absolute inset-0 border border-white/50 rounded-3xl"></div>
                        <div className="relative p-8">
                            <div className="flex flex-col md:flex-row items-center md:items-start gap-6">
                                <div className="w-28 h-28 rounded-full overflow-hidden border-2 border-white/70 shadow-lg backdrop-blur-lg">
                                    <img
                                        className="w-full h-full object-cover"
                                        src="https://imgs.search.brave.com/fbxInw05M6mkNEHaT64Qm3dNEzTIVXrwCD4lxk4ve3A/rs:fit:500:0:0:0/g:ce/aHR0cHM6Ly90NC5m/dGNkbi5uZXQvanBn/LzAyLzE3LzM0LzY3/LzM2MF9GXzIxNzM0/Njc4Ml83WHBDVHQ4/YkxOSnF2VkFhRFpK/d3Zaam0wZXBRbWo2/ai5qcGc"
                                        alt="Profile"
                                    />
                                </div>
                                <div className="flex-1 flex flex-col md:flex-row md:justify-between w-full text-center md:text-left">
                                    <div>
                                        <h1 className="text-3xl font-bold mb-1 bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-purple-600">
                                            {customer?.first_name} {customer?.last_name}
                                        </h1>
                                        <p className="text-gray-600 mb-4">{customer?.email}</p>

                                        <div className="flex flex-wrap gap-2 justify-center md:justify-start">
                                            <span className="px-3 py-1 rounded-full text-xs font-medium text-blue-800 bg-blue-100/50 backdrop-blur-md border border-blue-200/50">
                                                {customer?.nationality || 'Customer'}
                                            </span>
                                            <span className="px-3 py-1 rounded-full text-xs font-medium text-purple-800 bg-purple-100/50 backdrop-blur-md border border-purple-200/50">
                                                ID: {customer?.id_number?.substring(0, 6) || '******'}
                                            </span>
                                        </div>
                                    </div>

                                    <div className="mt-4 md:mt-0 md:self-end">
                                        <button
                                            onClick={() => navigate('/dashboard/account/edit')}
                                            className="px-4 py-2 rounded-full text-sm font-medium text-white bg-gradient-to-r from-blue-500/90 to-purple-500/90 backdrop-blur-md border border-white/30 hover:shadow-lg transition-all duration-300 flex items-center gap-1"
                                        >
                                            <MdEdit size={16} />
                                            Edit Profile
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Tabs - Glass Effect */}
                    <div className="flex mb-8 gap-4">
                        <button
                            className={`relative flex-1 py-4 px-6 rounded-2xl text-sm font-medium flex items-center justify-center gap-2 transition-all duration-300 backdrop-blur-lg border overflow-hidden ${activeTab === 'Info'
                                ? 'bg-white/60 border-white/70 shadow-lg text-blue-700'
                                : 'bg-white/30 border-white/40 text-gray-700 hover:bg-white/40'
                                }`}
                            onClick={() => setActiveTab('Info')}
                        >
                            {activeTab === 'Info' && (
                                <div className="absolute inset-0 bg-gradient-to-r from-blue-200/20 to-purple-200/20 -z-10"></div>
                            )}
                            <FaRegUser size={16} />
                            <span>Personal Information</span>
                        </button>
                        <button
                            className={`relative flex-1 py-4 px-6 rounded-2xl text-sm font-medium flex items-center justify-center gap-2 transition-all duration-300 backdrop-blur-lg border overflow-hidden ${activeTab === 'Documents'
                                ? 'bg-white/60 border-white/70 shadow-lg text-blue-700'
                                : 'bg-white/30 border-white/40 text-gray-700 hover:bg-white/40'
                                }`}
                            onClick={() => setActiveTab('Documents')}
                        >
                            {activeTab === 'Documents' && (
                                <div className="absolute inset-0 bg-gradient-to-r from-blue-200/20 to-purple-200/20 -z-10"></div>
                            )}
                            <MdOutlineAttachment size={18} />
                            <span>Documents</span>
                        </button>
                    </div>

                    {/* Content Panel - Glass Effect */}
                    <div className="relative rounded-3xl overflow-hidden backdrop-blur-lg">
                        <div className="absolute inset-0 bg-white/60 -z-10"></div>
                        <div className="absolute inset-0 border border-white/50 rounded-3xl"></div>
                        <div className="p-6 relative">
                            {renderTabContent()}
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default Account;