import React from 'react';
import { motion } from 'framer-motion';
import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedinIn } from 'react-icons/fa';
import { HiOutlineGlobe, HiOutlineShieldCheck, HiOutlinePhone, HiOutlineMail, HiOutlineLocationMarker, HiOutlineChevronRight } from 'react-icons/hi';
import logo from '../assets/miles_logo.svg';

const LandingFooter = () => {
    // Animation variants
    const containerVariants = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                staggerChildren: 0.1
            }
        }
    };

    const itemVariants = {
        hidden: { opacity: 0, y: 20 },
        visible: {
            opacity: 1,
            y: 0,
            transition: { duration: 0.5, ease: [0.22, 1, 0.36, 1] }
        }
    };

    const hoverVariants = {
        hover: {
            scale: 1.05,
            color: '#FFF',
            transition: { duration: 0.2 }
        }
    };

    const socialIconVariants = {
        hover: {
            scale: 1.2,
            transition: { duration: 0.2 }
        }
    };

    const socialIcons = [
        { icon: <FaFacebookF size={18} />, url: 'https://facebook.com', name: 'Facebook' },
        { icon: <FaTwitter size={18} />, url: 'https://twitter.com', name: 'Twitter' },
        { icon: <FaInstagram size={18} />, url: 'https://instagram.com', name: 'Instagram' },
        { icon: <FaLinkedinIn size={18} />, url: 'https://linkedin.com', name: 'LinkedIn' }
    ];

    const navLinks = [
        { text: 'Home', url: '#' },
        { text: 'Featured Projects', url: '#projects' },
        { text: 'Contact Us', url: '#contact' },
        { text: 'FAQs', url: '#faqs' }
    ];

    const supportLinks = [
        { text: 'Help Center', url: '#help-center' },
        { text: 'Privacy Policy', url: '#privacy-policy' },
        { text: 'Terms of Service', url: '#terms-of-service' },
        { text: 'Site Map', url: '#site-map' }
    ];

    const FooterLink = ({ text, url }) => (
        <motion.li whileHover="hover" variants={hoverVariants}>
            <a href={url} className="group flex items-center gap-1 transition-all">
                <HiOutlineChevronRight className="text-secondary opacity-0 group-hover:opacity-100 transition-all" size={14} />
                <span>{text}</span>
            </a>
        </motion.li>
    );

    return (
        <footer className="relative overflow-hidden">
            <div className="absolute inset-0 bg-gradient-to-b from-primary/95 to-primary/98 backdrop-blur-xl -z-10"></div>

            <div className="absolute inset-0 overflow-hidden pointer-events-none -z-10">
                <motion.div
                    animate={{
                        rotate: [0, 360],
                        opacity: [0.03, 0.05, 0.03],
                    }}
                    transition={{
                        duration: 20,
                        ease: "linear",
                        repeat: Infinity,
                        repeatType: "loop"
                    }}
                    className="absolute -top-32 -right-32 w-96 h-96 rounded-full border border-white/10 blur-2xl"
                />
                <motion.div
                    animate={{
                        rotate: [360, 0],
                        opacity: [0.02, 0.04, 0.02],
                    }}
                    transition={{
                        duration: 15,
                        ease: "linear",
                        repeat: Infinity,
                        repeatType: "loop",
                        delay: 5
                    }}
                    className="absolute -bottom-40 -left-20 w-80 h-80 rounded-full border border-white/10 blur-2xl"
                />
            </div>

            <motion.div
                variants={containerVariants}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                className="max-w-7xl mx-auto py-16 px-6 md:px-8 text-white/90"
            >
                <div className="grid grid-cols-1 md:grid-cols-12 gap-10">
                    <motion.div variants={itemVariants} className="md:col-span-4">
                        <div className="flex items-center gap-3 mb-5">
                            <img src={logo} alt="Milescoop Logo" className="w-12 h-12 bg-white p-2 rounded-xl" />
                            <div>
                                <h3 className="text-xl font-bold text-white">Milescoop</h3>
                                <p className="text-xs text-white/60">Ventures Ltd</p>
                            </div>
                        </div>

                        <p className="text-white/70 text-sm mb-6 leading-relaxed">
                            Your trusted partner in land investment across Kenya. Explore, invest, and build with confidence
                            in the most promising locations with expert guidance every step of the way.
                        </p>

                        <div className="flex gap-5 mb-8">
                            {socialIcons.map((social, index) => (
                                <motion.a
                                    key={index}
                                    href={social.url}
                                    aria-label={social.name}
                                    className="w-10 h-10 rounded-full flex items-center justify-center backdrop-blur-lg bg-white/10 border border-white/20 hover:bg-white/20 transition-all"
                                    whileHover="hover"
                                    variants={socialIconVariants}
                                >
                                    {social.icon}
                                </motion.a>
                            ))}
                        </div>

                        <div className="p-4 rounded-xl backdrop-blur-md bg-white/5 border border-white/10">
                            <p className="text-sm flex items-center gap-2">
                                <HiOutlineGlobe className="text-secondary" />
                                <span>Available throughout Kenya</span>
                            </p>
                            <p className="mt-2 text-sm flex items-center gap-2">
                                <HiOutlineShieldCheck className="text-secondary" />
                                <span>Secure and verified properties</span>
                            </p>
                        </div>
                    </motion.div>

                    {/* Navigation links */}
                    <motion.div variants={itemVariants} className="md:col-span-2">
                        <h3 className="text-lg font-semibold mb-5 relative inline-block">
                            Navigation
                            <span className="absolute -bottom-2 left-0 w-12 h-0.5 bg-secondary"></span>
                        </h3>
                        <ul className="space-y-3 text-white/80">
                            {navLinks.map((link, index) => (
                                <FooterLink key={index} text={link.text} url={link.url} />
                            ))}
                        </ul>
                    </motion.div>

                    {/* Support links */}
                    <motion.div variants={itemVariants} className="md:col-span-2">
                        <h3 className="text-lg font-semibold mb-5 relative inline-block">
                            Support
                            <span className="absolute -bottom-2 left-0 w-12 h-0.5 bg-secondary"></span>
                        </h3>
                        <ul className="space-y-3 text-white/80">
                            {supportLinks.map((link, index) => (
                                <FooterLink key={index} text={link.text} url={link.url} />
                            ))}
                        </ul>
                    </motion.div>

                    {/* Contact info */}
                    <motion.div variants={itemVariants} className="md:col-span-4">
                        <h3 className="text-lg font-semibold mb-5 relative inline-block">
                            Contact Us
                            <span className="absolute -bottom-2 left-0 w-12 h-0.5 bg-secondary"></span>
                        </h3>

                        <div className="space-y-4">
                            <motion.div
                                whileHover={{ x: 5 }}
                                transition={{ duration: 0.2 }}
                                className="flex gap-3"
                            >
                                <div className="flex-shrink-0 w-10 h-10 rounded-full backdrop-blur-md bg-white/10 border border-white/20 flex items-center justify-center">
                                    <HiOutlinePhone className="text-secondary" size={18} />
                                </div>
                                <div>
                                    <p className="text-sm text-white/60">Phone</p>
                                    <p className="text-white">+254 740 173 038</p>
                                    <p className="text-white">+254 709 120 800</p>
                                </div>
                            </motion.div>

                            <motion.div
                                whileHover={{ x: 5 }}
                                transition={{ duration: 0.2 }}
                                className="flex gap-3"
                            >
                                <div className="flex-shrink-0 w-10 h-10 rounded-full backdrop-blur-md bg-white/10 border border-white/20 flex items-center justify-center">
                                    <HiOutlineMail className="text-secondary" size={18} />
                                </div>
                                <div>
                                    <p className="text-sm text-white/60">Email</p>
                                    <p className="text-white">info@milescoop.com</p>
                                </div>
                            </motion.div>

                            <motion.div
                                whileHover={{ x: 5 }}
                                transition={{ duration: 0.2 }}
                                className="flex gap-3"
                            >
                                <div className="flex-shrink-0 w-10 h-10 rounded-full backdrop-blur-md bg-white/10 border border-white/20 flex items-center justify-center">
                                    <HiOutlineLocationMarker className="text-secondary" size={18} />
                                </div>
                                <div>
                                    <p className="text-sm text-white/60">Address</p>
                                    <p className="text-white">Design Centre, 4th Floor</p>
                                    <p className="text-white">Tausi Lane, Westlands</p>
                                    <p className="text-white">Nairobi, Kenya</p>
                                </div>
                            </motion.div>
                        </div>
                    </motion.div>
                </div>

                {/* Footer bottom */}
                <motion.div
                    variants={itemVariants}
                    className="mt-16 pt-8 border-t border-white/10 flex flex-col md:flex-row justify-between items-center gap-4"
                >
                    <p className="text-sm text-white/50">
                        &copy; {new Date().getFullYear()} Milescoop. All rights reserved.
                    </p>

                    <div className="flex flex-wrap gap-6 text-sm text-white/50">
                        <motion.a
                            href="#privacy-policy"
                            whileHover={{ color: '#FFFFFF', transition: { duration: 0.2 } }}
                            className="hover:underline"
                        >
                            Privacy Policy
                        </motion.a>
                        <motion.a
                            href="#terms"
                            whileHover={{ color: '#FFFFFF', transition: { duration: 0.2 } }}
                            className="hover:underline"
                        >
                            Terms of Service
                        </motion.a>
                        <motion.a
                            href="#cookies"
                            whileHover={{ color: '#FFFFFF', transition: { duration: 0.2 } }}
                            className="hover:underline"
                        >
                            Cookie Policy
                        </motion.a>
                    </div>
                </motion.div>
            </motion.div>
        </footer>
    );
};

export default LandingFooter;