import React from 'react'

const GlassSection = ({ title, isActive, children }) => (
    <div className={`transition-all duration-300 ${isActive ? 'block' : 'hidden'}`}>
        <div className="relative rounded-2xl overflow-hidden backdrop-blur-md mb-6">
            <div className="absolute inset-0 bg-white/60 -z-10"></div>
            <div className="absolute inset-0 border border-white/50 rounded-2xl"></div>
            <div className="p-6">
                <h2 className="text-xl font-bold mb-5 bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-purple-600">
                    {title}
                </h2>
                <div className="space-y-1">
                    {children}
                </div>
            </div>
        </div>
    </div>
);

export default GlassSection
