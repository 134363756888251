import React from 'react';
import Loading from '../elements/Loading';

const CustomerInfo = ({ customer }) => {
    if (!customer) {
        return <Loading />;
    }

    // Glass Card Component
    const GlassCard = ({ title, children }) => (
        <div className="relative mb-6 overflow-hidden rounded-2xl backdrop-blur-md">
            <div className="absolute inset-0 bg-white/50 -z-10"></div>
            <div className="absolute inset-0 border border-white/50 rounded-2xl"></div>
            <div className="p-6">
                <h2 className="text-xl font-bold mb-5 bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-purple-600">
                    {title}
                </h2>
                <div className="relative z-10">
                    {children}
                </div>
            </div>
        </div>
    );

    // Glass Detail Item Component
    const DetailItem = ({ label, value }) => (
        <div className="mb-5">
            <p className="text-sm font-medium text-gray-500 mb-1">{label}</p>
            <p className="text-lg font-medium text-gray-800">{value || 'N/A'}</p>
        </div>
    );

    return (
        <div className="w-full px-1">
            {/* Personal Details */}
            <GlassCard title="Personal Details">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
                    <DetailItem
                        label="Full Name"
                        value={`${customer.first_name} ${customer.last_name}`}
                    />
                    <DetailItem
                        label="Gender"
                        value={customer.gender}
                    />
                    <DetailItem
                        label="Date of Birth"
                        value={customer.dob}
                    />
                    <DetailItem
                        label="ID Number"
                        value={customer.id_number}
                    />
                </div>
            </GlassCard>

            {/* Address Details */}
            <GlassCard title="Address Information">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
                    <DetailItem
                        label="City"
                        value={customer.city}
                    />
                    <DetailItem
                        label="State/Province"
                        value={customer.state}
                    />
                    <DetailItem
                        label="Country"
                        value={customer.country}
                    />
                    <DetailItem
                        label="Nationality"
                        value={customer.nationality}
                    />
                </div>
            </GlassCard>

            {/* Contact Details */}
            <GlassCard title="Contact Information">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <DetailItem
                        label="Email Address"
                        value={customer.email}
                    />
                    <DetailItem
                        label="Phone Number"
                        value={customer.phone_number}
                    />
                </div>
            </GlassCard>

            {/* Next of Kin */}
            <GlassCard title="Next of Kin">
                <div className="mb-6">
                    <h3 className="text-base font-semibold text-gray-700 mb-4 flex items-center">
                        <span className="inline-block w-2 h-2 rounded-full bg-blue-500 mr-2"></span>
                        Primary Contact
                    </h3>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 ml-4">
                        <DetailItem
                            label="Name"
                            value={customer?.next_of_kin1_name}
                        />
                        <DetailItem
                            label="Phone"
                            value={customer?.next_of_kin1_phone}
                        />
                        <DetailItem
                            label="Email"
                            value={customer?.next_of_kin1_email}
                        />
                        <DetailItem
                            label="ID Number"
                            value={customer?.next_of_kin1_idNo}
                        />
                        <DetailItem
                            label="Relationship"
                            value={customer?.next_of_kin1_relationship}
                        />
                    </div>
                </div>

                <div>
                    <h3 className="text-base font-semibold text-gray-700 mb-4 flex items-center">
                        <span className="inline-block w-2 h-2 rounded-full bg-purple-500 mr-2"></span>
                        Secondary Contact
                    </h3>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 ml-4">
                        <DetailItem
                            label="Name"
                            value={customer?.next_of_kin2_name}
                        />
                        <DetailItem
                            label="Phone"
                            value={customer?.next_of_kin2_phone}
                        />
                        <DetailItem
                            label="Email"
                            value={customer?.next_of_kin2_email}
                        />
                        <DetailItem
                            label="ID Number"
                            value={customer?.next_of_kin2_idNo}
                        />
                        <DetailItem
                            label="Relationship"
                            value={customer?.next_of_kin2_relationship}
                        />
                    </div>
                </div>
            </GlassCard>
        </div>
    );
};

export default CustomerInfo;