import React, { useEffect, useState, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { motion, AnimatePresence, useScroll, useTransform } from 'framer-motion';
import logo from '../assets/miles_logo.svg';
import img from '../assets/land.png';
import { fetchProjects } from '../utils/api/api';
import toast from 'react-hot-toast';
import TestimonialSlider from '../components/TestimonialSlider';
import FAQSection from '../components/FAQSection';
import ContactUs from '../components/ContactUs';
import LandingFooter from '../components/LandiFooter';

// Fintech-inspired icon components
const ChartIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="3" y="12" width="4" height="8" rx="1" fill="currentColor" fillOpacity="0.6" />
    <rect x="10" y="8" width="4" height="12" rx="1" fill="currentColor" fillOpacity="0.8" />
    <rect x="17" y="4" width="4" height="16" rx="1" fill="currentColor" />
  </svg>
);

const ShieldIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 2L3 7V12C3 16.97 7.03 21 12 21C16.97 21 21 16.97 21 12V7L12 2Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" fill="currentColor" fillOpacity="0.1" />
    <path d="M12 16C14.2091 16 16 14.2091 16 12C16 9.79086 14.2091 8 12 8C9.79086 8 8 9.79086 8 12C8 14.2091 9.79086 16 12 16Z" fill="currentColor" />
  </svg>
);

const LeafIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6 21C7.5 19.4 9 17.5 9 14C9 10.5 12 7.5 16 7L18 3C18 3 14 5.5 11 5.5C8 5.5 6 3 6 3C6 3 7 7.5 7 10.5C7 13.5 4.5 17 3 18.5" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M18 15C18 15 14.5 19 10.5 19C8.16 19 6.15 17.72 5 16" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

const Index = () => {
  const [projects, setProjects] = useState([]);
  const location = useLocation();
  const [scrolled, setScrolled] = useState(false);
  const { scrollYProgress } = useScroll();
  const heroRef = useRef(null);
  const opacity = useTransform(scrollYProgress, [0, 0.2], [1, 0]);

  const getProjects = async () => {
    try {
      const response = await fetchProjects();
      setProjects(response.projects);
    } catch (error) {
      toast.error("Request failed");
    }
  }

  useEffect(() => {
    getProjects();

    const handleScroll = () => {
      setScrolled(window.scrollY > 20);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const isActive = (path) =>
    location.pathname === path ? 'border-b-2 border-white font-medium text-white' : 'text-gray-100';

  // Animated variants for Framer Motion
  const fadeIn = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.6, ease: [0.22, 1, 0.36, 1] }
    }
  };

  const staggerContainer = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const cardHover = {
    rest: { scale: 1 },
    hover: {
      scale: 1.03,
      boxShadow: "0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)",
      transition: { type: "spring", stiffness: 300, damping: 20 }
    }
  };

  return (
    <div className='relative tracking-wide overflow-hidden bg-gray-50/30'>
      {/* Animated background gradient */}
      <div className="fixed inset-0 z-0 overflow-hidden">
        <motion.div
          animate={{
            rotate: [0, 360],
            scale: [1, 1.2, 1],
            opacity: [0.05, 0.1, 0.05]
          }}
          transition={{
            duration: 25,
            ease: "linear",
            repeat: Infinity,
            repeatType: "reverse"
          }}
          className="absolute top-0 left-0 w-[80vw] h-[80vh] rounded-full bg-gradient-to-r from-primary/30 to-secondary/30 blur-3xl"
        />
        <motion.div
          animate={{
            rotate: [360, 0],
            scale: [1, 1.2, 1],
            opacity: [0.05, 0.08, 0.05]
          }}
          transition={{
            duration: 20,
            ease: "linear",
            repeat: Infinity,
            repeatType: "reverse",
            delay: 5
          }}
          className="absolute bottom-0 right-0 w-[70vw] h-[70vh] rounded-full bg-gradient-to-r from-secondary/30 to-blue-400/30 blur-3xl"
        />
      </div>

      {/* Main content */}
      <div className="relative z-10">
        <div id='#' className="relative tracking-wide flex px-[5%] w-full flex-col img-bg bg-gradient-to-b from-transparent to-black min-h-screen">
          {/* Glass Navigation Bar */}
          <motion.nav
            initial={{ y: -20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.5, ease: [0.22, 1, 0.36, 1] }}
            className={`fixed w-full left-0 px-[5%] z-50 transition-all duration-300 ${scrolled ? 'py-2 bg-black/20 backdrop-blur-xl border-b border-white/10 shadow-lg' : 'py-4 bg-transparent'
              }`}
          >
            <div className="flex justify-between items-center">
              <Link to="/" className="relative z-10">
                <motion.img
                  src={logo}
                  alt="Logo"
                  className="w-[60px]"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                />
              </Link>

              <div className="hidden md:flex items-center gap-6 text-white">
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.5, delay: 0.2 }}
                  className="flex items-center gap-6"
                >
                  <Link to="/" className={`${isActive('/')} hover:text-white transition-colors px-2 py-1 relative group`}>
                    Home
                    <motion.div
                      className="absolute bottom-0 left-0 w-0 h-0.5 bg-white rounded-full group-hover:w-full"
                      transition={{ duration: 0.3 }}
                      whileHover={{ width: '100%' }}
                    />
                  </Link>
                  <Link to="/projects" className={`${isActive('/projects')} hover:text-white transition-colors px-2 py-1 relative group`}>
                    Featured Projects
                    <motion.div
                      className="absolute bottom-0 left-0 w-0 h-0.5 bg-white rounded-full group-hover:w-full"
                      transition={{ duration: 0.3 }}
                      whileHover={{ width: '100%' }}
                    />
                  </Link>
                  <Link to="/faqs" className={`${isActive('/faqs')} hover:text-white transition-colors px-2 py-1 relative group`}>
                    FAQs
                    <motion.div
                      className="absolute bottom-0 left-0 w-0 h-0.5 bg-white rounded-full group-hover:w-full"
                      transition={{ duration: 0.3 }}
                      whileHover={{ width: '100%' }}
                    />
                  </Link>
                  <Link to="/contact" className={`${isActive('/contact')} hover:text-white transition-colors px-2 py-1 relative group`}>
                    Contact Us
                    <motion.div
                      className="absolute bottom-0 left-0 w-0 h-0.5 bg-white rounded-full group-hover:w-full"
                      transition={{ duration: 0.3 }}
                      whileHover={{ width: '100%' }}
                    />
                  </Link>
                </motion.div>

                <motion.div
                  initial={{ opacity: 0, x: 20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.5, delay: 0.3 }}
                  className="flex items-center gap-3"
                >
                  <motion.div whileHover={{ scale: 1.03 }} whileTap={{ scale: 0.97 }}>
                    <Link to="/accounts/login" className="border border-white/30 backdrop-blur-lg hover:bg-white/10 transition-all duration-300 rounded-full px-6 py-1.5 flex items-center gap-2">
                      <span>Login</span>
                    </Link>
                  </motion.div>

                  <motion.div whileHover={{ scale: 1.03 }} whileTap={{ scale: 0.97 }}>
                    <Link
                      to="/accounts/sign-up"
                      className="bg-gradient-to-r from-secondary/90 to-secondary backdrop-blur-lg shadow-lg border border-white/10 transition-all duration-300 text-white px-6 py-1.5 rounded-full flex items-center gap-2"
                    >
                      <span>Sign Up</span>
                    </Link>
                  </motion.div>
                </motion.div>
              </div>

              {/* Mobile Hamburger Icon */}
              <motion.div
                className="md:hidden flex items-center"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
              >
                <motion.button
                  onClick={toggleMenu}
                  className="text-white focus:outline-none"
                  whileTap={{ scale: 0.95 }}
                >
                  <svg
                    className="w-6 h-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d={menuOpen ? 'M6 18L18 6M6 6l12 12' : 'M4 6h16M4 12h16M4 18h16'}
                    />
                  </svg>
                </motion.button>
              </motion.div>
            </div>

            {/* Mobile Menu with AnimatePresence */}
            <AnimatePresence>
              {menuOpen && (
                <motion.div
                  initial={{ opacity: 0, y: -10, height: 0 }}
                  animate={{ opacity: 1, y: 0, height: 'auto' }}
                  exit={{ opacity: 0, y: -10, height: 0 }}
                  transition={{ duration: 0.3, ease: [0.22, 1, 0.36, 1] }}
                  className="md:hidden mt-4 bg-black/40 backdrop-blur-xl rounded-2xl p-4 border border-white/10 shadow-lg"
                >
                  <motion.div
                    variants={staggerContainer}
                    initial="hidden"
                    animate="visible"
                    className="flex flex-col space-y-4 text-white"
                  >
                    <motion.div variants={fadeIn}>
                      <Link to="/" className={`${isActive('/')} hover:bg-white/10 block rounded-xl px-4 py-3 transition-colors`} onClick={toggleMenu}>
                        Home
                      </Link>
                    </motion.div>
                    <motion.div variants={fadeIn}>
                      <Link to="/projects" className={`${isActive('/projects')} hover:bg-white/10 block rounded-xl px-4 py-3 transition-colors`} onClick={toggleMenu}>
                        Featured Projects
                      </Link>
                    </motion.div>
                    <motion.div variants={fadeIn}>
                      <Link to="/faqs" className={`${isActive('/faqs')} hover:bg-white/10 block rounded-xl px-4 py-3 transition-colors`} onClick={toggleMenu}>
                        FAQs
                      </Link>
                    </motion.div>
                    <motion.div variants={fadeIn}>
                      <Link to="/contact" className={`${isActive('/contact')} hover:bg-white/10 block rounded-xl px-4 py-3 transition-colors`} onClick={toggleMenu}>
                        Contact Us
                      </Link>
                    </motion.div>
                    <motion.div variants={fadeIn} className="pt-2 flex flex-col gap-3">
                      <Link
                        to="/accounts/login"
                        className="border border-white/30 backdrop-blur-lg hover:bg-white/10 transition-all text-center rounded-xl px-6 py-3"
                        onClick={toggleMenu}
                      >
                        Login
                      </Link>
                      <Link
                        to="/accounts/sign-up"
                        className="bg-gradient-to-r from-secondary/90 to-secondary backdrop-blur-lg shadow-lg border border-white/10 transition-all text-white text-center px-6 py-3 rounded-xl"
                        onClick={toggleMenu}
                      >
                        Sign Up
                      </Link>
                    </motion.div>
                  </motion.div>
                </motion.div>
              )}
            </AnimatePresence>
          </motion.nav>

          {/* Hero Section with Framer Motion */}
          <motion.div
            className="flex flex-col w-full md:w-1/2 pt-32 md:pt-48 pb-16"
            ref={heroRef}
            style={{ opacity }}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.7, ease: [0.22, 1, 0.36, 1] }}
          >
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.7, delay: 0.2, ease: [0.22, 1, 0.36, 1] }}
              className="relative"
            >
              <div className="absolute -left-6 -top-6 w-20 h-20 bg-secondary/20 rounded-full blur-xl"></div>
              <h1 className="text-white font-semibold text-[30px] md:text-[54px] leading-tight">
                Find your perfect property with confidence and expert guidance
              </h1>
            </motion.div>

            <motion.p
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.7, delay: 0.4, ease: [0.22, 1, 0.36, 1] }}
              className="text-white text-[20px] font-medium my-6"
            >
              Discover the perfect property with expert support every step on the way
            </motion.p>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.7, delay: 0.6, ease: [0.22, 1, 0.36, 1] }}
              whileHover={{ scale: 1.03 }}
              whileTap={{ scale: 0.97 }}
            >
              <Link
                to="/accounts/sign-up"
                className="bg-gradient-to-r from-secondary/90 to-secondary backdrop-blur-lg border border-white/10 shadow-lg hover:shadow-xl transition-all text-white font-medium w-fit uppercase text-[14px] px-8 py-3 rounded-full inline-flex items-center gap-2"
              >
                <span>Sign up now</span>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M4.16669 10H15.8334" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M10.8334 5L15.8334 10L10.8334 15" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </Link>
            </motion.div>

            {/* Stats display - fintech element */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.7, delay: 0.8, ease: [0.22, 1, 0.36, 1] }}
              className="mt-12 flex flex-wrap gap-6"
            >
              <div className="bg-white/10 backdrop-blur-xl border border-white/20 rounded-2xl p-4 flex flex-col">
                <span className="text-white/60 text-sm">Properties</span>
                <span className="text-white text-2xl font-semibold">500+</span>
              </div>
              <div className="bg-white/10 backdrop-blur-xl border border-white/20 rounded-2xl p-4 flex flex-col">
                <span className="text-white/60 text-sm">Happy Clients</span>
                <span className="text-white text-2xl font-semibold">1,200+</span>
              </div>
              <div className="bg-white/10 backdrop-blur-xl border border-white/20 rounded-2xl p-4 flex flex-col">
                <span className="text-white/60 text-sm">Success Rate</span>
                <span className="text-white text-2xl font-semibold">98%</span>
              </div>
            </motion.div>
          </motion.div>
        </div>

        {/* About Section with Framer Motion */}
        <motion.div
          id="about"
          className="w-full justify-between flex py-20 flex-col md:flex-row px-[5%] gap-16 md:gap-24 relative"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true, margin: "-100px" }}
          transition={{ duration: 0.7 }}
        >
          <motion.div
            className="flex w-full md:w-[50%] flex-col gap-6"
            initial={{ opacity: 0, x: -20 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true, margin: "-100px" }}
            transition={{ duration: 0.7, delay: 0.2 }}
          >
            <div className="flex items-center gap-3">
              <div className="w-[150px] bg-primary h-[2px]" />
              <p className="text-primary font-medium text-[13px]">Why choose us</p>
            </div>
            <div className="relative">
              <div className="absolute -left-4 -top-4 w-16 h-16 bg-primary/20 rounded-full blur-xl"></div>
              <h2 className="text-[28px] md:text-[36px] font-semibold relative z-10">
                Discover exceptional land for your next project
              </h2>
            </div>

            <motion.div
              className="relative backdrop-blur-xl bg-white/40 rounded-3xl p-8 border border-white/50 shadow-lg overflow-hidden"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true, margin: "-100px" }}
              transition={{ duration: 0.7, delay: 0.4 }}
            >
              <div className="absolute -right-20 -bottom-20 w-40 h-40 bg-primary/10 rounded-full blur-3xl"></div>
              <p className="text-[16px] font-light text-black relative z-10">
                We offer a diverse selection of prime land, perfect for a wide range of purposes, including residential, commercial, and developmental projects. Each plot in our portfolio has been carefully selected to provide you with the ideal foundation for your vision.
              </p>
              <p className="text-[16px] font-light text-black mt-4 relative z-10">
                Our land options cater to various needs, from small parcels in growing communities to larger plots in prime locations, offering endless possibilities for investors and developers alike.
              </p>

              {/* Fintech-inspired feature highlights */}
              <div className="mt-8 grid grid-cols-1 sm:grid-cols-3 gap-6 relative z-10">
                <div className="flex items-start gap-3">
                  <div className="bg-primary/20 text-primary p-2 rounded-full">
                    <ChartIcon />
                  </div>
                  <div>
                    <h4 className="font-medium text-black">High ROI</h4>
                    <p className="text-sm text-gray-600">Premium investment returns</p>
                  </div>
                </div>
                <div className="flex items-start gap-3">
                  <div className="bg-secondary/20 text-secondary p-2 rounded-full">
                    <ShieldIcon />
                  </div>
                  <div>
                    <h4 className="font-medium text-black">Secure Deals</h4>
                    <p className="text-sm text-gray-600">Transparent transactions</p>
                  </div>
                </div>
                <div className="flex items-start gap-3">
                  <div className="bg-green-500/20 text-green-600 p-2 rounded-full">
                    <LeafIcon />
                  </div>
                  <div>
                    <h4 className="font-medium text-black">Sustainable</h4>
                    <p className="text-sm text-gray-600">Eco-friendly options</p>
                  </div>
                </div>
              </div>
            </motion.div>
          </motion.div>

          <motion.div
            className="w-full md:w-[50%] relative"
            initial={{ opacity: 0, x: 20 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true, margin: "-100px" }}
            transition={{ duration: 0.7, delay: 0.3 }}
          >
            <motion.div
              className="absolute inset-0 bg-gradient-to-r from-primary/10 to-secondary/10 rounded-3xl transform rotate-3 scale-105"
              animate={{ rotate: [3, 2, 3], scale: [1.05, 1.03, 1.05] }}
              transition={{ duration: 8, repeat: Infinity, repeatType: "reverse" }}
            ></motion.div>
            <motion.div
              className="relative overflow-hidden rounded-3xl shadow-xl border border-white/50"
              whileHover={{ scale: 1.02 }}
              transition={{ duration: 0.5 }}
            >
              <img src={img} alt="Land" className="w-full h-full object-cover" />

              {/* iOS 18-style overlay effect */}
              <div className="absolute inset-0 bg-gradient-to-t from-black/40 to-transparent"></div>
              <div className="absolute bottom-0 left-0 w-full p-6 text-white">
                <motion.div
                  initial={{ opacity: 0, y: 10 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: 0.6 }}
                >
                  <span className="bg-white/20 backdrop-blur-md px-3 py-1 rounded-full text-xs font-medium">Featured</span>
                  <h3 className="text-xl font-semibold mt-2">Premium Locations</h3>
                  <p className="text-sm text-white/80 mt-1">Exclusive properties in high-growth areas</p>
                </motion.div>
              </div>
            </motion.div>

            {/* Chart overlay - fintech element */}
            <motion.div
              className="absolute top-6 right-6 bg-white/90 backdrop-blur-xl rounded-2xl shadow-lg p-4 border border-white/50"
              initial={{ opacity: 0, scale: 0.9 }}
              whileInView={{ opacity: 1, scale: 1 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: 0.8 }}
            >
              <p className="text-xs font-medium text-gray-600">Property Value Growth</p>
              <div className="mt-2 h-10 flex items-end gap-1">
                <motion.div
                  className="w-3 bg-gradient-to-t from-primary to-primary/70 rounded-t-sm"
                  initial={{ height: 0 }}
                  whileInView={{ height: '40%' }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: 0.9 }}
                ></motion.div>
                <motion.div
                  className="w-3 bg-gradient-to-t from-primary to-primary/70 rounded-t-sm"
                  initial={{ height: 0 }}
                  whileInView={{ height: '60%' }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: 1.0 }}
                ></motion.div>
                <motion.div
                  className="w-3 bg-gradient-to-t from-primary to-primary/70 rounded-t-sm"
                  initial={{ height: 0 }}
                  whileInView={{ height: '50%' }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: 1.1 }}
                ></motion.div>
                <motion.div
                  className="w-3 bg-gradient-to-t from-primary to-primary/70 rounded-t-sm"
                  initial={{ height: 0 }}
                  whileInView={{ height: '80%' }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: 1.2 }}
                ></motion.div>
                <motion.div
                  className="w-3 bg-gradient-to-t from-secondary to-secondary/70 rounded-t-sm"
                  initial={{ height: 0 }}
                  whileInView={{ height: '100%' }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: 1.3 }}
                ></motion.div>
              </div>
              <p className="text-xs mt-2 text-green-600 font-medium">+24.5% increase</p>
            </motion.div>
          </motion.div>
        </motion.div>

        {/* Featured Projects Section with Framer Motion */}
        <motion.div
          id="projects"
          className="relative bg-gray-50/60 backdrop-blur-xl flex flex-col justify-center items-center gap-3 py-20 px-[5%]"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true, margin: "-100px" }}
          transition={{ duration: 0.7 }}
        >
          <div className="absolute -top-40 right-20 w-80 h-80 bg-secondary/10 rounded-full blur-3xl"></div>
          <div className="absolute -bottom-40 left-20 w-80 h-80 bg-primary/10 rounded-full blur-3xl"></div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true, margin: "-100px" }}
            transition={{ duration: 0.7 }}
            className="flex flex-col items-center"
          >
            <div className="w-[150px] bg-primary h-[2px]" />
            <p className="text-primary font-semibold text-[15px] mt-3">Featured projects</p>
            <h2 className="text-[28px] md:text-[36px] font-semibold text-center max-w-2xl mt-3">
              Discover our outstanding prime land selections
            </h2>
          </motion.div>

          <motion.div
            className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 mt-10 w-full"
            variants={staggerContainer}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, margin: "-100px" }}
          >
            {projects.slice(0, 4).map((project, index) => (
              <motion.div
                key={index}
                variants={fadeIn}
                whileHover="hover"
                initial="rest"
                animate="rest"
                className="group"
              >
                <motion.div
                  variants={cardHover}
                  className="h-full relative backdrop-blur-xl overflow-hidden rounded-3xl border border-white/50 shadow-lg"
                >
                  {/* iOS 18-style card */}
                  <div className="relative h-48 overflow-hidden">
                    <motion.img
                      className="h-full w-full object-cover"
                      src={project?.images?.file || 'https://imgs.search.brave.com/L0mOyBBDOxVD6jmTiwNh93bAa_8D_KweRLE_oYnq-Kg/rs:fit:500:0:0:0/g:ce/aHR0cHM6Ly9jZG4u/dmVjdG9yc3RvY2su/Y29tL2kvcHJldmll/dy0xeC82MS80Ni9n/YWxsZXJ5LWljb24t/c2lsaG91ZXR0ZS1v/Zi1hbi1pbWFnZS1w/aG90by1hbGJ1bS12/ZWN0b3ItNDU0MzYx/NDYuanBn'}
                      alt={project.title}
                      whileHover={{ scale: 1.1 }}
                      transition={{ duration: 1 }}
                    />
                    <div className="absolute inset-0 bg-gradient-to-t from-black/60 via-black/20 to-transparent"></div>
                    <div className="absolute top-4 right-4">
                      <span className="bg-white/20 backdrop-blur-md px-3 py-1 rounded-full text-xs font-medium text-white">Premium</span>
                    </div>
                  </div>

                  <div className="p-6 bg-white/70 backdrop-blur-xl">
                    <h3 className="text-black font-medium text-xl">{project.title}</h3>
                    <p className="text-gray-600 mt-2 text-sm line-clamp-2">
                      {project.description || 'Discover this amazing property opportunity perfect for your next project.'}
                    </p>

                    {/* Fintech price indicator */}
                    <div className="mt-4 flex items-center justify-between">
                      <div className="flex flex-col">
                        <span className="text-xs text-gray-500">Starting from</span>
                        <span className="text-lg font-semibold text-primary">$240,000</span>
                      </div>
                      <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
                        <Link
                          to={`/projects/${project.id}`}
                          className="px-4 py-2 rounded-full bg-primary/10 text-primary border border-primary/30 hover:bg-primary/20 transition-colors text-sm font-medium inline-flex items-center gap-1"
                        >
                          <span>Details</span>
                          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.33331 8H12.6666" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M8.66669 4L12.6667 8L8.66669 12" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                          </svg>
                        </Link>
                      </motion.div>
                    </div>
                  </div>
                </motion.div>
              </motion.div>
            ))}
          </motion.div>
        </motion.div>

        <div className="relative bg-white/60 backdrop-blur-xl">
          <div className="absolute inset-0 bg-gradient-to-r from-primary/5 to-secondary/5"></div>
          <TestimonialSlider />
        </div>

        <div id="faqs" className="relative bg-gray-50/60 backdrop-blur-xl">
          <div className="absolute -top-40 left-20 w-80 h-80 bg-primary/10 rounded-full blur-3xl"></div>
          <FAQSection />
        </div>

        <div id="contact" className="relative bg-white/60 backdrop-blur-xl">
          <div className="absolute -top-40 right-20 w-80 h-80 bg-secondary/10 rounded-full blur-3xl"></div>
          <ContactUs />
        </div>

        <div className="relative">
          <div className="absolute inset-0 bg-gradient-to-b from-gray-900/80 to-black/90 backdrop-blur-xl"></div>
          <div className="relative z-10">
            <LandingFooter />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;