import React, { useEffect, useState } from 'react';
import { MdAdd, MdOutlineAttachment, MdRemoveRedEye, MdClose } from 'react-icons/md';
import { fetchCustomerDocs } from '../utils/api/api';
import toast from 'react-hot-toast';
import Cookies from 'js-cookie';

const CustomerKYC = () => {
    const [documents, setDocuments] = useState([]);
    const [title, setTitle] = useState('');
    const [note, setNote] = useState('');
    const [doc, setDoc] = useState(null);
    const [loading, setLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedDocument, setSelectedDocument] = useState(null);
    const id = Cookies.get('user_id');

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setDoc(file);
        }
    };

    const getDocs = async () => {
        try {
            const data = await fetchCustomerDocs(id);
            setDocuments(data.documents);
        } catch (err) {
            toast.error('Failed to get documents.');
        }
    }

    const handleViewDocument = (document) => {
        setSelectedDocument(document);
        setIsModalOpen(true);
    };

    const handleAddDocument = () => {
        setSelectedDocument(null);
        setIsModalOpen(true);
    };

    useEffect(() => {
        getDocs();
    }, [id]);

    // Glass Morphism Modal
    const GlassModal = ({ title, onClose, children }) => (
        <div className="fixed inset-0 z-50 flex items-center justify-center p-4">
            <div className="absolute inset-0 bg-black/20 backdrop-blur-md" onClick={onClose}></div>
            <div className="relative w-full max-w-2xl max-h-[90vh] overflow-auto rounded-3xl backdrop-blur-xl">
                <div className="absolute inset-0 bg-white/70 -z-10 rounded-3xl"></div>
                <div className="absolute inset-0 border border-white/50 rounded-3xl"></div>

                <div className="p-6 border-b border-gray-100/80">
                    <div className="flex items-center justify-between">
                        <h2 className="text-xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-purple-600">
                            {title}
                        </h2>
                        <button
                            onClick={onClose}
                            className="w-8 h-8 rounded-full bg-white/50 backdrop-blur-md flex items-center justify-center text-gray-700 hover:bg-white/70 border border-white/50"
                        >
                            <MdClose size={18} />
                        </button>
                    </div>
                </div>

                <div className="p-6 relative">
                    {children}
                </div>
            </div>
        </div>
    );

    return (
        <div className='w-full'>
            <div className="w-full flex items-center justify-between mb-6">
                <div className="flex flex-col">
                    <p className="text-xl font-semibold bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-purple-600">Documents</p>
                    <p className="text-sm text-gray-600">Add notes & documents to your profile</p>
                </div>
                <button
                    onClick={handleAddDocument}
                    className="px-4 py-2 rounded-full text-sm font-medium text-white bg-gradient-to-r from-blue-500/90 to-purple-500/90 backdrop-blur-md border border-white/30 hover:shadow-lg transition-all duration-300 flex items-center gap-1"
                >
                    <MdAdd size={18} />
                    Add Document
                </button>
            </div>

            {/* Glass Table */}
            <div className="relative rounded-2xl overflow-hidden backdrop-blur-md border border-white/50">
                <div className="absolute inset-0 bg-white/40 -z-10"></div>
                <div className="overflow-x-auto p-4">
                    <table className="min-w-full table-auto">
                        <thead>
                            <tr className="text-gray-700 border-b border-gray-200/60">
                                <th className="p-3 text-left">Title</th>
                                <th className="p-3 text-left">Note</th>
                                <th className="p-3 text-left">Date</th>
                                <th className="p-3 text-left">Attachment</th>
                                <th className="p-3 text-left">Actions</th>
                            </tr>
                        </thead>
                        <tbody className="text-gray-700 text-sm">
                            {documents.length === 0 ? (
                                <tr>
                                    <td colSpan="5" className="py-6 text-center text-gray-500">
                                        {loading ? 'Loading, please wait' : 'No documents found'}
                                    </td>
                                </tr>
                            ) : (
                                documents.map((document, index) => (
                                    <tr
                                        key={index}
                                        className="border-b border-gray-200/30 hover:bg-white/30 transition-colors"
                                    >
                                        <td className="p-3">
                                            <span className="font-medium">{document.title}</span>
                                        </td>
                                        <td className="p-3">
                                            {document.note.split(' ').slice(0, 5).join(' ')}...
                                        </td>
                                        <td className="p-3">
                                            <span>{new Date(document.createdAt).toLocaleDateString()}</span>
                                        </td>
                                        <td className="p-3">
                                            <a
                                                href={document.file}
                                                target='_blank'
                                                rel="noreferrer"
                                                className="w-9 h-9 rounded-full bg-blue-100/50 backdrop-blur-sm flex items-center justify-center text-blue-600"
                                            >
                                                <MdOutlineAttachment size={18} />
                                            </a>
                                        </td>
                                        <td className="p-3">
                                            <button
                                                onClick={() => handleViewDocument(document)}
                                                className="w-9 h-9 rounded-full bg-green-100/50 backdrop-blur-sm flex items-center justify-center text-green-600"
                                            >
                                                <MdRemoveRedEye size={18} />
                                            </button>
                                        </td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </table>
                </div>
            </div>

            {/* Document Modal */}
            {isModalOpen && (
                <GlassModal
                    title={selectedDocument ? "Document Details" : "Add Document"}
                    onClose={() => setIsModalOpen(false)}
                >
                    {selectedDocument ? (
                        <div className="text-gray-700">
                            <div className="mb-6">
                                <h3 className="text-sm font-medium text-gray-500 mb-1">Title</h3>
                                <p className="text-lg font-medium">{selectedDocument.title}</p>
                            </div>
                            <div className="mb-6">
                                <h3 className="text-sm font-medium text-gray-500 mb-1">Note</h3>
                                <p className="text-base">{selectedDocument.note}</p>
                            </div>
                            <div className="mt-8">
                                <a
                                    href={selectedDocument.file}
                                    target='_blank'
                                    rel="noreferrer"
                                    className="px-5 py-2.5 rounded-xl text-white bg-gradient-to-r from-blue-500/90 to-purple-500/90 backdrop-blur-md border border-white/30 hover:shadow-lg transition-all flex items-center gap-2 w-fit"
                                >
                                    <MdOutlineAttachment size={18} />
                                    View Attachment
                                </a>
                            </div>
                        </div>
                    ) : (
                        <form>
                            <div className="mb-5">
                                <label className="block text-sm font-medium text-gray-700 mb-2">Title</label>
                                <div className="relative">
                                    <input
                                        type="text"
                                        value={title}
                                        onChange={(e) => setTitle(e.target.value)}
                                        placeholder="Document Title"
                                        className="w-full h-12 pl-4 pr-10 rounded-xl bg-white/50 backdrop-blur-md border border-white/50 focus:border-blue-300 focus:ring-2 focus:ring-blue-100 outline-none transition-all text-gray-800"
                                    />
                                </div>
                            </div>
                            <div className="mb-5">
                                <label className="block text-sm font-medium text-gray-700 mb-2">Note</label>
                                <div className="relative">
                                    <textarea
                                        value={note}
                                        onChange={(e) => setNote(e.target.value)}
                                        placeholder="Add a note about this document"
                                        rows={4}
                                        className="w-full p-4 rounded-xl bg-white/50 backdrop-blur-md border border-white/50 focus:border-blue-300 focus:ring-2 focus:ring-blue-100 outline-none transition-all text-gray-800"
                                    />
                                </div>
                            </div>
                            <div className="mb-5">
                                <label className="block text-sm font-medium text-gray-700 mb-2">Document</label>
                                <div className="relative">
                                    <div className="flex items-center justify-center w-full">
                                        <label className="flex flex-col items-center justify-center w-full h-32 border-2 border-dashed rounded-xl cursor-pointer bg-white/30 border-gray-300 hover:bg-white/40">
                                            <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                                <MdOutlineAttachment size={28} className="text-gray-400 mb-2" />
                                                <p className="mb-2 text-sm text-gray-500">
                                                    <span className="font-medium text-blue-600">Click to upload</span> or drag and drop
                                                </p>
                                                <p className="text-xs text-gray-500">PDF, DOCX, JPEG, PNG (MAX. 10MB)</p>
                                            </div>
                                            <input
                                                type="file"
                                                onChange={handleFileChange}
                                                className="hidden"
                                            />
                                        </label>
                                    </div>
                                    {doc && (
                                        <div className="mt-3 p-2 bg-blue-50/70 backdrop-blur-sm rounded-lg flex items-center justify-between">
                                            <span className="text-sm text-blue-700 truncate">{doc.name}</span>
                                            <button
                                                type="button"
                                                onClick={() => setDoc(null)}
                                                className="text-red-500"
                                            >
                                                <MdClose size={16} />
                                            </button>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="flex items-center justify-end gap-4 mt-8">
                                <button
                                    type="button"
                                    onClick={() => setIsModalOpen(false)}
                                    className="px-6 py-3 rounded-xl font-medium text-gray-700 bg-white/40 backdrop-blur-md border border-white/50 hover:bg-white/60 transition-all"
                                >
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    disabled={loading}
                                    className="px-6 py-3 rounded-xl font-medium text-white bg-gradient-to-r from-blue-500/90 to-purple-500/90 backdrop-blur-md border border-white/30 hover:shadow-lg transition-all"
                                >
                                    {loading ? 'Uploading...' : 'Add Document'}
                                </button>
                            </div>
                        </form>
                    )}
                </GlassModal>
            )}
        </div>
    );
};

export default CustomerKYC;