import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import moment from 'moment';
import { FaArrowDown, FaArrowUp } from 'react-icons/fa6';
import { HiOutlineCash, HiOutlineClock, HiOutlineBell, HiOutlineExclamation, HiOutlineDocumentText, HiOutlineChevronRight, HiOutlineChevronDown } from 'react-icons/hi';
import Cookies from 'js-cookie';
import { fetchCustomerInvoices, fetchCustomerOrders, fetchCustomerWallet } from '../utils/api/api';
import toast from 'react-hot-toast';
import { Link, useNavigate } from 'react-router-dom';
import api from '../utils/api/axiosInstance';
import Loading from '../elements/Loading';

const WalletBalance = () => {
    const [wallet, setWallet] = useState([]);
    const [invoices, setInvoices] = useState([]);
    const [paymentStatus, setPaymentStatus] = useState({ message: '', dueIn: null });
    const customer_id = Cookies.get('user_id');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [orders, setOrders] = useState([]);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [canPayFromWallet, setCanPayFromWallet] = useState(false);
    const [isOrderDropdownOpen, setIsOrderDropdownOpen] = useState(false);
    const [showAllReminders, setShowAllReminders] = useState(false);

    // Animation variants
    const containerVariants = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                staggerChildren: 0.1
            }
        }
    };

    const itemVariants = {
        hidden: { opacity: 0, y: 20 },
        visible: {
            opacity: 1,
            y: 0,
            transition: { duration: 0.5, ease: [0.22, 1, 0.36, 1] }
        }
    };

    const buttonVariants = {
        hover: {
            scale: 1.03,
            boxShadow: "0 10px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)",
            transition: { duration: 0.2 }
        },
        tap: { scale: 0.97 }
    };

    // Fetch wallet data
    const getWallet = async () => {
        try {
            const response = await fetchCustomerWallet(customer_id);
            setWallet(response.wallet);
        } catch (error) {
            toast.error(error.message || "Request failed");
        }
    }

    // Fetch invoices and evaluate payment status
    const getInvoices = async () => {
        const payload = {
            page: 0,
            pageSize: 0,
            customer_id: customer_id,
        }
        try {
            const response = await fetchCustomerInvoices(payload);
            setInvoices(response.invoices);
            evaluatePaymentStatus(response.invoices);
        } catch (error) {
            toast.error(error.message || "Failed to get invoices");
        }
    }

    const evaluatePaymentStatus = (invoices) => {
        const today = moment();
        let upcomingPayment = null;
        let overduePayment = null;

        invoices.forEach((invoice) => {
            const dueDate = moment(invoice.payment_due_date);

            if (invoice.payment_status === 'pending' && dueDate.isBefore(today)) {
                overduePayment = dueDate;
            }

            if (!overduePayment && invoice.payment_status === 'pending' && dueDate.isAfter(today)) {
                if (!upcomingPayment || dueDate.isBefore(upcomingPayment)) {
                    upcomingPayment = dueDate;
                }
            }
        });

        if (overduePayment) {
            setPaymentStatus({ message: 'You have overdue payments.', dueIn: overduePayment, isOverdue: true });
        } else if (upcomingPayment) {
            const daysUntilDue = upcomingPayment.diff(today, 'days');
            setPaymentStatus({
                message: `Next payment in ${daysUntilDue} day${daysUntilDue > 1 ? 's' : ''}.`,
                dueIn: upcomingPayment,
                isOverdue: false
            });
        } else {
            setPaymentStatus({ message: 'No pending payments.', dueIn: null, isOverdue: false });
        }
    };

    // Fetch customer orders
    const getOrders = async () => {
        try {
            const response = await fetchCustomerOrders({
                customer_id: customer_id,
                page: 1,
                pageSize: 600,
            });
            setOrders(response.orders);
        } catch (error) {
            toast.error(error.message || "Failed to get orders");
        }
    };

    // Fetch all data before rendering payment option
    const fetchData = async () => {
        setLoading(true);
        try {
            await Promise.all([getWallet(), getInvoices(), getOrders()]);

            const eligibleOrders = orders.filter(order =>
                (order.payment_status === 'pending' || order.payment_status === 'partially_paid')
            );

            if (eligibleOrders.length > 0 && Number(wallet.balance) > 0) {
                setCanPayFromWallet(true);
            } else {
                setCanPayFromWallet(false);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    // Handle wallet payment
    const payFromWallet = async () => {
        setLoading(true);
        if (selectedOrder) {
            const payload = {
                order_id: selectedOrder.id,
                customer_id: selectedOrder.customer_id,
            };

            try {
                const response = await api.post('/payments/pay-from-wallet', payload);
                toast.success("Payment processed successfully.");
                fetchData(); // Refresh data after payment
                setIsOrderDropdownOpen(false);
            } catch (error) {
                toast.error("Failed to process payment.");
                console.error(error);
            } finally {
                setLoading(false);
            }
        } else {
            toast.error("Please select an order to pay.");
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    // Sample reminders and activities data for display purposes
    const reminders = [
        {
            id: 1,
            title: "Site viewing reminder",
            description: "Wisdom garden, kilifi",
            date: "24th Oct, 2024",
            priority: "high"
        },
        {
            id: 2,
            title: "Payment due",
            description: "Malindi beachfront plot",
            date: "30th Oct, 2024",
            priority: "medium"
        },
        {
            id: 3,
            title: "Document submission",
            description: "KYC verification pending",
            date: "2nd Nov, 2024",
            priority: "low"
        }
    ];

    const recentActivities = [
        {
            id: 1,
            action: "Payment made",
            details: "KES 25,000 for Wisdom Gardens",
            time: "Today, 09:45 AM"
        },
        {
            id: 2,
            action: "Document uploaded",
            details: "ID verification completed",
            time: "Yesterday, 02:30 PM"
        },
        {
            id: 3,
            action: "Property viewed",
            details: "Beach plot in Malindi",
            time: "Oct 25, 11:20 AM"
        }
    ];

    // Get priority color based on priority level
    const getPriorityColor = (priority) => {
        switch (priority) {
            case 'high':
                return "bg-red-100 text-red-500";
            case 'medium':
                return "bg-yellow-100 text-yellow-500";
            case 'low':
                return "bg-blue-100 text-blue-500";
            default:
                return "bg-gray-100 text-gray-500";
        }
    };

    return (
        <motion.div
            className="flex flex-col w-full md:w-[35%]"
            variants={containerVariants}
            initial="hidden"
            animate="visible"
        >
            {loading && <Loading />}

            {/* Wallet Balance Card */}
            <motion.div
                variants={itemVariants}
                className="w-full rounded-2xl backdrop-blur-xl bg-white/60 border border-white/50 shadow-lg overflow-hidden"
            >
                {/* Wallet Header */}
                <div className="relative p-5 border-b border-gray-100/80">
                    <div className="absolute top-0 right-0 w-40 h-40 rounded-full bg-primary/5 blur-3xl -mr-20 -mt-20"></div>

                    <div className="flex w-full items-center justify-between relative z-10">
                        <div className="flex items-center gap-3">
                            <div className="w-10 h-10 rounded-full bg-primary/10 flex items-center justify-center">
                                <HiOutlineCash className="text-primary" size={20} />
                            </div>
                            <p className="text-gray-800 font-medium text-lg">Wallet Balance</p>
                        </div>
                        <p className="text-gray-500 text-sm">{moment().format('MMM DD, YYYY')}</p>
                    </div>

                    <div className="mt-6 relative">
                        <motion.p
                            className="text-3xl font-bold bg-gradient-to-r from-primary to-secondary bg-clip-text text-transparent"
                            initial={{ scale: 0.9, opacity: 0 }}
                            animate={{ scale: 1, opacity: 1 }}
                            transition={{ duration: 0.5, delay: 0.2 }}
                        >
                            {Number(wallet?.balance || 0).toLocaleString("en-US")}
                            <span className="text-sm ml-2 font-medium">KES</span>
                        </motion.p>
                    </div>
                </div>

                {/* Money In/Out Section */}
                <div className="p-5">
                    <div className="flex items-center w-full justify-between mb-6">
                        <div className="flex items-center gap-3">
                            <div className="w-10 h-10 rounded-full bg-green-100 flex items-center justify-center text-green-500">
                                <FaArrowDown size={16} />
                            </div>
                            <div className="flex flex-col">
                                <p className="text-gray-800 font-medium">
                                    {Number(wallet?.deposits || 0).toLocaleString("en-US")}
                                    <span className="text-xs ml-1 text-gray-500">KES</span>
                                </p>
                                <p className="text-xs text-gray-500">Money In</p>
                            </div>
                        </div>
                        <div className="flex items-center gap-3">
                            <div className="w-10 h-10 rounded-full bg-red-100 flex items-center justify-center text-red-500">
                                <FaArrowUp size={16} />
                            </div>
                            <div className="flex flex-col">
                                <p className="text-gray-800 font-medium">
                                    {Number(wallet?.withdrawals || 0).toLocaleString("en-US")}
                                    <span className="text-xs ml-1 text-gray-500">KES</span>
                                </p>
                                <p className="text-xs text-gray-500">Money Out</p>
                            </div>
                        </div>
                    </div>

                    {/* Action Buttons */}
                    <div className="space-y-3">
                        <motion.div
                            variants={buttonVariants}
                            whileHover="hover"
                            whileTap="tap"
                        >
                            <Link
                                to='/dashboard/statement'
                                className="block w-full py-2.5 px-4 rounded-xl bg-gradient-to-r from-primary/90 to-primary text-white font-medium text-center shadow-sm"
                            >
                                Request Statement
                            </Link>
                        </motion.div>

                        {canPayFromWallet && (
                            <>
                                <div className="relative">
                                    <motion.button
                                        onClick={() => setIsOrderDropdownOpen(!isOrderDropdownOpen)}
                                        className="flex items-center justify-between w-full py-2.5 px-4 rounded-xl backdrop-blur-md bg-white/80 border border-gray-200/80 text-gray-700 font-medium shadow-sm"
                                        variants={buttonVariants}
                                        whileHover="hover"
                                        whileTap="tap"
                                    >
                                        <span>{selectedOrder ? `${selectedOrder.property?.title_number || 'Selected Order'}` : 'Select Order'}</span>
                                        <HiOutlineChevronDown className={`transition-transform ${isOrderDropdownOpen ? 'rotate-180' : ''}`} />
                                    </motion.button>

                                    <AnimatePresence>
                                        {isOrderDropdownOpen && (
                                            <motion.div
                                                initial={{ opacity: 0, y: -10, height: 0 }}
                                                animate={{ opacity: 1, y: 0, height: 'auto' }}
                                                exit={{ opacity: 0, y: -10, height: 0 }}
                                                transition={{ duration: 0.2 }}
                                                className="absolute z-10 mt-2 w-full bg-white/90 backdrop-blur-xl rounded-xl border border-white/50 shadow-lg max-h-48 overflow-y-auto"
                                            >
                                                <div className="p-1">
                                                    {orders
                                                        .filter(order => order.payment_status === 'pending' || order.payment_status === 'partially_paid')
                                                        .map(order => (
                                                            <motion.div
                                                                key={order.id}
                                                                className={`p-3 rounded-lg cursor-pointer ${selectedOrder?.id === order.id ? 'bg-primary/10 text-primary' : 'hover:bg-gray-100'}`}
                                                                onClick={() => {
                                                                    setSelectedOrder(order);
                                                                    setIsOrderDropdownOpen(false);
                                                                }}
                                                                whileHover={{ backgroundColor: 'rgba(0,0,0,0.05)' }}
                                                            >
                                                                <div className="flex items-center justify-between">
                                                                    <span className="font-medium">{order.property?.title_number || `Order #${order.id}`}</span>
                                                                    <span className={`text-xs px-2 py-1 rounded-full ${order.payment_status === 'pending' ? 'bg-yellow-100 text-yellow-700' : 'bg-blue-100 text-blue-700'}`}>
                                                                        {order.payment_status.replace('_', ' ')}
                                                                    </span>
                                                                </div>
                                                                <p className="text-xs text-gray-500 mt-1">
                                                                    Amount: KES {Number(order.amount || 0).toLocaleString("en-US")}
                                                                </p>
                                                            </motion.div>
                                                        ))}
                                                </div>
                                            </motion.div>
                                        )}
                                    </AnimatePresence>
                                </div>

                                <motion.button
                                    onClick={payFromWallet}
                                    disabled={!selectedOrder || loading}
                                    className={`w-full py-2.5 px-4 rounded-xl text-white font-medium text-center shadow-sm ${!selectedOrder ? 'bg-gray-400' : 'bg-gradient-to-r from-secondary/90 to-secondary'}`}
                                    variants={buttonVariants}
                                    whileHover={selectedOrder ? "hover" : {}}
                                    whileTap={selectedOrder ? "tap" : {}}
                                >
                                    {loading ? 'Processing...' : 'Pay from Wallet'}
                                </motion.button>
                            </>
                        )}
                    </div>
                </div>
            </motion.div>

            {/* Payment Status Card */}
            <motion.div
                variants={itemVariants}
                className="mt-5 rounded-2xl backdrop-blur-xl bg-white/60 border border-white/50 shadow-lg p-5"
            >
                <div className="flex items-center gap-3 mb-4">
                    <div className={`w-10 h-10 rounded-full flex items-center justify-center ${paymentStatus.isOverdue ? 'bg-red-100 text-red-500' : 'bg-primary/10 text-primary'}`}>
                        <HiOutlineClock size={20} />
                    </div>
                    <p className="text-gray-800 font-medium">Payment Status</p>
                </div>

                <p className={`text-xl font-semibold ${paymentStatus.isOverdue ? 'text-red-500' : 'text-gray-800'}`}>
                    {paymentStatus.message}
                </p>

                {paymentStatus.dueIn && (
                    <p className={`text-sm mt-1 ${paymentStatus.isOverdue ? 'text-red-500' : 'text-gray-600'}`}>
                        {paymentStatus.isOverdue ? 'Payment was due on ' : 'Payment due on '}
                        <span className="font-medium">{paymentStatus.dueIn.format('DD MMM, YYYY')}</span>
                    </p>
                )}

                <motion.button
                    onClick={() => navigate('/dashboard/orders')}
                    className="mt-4 w-full py-2.5 px-4 rounded-xl bg-white/80 border border-gray-200/80 text-gray-700 font-medium shadow-sm"
                    variants={buttonVariants}
                    whileHover="hover"
                    whileTap="tap"
                >
                    Review Payment Records
                </motion.button>
            </motion.div>

            {/* Reminders */}
            <motion.div
                variants={itemVariants}
                className="mt-5 rounded-2xl backdrop-blur-xl bg-white/60 border border-white/50 shadow-lg"
            >
                <div className="p-5 border-b border-gray-100/80">
                    <div className="flex justify-between items-center">
                        <div className="flex items-center gap-3">
                            <div className="w-10 h-10 rounded-full bg-primary/10 flex items-center justify-center">
                                <HiOutlineBell className="text-primary" size={20} />
                            </div>
                            <p className="text-gray-800 font-medium">Reminders</p>
                        </div>
                        <motion.button
                            onClick={() => setShowAllReminders(!showAllReminders)}
                            className="text-primary text-sm font-medium"
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                        >
                            {showAllReminders ? 'Show Less' : 'View All'}
                        </motion.button>
                    </div>
                </div>

                <div className="p-3 max-h-60 overflow-y-auto">
                    <AnimatePresence>
                        {(showAllReminders ? reminders : reminders.slice(0, 1)).map((reminder) => (
                            <motion.div
                                key={reminder.id}
                                className="p-3 rounded-xl hover:bg-white/40 transition-colors"
                                initial={{ opacity: 0, y: 10 }}
                                animate={{ opacity: 1, y: 0 }}
                                exit={{ opacity: 0, y: -10 }}
                                transition={{ duration: 0.2 }}
                            >
                                <div className="flex items-center gap-3">
                                    <div className={`w-10 h-10 rounded-full flex items-center justify-center ${getPriorityColor(reminder.priority)}`}>
                                        <HiOutlineExclamation size={18} />
                                    </div>
                                    <div className="flex-1">
                                        <div className="flex justify-between w-full">
                                            <p className="font-medium text-gray-800">{reminder.title}</p>
                                            <p className="text-xs text-gray-500">{reminder.date}</p>
                                        </div>
                                        <p className="text-sm text-gray-600">{reminder.description}</p>
                                    </div>
                                </div>
                            </motion.div>
                        ))}
                    </AnimatePresence>
                </div>
            </motion.div>

            {/* Recent Activities */}
            <motion.div
                variants={itemVariants}
                className="mt-5 rounded-2xl backdrop-blur-xl bg-white/60 border border-white/50 shadow-lg overflow-hidden"
            >
                <div className="p-5 border-b border-gray-100/80">
                    <div className="flex items-center gap-3">
                        <div className="w-10 h-10 rounded-full bg-primary/10 flex items-center justify-center">
                            <HiOutlineDocumentText className="text-primary" size={20} />
                        </div>
                        <p className="text-gray-800 font-medium">Recent Activities</p>
                    </div>
                </div>

                <div className="divide-y divide-gray-100/80">
                    {recentActivities.map((activity) => (
                        <motion.div
                            key={activity.id}
                            className="p-4 hover:bg-white/40 transition-colors"
                            whileHover={{ x: 5 }}
                            transition={{ duration: 0.2 }}
                        >
                            <div className="flex justify-between">
                                <p className="font-medium text-gray-800">{activity.action}</p>
                                <p className="text-xs text-gray-500">{activity.time}</p>
                            </div>
                            <p className="text-sm text-gray-600 mt-1">{activity.details}</p>
                        </motion.div>
                    ))}

                    <div className="p-3">
                        <motion.button
                            className="w-full py-2 rounded-xl text-primary text-sm font-medium flex items-center justify-center gap-1"
                            whileHover={{ scale: 1.02 }}
                            whileTap={{ scale: 0.98 }}
                        >
                            <span>View Activity History</span>
                            <HiOutlineChevronRight size={16} />
                        </motion.button>
                    </div>
                </div>
            </motion.div>
        </motion.div>
    );
};

export default WalletBalance;