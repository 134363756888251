import React, { useState } from 'react';
import Slider from 'react-slick';
import { motion } from 'framer-motion';
import { FaQuoteLeft } from 'react-icons/fa';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import { RiVerifiedBadgeFill } from 'react-icons/ri';

const testimonials = [
    {
        name: 'John Mwangi',
        title: 'Property Investor',
        image: 'https://randomuser.me/api/portraits/men/32.jpg',
        description: 'Milescoop made the process of purchasing land seamless and worry-free. I secured my plot in less than a week, and the customer support was outstanding.',
        rating: 5,
        location: 'Nairobi'
    },
    {
        name: 'Grace Wanjiru',
        title: 'First-time Buyer',
        image: 'https://randomuser.me/api/portraits/women/44.jpg',
        description: 'I was hesitant at first, but Milescoop offered me transparency and a great deal on prime land in Kitengela. I now own my first piece of land, thanks to their great team.',
        rating: 5,
        location: 'Kitengela'
    },
    {
        name: 'Peter Kamau',
        title: 'Real Estate Developer',
        image: 'https://randomuser.me/api/portraits/men/41.jpg',
        description: 'The best decision I made was investing in land with Milescoop. They guided me through the entire process with integrity and professionalism.',
        rating: 4,
        location: 'Mombasa'
    },
    {
        name: 'Lucy Njoroge',
        title: 'Business Owner',
        image: 'https://randomuser.me/api/portraits/women/51.jpg',
        description: 'I am extremely satisfied with my purchase. The land I bought in Nanyuki is a great investment. I highly recommend Milescoop for their transparency and great customer service.',
        rating: 5,
        location: 'Nanyuki'
    },
    {
        name: 'Joseph Otieno',
        title: 'Financial Advisor',
        image: 'https://randomuser.me/api/portraits/men/52.jpg',
        description: 'If you\'re looking for genuine land deals, Milescoop is the way to go.They were patient and explained everything, which made me trust the process entirely.',
        rating: 5,
        location: 'Nakuru'
    },
];

const TestimonialSlider = () => {
    const [currentSlide, setCurrentSlide] = useState(0);

    const PrevArrow = ({ onClick }) => (
        <motion.button
            className="absolute left-0 top-1/2 -ml-6 md:-ml-8 transform -translate-y-1/2 z-10 w-12 h-12 rounded-full bg-white/80 backdrop-blur-lg border border-white/50 shadow-lg flex items-center justify-center text-primary"
            whileHover={{ scale: 1.1, backgroundColor: "rgba(255, 255, 255, 0.9)" }}
            whileTap={{ scale: 0.95 }}
            onClick={onClick}
        >
            <IoIosArrowBack size={22} />
        </motion.button>
    );

    const NextArrow = ({ onClick }) => (
        <motion.button
            className="absolute right-0 top-1/2 -mr-6 md:-mr-8 transform -translate-y-1/2 z-10 w-12 h-12 rounded-full bg-white/80 backdrop-blur-lg border border-white/50 shadow-lg flex items-center justify-center text-primary"
            whileHover={{ scale: 1.1, backgroundColor: "rgba(255, 255, 255, 0.9)" }}
            whileTap={{ scale: 0.95 }}
            onClick={onClick}
        >
            <IoIosArrowForward size={22} />
        </motion.button>
    );

    const settings = {
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        arrows: true,
        dots: true,
        prevArrow: <PrevArrow />,
        nextArrow: <NextArrow />,
        beforeChange: (current, next) => setCurrentSlide(next),
        dotsClass: "slick-dots custom-dots",
        customPaging: i => (
            <div className={`w-3 h-3 mx-1 rounded-full transition-all duration-300 ${i === currentSlide ? 'bg-primary shadow-md scale-125' : 'bg-gray-300'}`} />
        ),
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    arrows: false,
                },
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                },
            },
        ],
    };

    // Star rating component
    const StarRating = ({ rating }) => {
        return (
            <div className="flex">
                {[...Array(5)].map((_, i) => (
                    <div key={i} className={`text-xs ${i < rating ? 'text-yellow-400' : 'text-gray-300'}`}>★</div>
                ))}
            </div>
        );
    };

    // Animation variants
    const containerVariants = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                staggerChildren: 0.1
            }
        }
    };

    const itemVariants = {
        hidden: { opacity: 0, y: 20 },
        visible: {
            opacity: 1,
            y: 0,
            transition: { duration: 0.5, ease: [0.22, 1, 0.36, 1] }
        }
    };

    return (
        <div className="testimonial-slider py-16 overflow-x-hidden relative">
            {/* Decorative background elements */}
            <div className="absolute inset-0 -z-10 overflow-hidden">
                <div className="absolute top-0 left-1/4 w-64 h-64 rounded-full bg-primary/10 blur-3xl transform -translate-x-1/2"></div>
                <div className="absolute bottom-0 right-1/4 w-64 h-64 rounded-full bg-secondary/10 blur-3xl transform translate-x-1/2"></div>
            </div>

            <motion.div
                variants={containerVariants}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, margin: "-100px" }}
                className="max-w-7xl mx-auto px-6 md:px-8"
            >
                <motion.div variants={itemVariants} className="w-[150px] bg-primary h-[2px] mx-auto mb-6" />

                <motion.h2
                    variants={itemVariants}
                    className="text-3xl md:text-4xl font-bold text-center bg-gradient-to-r from-primary to-secondary bg-clip-text text-transparent"
                >
                    What Our Customers Say
                </motion.h2>

                <motion.p
                    variants={itemVariants}
                    className="text-gray-600 text-lg text-center max-w-2xl mx-auto mb-12"
                >
                    Hear from our satisfied clients and see how we've helped them find their perfect property investments
                </motion.p>

                <motion.div variants={itemVariants} className="px-8 relative">
                    <Slider {...settings} className="testimonial-glass-slider">
                        {testimonials.map((testimonial, index) => (
                            <div key={index} className="px-3 py-2">
                                <motion.div
                                    whileHover={{ y: -5, transition: { duration: 0.3 } }}
                                    className="relative backdrop-blur-xl bg-white/60 rounded-2xl shadow-xl border border-white/50 p-6 h-full flex flex-col"
                                >
                                    <FaQuoteLeft className="text-primary/20 text-4xl absolute top-6 left-6" />

                                    {/* Content */}
                                    <div className="flex-1">
                                        <div className="pt-6 pb-4 relative z-10">
                                            <p className="text-gray-700 text-base leading-relaxed">
                                                "{testimonial.description}"
                                            </p>
                                        </div>
                                    </div>

                                    {/* Ratings */}
                                    <div className="mt-4 flex justify-between items-center">
                                        <StarRating rating={testimonial.rating} />
                                        <span className="text-xs text-gray-500">{testimonial.location}</span>
                                    </div>

                                    {/* Author info */}
                                    <div className="mt-4 pt-4 border-t border-gray-200/50 flex items-center justify-between">
                                        <div>
                                            <div className="flex items-center gap-1">
                                                <h3 className="font-semibold text-gray-900">{testimonial.name}</h3>
                                                <RiVerifiedBadgeFill className="text-primary text-sm" />
                                            </div>
                                            <p className="text-xs text-gray-500">{testimonial.title}</p>
                                        </div>

                                        <div className="relative">
                                            <div className="absolute inset-0 bg-gradient-to-r from-primary/20 to-secondary/20 rounded-full blur-sm"></div>
                                            <img
                                                src={testimonial.image}
                                                alt={testimonial.name}
                                                className="w-12 h-12 rounded-full object-cover border-2 border-white relative z-10"
                                            />
                                        </div>
                                    </div>
                                </motion.div>
                            </div>
                        ))}
                    </Slider>
                </motion.div>
            </motion.div>

            {/* Custom styling for dots */}
            <style jsx global>{`
                .testimonial-glass-slider .slick-dots {
                    bottom: -50px;
                }
                
                .testimonial-glass-slider .slick-dots li {
                    margin: 0 5px;
                }
                
                .testimonial-glass-slider .slick-dots li button:before {
                    content: '';
                }
                
                .testimonial-glass-slider .slick-track {
                    display: flex !important;
                }
                
                .testimonial-glass-slider .slick-slide {
                    height: inherit !important;
                    display: flex !important;
                }
                
                .testimonial-glass-slider .slick-slide > div {
                    display: flex;
                    height: 100%;
                    width: 100%;
                }
            `}</style>
        </div>
    );
};

export default TestimonialSlider;