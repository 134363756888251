import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import Layout from '../elements/Layout';
import { fetchOrder } from '../utils/api/api';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import Loading from '../elements/Loading';
import logo from '../assets/miles_logo.svg';
import moment from 'moment';
import { FaAngleDown, FaAngleUp, FaTimesCircle, FaChevronRight } from 'react-icons/fa';
import { FaDownload, FaCreditCard, FaFileInvoiceDollar, FaCalendar } from 'react-icons/fa6';
import { IoCheckmarkCircle, IoAlertCircle } from 'react-icons/io5';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import Modal from '../elements/Modal';

const PropertyOrder = () => {
  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isInstallmentOpen, setIsInstallmentOpen] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const navigate = useNavigate();
  const { id } = useParams();
  const mastercardLogo = 'https://imgs.search.brave.com/P3S-4nzSLPOsVBu50WqTJkvji1ahb56zJ-22cbFeeHI/rs:fit:500:0:0:0/g:ce/aHR0cHM6Ly9hc3Nl/dHMuc3RpY2twbmcu/Y29tL2ltYWdlcy81/ODQ4MjM1NGNlZjEw/MTRjMGI1ZTQ5YzAu/cG5n';
  const visaLogo = 'https://imgs.search.brave.com/bNsCAqw-xAbHOBLJ8nQRkVsPfIQJGxTG2tnzaQY6ytk/rs:fit:500:0:0:0/g:ce/aHR0cHM6Ly9zZWVr/bG9nby5jb20vaW1h/Z2VzL1YvVklTQS1s/b2dvLTE2RjRGODJE/MTMtc2Vla2xvZ28u/Y29tLnBuZw';
  const mpesaLogo = 'https://imgs.search.brave.com/ksKqVibKAMDeEPrAJiYsygtSjr-is_IBO44A08W82Ts/rs:fit:500:0:0:0/g:ce/aHR0cHM6Ly91cGxv/YWQud2lraW1lZGlh/Lm9yZy93aWtpcGVk/aWEvY29tbW9ucy8w/LzBiL00tUEVTQS5w/bmc';
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleDownloadPDF = () => {
    const doc = new jsPDF();
    doc.text('Installment Report', 10, 10);

    const tableColumn = ["Installment", "Type", "Due Date", "Amount", "Payment Status", "Amount Paid", "Remaining Balance", "Invoice Serial"];
    const tableRows = [];

    order?.installments?.forEach((installment, index) => {
      const installmentData = [
        index + 1,
        installment.type,
        moment(installment.due_date).format('DD MMM, YYYY'),
        `Kes. ${Number(installment.amount).toLocaleString("KES")}`,
        installment.invoice.payment_status,
        `Kes. ${installment.invoice.paid_amount || '0.00'}`,
        `Kes. ${installment.invoice.remaining_balance || 'N/A'}`,
        installment.invoice.serial_no
      ];
      tableRows.push(installmentData);
    });

    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
      startY: 20,
      margin: { top: 10 },
      theme: 'striped',
      styles: {
        fontSize: 10,
      },
      pageBreak: 'auto',
    });

    doc.save('installments_report.pdf');
  };

  const getOrder = async () => {
    setLoading(true);
    try {
      const response = await fetchOrder(id);
      setOrder(response.order);
    } catch (error) {
      toast.error('Failed to get order', {
        style: {
          borderRadius: '12px',
          background: '#333',
          color: '#fff',
        },
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getOrder();
  }, [id]);

  if (loading) {
    return (
      <div className="h-screen flex items-center justify-center">
        <motion.div
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5 }}
          className="flex flex-col items-center"
        >
          <motion.div
            animate={{
              rotate: 360,
              transition: { duration: 1.5, ease: "linear", repeat: Infinity }
            }}
            className="w-16 h-16 border-4 border-t-secondary border-r-primary border-b-secondary border-l-primary rounded-full"
          />
          <motion.p
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.5 }}
            className="mt-4 text-lg text-gray-600 font-medium"
          >
            Loading order details...
          </motion.p>
        </motion.div>
      </div>
    );
  }

  if (!order) {
    return (
      <Layout text="View Order">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="flex flex-col items-center justify-center p-8"
        >
          <IoAlertCircle className="text-6xl text-red-500 mb-4" />
          <h2 className="text-2xl font-semibold text-gray-800 mb-2">Order Not Found</h2>
          <p className="text-gray-600 mb-6">We couldn't find the order you're looking for.</p>
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => navigate('/dashboard/orders')}
            className="px-6 py-3 bg-secondary text-white font-medium rounded-xl shadow-md"
          >
            Back to Orders
          </motion.button>
        </motion.div>
      </Layout>
    );
  }

  const { property, payment_type, payment_status, customer } = order;
  const isInstallment = payment_type === 'installments';

  // Animation variants
  const fadeIn = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.5 }
    }
  };

  const cardVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        type: "spring",
        damping: 20,
        stiffness: 100
      }
    },
    hover: {
      y: -5,
      boxShadow: "0 10px 25px rgba(0, 0, 0, 0.1)",
      transition: { duration: 0.3 }
    }
  };

  const installmentVariants = {
    hidden: { opacity: 0, height: 0 },
    visible: {
      opacity: 1,
      height: "auto",
      transition: {
        duration: 0.5,
        staggerChildren: 0.1
      }
    },
    exit: {
      opacity: 0,
      height: 0,
      transition: { duration: 0.3 }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, x: -20 },
    visible: {
      opacity: 1,
      x: 0,
      transition: { duration: 0.3 }
    }
  };

  return (
    <Layout text="View Order">
      <motion.div
        className="max-w-4xl mx-auto"
        initial="hidden"
        animate="visible"
        variants={fadeIn}
      >
        <motion.div
          className="bg-white rounded-2xl shadow-lg overflow-hidden"
          variants={cardVariants}
        >
          {/* Header with Order Status Banner */}
          <div className={`w-full p-1 ${payment_status === 'paid'
              ? 'bg-gradient-to-r from-green-500 to-green-600'
              : 'bg-gradient-to-r from-yellow-500 to-yellow-600'
            }`}>
            <motion.div
              className="flex justify-center items-center text-white font-medium py-1 text-sm"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.3 }}
            >
              {payment_status === 'paid'
                ? <><IoCheckmarkCircle className="mr-2" /> Payment Complete</>
                : <><IoAlertCircle className="mr-2" /> Payment Pending</>
              }
            </motion.div>
          </div>

          {/* Order Header */}
          <div className="p-8">
            <motion.div
              className="flex flex-col md:flex-row justify-between items-start md:items-center mb-6"
              variants={fadeIn}
            >
              <div className="flex items-center mb-4 md:mb-0">
                <motion.img
                  src={logo}
                  alt="Company Logo"
                  className="h-10 w-auto mr-4"
                  initial={{ opacity: 0, scale: 0.8 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 0.5 }}
                />
                <div>
                  <motion.p
                    className="text-lg font-semibold text-gray-800"
                    initial={{ opacity: 0, x: -20 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ delay: 0.2 }}
                  >
                    Milescoop Ventures Ltd
                  </motion.p>
                  <motion.p
                    className="text-sm text-gray-500"
                    initial={{ opacity: 0, x: -20 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ delay: 0.3 }}
                  >
                    Property Order
                  </motion.p>
                </div>
              </div>

              <motion.div
                className="flex flex-col items-end"
                initial={{ opacity: 0, x: 20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 0.2 }}
              >
                <div className="flex items-center">
                  <span className="text-sm text-gray-500 mr-2">Order ID:</span>
                  <span className="bg-gray-100 px-3 py-1 rounded-full text-gray-800 font-medium text-sm">
                    #{id}
                  </span>
                </div>
                <p className="text-sm text-gray-500 mt-1">
                  {moment().format('MMMM D, YYYY')}
                </p>
              </motion.div>
            </motion.div>

            <motion.div
              className="h-px bg-gradient-to-r from-gray-200 via-gray-300 to-gray-200 my-6"
              initial={{ scaleX: 0 }}
              animate={{ scaleX: 1 }}
              transition={{ duration: 0.7 }}
            />

            {/* Payment Methods Section */}
            {order?.payment_status !== 'paid' && (
              <motion.div
                className="mb-8"
                variants={fadeIn}
              >
                <motion.h3
                  className="text-xl font-medium text-gray-800 mb-3"
                  initial={{ opacity: 0, y: -10 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.3 }}
                >
                  Select Payment Method
                </motion.h3>
                <p className="text-gray-600 text-sm mb-5">Choose your preferred payment method:</p>

                <div className="grid grid-cols-3 gap-4 mb-6">
                  {[
                    { name: 'mpesa', logo: mpesaLogo, label: 'M-Pesa' },
                    { name: 'visa', logo: visaLogo, label: 'Visa' },
                    { name: 'mastercard', logo: mastercardLogo, label: 'Mastercard' }
                  ].map((method) => (
                    <motion.div
                      key={method.name}
                      className={`relative rounded-xl border-2 p-4 flex flex-col items-center justify-center cursor-pointer ${selectedPaymentMethod === method.name
                          ? 'border-secondary bg-secondary bg-opacity-5'
                          : 'border-gray-200 hover:border-gray-300'
                        }`}
                      onClick={() => setSelectedPaymentMethod(method.name)}
                      whileHover={{ y: -5, boxShadow: "0 10px 25px rgba(0, 0, 0, 0.08)" }}
                      whileTap={{ scale: 0.98 }}
                    >
                      <img
                        src={method.logo}
                        alt={method.label}
                        className="h-10 w-auto object-contain mb-2"
                      />
                      <p className="text-sm font-medium text-gray-700">{method.label}</p>

                      {selectedPaymentMethod === method.name && (
                        <motion.div
                          className="absolute top-2 right-2 w-5 h-5 bg-secondary rounded-full flex items-center justify-center"
                          initial={{ scale: 0 }}
                          animate={{ scale: 1 }}
                          transition={{ type: "spring", stiffness: 500, damping: 15 }}
                        >
                          <div className="w-2.5 h-2.5 bg-white rounded-full" />
                        </motion.div>
                      )}
                    </motion.div>
                  ))}
                </div>

                <div className="flex items-center justify-end gap-4">
                  <motion.button
                    className="px-5 py-2.5 rounded-xl text-gray-600 font-medium border border-gray-200 text-sm hover:bg-gray-50"
                    onClick={() => navigate(-1)}
                    whileHover={{ scale: 1.03 }}
                    whileTap={{ scale: 0.97 }}
                  >
                    Cancel
                  </motion.button>
                  <motion.button
                    className={`px-6 py-2.5 rounded-xl text-white font-medium text-sm shadow-lg ${!selectedPaymentMethod
                        ? 'bg-gray-400 cursor-not-allowed'
                        : 'bg-secondary hover:bg-opacity-90'
                      }`}
                    onClick={() => navigate(`/dashboard/orders/${id}/pay`)}
                    disabled={!selectedPaymentMethod}
                    whileHover={selectedPaymentMethod ? { scale: 1.03 } : {}}
                    whileTap={selectedPaymentMethod ? { scale: 0.97 } : {}}
                  >
                    <div className="flex items-center">
                      <span>Confirm & Pay</span>
                      <FaChevronRight className="ml-2 text-xs" />
                    </div>
                  </motion.button>
                </div>
              </motion.div>
            )}

            {order?.payment_status !== 'paid' && (
              <motion.div
                className="h-px bg-gradient-to-r from-gray-200 via-gray-300 to-gray-200 my-6"
                initial={{ scaleX: 0 }}
                animate={{ scaleX: 1 }}
                transition={{ duration: 0.7, delay: 0.1 }}
              />
            )}

            {/* Property and Customer Info */}
            <motion.div
              className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-8"
              variants={{
                hidden: { opacity: 0 },
                visible: {
                  opacity: 1,
                  transition: { staggerChildren: 0.2 }
                }
              }}
            >
              <motion.div
                className="bg-gray-50 rounded-2xl p-6 border border-gray-100"
                variants={cardVariants}
                whileHover="hover"
              >
                <div className="flex items-center mb-4">
                  <div className="w-10 h-10 rounded-full bg-primary flex items-center justify-center mr-3">
                    <FaFileInvoiceDollar className="text-white" />
                  </div>
                  <h3 className="text-lg font-medium text-gray-800">Property Details</h3>
                </div>
                <div className="space-y-2">
                  <div className="flex justify-between items-center">
                    <span className="text-sm text-gray-500">Title Number:</span>
                    <span className="font-medium text-gray-800">{property?.title_number || 'N/A'}</span>
                  </div>
                  <div className="flex justify-between items-center">
                    <span className="text-sm text-gray-500">Property Number:</span>
                    <span className="font-medium text-gray-800">{property?.property_number || 'N/A'}</span>
                  </div>
                  <div className="flex justify-between items-center">
                    <span className="text-sm text-gray-500">Type:</span>
                    <span className="font-medium text-gray-800">{property?.type || 'Residential'}</span>
                  </div>
                </div>
              </motion.div>

              <motion.div
                className="bg-gray-50 rounded-2xl p-6 border border-gray-100"
                variants={cardVariants}
                whileHover="hover"
              >
                <div className="flex items-center mb-4">
                  <div className="w-10 h-10 rounded-full bg-primary flex items-center justify-center mr-3">
                    <FaCreditCard className="text-white" />
                  </div>
                  <h3 className="text-lg font-medium text-gray-800">Customer Information</h3>
                </div>
                <div className="space-y-2">
                  <div className="flex justify-between items-center">
                    <span className="text-sm text-gray-500">Name:</span>
                    <span className="font-medium text-gray-800">
                      {customer?.first_name} {customer?.last_name}
                    </span>
                  </div>
                  <div className="flex justify-between items-center">
                    <span className="text-sm text-gray-500">Customer ID:</span>
                    <span className="font-medium text-gray-800">{customer?.unique_number || 'N/A'}</span>
                  </div>
                  <div className="flex justify-between items-center">
                    <span className="text-sm text-gray-500">Email:</span>
                    <span className="font-medium text-gray-800">{customer?.email}</span>
                  </div>
                </div>
              </motion.div>
            </motion.div>

            <motion.div
              className="h-px bg-gradient-to-r from-gray-200 via-gray-300 to-gray-200 my-6"
              initial={{ scaleX: 0 }}
              animate={{ scaleX: 1 }}
              transition={{ duration: 0.7, delay: 0.2 }}
            />

            {/* Payment and Order Summary */}
            <motion.div
              className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-8"
              variants={{
                hidden: { opacity: 0 },
                visible: {
                  opacity: 1,
                  transition: { staggerChildren: 0.2, delayChildren: 0.3 }
                }
              }}
            >
              <motion.div
                className="bg-gray-50 rounded-2xl p-6 border border-gray-100"
                variants={cardVariants}
                whileHover="hover"
              >
                <div className="flex items-center mb-4">
                  <div className="w-10 h-10 rounded-full bg-primary flex items-center justify-center mr-3">
                    <FaCalendar className="text-white" />
                  </div>
                  <h3 className="text-lg font-medium text-gray-800">Payment Information</h3>
                </div>
                <div className="space-y-3">
                  <div className="flex justify-between items-center">
                    <span className="text-sm text-gray-500">Payment Type:</span>
                    <span className="font-medium text-gray-800">
                      {payment_type === 'one_time' ? 'One-Time Payment' : 'Installment Plan'}
                    </span>
                  </div>
                  <div className="flex justify-between items-center">
                    <span className="text-sm text-gray-500">Payment Status:</span>
                    <span className={`px-3 py-1 rounded-full text-xs font-medium ${payment_status === 'paid'
                        ? 'bg-green-100 text-green-600'
                        : 'bg-yellow-100 text-yellow-600'
                      }`}>
                      {payment_status.toUpperCase()}
                    </span>
                  </div>
                  <div className="pt-3">
                    <p className="text-sm text-gray-600">
                      {order?.payment_status === 'paid'
                        ? 'This property has been fully paid, which grants you a ready title and ownership of the property listed above.'
                        : payment_type === 'one_time'
                          ? 'You have chosen to pay the full amount at once, which grants you immediate ownership of the property upon completion of the transaction.'
                          : 'You will be making monthly payments for this property. Once the full amount is paid, you will be handed full ownership of the property.'
                      }
                    </p>
                  </div>
                </div>
              </motion.div>

              <motion.div
                className="bg-gray-50 rounded-2xl p-6 border border-gray-100"
                variants={cardVariants}
                whileHover="hover"
              >
                <div className="flex items-center mb-4">
                  <div className="w-10 h-10 rounded-full bg-primary flex items-center justify-center mr-3">
                    <FaFileInvoiceDollar className="text-white" />
                  </div>
                  <h3 className="text-lg font-medium text-gray-800">Order Summary</h3>
                </div>
                <div className="space-y-4">
                  <div className="flex justify-between items-center pb-4 border-b border-gray-200">
                    <span className="text-gray-600">Property Price:</span>
                    <span className="text-xl font-semibold text-gray-900">
                      Kes {Number(order.amount).toLocaleString("KES")}
                    </span>
                  </div>

                  {isInstallment && (
                    <div className="flex justify-between items-center pb-2">
                      <span className="text-gray-600">Installment Plan:</span>
                      <span className="text-gray-900">
                        {order?.installments?.length || 0} payments
                      </span>
                    </div>
                  )}

                  <div className="pt-2 border-t border-gray-200">
                    <div className="flex justify-between items-center">
                      <span className="text-sm font-medium text-gray-700">Payment Status:</span>
                      <motion.div
                        className={`flex items-center gap-1.5 px-3 py-1 rounded-full text-sm font-medium ${payment_status === 'paid'
                            ? 'bg-green-100 text-green-600'
                            : 'bg-yellow-100 text-yellow-600'
                          }`}
                        whileHover={{ scale: 1.05 }}
                      >
                        {payment_status === 'paid' ? <IoCheckmarkCircle /> : <IoAlertCircle />}
                        {payment_status.toUpperCase()}
                      </motion.div>
                    </div>
                  </div>
                </div>
              </motion.div>
            </motion.div>

            {/* Installment Section */}
            {isInstallment && (
              <motion.div
                className="mt-4 rounded-2xl border border-gray-200 overflow-hidden"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.5 }}
              >
                <motion.div
                  className="bg-gray-50 px-6 py-4 flex items-center justify-between cursor-pointer"
                  onClick={() => setIsInstallmentOpen(!isInstallmentOpen)}
                  whileHover={{ backgroundColor: "#f5f5f5" }}
                >
                  <div className="flex items-center">
                    <div className="w-8 h-8 rounded-full bg-primary flex items-center justify-center mr-3">
                      <FaCalendar className="text-white text-sm" />
                    </div>
                    <h3 className="text-lg font-medium text-gray-800">
                      Installment Schedule
                    </h3>
                  </div>
                  <motion.div
                    animate={{ rotate: isInstallmentOpen ? 180 : 0 }}
                    transition={{ duration: 0.3 }}
                  >
                    <FaAngleDown className="text-gray-500 text-xl" />
                  </motion.div>
                </motion.div>

                <AnimatePresence>
                  {isInstallmentOpen && (
                    <motion.div
                      variants={installmentVariants}
                      initial="hidden"
                      animate="visible"
                      exit="exit"
                      className="overflow-hidden"
                    >
                      <div className="bg-white p-4 space-y-2">
                        {order?.installments
                          ?.sort((a, b) => a.id - b.id)
                          .map((installment, index) => (
                            <motion.div
                              key={installment.id}
                              variants={itemVariants}
                              className={`p-4 rounded-xl ${index % 2 === 0 ? 'bg-gray-50' : 'bg-white border border-gray-100'}`}
                              whileHover={{
                                backgroundColor: "#f9fafb",
                                boxShadow: "0 4px 12px rgba(0, 0, 0, 0.05)"
                              }}
                            >
                              <div className="flex items-center justify-between">
                                <div className="flex items-center">
                                  <motion.div
                                    className={`flex items-center justify-center rounded-full w-8 h-8 text-xs text-white ${installment?.invoice?.payment_status === 'paid'
                                        ? 'bg-green-500'
                                        : installment?.invoice?.payment_status === 'pending'
                                          ? 'bg-primary'
                                          : installment?.invoice?.payment_status === 'partially_paid'
                                            ? 'bg-secondary'
                                            : 'bg-red-500'
                                      }`}
                                    whileHover={{ scale: 1.1 }}
                                  >
                                    {index + 1}
                                  </motion.div>
                                  <div className="ml-3">
                                    <p className="capitalize font-medium text-gray-800">
                                      {installment.type}
                                    </p>
                                    <p className="text-sm text-gray-600">
                                      Kes. {Number(installment.amount).toLocaleString("KES")}
                                    </p>
                                  </div>
                                </div>
                                <div className="text-right">
                                  <p className="text-sm text-gray-600 flex items-center justify-end">
                                    <FaCalendar className="mr-1 text-xs text-gray-400" />
                                    {moment(installment.invoice.payment_due_date).format('DD MMM, YYYY')}
                                  </p>
                                  <p className={`text-xs font-medium capitalize mt-1 ${installment.invoice.payment_status === 'paid'
                                      ? 'text-green-600'
                                      : installment.invoice.payment_status === 'pending'
                                        ? 'text-secondary'
                                        : 'text-red-600'
                                    }`}>
                                    {installment.invoice.payment_status}
                                  </p>
                                </div>
                              </div>

                              {/* Progress bar for partially paid installments */}
                              {installment.invoice.payment_status === 'partially_paid' && (
                                <div className="mt-3">
                                  <div className="h-1.5 w-full bg-gray-200 rounded-full overflow-hidden">
                                    <motion.div
                                      className="h-full bg-secondary"
                                      style={{
                                        width: `${(installment.invoice.paid_amount / installment.amount) * 100}%`
                                      }}
                                      initial={{ width: 0 }}
                                      animate={{
                                        width: `${(installment.invoice.paid_amount / installment.amount) * 100}%`
                                      }}
                                      transition={{ duration: 1, delay: 0.5 }}
                                    />
                                  </div>
                                  <div className="flex justify-between mt-1 text-xs text-gray-500">
                                    <span>Paid: Kes {Number(installment.invoice.paid_amount).toLocaleString("KES")}</span>
                                    <span>Remaining: Kes {Number(installment.invoice.remaining_balance).toLocaleString("KES")}</span>
                                  </div>
                                </div>
                              )}
                            </motion.div>
                          ))}
                      </div>

                      <div className="bg-gray-50 p-4 flex justify-center">
                        <motion.button
                          className="flex items-center gap-2 px-6 py-2.5 bg-primary text-white rounded-xl text-sm font-medium"
                          onClick={() => setIsModalOpen(true)}
                          whileHover={{ scale: 1.03, backgroundColor: "#1a365d" }}
                          whileTap={{ scale: 0.97 }}
                        >
                          <FaFileInvoiceDollar />
                          View Detailed Report
                        </motion.button>
                      </div>
                    </motion.div>
                  )}
                </AnimatePresence>
              </motion.div>
            )}
          </div>
        </motion.div>
      </motion.div>

      {/* Modal for Installment Details */}
      <AnimatePresence>
        {isModalOpen && (
          <motion.div
            className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <motion.div
              className="bg-white rounded-2xl overflow-hidden w-full max-w-3xl max-h-[90vh]"
              initial={{ opacity: 0, y: 50, scale: 0.9 }}
              animate={{ opacity: 1, y: 0, scale: 1 }}
              exit={{ opacity: 0, y: 50, scale: 0.9 }}
              transition={{ type: "spring", damping: 20 }}
            >
              <div className="p-6 bg-secondary text-white flex justify-between items-center">
                <h3 className="text-xl font-medium">Installment Payment Report</h3>
                <motion.button
                  whileHover={{ scale: 1.1, rotate: 90 }}
                  whileTap={{ scale: 0.9 }}
                  onClick={() => setIsModalOpen(false)}
                >
                  <FaTimesCircle className="text-xl" />
                </motion.button>
              </div>

              <div className="p-6 overflow-y-auto max-h-[70vh]">
                <div className="space-y-6">
                  {order?.installments?.map((installment, index) => (
                    <motion.div
                      key={installment.id}
                      className="p-5 bg-gray-50 rounded-xl border border-gray-200 shadow-sm"
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ delay: index * 0.1 }}
                      whileHover={{
                        y: -5,
                        boxShadow: "0 10px 25px rgba(0, 0, 0, 0.1)"
                      }}
                    >
                      <div className="flex justify-between items-center mb-4">
                        <h4 className="text-lg font-medium text-gray-800 flex items-center">
                          <div className={`w-6 h-6 rounded-full mr-2 flex items-center justify-center text-xs text-white ${installment.invoice.payment_status === 'paid'
                              ? 'bg-green-500'
                              : installment.invoice.payment_status === 'pending'
                                ? 'bg-yellow-500'
                                : 'bg-red-500'
                            }`}>
                            {index + 1}
                          </div>
                          Installment {index + 1}
                        </h4>
                        <div className={`px-3 py-1 rounded-full text-xs font-medium ${installment.invoice.payment_status === 'paid'
                            ? 'bg-green-100 text-green-600'
                            : installment.invoice.payment_status === 'pending'
                              ? 'bg-yellow-100 text-yellow-600'
                              : 'bg-red-100 text-red-600'
                          }`}>
                          {installment.invoice.payment_status}
                        </div>
                      </div>

                      <div className="grid grid-cols-2 gap-y-3 gap-x-6 mb-4">
                        <div>
                          <p className="text-xs text-gray-500 mb-1">Type</p>
                          <p className="text-sm font-medium text-gray-800 capitalize">{installment.type}</p>
                        </div>
                        <div>
                          <p className="text-xs text-gray-500 mb-1">Due Date</p>
                          <p className="text-sm font-medium text-gray-800">
                            {moment(installment?.invoice?.payment_due_date).format('DD MMM, YYYY')}
                          </p>
                        </div>
                        <div>
                          <p className="text-xs text-gray-500 mb-1">Total Amount</p>
                          <p className="text-sm font-medium text-gray-800">
                            Kes. {Number(installment?.amount).toLocaleString("KES")}
                          </p>
                        </div>
                        <div>
                          <p className="text-xs text-gray-500 mb-1">Amount Paid</p>
                          <p className="text-sm font-medium text-gray-800">
                            Kes. {Number(installment?.invoice?.paid_amount || 0).toLocaleString("KES")}
                          </p>
                        </div>
                        <div>
                          <p className="text-xs text-gray-500 mb-1">Remaining Balance</p>
                          <p className="text-sm font-medium text-gray-800">
                            Kes. {Number(installment?.invoice?.remaining_balance || 0).toLocaleString("KES")}
                          </p>
                        </div>
                        <div>
                          <p className="text-xs text-gray-500 mb-1">Invoice Serial</p>
                          <p className="text-sm font-medium text-gray-800">
                            {installment?.invoice?.serial_no}
                          </p>
                        </div>
                      </div>

                      <div className="mt-4">
                        <div className="text-xs text-gray-500 mb-2">Payment Progress</div>
                        <div className="relative w-full h-2 bg-gray-200 rounded-full overflow-hidden">
                          <motion.div
                            className="absolute top-0 left-0 h-full bg-secondary"
                            style={{
                              width: `${(installment.invoice.paid_amount / installment.amount) * 100 || 0}%`
                            }}
                            initial={{ width: 0 }}
                            animate={{
                              width: `${(installment.invoice.paid_amount / installment.amount) * 100 || 0}%`
                            }}
                            transition={{ duration: 1, delay: 0.3 + index * 0.1 }}
                          />
                        </div>
                        <div className="flex justify-between mt-1">
                          <span className="text-xs text-gray-500">0%</span>
                          <span className="text-xs text-gray-500">
                            {Math.round((installment.invoice.paid_amount / installment.amount) * 100 || 0)}%
                          </span>
                          <span className="text-xs text-gray-500">100%</span>
                        </div>
                      </div>
                    </motion.div>
                  ))}
                </div>
              </div>

              <div className="p-4 bg-gray-50 border-t border-gray-200 flex justify-between">
                <motion.button
                  className="flex items-center gap-2 px-5 py-2.5 bg-primary text-white rounded-xl text-sm font-medium"
                  onClick={handleDownloadPDF}
                  whileHover={{ scale: 1.03 }}
                  whileTap={{ scale: 0.97 }}
                >
                  <FaDownload />
                  Download as PDF
                </motion.button>
                <motion.button
                  className="flex items-center gap-2 px-5 py-2.5 border border-gray-200 text-gray-700 rounded-xl text-sm font-medium"
                  onClick={() => setIsModalOpen(false)}
                  whileHover={{ scale: 1.03, backgroundColor: "#f9fafb" }}
                  whileTap={{ scale: 0.97 }}
                >
                  <FaTimesCircle />
                  Close
                </motion.button>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </Layout>
  );
};

export default PropertyOrder;