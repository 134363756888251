import React, { useEffect, useRef, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import Layout from '../elements/Layout';
import logo from '../assets/miles_logo.svg';
import Barcode from 'react-barcode';
import QRCode from 'react-qr-code';
import { IoCallSharp, IoCheckmarkCircle } from 'react-icons/io5';
import { MdOutlineMailOutline } from 'react-icons/md';
import { IoIosGlobe } from 'react-icons/io';
import { FaDownload, FaLocationDot, FaPrint, FaShare, FaRegFilePdf, FaCalendar } from 'react-icons/fa6';
import { useParams } from 'react-router-dom/dist';
import toast from 'react-hot-toast';
import { checkPaymentStatus } from '../utils/api/api';
import moment from 'moment/moment';
import { ToWords } from 'to-words';
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

const Receipt = () => {
    const [payment, setPayment] = useState([]);
    const [amountInWords, setAmountInWords] = useState('');
    const [loading, setLoading] = useState(false);
    const [downloadLoading, setDownloadLoading] = useState(false);
    const [showSuccessToast, setShowSuccessToast] = useState(false);
    const { id } = useParams();
    const toWords = new ToWords({
        localeCode: 'en-GB',
    });
    const componentRef = useRef();

    const getPayment = async () => {
        setLoading(true);
        try {
            const response = await checkPaymentStatus(id);
            setPayment(response.payment);
        } catch (error) {
            toast.error(error.message || "Failed to get transaction", {
                style: {
                    borderRadius: '12px',
                    background: '#333',
                    color: '#fff',
                }
            });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getPayment();
    }, []);

    useEffect(() => {
        if (payment?.amount) {
            const words = toWords.convert(payment.amount);
            setAmountInWords(words);
        }
    }, [payment?.amount]);

    const downloadPdf = () => {
        const element = componentRef.current;

        html2canvas(element, { scale: 2 }).then((canvas) => {
            // Generate the PDF
            const pdf = new jsPDF({
                unit: "in",
                format: "letter",
                orientation: "portrait",
            });

            const imgData = canvas.toDataURL("image/jpeg", 0.98);
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = pdf.internal.pageSize.getHeight();

            let imgHeight = (canvas.height * pdfWidth) / canvas.width;
            let heightLeft = imgHeight;
            let position = 0;

            pdf.addImage(imgData, "JPEG", 0, position, pdfWidth, imgHeight);
            heightLeft -= pdfHeight;

            while (heightLeft > 0) {
                position -= pdfHeight;
                pdf.addPage();
                pdf.addImage(imgData, "JPEG", 0, position, pdfWidth, imgHeight);
                heightLeft -= pdfHeight;
            }
            pdf.save(`receipt.pdf`);

            // Create a Blob from the PDF
            const blob = pdf.output("blob");

            const reader = new FileReader();
            reader.onload = () => {
                const base64data = reader.result.split(",")[1];
                // console.log(base64data)

                // Create a URL for the Blob            // console.log(blobUrl)

                // Pass the URL and filename to Flutter
                if (window.flutter_inappwebview) {
                    window.flutter_inappwebview.callHandler("onDownloadStarted", {
                        filename: "receipt.pdf",
                        base64: base64data
                    });
                }
            };

            reader.readAsDataURL(blob);
        })
    }

    // const downloadPdf = () => {
    //     setDownloadLoading(true);
    //     const element = componentRef.current;

    //     html2canvas(element, { scale: 2 }).then((canvas) => {
    //         // Generate the PDF
    //         const pdf = new jsPDF({
    //             unit: "in",
    //             format: "letter",
    //             orientation: "portrait",
    //         });

    //         const imgData = canvas.toDataURL("image/jpeg", 0.98);
    //         const pdfWidth = pdf.internal.pageSize.getWidth();
    //         const pdfHeight = pdf.internal.pageSize.getHeight();

    //         let imgHeight = (canvas.height * pdfWidth) / canvas.width;
    //         let heightLeft = imgHeight;
    //         let position = 0;

    //         pdf.addImage(imgData, "JPEG", 0, position, pdfWidth, imgHeight);
    //         heightLeft -= pdfHeight;

    //         while (heightLeft > 0) {
    //             position -= pdfHeight;
    //             pdf.addPage();
    //             pdf.addImage(imgData, "JPEG", 0, position, pdfWidth, imgHeight);
    //             heightLeft -= pdfHeight;
    //         }
    //         pdf.save(`receipt.pdf`);

    //         // Create a Blob from the PDF
    //         const blob = pdf.output("blob");

    //         const reader = new FileReader();
    //         reader.onload = () => {
    //             const base64data = reader.result.split(",")[1];

    //             // Pass the URL and filename to Flutter
    //             if (window.flutter_inappwebview) {
    //                 window.flutter_inappwebview.callHandler("onDownloadStarted", {
    //                     filename: "receipt.pdf",
    //                     base64: base64data
    //                 });
    //             }

    //             setDownloadLoading(false);
    //             setShowSuccessToast(true);
    //             setTimeout(() => setShowSuccessToast(false), 3000);
    //         };

    //         reader.readAsDataURL(blob);
    //     }).catch(err => {
    //         console.error("Error generating PDF:", err);
    //         setDownloadLoading(false);
    //         toast.error("Failed to generate PDF", {
    //             style: {
    //                 borderRadius: '12px',
    //                 background: '#333',
    //                 color: '#fff',
    //             }
    //         });
    //     });
    // };

    // Animation variants
    const containerVariants = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                duration: 0.5
            }
        }
    };

    const buttonVariants = {
        hover: {
            scale: 1.05,
            boxShadow: "0 8px 20px rgba(0, 0, 0, 0.1)"
        },
        tap: {
            scale: 0.95
        }
    };

    const receiptVariants = {
        hidden: { opacity: 0, y: 20 },
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                duration: 0.6,
                ease: "easeOut"
            }
        }
    };

    // Receipt loading skeleton
    const ReceiptSkeleton = () => (
        <div className="bg-white rounded-2xl p-6 shadow-lg animate-pulse">
            <div className="border-2 border-gray-200 p-8 mb-4 rounded-xl">
                <div className="flex justify-between">
                    <div className="flex items-center gap-3">
                        <div className="w-32 h-12 bg-gray-200 rounded"></div>
                        <div className="space-y-2">
                            <div className="h-5 w-48 bg-gray-200 rounded"></div>
                            <div className="h-3 w-40 bg-gray-200 rounded"></div>
                            <div className="h-3 w-36 bg-gray-200 rounded"></div>
                        </div>
                    </div>
                    <div className="w-60 h-40 bg-gray-200 rounded"></div>
                </div>
            </div>
            <div className="h-1 bg-gray-200 rounded w-full mb-4"></div>
            <div className="space-y-4 mt-6">
                <div className="flex">
                    <div className="w-32 h-4 bg-gray-200 rounded mr-4"></div>
                    <div className="w-full h-4 bg-gray-200 rounded"></div>
                </div>
                <div className="flex justify-between">
                    <div className="w-48 h-8 bg-gray-200 rounded"></div>
                    <div className="w-48 h-8 bg-gray-200 rounded"></div>
                </div>
                <div className="flex">
                    <div className="w-32 h-4 bg-gray-200 rounded mr-4"></div>
                    <div className="w-full h-10 bg-gray-200 rounded"></div>
                </div>
                <div className="h-32 w-full bg-gray-200 rounded mt-4"></div>
                <div className="h-24 w-full bg-gray-200 rounded mt-4"></div>
                <div className="flex justify-between mt-6">
                    <div className="w-32 h-32 bg-gray-200 rounded"></div>
                    <div className="w-32 h-32 bg-gray-200 rounded"></div>
                </div>
            </div>
        </div>
    );

    return (
        <Layout>
            <motion.div
                className="max-w-6xl mx-auto py-4 px-4"
                initial="hidden"
                animate="visible"
                variants={containerVariants}
            >
                <div className="flex gap-4 items-start justify-between mb-6">
                    <motion.h1
                        className="text-2xl font-bold text-gray-800"
                        initial={{ opacity: 0, x: -20 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ duration: 0.5 }}
                    >
                        Payment Receipt
                    </motion.h1>

                    <div className="flex gap-3">
                        <motion.button
                            onClick={downloadPdf}
                            className={`px-6 py-2.5 rounded-xl text-white font-medium text-sm flex items-center gap-2 shadow-md ${downloadLoading ? 'bg-gray-400' : 'bg-secondary'
                                }`}
                            disabled={downloadLoading || loading}
                            variants={buttonVariants}
                            whileHover="hover"
                            whileTap="tap"
                        >
                            {downloadLoading ? (
                                <>
                                    <div className="w-4 h-4 border-2 border-white border-t-transparent rounded-full animate-spin"></div>
                                    Downloading...
                                </>
                            ) : (
                                <>
                                    <FaDownload />
                                    Download PDF
                                </>
                            )}
                        </motion.button>
                    </div>
                </div>

                {/* Success Toast */}
                <AnimatePresence>
                    {showSuccessToast && (
                        <motion.div
                            className="fixed top-4 right-4 bg-green-50 border border-green-200 text-green-800 px-4 py-3 rounded-xl shadow-lg flex items-center gap-2 z-50"
                            initial={{ opacity: 0, y: -20, scale: 0.9 }}
                            animate={{ opacity: 1, y: 0, scale: 1 }}
                            exit={{ opacity: 0, y: -20, scale: 0.9 }}
                            transition={{ duration: 0.3 }}
                        >
                            <IoCheckmarkCircle className="text-green-600 text-xl" />
                            <span>Receipt downloaded successfully!</span>
                        </motion.div>
                    )}
                </AnimatePresence>

                {loading ? (
                    <ReceiptSkeleton />
                ) : (
                    <motion.div
                        ref={componentRef}
                        className="bg-white rounded-2xl shadow-lg w-[800px]"
                        variants={receiptVariants}
                    >
                        <div className="p-8">
                            <div className="flex justify-between border-2 border-primary rounded-xl overflow-hidden">
                                {/* Company Info */}
                                <div className="bg-white p-6 flex items-start gap-4">
                                    <img src={logo} className="h-16 w-auto" alt="MileScoop Logo" />
                                    <div className="flex flex-col">
                                        <p className="uppercase text-primary font-bold text-xl mb-1">Milescoop Ventures Ltd</p>
                                        <div className="space-y-1 text-gray-600 text-sm">
                                            <p className="uppercase">P.O. Box 2760 - 00100 Nairobi</p>
                                            <p>Phone: +254 (0) 737 900 424</p>
                                            <p>Email: info@milescoop.com</p>
                                            <p>PIN NO: 052203487E</p>
                                            <p>Website: www.milescoop.com</p>
                                        </div>
                                    </div>
                                </div>

                                {/* Payment Info */}
                                <div className="bg-gray-50 p-6">
                                    <div className="mb-4">
                                        <h3 className="text-primary font-semibold text-lg uppercase border-b-2 border-primary pb-1 mb-2">
                                            Payment Method: {payment.method}
                                        </h3>

                                        <div className="space-y-3 mt-4">
                                            <div className="flex justify-between items-center py-1 border-b border-gray-200">
                                                <span className="text-gray-600 text-sm">Date:</span>
                                                <span className="font-medium text-gray-800">{moment(payment?.createdAt).format("MMM DD, YYYY")}</span>
                                            </div>

                                            <div className="flex justify-between items-center py-1 border-b border-gray-200">
                                                <span className="text-gray-600 text-sm">TRX ID:</span>
                                                <span className="font-medium text-gray-800">{payment.transaction_id}</span>
                                            </div>

                                            <div className="flex justify-between items-center py-1 border-b border-gray-200">
                                                <span className="text-gray-600 text-sm">Status:</span>
                                                <span className={`px-2 py-0.5 rounded-full text-xs font-medium ${payment.status === 'completed' ? 'bg-green-100 text-green-800' :
                                                    payment.status === 'pending' ? 'bg-yellow-100 text-yellow-800' :
                                                        'bg-red-100 text-red-800'
                                                    }`}>
                                                    {payment.status}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="mt-4 space-y-6">
                                {/* Recipient Info */}
                                <div className="flex items-center">
                                    <p className="w-[150px] text-gray-600 text-sm">
                                        Received from:
                                    </p>
                                    <p className="font-semibold uppercase text-primary text-lg">
                                        {payment?.customer?.first_name} {payment?.customer?.last_name}
                                    </p>
                                </div>

                                {/* Amount Info */}
                                <div className="flex gap-4 items-start">
                                    <div className="flex items-center">
                                        <p className="w-[150px] text-gray-600 text-sm">
                                            Currency:
                                        </p>
                                        <div className="bg-gray-50 border border-gray-300 rounded-xl px-4 py-2 text-gray-800 font-medium uppercase">
                                            KES
                                        </div>
                                    </div>

                                    <div className="flex items-center">
                                        <p className="w-[100px] text-gray-600 text-sm">
                                            Amount:
                                        </p>
                                        <div className="bg-primary bg-opacity-5 border border-primary rounded-xl px-4 py-2 text-primary font-bold">
                                            {Number(payment?.amount).toLocaleString("KES")}
                                        </div>
                                    </div>
                                </div>

                                {/* Amount in Words */}
                                <div className="flex items-center">
                                    <p className="w-[150px] text-gray-600 text-sm">
                                        Amount in words:
                                    </p>
                                    <p className="flex-1 bg-gray-50 border border-gray-300 rounded-xl px-4 py-3 text-gray-700 text-sm italic">
                                        {amountInWords} shillings only
                                    </p>
                                </div>

                                {/* Remarks Section */}
                                <div className="bg-gray-50 border-l-4 border-primary rounded-xl p-4">
                                    <h3 className="text-primary font-semibold text-lg mb-1">Remarks</h3>
                                    <p className="text-gray-700 text-[12px] leading-relaxed">
                                        Amount KES {Number(payment?.amount).toLocaleString("KES")} received from
                                        <span className="px-2 text-primary font-medium uppercase">{payment?.customer?.first_name} {payment?.customer?.last_name}</span>
                                        transaction reference number
                                        <span className="px-2 text-primary font-medium">{payment.transaction_id}</span>
                                        dated
                                        <span className="px-2 text-primary font-medium">{moment(payment?.createdAt).format("MMM DD, YYYY")}</span>
                                    </p>
                                </div>

                                {/* Receipt Details */}
                                <div className="bg-gray-50 rounded-xl p-6">
                                    <h3 className="text-primary font-semibold text-lg mb-3">
                                        Receipt Type: <span className="text-gray-700">({payment?.method})</span>
                                    </h3>
                                    <div className="flex justify-between gap-4 text-sm text-gray-700">
                                        <div className="flex items-center gap-2">
                                            <FaRegFilePdf className="text-secondary" />
                                            <span>Transaction ID: {payment.transaction_id}</span>
                                        </div>
                                        <div className="flex items-center gap-2">
                                            <FaCalendar className="text-secondary" />
                                            <span>Date & time: {moment(payment?.createdAt).format("MMM DD, YYYY, HH:mm A")}</span>
                                        </div>
                                    </div>
                                </div>

                                {/* Barcode and QR Code */}
                                <div className="flex items-center justify-between gap-6">
                                    <div className="bg-white p-4 rounded-xl border border-gray-200 shadow-sm">
                                        <Barcode
                                            value={"JJKKD8892jko2"}
                                            width={1.5}
                                            height={60}
                                            displayValue={true}
                                            background="#ffffff"
                                            lineColor="#000000"
                                            margin={5}
                                            fontOptions="monospace"
                                            fontSize={12}
                                            textAlign="center"
                                        />
                                    </div>

                                    <div className="bg-white p-4 rounded-xl border border-gray-200 shadow-sm">
                                        <QRCode
                                            value={"JJKKD8892jko2"}
                                            size={80}
                                            bgColor="#ffffff"
                                            fgColor="#000000"
                                            level="H"
                                        />
                                    </div>
                                </div>
                            </div>

                            <footer className="mt-4 border-t border-gray-200 pt-2 text-[13px]">
                                <div className="grid grid-cols-3 gap-6 text-gray-600 text-sm">
                                    <div className="space-y-3">
                                        <p className="flex items-center gap-2">
                                            <span className="bg-secondary text-white rounded-full p-1.5 w-7 h-7 flex items-center justify-center">
                                                <IoCallSharp />
                                            </span>
                                            +254 (0) 737 900 424
                                        </p>
                                        <p className="flex items-center gap-2">
                                            <span className="bg-secondary text-white rounded-full p-1.5 w-7 h-7 flex items-center justify-center">
                                                <MdOutlineMailOutline />
                                            </span>
                                            info@milescoop.com
                                        </p>
                                    </div>

                                    <div className="space-y-3">
                                        <p className="flex items-center gap-2">
                                            <span className="bg-secondary text-white rounded-full p-1.5 w-7 h-7 flex items-center justify-center">
                                                <IoIosGlobe />
                                            </span>
                                            www.milescoop.com
                                        </p>
                                    </div>

                                    <div className="space-y-3">
                                        <p className="flex items-center gap-2 text-[13px]">
                                            <span className="bg-secondary text-white rounded-full p-1.5 w-7 h-7 flex items-center justify-center">
                                                <FaLocationDot />
                                            </span>
                                            Design Centre, 4th floor, Room 4A
                                        </p>
                                        <p className="flex items-center gap-2 text-[13px]">
                                            <span className="bg-secondary text-white rounded-full p-1.5 w-7 h-7 flex items-center justify-center">
                                                <FaLocationDot />
                                            </span>
                                            PIN NUMBER: 052203487E
                                        </p>
                                    </div>
                                </div>

                                <div className="mt-1 pt-4 border-t border-gray-200 text-center text-xs text-gray-500">
                                    This is an electronically generated receipt and requires no signature.
                                </div>
                            </footer>
                        </div>
                    </motion.div>
                )}
            </motion.div>
        </Layout>
    );
};

export default Receipt;