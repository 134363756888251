import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import {
    MdOutlineDashboard,
    MdAttachMoney,
    MdAssignment,
    MdMessage,
    MdSupportAgent,
    MdSettings,
    MdLogout,
    MdWorkOutline,
    MdPayment,
    MdReceipt,
    MdPersonAddAlt,
    MdEvent,
    MdKeyboardArrowRight
} from 'react-icons/md';
import { LuTableProperties } from "react-icons/lu";
import { Link, useLocation, useParams } from 'react-router-dom';
import { removeAccessToken } from '../utils/api/authToken';

const SideMenu = () => {
    const location = useLocation();
    const { id } = useParams();
    const [expandedCategory, setExpandedCategory] = useState('general');
    const [hoveredItem, setHoveredItem] = useState(null);

    const isActive = (paths) => paths.includes(location.pathname);

    const menuCategories = {
        general: [
            { path: '/dashboard', icon: <MdOutlineDashboard className="text-lg" />, label: 'Dashboard', activePaths: ['/dashboard', '/dashboard/statement'] },
            { path: '/dashboard/orders', icon: <MdAttachMoney className="text-lg" />, label: 'Pay now', activePaths: ['/dashboard/orders', `/dashboard/orders/${id}/view`] },
        ],
        management: [
            { path: '/dashboard/projects', icon: <MdWorkOutline className="text-lg" />, label: 'Projects', activePaths: ['/dashboard/projects', `/dashboard/projects/${id}/view`, `/dashboard/projects/${id}/payment`] },
            { path: '/dashboard/my-properties', icon: <LuTableProperties className="text-lg" />, label: 'My Properties', activePaths: ['/dashboard/my-properties'] },
            { path: '/dashboard/contracts', icon: <MdAssignment className="text-lg" />, label: 'Contracts', activePaths: ['/dashboard/contracts', `/dashboard/contracts/${id}/view-details`] },
        ],
        financial: [
            { path: '/dashboard/invoices', icon: <MdReceipt className="text-lg" />, label: 'Invoices', activePaths: ['/dashboard/invoices', `/dashboard/invoices/${id}/view`] },
            { path: '/dashboard/payments', icon: <MdPayment className="text-lg" />, label: 'My Statement', activePaths: ['/dashboard/payments', `/dashboard/payments/${id}/view-details`] },
            { path: '/dashboard/bookings', icon: <MdEvent className="text-lg" />, label: 'Bookings', activePaths: ['/dashboard/bookings'] },
        ],
        support: [
            { path: '/dashboard/messaging', icon: <MdMessage className="text-lg" />, label: 'Messaging', activePaths: ['/dashboard/messaging'] },
            { path: '/dashboard/support', icon: <MdSupportAgent className="text-lg" />, label: 'Support', activePaths: ['/dashboard/support'] },
            { path: '/dashboard/referral', icon: <MdPersonAddAlt className="text-lg" />, label: 'Referral', activePaths: ['/dashboard/referral'] },
            { path: '/dashboard/settings', icon: <MdSettings className="text-lg" />, label: 'Account', activePaths: ['/dashboard/settings'] },
        ]
    };

    const toggleCategory = (category) => {
        setExpandedCategory(expandedCategory === category ? null : category);
    };

    // Animation variants
    const itemVariants = {
        hidden: { opacity: 0, x: -20 },
        visible: (i) => ({
            opacity: 1,
            x: 0,
            transition: {
                delay: i * 0.05,
                duration: 0.3,
                ease: "easeOut"
            }
        }),
        hover: {
            scale: 1.02,
            backgroundColor: "rgba(255, 255, 255, 0.08)",
            transition: { duration: 0.2 }
        },
        tap: { scale: 0.98 }
    };

    const containerVariants = {
        hidden: { opacity: 0, height: 0 },
        visible: {
            opacity: 1,
            height: "auto",
            transition: {
                staggerChildren: 0.05,
                duration: 0.3,
                ease: "easeInOut"
            }
        },
        exit: {
            opacity: 0,
            height: 0,
            transition: {
                duration: 0.2,
                ease: "easeInOut"
            }
        }
    };

    const indicatorVariants = {
        hidden: { scale: 0, opacity: 0 },
        visible: {
            scale: 1,
            opacity: 1,
            transition: {
                type: "spring",
                stiffness: 500,
                damping: 25
            }
        }
    };

    const arrowVariants = {
        closed: { rotate: 0 },
        open: { rotate: 90 }
    };

    const logoVariants = {
        initial: { scale: 0.8, opacity: 0 },
        animate: {
            scale: 1,
            opacity: 1,
            transition: {
                duration: 0.5,
                ease: "easeOut"
            }
        }
    };

    const renderMenuItems = (items, category) => {
        return items.map((item, index) => {
            const isItemActive = isActive(item.activePaths);

            return (
                <motion.div
                    key={`${category}-${index}`}
                    custom={index}
                    variants={itemVariants}
                    initial="hidden"
                    animate="visible"
                    whileHover="hover"
                    whileTap="tap"
                    onHoverStart={() => setHoveredItem(`${category}-${index}`)}
                    onHoverEnd={() => setHoveredItem(null)}
                >
                    <Link
                        to={item.path}
                        className={`flex items-center px-4 py-3 my-1 rounded-xl text-sm ${isItemActive
                            ? 'bg-secondary bg-opacity-90 text-white font-medium shadow-md'
                            : 'text-white'
                            }`}
                    >
                        <motion.span
                            className={`mr-3 ${isItemActive ? 'text-white' : 'text-gray-400'}`}
                            animate={{
                                scale: hoveredItem === `${category}-${index}` || isItemActive ? 1.1 : 1,
                                color: isItemActive ? "#ffffff" : (hoveredItem === `${category}-${index}` ? "#cccccc" : "#888888"),
                            }}
                            transition={{ duration: 0.2 }}
                        >
                            {item.icon}
                        </motion.span>
                        <span>{item.label}</span>
                        {isItemActive && (
                            <motion.div
                                className="w-1.5 h-1.5 rounded-full bg-white ml-auto mr-1"
                                variants={indicatorVariants}
                                initial="hidden"
                                animate="visible"
                            />
                        )}
                    </Link>
                </motion.div>
            );
        });
    };

    const renderCategoryHeader = (title, category) => {
        const isExpanded = expandedCategory === category;
        const isAnyCategoryItemActive = menuCategories[category].some(item =>
            isActive(item.activePaths)
        );

        return (
            <motion.div
                className={`flex items-center justify-between py-2 mb-1 mt-4 cursor-pointer ${isExpanded || isAnyCategoryItemActive ? 'text-white' : 'text-gray-400'
                    }`}
                onClick={() => toggleCategory(category)}
                whileHover={{ color: "#ffffff" }}
                whileTap={{ scale: 0.98 }}
            >
                <motion.span
                    className="text-xs font-medium uppercase tracking-wider"
                    animate={{
                        fontWeight: isExpanded ? 600 : 500,
                        letterSpacing: isExpanded ? "0.1em" : "0.05em"
                    }}
                >
                    {title}
                </motion.span>
                <motion.div
                    variants={arrowVariants}
                    initial="closed"
                    animate={isExpanded ? "open" : "closed"}
                    transition={{ duration: 0.3 }}
                >
                    <MdKeyboardArrowRight />
                </motion.div>
            </motion.div>
        );
    };

    return (
        <motion.div
            className='bg-primary h-full w-full hidden md:flex flex-col py-8 overflow-y-auto'
            initial={{ opacity: 0, x: -30 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, ease: "easeOut" }}
        >
            <motion.div
                className="mb-8"
                variants={logoVariants}
                initial="initial"
                animate="animate"
            >
                <motion.div
                    className="h-10 w-10 bg-secondary rounded-full flex items-center justify-center mb-2"
                    whileHover={{ scale: 1.05, boxShadow: "0 0 10px rgba(0,0,0,0.3)" }}
                    whileTap={{ scale: 0.95 }}
                >
                    <span className="text-primary font-bold">MS</span>
                </motion.div>
                <motion.h2
                    className="text-white text-lg font-medium"
                    initial={{ opacity: 0, y: 5 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.2, duration: 0.3 }}
                >
                    MileScoop
                </motion.h2>
                <motion.p
                    className="text-gray-400 text-xs"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 0.3, duration: 0.3 }}
                >
                    Financial Dashboard
                </motion.p>
            </motion.div>

            <div className=" overflow-y-auto px-2 mt-2">
                {renderCategoryHeader('General', 'general')}
                <AnimatePresence>
                    {expandedCategory === 'general' && (
                        <motion.div
                            variants={containerVariants}
                            initial="hidden"
                            animate="visible"
                            exit="exit"
                        >
                            {renderMenuItems(menuCategories.general, 'general')}
                        </motion.div>
                    )}
                </AnimatePresence>

                {renderCategoryHeader('Management', 'management')}
                <AnimatePresence>
                    {expandedCategory === 'management' && (
                        <motion.div
                            variants={containerVariants}
                            initial="hidden"
                            animate="visible"
                            exit="exit"
                        >
                            {renderMenuItems(menuCategories.management, 'management')}
                        </motion.div>
                    )}
                </AnimatePresence>

                {renderCategoryHeader('Financial', 'financial')}
                <AnimatePresence>
                    {expandedCategory === 'financial' && (
                        <motion.div
                            variants={containerVariants}
                            initial="hidden"
                            animate="visible"
                            exit="exit"
                        >
                            {renderMenuItems(menuCategories.financial, 'financial')}
                        </motion.div>
                    )}
                </AnimatePresence>

                {renderCategoryHeader('Support & Settings', 'support')}
                <AnimatePresence>
                    {expandedCategory === 'support' && (
                        <motion.div
                            variants={containerVariants}
                            initial="hidden"
                            animate="visible"
                            exit="exit"
                        >
                            {renderMenuItems(menuCategories.support, 'support')}
                        </motion.div>
                    )}
                </AnimatePresence>
            </div>

            <motion.div
                className="mt-auto pt-4 border-t border-gray-700 mx-4"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.5, duration: 0.3 }}
            >
                <motion.button
                    onClick={removeAccessToken}
                    className="flex items-center w-full px-4 py-3 rounded-xl text-sm text-white"
                    whileHover={{
                        backgroundColor: "rgba(255, 255, 255, 0.08)",
                        scale: 1.02
                    }}
                    whileTap={{ scale: 0.98 }}
                >
                    <motion.span
                        className="mr-3 text-gray-400"
                        whileHover={{ color: "#ff7070", rotate: 10 }}
                    >
                        <MdLogout className="text-lg" />
                    </motion.span>
                    <span>Log Out</span>
                </motion.button>
            </motion.div>
        </motion.div>
    );
};

export default SideMenu;