import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { MdPhone, MdEmail, MdLocationOn, MdAccessTime, MdSend, MdCheck } from 'react-icons/md';
import { HiOutlineSparkles } from 'react-icons/hi';

const ContactUs = () => {
    const [formState, setFormState] = useState({
        name: '',
        email: '',
        message: '',
        submitted: false,
        loading: false
    });

    const [focusedField, setFocusedField] = useState(null);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormState({
            ...formState,
            [name]: value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setFormState({ ...formState, loading: true });

        // Simulate form submission
        setTimeout(() => {
            setFormState({
                ...formState,
                submitted: true,
                loading: false
            });
        }, 1500);
    };

    // Animation variants
    const containerVariants = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                staggerChildren: 0.1
            }
        }
    };

    const itemVariants = {
        hidden: { opacity: 0, y: 20 },
        visible: {
            opacity: 1,
            y: 0,
            transition: { duration: 0.5, ease: [0.22, 1, 0.36, 1] }
        }
    };

    const ContactInfoCard = ({ icon, title, text, link, linkText }) => (
        <motion.div
            whileHover={{ y: -5 }}
            transition={{ duration: 0.3 }}
            className="backdrop-blur-xl bg-white/40 border border-white/50 rounded-2xl p-5 shadow-lg flex gap-4 items-center"
        >
            <div className="p-3 rounded-full bg-primary/10 text-primary">
                {icon}
            </div>
            <div>
                <h4 className="font-medium text-gray-800">{title}</h4>
                <p className="text-gray-600 text-sm">{text}</p>
                {link && (
                    <a href={link} className="text-primary text-sm font-medium mt-1 inline-block hover:underline">
                        {linkText || "Learn more"}
                    </a>
                )}
            </div>
        </motion.div>
    );

    return (
        <div className="py-16 px-[5%] relative overflow-hidden">
            {/* Decorative background elements */}
            <div className="absolute inset-0 -z-10">
                <div className="absolute top-0 right-0 w-80 h-80 rounded-full bg-primary/10 blur-3xl"></div>
                <div className="absolute bottom-0 left-0 w-80 h-80 rounded-full bg-secondary/10 blur-3xl"></div>
            </div>

            <motion.div
                variants={containerVariants}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, margin: "-100px" }}
                className="max-w-7xl mx-auto"
            >
                <motion.div variants={itemVariants} className="flex items-center gap-3 mb-6">
                    <div className="w-[100px] bg-primary h-[2px]" />
                    <p className="text-primary font-medium text-sm">Contact Us</p>
                </motion.div>

                <motion.h2
                    variants={itemVariants}
                    className="text-3xl md:text-4xl font-bold bg-gradient-to-r from-primary to-secondary bg-clip-text text-transparent mb-8"
                >
                    Get in Touch with Us
                </motion.h2>

                <div className="grid grid-cols-1 lg:grid-cols-5 gap-8">
                    {/* Contact Form */}
                    <motion.div
                        variants={itemVariants}
                        className="lg:col-span-3 backdrop-blur-xl bg-white/60 rounded-3xl border border-white/50 shadow-xl p-8"
                    >
                        <motion.div variants={itemVariants}>
                            <h3 className="text-2xl font-semibold text-gray-800 mb-2">
                                Send us a message
                            </h3>
                            <p className="text-gray-600 mb-6">
                                Have questions about our properties or services? Fill out the form below and our team will get back to you shortly.
                            </p>
                        </motion.div>

                        {formState.submitted ? (
                            <motion.div
                                initial={{ opacity: 0, scale: 0.9 }}
                                animate={{ opacity: 1, scale: 1 }}
                                className="py-16 text-center"
                            >
                                <div className="inline-flex items-center justify-center w-16 h-16 rounded-full bg-green-100 text-green-500 mb-4">
                                    <MdCheck size={32} />
                                </div>
                                <h3 className="text-2xl font-semibold text-gray-800 mb-2">Message Sent!</h3>
                                <p className="text-gray-600">
                                    Thank you for contacting us. We'll respond to your inquiry as soon as possible.
                                </p>
                                <motion.button
                                    whileHover={{ scale: 1.03 }}
                                    whileTap={{ scale: 0.97 }}
                                    className="mt-6 px-6 py-2 rounded-full bg-white border border-gray-200 text-gray-700 font-medium shadow-sm"
                                    onClick={() => setFormState({
                                        name: '',
                                        email: '',
                                        message: '',
                                        submitted: false,
                                        loading: false
                                    })}
                                >
                                    Send another message
                                </motion.button>
                            </motion.div>
                        ) : (
                            <motion.form
                                variants={containerVariants}
                                onSubmit={handleSubmit}
                                className="space-y-5"
                            >
                                <motion.div variants={itemVariants}>
                                    <label className="block text-sm font-medium text-gray-700 mb-2" htmlFor="name">
                                        Your Name
                                    </label>
                                    <div className={`relative transition-all duration-300 ${focusedField === 'name' ? 'ring-2 ring-primary/20' : ''
                                        }`}>
                                        <input
                                            type="text"
                                            id="name"
                                            name="name"
                                            value={formState.name}
                                            onChange={handleChange}
                                            onFocus={() => setFocusedField('name')}
                                            onBlur={() => setFocusedField(null)}
                                            className="w-full p-3 border border-white/50 bg-white/50 backdrop-blur-sm rounded-xl text-gray-800 outline-none focus:border-primary/50 transition-all"
                                            placeholder="Enter your name"
                                            required
                                        />
                                    </div>
                                </motion.div>

                                <motion.div variants={itemVariants}>
                                    <label className="block text-sm font-medium text-gray-700 mb-2" htmlFor="email">
                                        Email Address
                                    </label>
                                    <div className={`relative transition-all duration-300 ${focusedField === 'email' ? 'ring-2 ring-primary/20' : ''
                                        }`}>
                                        <input
                                            type="email"
                                            id="email"
                                            name="email"
                                            value={formState.email}
                                            onChange={handleChange}
                                            onFocus={() => setFocusedField('email')}
                                            onBlur={() => setFocusedField(null)}
                                            className="w-full p-3 border border-white/50 bg-white/50 backdrop-blur-sm rounded-xl text-gray-800 outline-none focus:border-primary/50 transition-all"
                                            placeholder="Enter your email"
                                            required
                                        />
                                    </div>
                                </motion.div>

                                <motion.div variants={itemVariants}>
                                    <label className="block text-sm font-medium text-gray-700 mb-2" htmlFor="message">
                                        Your Message
                                    </label>
                                    <div className={`relative transition-all duration-300 ${focusedField === 'message' ? 'ring-2 ring-primary/20' : ''
                                        }`}>
                                        <textarea
                                            id="message"
                                            name="message"
                                            rows="5"
                                            value={formState.message}
                                            onChange={handleChange}
                                            onFocus={() => setFocusedField('message')}
                                            onBlur={() => setFocusedField(null)}
                                            className="w-full p-3 border border-white/50 bg-white/50 backdrop-blur-sm rounded-xl text-gray-800 outline-none focus:border-primary/50 transition-all"
                                            placeholder="Type your message here"
                                            required
                                        />
                                    </div>
                                </motion.div>

                                <motion.div variants={itemVariants} className="pt-2">
                                    <motion.button
                                        type="submit"
                                        whileHover={{ scale: 1.03 }}
                                        whileTap={{ scale: 0.97 }}
                                        disabled={formState.loading}
                                        className="w-full py-3 px-6 bg-gradient-to-r from-primary to-secondary text-white font-medium rounded-xl flex items-center justify-center gap-2 shadow-lg hover:shadow-xl transition-all"
                                    >
                                        {formState.loading ? (
                                            <>
                                                <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                </svg>
                                                <span>Sending...</span>
                                            </>
                                        ) : (
                                            <>
                                                <MdSend size={20} />
                                                <span>Send Message</span>
                                            </>
                                        )}
                                    </motion.button>
                                </motion.div>

                                <motion.div variants={itemVariants} className="flex items-center gap-2 text-gray-500 text-sm mt-4 justify-center">
                                    <HiOutlineSparkles className="text-primary" />
                                    <span>We typically respond within 24 hours</span>
                                </motion.div>
                            </motion.form>
                        )}
                    </motion.div>

                    {/* Contact Info */}
                    <motion.div
                        variants={containerVariants}
                        className="lg:col-span-2 space-y-5"
                    >
                        <motion.div variants={itemVariants}>
                            <h3 className="text-2xl font-semibold text-gray-800 mb-5">
                                Contact Information
                            </h3>
                        </motion.div>

                        <motion.div variants={itemVariants}>
                            <ContactInfoCard
                                icon={<MdPhone size={24} />}
                                title="Phone Number"
                                text="+254 740 173 456"
                                link="tel:+254740173456"
                                linkText="Call now"
                            />
                        </motion.div>

                        <motion.div variants={itemVariants}>
                            <ContactInfoCard
                                icon={<MdEmail size={24} />}
                                title="Email Address"
                                text="info@milescoop.com"
                                link="mailto:info@milescoop.com"
                                linkText="Send email"
                            />
                        </motion.div>

                        <motion.div variants={itemVariants}>
                            <ContactInfoCard
                                icon={<MdLocationOn size={24} />}
                                title="Office Address"
                                text="Design Centre, 4th Floor, Tausi Lane, Westlands, Nairobi, Kenya"
                                link="https://maps.google.com"
                                linkText="View on map"
                            />
                        </motion.div>

                        <motion.div variants={itemVariants}>
                            <ContactInfoCard
                                icon={<MdAccessTime size={24} />}
                                title="Working Hours"
                                text="Monday - Friday: 8:00 AM - 5:00 PM"
                            />
                        </motion.div>

                        {/* Map placeholder */}
                        <motion.div
                            variants={itemVariants}
                            className="h-44 rounded-2xl overflow-hidden backdrop-blur-xl bg-white/40 border border-white/50 shadow-lg mt-5 flex items-center justify-center"
                        >
                            <div className="text-center p-4">
                                <p className="text-gray-500 text-sm mb-2">Map location available on click</p>
                                <motion.button
                                    whileHover={{ scale: 1.03 }}
                                    whileTap={{ scale: 0.97 }}
                                    className="px-4 py-2 rounded-full bg-white border border-gray-200 text-gray-700 text-sm shadow-sm"
                                >
                                    Open Map
                                </motion.button>
                            </div>
                        </motion.div>
                    </motion.div>
                </div>
            </motion.div>
        </div>
    );
};

export default ContactUs;