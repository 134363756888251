import React, { useContext, useEffect, useState } from 'react';
import TopMenu from '../components/TopMenu';
import Footer from '../components/Footer';
import SideMenu from '../components/SideMenu';
import BottomNav from '../components/BottomNav';
import { getCustomerStatements } from '../utils/api/api';
import Cookies from 'js-cookie';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { AuthContext } from '../utils/context/AuthContext';

const Layout = ({ children, text, backgroundColor = 'bg-gray-100' }) => {
    const [statements, setStatements] = useState({});
    const [showBanner, setShowBanner] = useState(true);
    const customer_id = Cookies.get('user_id');
    const { user } = useContext(AuthContext);

    const fetchCustomerStatements = async () => {
        try {
            const response = await getCustomerStatements(customer_id);
            setStatements(response);
        } catch (error) {
            toast.error("Failed to get statements");
        }
    };

    useEffect(() => {
        fetchCustomerStatements();
        const bannerClosed = sessionStorage.getItem('bannerClosed');
        if (bannerClosed === 'true') {
            setShowBanner(false);
        }
    }, []);

    const closeBanner = () => {
        setShowBanner(false);
        sessionStorage.setItem('bannerClosed', 'true');
    };

    const renderBanner = () => {
        if (!showBanner) return null;

        const isOverdue = statements.total_overdue > 0;
        const isUpcoming = statements.next_due && new Date(statements.next_due.payment_due_date) > new Date();

        if (isOverdue) {
            return (
                <div className="bg-red-500 text-white text-center p-4 relative">
                    <p>
                        Dear {user?.first_name}, Your account has an outstanding balance of Ksh. {Number(statements.total_overdue).toLocaleString("KES")}{' '}
                        that needs attention to avoid late payment default. Please click{' '}
                        <Link to="/dashboard/orders" className="underline text-white">here</Link> to make a payment.
                    </p>
                    <button onClick={closeBanner} className="absolute top-2 right-4 text-white">X</button>
                </div>
            );
        } else if (isUpcoming) {
            return (
                <div className="bg-secondary text-black text-center p-4 relative">
                    <p>Dear {user?.first_name}, Your next payment is due on: {moment(statements.next_due.payment_due_date).format("MMM DD, YYYY")}</p>
                    <button onClick={closeBanner} className="absolute top-2 right-4 text-black">X</button>
                </div>
            );
        }
        return null;
    };

    return (
        <div className="z-50 flex flex-col h-[100vh] w-full">
            {renderBanner()}
            <TopMenu header={text} />
            <div className="flex w-full h-[88vh]">
                <div className="w-[18%] pl-[3%] bg-primary hidden md:flex">
                    <SideMenu />
                </div>

                <div className={`flex flex-col flex-grow overflow-auto w-full md:w-[82%] pt-8 pb-16 md:pb-8 px-[5%] md:pl-8 md:pr-[3%] h-auto ${backgroundColor}`}>
                    {children}
                </div>
            </div>

            <Footer />
            <div className="md:hidden fixed bottom-0 w-full">
                <BottomNav />
            </div>
        </div>
    );
};

export default Layout;
