import React, { useContext, useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import Layout from '../elements/Layout';
import { IoMdSearch } from "react-icons/io";
import { HiOutlineSparkles, HiOutlineHome, HiOutlineDocumentText, HiOutlineClock, HiOutlineClipboardCheck } from "react-icons/hi";
import { fetchCustomerStats } from '../utils/api/api';
import toast from 'react-hot-toast';
import Loading from '../elements/Loading';
import Cookies from 'js-cookie';
import { AuthContext } from '../utils/context/AuthContext';
import { Link } from 'react-router-dom';
import WalletBalance from '../minicomponents/WalletBalance';

const Dashboard = () => {
  const [stats, setStats] = useState([]);
  const [loading, setLoading] = useState(false);
  const { user } = useContext(AuthContext);
  const customer_id = Cookies.get('user_id');
  const [hoverIndex, setHoverIndex] = useState(null);

  const getStats = async () => {
    setLoading(true)
    try {
      const response = await fetchCustomerStats(customer_id);
      setStats(response.data);
    } catch (error) {
      toast.error("Failed to get stats")
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getStats()
  }, [])

  // Animation variants
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.5, ease: [0.22, 1, 0.36, 1] }
    }
  };

  const cardVariants = {
    hover: {
      y: -5,
      boxShadow: "0 10px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)",
      transition: { duration: 0.3 }
    }
  };

  const statsItems = [
    {
      label: "Properties Listed",
      value: stats?.properties || 0,
      color: "from-blue-500/20 to-blue-600/20",
      borderColor: "border-blue-200/50",
      textColor: "text-blue-700",
      icon: <HiOutlineHome className="text-blue-500" size={24} />
    },
    {
      label: "Your Purchases",
      value: stats?.my_properties || 0,
      color: "from-green-500/20 to-green-600/20",
      borderColor: "border-green-200/50",
      textColor: "text-green-700",
      icon: <HiOutlineClipboardCheck className="text-green-500" size={24} />
    },
    {
      label: "In Progress",
      value: stats?.purchases_inProgress || 0,
      color: "from-yellow-400/20 to-yellow-500/20",
      borderColor: "border-yellow-200/50",
      textColor: "text-yellow-700",
      icon: <HiOutlineClock className="text-yellow-500" size={24} />
    },
    {
      label: "Contracts",
      value: stats?.contracts || 0,
      color: "from-indigo-500/20 to-indigo-600/20",
      borderColor: "border-indigo-200/50",
      textColor: "text-indigo-700",
      icon: <HiOutlineDocumentText className="text-indigo-500" size={24} />
    },
  ];

  return (
    <Layout text="Dashboard" backgroundColor="bg-gray-50/50">
      {loading && <Loading />}

      {/* Background decorative elements */}
      <div className="fixed inset-0 -z-10 pointer-events-none overflow-hidden">
        <div className="absolute top-40 left-20 w-64 h-64 rounded-full bg-primary/5 blur-3xl"></div>
        <div className="absolute bottom-20 right-20 w-80 h-80 rounded-full bg-secondary/5 blur-3xl"></div>
      </div>

      <motion.div
        variants={containerVariants}
        initial="hidden"
        animate="visible"
        className="w-full"
      >
        {/* Header greeting and search */}
        <motion.div
          variants={itemVariants}
          className="flex flex-col md:flex-row items-start md:items-center justify-between w-full gap-4"
        >
          <div className="flex items-center gap-3">
            <div className="w-12 h-12 rounded-xl backdrop-blur-lg bg-primary/10 flex items-center justify-center">
              <HiOutlineSparkles className="text-primary" size={24} />
            </div>
            <div>
              <h1 className="text-2xl font-bold bg-gradient-to-r from-primary to-secondary bg-clip-text text-transparent">
                Hello, {user?.first_name}
              </h1>
              <p className="text-gray-600">Welcome back to your dashboard</p>
            </div>
          </div>

          <div className="w-full md:w-auto">
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <IoMdSearch className="text-gray-400" size={20} />
              </div>
              <input
                type="text"
                placeholder="Search properties, contracts..."
                className="w-full md:w-64 pl-10 pr-4 py-2.5 rounded-xl backdrop-blur-lg bg-white/70 border border-white/50 shadow-sm focus:outline-none focus:ring-2 focus:ring-primary/20 transition-all"
              />
            </div>
          </div>
        </motion.div>

        {/* Stats cards */}
        <motion.div
          variants={itemVariants}
          className="mt-8 w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4"
        >
          {statsItems.map((stat, idx) => (
            <motion.div
              key={idx}
              variants={cardVariants}
              whileHover="hover"
              onMouseEnter={() => setHoverIndex(idx)}
              onMouseLeave={() => setHoverIndex(null)}
              className={`w-full rounded-2xl p-5 backdrop-blur-xl bg-gradient-to-br ${stat.color} border ${stat.borderColor} shadow-sm relative overflow-hidden`}
            >
              <div className="absolute top-0 right-0 w-20 h-20 bg-white/20 rounded-full -mt-10 -mr-10 blur-xl"></div>

              <div className="flex justify-between items-start mb-6">
                <div className="p-3 rounded-xl bg-white/50 backdrop-blur-sm">
                  {stat.icon}
                </div>
                <div className={`text-3xl font-bold ${stat.textColor}`}>
                  {stat.value}
                </div>
              </div>

              <div className="flex justify-between items-end">
                <p className="text-gray-700 font-medium">{stat.label}</p>
                {hoverIndex === idx && (
                  <motion.div
                    initial={{ opacity: 0, scale: 0.8 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 0.2 }}
                  >
                    <Link to={`/dashboard/${stat.label.toLowerCase().replace(' ', '-')}`} className="text-sm font-medium text-primary">
                      View all
                    </Link>
                  </motion.div>
                )}
              </div>
            </motion.div>
          ))}
        </motion.div>

        {/* Main content and wallet */}
        <div className="flex flex-col-reverse md:flex-row gap-6 mt-8 w-full">
          {/* Projects grid */}
          <motion.div
            variants={itemVariants}
            className="flex flex-col w-full md:w-[65%]"
          >
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-xl font-semibold text-gray-800">Featured Projects</h2>
              <Link to="/dashboard/projects" className="text-sm font-medium text-primary hover:underline">
                View all projects
              </Link>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
              {stats?.popular_listing?.map((property, index) => (
                <motion.div
                  key={property.id}
                  variants={cardVariants}
                  whileHover="hover"
                  className="h-full"
                >
                  <Link
                    to={`/dashboard/projects/${property.id}/view`}
                    className="block h-full rounded-2xl backdrop-blur-xl bg-white/70 border border-white/50 shadow-sm overflow-hidden"
                  >
                    <div className="relative">
                      <img
                        src={
                          property?.images?.file ||
                          "https://imgs.search.brave.com/L0mOyBBDOxVD6jmTiwNh93bAa_8D_KweRLE_oYnq-Kg/rs:fit:500:0:0:0/g:ce/aHR0cHM6Ly9jZG4u/dmVjdG9yc3RvY2su/Y29tL2kvcHJldmll/dy0xeC82MS80Ni9n/YWxsZXJ5LWljb24t/c2lsaG91ZXR0ZS1v/Zi1hbi1pbWFnZS1w/aG90by1hbGJ1bS12/ZWN0b3ItNDU0MzYx/NDYuanBn"
                        }
                        alt={property.title}
                        className="w-full h-44 object-cover"
                      />

                      {/* Overlay gradient */}
                      <div className="absolute inset-0 bg-gradient-to-t from-black/40 to-transparent"></div>

                      {/* Property location tag */}
                      <div className="absolute bottom-3 left-3 px-3 py-1 rounded-full bg-white/30 backdrop-blur-md text-white text-xs font-medium">
                        {property.location || 'Prime Location'}
                      </div>
                    </div>

                    <div className="p-4">
                      <h3 className="text-gray-800 font-medium text-lg mb-1 truncate">
                        {property.title}
                      </h3>

                      <div className="flex items-center justify-between mt-2">
                        <div className="text-gray-600 text-sm">
                          {property.size ? `${property.size} sq.ft` : 'Land'}
                        </div>
                        <div className="px-3 py-1 rounded-full bg-primary/10 text-primary text-xs font-medium">
                          View Details
                        </div>
                      </div>
                    </div>
                  </Link>
                </motion.div>
              ))}

              {(!stats?.popular_listing || stats.popular_listing.length === 0) && (
                <div className="col-span-full py-12 flex flex-col items-center justify-center backdrop-blur-xl bg-white/50 rounded-2xl border border-white/50">
                  <HiOutlineHome className="text-gray-400 mb-3" size={40} />
                  <p className="text-gray-500 text-center">No properties available yet.</p>
                  <Link to="/dashboard/projects" className="mt-3 px-4 py-2 bg-primary/10 text-primary rounded-full text-sm font-medium">
                    Browse All Projects
                  </Link>
                </div>
              )}
            </div>
          </motion.div>

          {/* Wallet Balance */}
          <WalletBalance />
        </div>
      </motion.div>
    </Layout>
  );
}

export default Dashboard;