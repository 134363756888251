import React, { useEffect, useState, useRef } from 'react';
import toast from 'react-hot-toast';
import Cookies from 'js-cookie';
import Loading from './elements/Loading';
import { fetchCustomerById, updateCustomer } from './utils/api/api';
import { IoIosArrowForward } from 'react-icons/io';
import { MdOutlinePhotoCamera } from 'react-icons/md';
import Layout from './elements/Layout';
import GlassInput from './components/GlassInput';
import GlassSection from './components/GlassSection';

const EditInfo = () => {
    const [loading, setLoading] = useState(false);
    const [activeSection, setActiveSection] = useState('personal');
    const [previewImage, setPreviewImage] = useState(null);
    const customerId = Cookies.get('user_id');
    const [editCustomerData, setEditCustomerData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        phone_number: '',
        id_number: '',
        dob: '',
        gender: '',
        nationality: '',
        city: '',
        state: '',
        country: '',
        next_of_kin1_name: '',
        next_of_kin1_phone: '',
        next_of_kin1_email: '',
        next_of_kin1_idNo: '',
        next_of_kin1_relationship: '',
        next_of_kin2_name: '',
        next_of_kin2_phone: '',
        next_of_kin2_email: '',
        next_of_kin2_idNo: '',
        next_of_kin2_relationship: '',
        photo: '',
        is_group: false,
        participants: '',
    });

    useEffect(() => {
        const getCustomer = async () => {
            try {
                const data = await fetchCustomerById(customerId);
                setEditCustomerData({ ...data.customer, photo: null });
                setLoading(false);
            } catch (err) {
                setLoading(false);
            }
        };

        getCustomer();
    }, [customerId]);

    const handleEditInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        setEditCustomerData({
            ...editCustomerData,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setEditCustomerData((prevData) => ({
            ...prevData,
            photo: file || null,
        }));
    };

    const handleCustomerFormSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();

        const fieldsToSend = [
            'id',
            'email',
            'first_name',
            'last_name',
            'phone_number',
            'id_number',
            'dob',
            'gender',
            'nationality',
            'city',
            'state',
            'country',
            'next_of_kin1_name',
            'next_of_kin1_phone',
            'next_of_kin1_email',
            'next_of_kin1_idNo',
            'next_of_kin1_relationship',
            'next_of_kin2_name',
            'next_of_kin2_phone',
            'next_of_kin2_email',
            'next_of_kin2_idNo',
            'next_of_kin2_relationship',
            'photo',
            'is_group',
            'participants',
        ];

        fieldsToSend.forEach((field) => {
            if (editCustomerData[field] !== undefined) {
                formData.append(field, editCustomerData[field]);
            }
        });

        for (const pair of formData.entries()) {
            console.log(`${pair[0]}: ${pair[1]}`);
        }

        setLoading(true);
        try {
            const response = await updateCustomer(formData);
            toast.success('Info updated');
        } catch (error) {
            console.error('Update failed:', error);
            toast.error(error?.response?.data?.message || 'Failed to update');
        } finally {
            setLoading(false);
        }
    };

    return (
        <Layout>
            <div className="min-h-screen bg-gradient-to-br from-blue-50 to-purple-50">
                {loading && <Loading />}

                <div className="relative z-10 max-w-4xl mx-auto px-4 py-8">
                    <header className="mb-8">
                        <h1 className="text-2xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-purple-600">
                            Edit Profile
                        </h1>
                        <p className="text-gray-600 mt-2">Update your personal information and preferences</p>
                    </header>

                    {/* Profile Photo */}
                    <div className="relative mb-8 overflow-hidden rounded-2xl backdrop-blur-md">
                        <div className="absolute inset-0 bg-white/60 -z-10"></div>
                        <div className="absolute inset-0 border border-white/50 rounded-2xl"></div>
                        <div className="p-6 flex flex-col md:flex-row items-center gap-6">
                            <div className="relative group">
                                <div className="w-28 h-28 rounded-full overflow-hidden border-2 border-white/70 shadow-lg">
                                    <img
                                        src={previewImage || "https://imgs.search.brave.com/fbxInw05M6mkNEHaT64Qm3dNEzTIVXrwCD4lxk4ve3A/rs:fit:500:0:0:0/g:ce/aHR0cHM6Ly90NC5m/dGNkbi5uZXQvanBn/LzAyLzE3LzM0LzY3/LzM2MF9GXzIxNzM0/Njc4Ml83WHBDVHQ4/YkxOSnF2VkFhRFpK/d3Zaam0wZXBRbWo2/ai5qcGc"}
                                        alt="Profile"
                                        className="w-full h-full object-cover"
                                    />
                                </div>
                                <label className="absolute inset-0 flex items-center justify-center bg-black/40 opacity-0 group-hover:opacity-100 transition-opacity duration-200 rounded-full cursor-pointer">
                                    <MdOutlinePhotoCamera size={24} className="text-white" />
                                    <input
                                        type="file"
                                        name="photo"
                                        onChange={handleFileChange}
                                        accept="image/*"
                                        className="hidden"
                                    />
                                </label>
                            </div>
                            <div>
                                <h3 className="text-lg font-semibold text-gray-800">Your Profile</h3>
                            </div>
                        </div>
                    </div>

                    {/* Form Navigation Tabs */}
                    <div className="flex mb-8 gap-4 overflow-x-auto no-scrollbar">
                        <button
                            className={`relative flex-shrink-0 py-3 px-5 rounded-xl text-sm font-medium flex items-center gap-2 transition-all duration-300 backdrop-blur-lg border overflow-hidden ${activeSection === 'personal'
                                ? 'bg-white/60 border-white/70 shadow-lg text-blue-700'
                                : 'bg-white/30 border-white/40 text-gray-700 hover:bg-white/40'
                                }`}
                            onClick={() => setActiveSection('personal')}
                        >
                            {activeSection === 'personal' && (
                                <div className="absolute inset-0 bg-gradient-to-r from-blue-200/20 to-purple-200/20 -z-10"></div>
                            )}
                            <span>Personal Details</span>
                        </button>
                        <button
                            className={`relative flex-shrink-0 py-3 px-5 rounded-xl text-sm font-medium flex items-center gap-2 transition-all duration-300 backdrop-blur-lg border overflow-hidden ${activeSection === 'address'
                                ? 'bg-white/60 border-white/70 shadow-lg text-blue-700'
                                : 'bg-white/30 border-white/40 text-gray-700 hover:bg-white/40'
                                }`}
                            onClick={() => setActiveSection('address')}
                        >
                            {activeSection === 'address' && (
                                <div className="absolute inset-0 bg-gradient-to-r from-blue-200/20 to-purple-200/20 -z-10"></div>
                            )}
                            <span>Location</span>
                        </button>
                        <button
                            className={`relative flex-shrink-0 py-3 px-5 rounded-xl text-sm font-medium flex items-center gap-2 transition-all duration-300 backdrop-blur-lg border overflow-hidden ${activeSection === 'nextofkin1'
                                ? 'bg-white/60 border-white/70 shadow-lg text-blue-700'
                                : 'bg-white/30 border-white/40 text-gray-700 hover:bg-white/40'
                                }`}
                            onClick={() => setActiveSection('nextofkin1')}
                        >
                            {activeSection === 'nextofkin1' && (
                                <div className="absolute inset-0 bg-gradient-to-r from-blue-200/20 to-purple-200/20 -z-10"></div>
                            )}
                            <span>Primary Contact</span>
                        </button>
                        <button
                            className={`relative flex-shrink-0 py-3 px-5 rounded-xl text-sm font-medium flex items-center gap-2 transition-all duration-300 backdrop-blur-lg border overflow-hidden ${activeSection === 'nextofkin2'
                                ? 'bg-white/60 border-white/70 shadow-lg text-blue-700'
                                : 'bg-white/30 border-white/40 text-gray-700 hover:bg-white/40'
                                }`}
                            onClick={() => setActiveSection('nextofkin2')}
                        >
                            {activeSection === 'nextofkin2' && (
                                <div className="absolute inset-0 bg-gradient-to-r from-blue-200/20 to-purple-200/20 -z-10"></div>
                            )}
                            <span>Secondary Contact</span>
                        </button>
                        <button
                            className={`relative flex-shrink-0 py-3 px-5 rounded-xl text-sm font-medium flex items-center gap-2 transition-all duration-300 backdrop-blur-lg border overflow-hidden ${activeSection === 'account'
                                ? 'bg-white/60 border-white/70 shadow-lg text-blue-700'
                                : 'bg-white/30 border-white/40 text-gray-700 hover:bg-white/40'
                                }`}
                            onClick={() => setActiveSection('account')}
                        >
                            {activeSection === 'account' && (
                                <div className="absolute inset-0 bg-gradient-to-r from-blue-200/20 to-purple-200/20 -z-10"></div>
                            )}
                            <span>Account Settings</span>
                        </button>
                    </div>

                    <form onSubmit={handleCustomerFormSubmit}>
                        {/* Personal Details Section */}
                        <GlassSection title="Personal Information" isActive={activeSection === 'personal'}>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                                <GlassInput
                                    label="First Name"
                                    name="first_name"
                                    value={editCustomerData.first_name}
                                    onChange={handleEditInputChange}
                                    required
                                />
                                <GlassInput
                                    label="Last Name"
                                    name="last_name"
                                    value={editCustomerData.last_name}
                                    onChange={handleEditInputChange}
                                    required
                                />
                            </div>
                            <GlassInput
                                label="Email Address"
                                name="email"
                                type="email"
                                value={editCustomerData.email}
                                onChange={handleEditInputChange}
                                required
                            />
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                                <GlassInput
                                    label="Phone Number"
                                    name="phone_number"
                                    value={editCustomerData.phone_number}
                                    onChange={handleEditInputChange}
                                    required
                                />
                                <GlassInput
                                    label="ID Number"
                                    name="id_number"
                                    value={editCustomerData.id_number}
                                    onChange={handleEditInputChange}
                                    required
                                />
                            </div>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                                <GlassInput
                                    label="Date of Birth"
                                    name="dob"
                                    type="date"
                                    value={editCustomerData.dob}
                                    onChange={handleEditInputChange}
                                    required
                                />
                                <GlassInput
                                    label="Gender"
                                    name="gender"
                                    type="select"
                                    value={editCustomerData.gender}
                                    onChange={handleEditInputChange}
                                    required
                                    options={[
                                        { value: 'Male', label: 'Male' },
                                        { value: 'Female', label: 'Female' },
                                        { value: 'Other', label: 'Other' }
                                    ]}
                                />
                            </div>
                        </GlassSection>

                        {/* Address Section */}
                        <GlassSection title="Location Information" isActive={activeSection === 'address'}>
                            <GlassInput
                                label="Nationality"
                                name="nationality"
                                value={editCustomerData.nationality}
                                onChange={handleEditInputChange}
                                required
                            />
                            <GlassInput
                                label="Country"
                                name="country"
                                value={editCustomerData.country}
                                onChange={handleEditInputChange}
                                required
                            />
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                                <GlassInput
                                    label="City"
                                    name="city"
                                    value={editCustomerData.city}
                                    onChange={handleEditInputChange}
                                    required
                                />
                                <GlassInput
                                    label="State/Province"
                                    name="state"
                                    value={editCustomerData.state}
                                    onChange={handleEditInputChange}
                                    required
                                />
                            </div>
                        </GlassSection>

                        {/* Next of Kin 1 */}
                        <GlassSection title="Primary Emergency Contact" isActive={activeSection === 'nextofkin1'}>
                            <GlassInput
                                label="Full Name"
                                name="next_of_kin1_name"
                                value={editCustomerData.next_of_kin1_name}
                                onChange={handleEditInputChange}
                                required
                            />
                            <GlassInput
                                label="Phone Number"
                                name="next_of_kin1_phone"
                                value={editCustomerData.next_of_kin1_phone}
                                onChange={handleEditInputChange}
                                required
                            />
                            <GlassInput
                                label="Email Address"
                                name="next_of_kin1_email"
                                type="email"
                                value={editCustomerData.next_of_kin1_email}
                                onChange={handleEditInputChange}
                            />
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                                <GlassInput
                                    label="ID Number"
                                    name="next_of_kin1_idNo"
                                    value={editCustomerData.next_of_kin1_idNo}
                                    onChange={handleEditInputChange}
                                />
                                <GlassInput
                                    label="Relationship"
                                    name="next_of_kin1_relationship"
                                    value={editCustomerData.next_of_kin1_relationship}
                                    onChange={handleEditInputChange}
                                />
                            </div>
                        </GlassSection>

                        {/* Next of Kin 2 */}
                        <GlassSection title="Secondary Emergency Contact" isActive={activeSection === 'nextofkin2'}>
                            <GlassInput
                                label="Full Name"
                                name="next_of_kin2_name"
                                value={editCustomerData.next_of_kin2_name}
                                onChange={handleEditInputChange}
                            />
                            <GlassInput
                                label="Phone Number"
                                name="next_of_kin2_phone"
                                value={editCustomerData.next_of_kin2_phone}
                                onChange={handleEditInputChange}
                            />
                            <GlassInput
                                label="Email Address"
                                name="next_of_kin2_email"
                                type="email"
                                value={editCustomerData.next_of_kin2_email}
                                onChange={handleEditInputChange}
                            />
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                                <GlassInput
                                    label="ID Number"
                                    name="next_of_kin2_idNo"
                                    value={editCustomerData.next_of_kin2_idNo}
                                    onChange={handleEditInputChange}
                                />
                                <GlassInput
                                    label="Relationship"
                                    name="next_of_kin2_relationship"
                                    value={editCustomerData.next_of_kin2_relationship}
                                    onChange={handleEditInputChange}
                                />
                            </div>
                        </GlassSection>

                        {/* Account Settings */}
                        <GlassSection title="Account Settings" isActive={activeSection === 'account'}>
                            <div className="mb-6">
                                <GlassInput
                                    label="Group Account"
                                    name="is_group"
                                    type="checkbox"
                                    value={editCustomerData.is_group}
                                    onChange={handleEditInputChange}
                                />
                                <p className="ml-7 text-sm text-gray-500">Enable this option for business or group accounts</p>
                            </div>

                            {editCustomerData.is_group && (
                                <GlassInput
                                    label="Participants"
                                    name="participants"
                                    type="textarea"
                                    value={editCustomerData.participants}
                                    onChange={handleEditInputChange}
                                />
                            )}
                        </GlassSection>

                        {/* Submit Button Section */}
                        <div className="flex justify-between items-center mt-8">
                            <button
                                type="button"
                                onClick={() => window.history.back()}
                                className="px-6 py-3 rounded-xl font-medium text-gray-700 bg-white/40 backdrop-blur-md border border-white/50 hover:bg-white/60 transition-all"
                            >
                                Cancel
                            </button>
                            <button
                                type="submit"
                                className="px-8 py-3 rounded-xl font-medium text-white bg-gradient-to-r from-blue-500/90 to-purple-500/90 backdrop-blur-md border border-white/30 hover:shadow-lg transition-all"
                            >
                                Save Changes
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </Layout>
    );
};

export default EditInfo;