import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import axios from 'axios';
import logo from '../../assets/miles_logo.svg';
import image from '../../assets/login.jpg';
import toast from 'react-hot-toast';
import { FaFacebook, FaGoogle, FaApple, FaRegEye, FaRegEyeSlash, FaCheckCircle } from 'react-icons/fa';
import { HiOutlineChevronDown, HiOutlineShieldCheck } from 'react-icons/hi';
import { IoIosArrowBack } from 'react-icons/io';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { motion, AnimatePresence } from 'framer-motion';

const SignUp = () => {
  const [formData, setFormData] = useState({
    email: '',
    first_name: '',
    last_name: '',
    phone_number: '',
    id_number: '',
    dob: '',
    gender: '',
    nationality: '',
    city: '',
    state: '',
    country: '',
    password: '',
    referral_code: '',
  });

  const [formStage, setFormStage] = useState(1);
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const [countryCode, setCountryCode] = useState('+254');
  const [countryCodes, setCountryCodes] = useState([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();
  const API_KEY = '14CA4D6A5CE4D441CFBFB23CCB699';

  useEffect(() => {
    const fetchCountryCodes = async () => {
      try {
        const response = await axios.get('https://restcountries.com/v3.1/all');
        const countryData = response.data.map(country => ({
          name: country.name.common,
          code: country.idd.root + (country.idd.suffixes ? country.idd.suffixes[0] : ''),
          flag: country.flags.svg,
        }));

        const sortedCountryData = countryData.sort((a, b) => a.name.localeCompare(b.name));
        setCountryCodes(sortedCountryData);
      } catch (error) {
        console.error('Error fetching country codes:', error);
      }
    };

    fetchCountryCodes();
    const params = new URLSearchParams(window.location.search);
    const referralCode = params.get('referralCode');
    if (referralCode) {
      setFormData(prev => ({
        ...prev,
        referral_code: referralCode,
      }));
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handlePhoneNumber = (number) => {
    // Extract the last 9 digits
    const lastNineDigits = number.slice(-9);
    // Prepend the selected country code
    return countryCode + lastNineDigits;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.email || !formData.password) {
      setError('Email and password are required');
      return;
    }

    try {
      setLoading(true);
      setError('');

      const processedPhoneNumber = handlePhoneNumber(formData.phone_number);

      const response = await axios.post('https://api.milescoop.com/api/v1/auth/customer-signup',
        { ...formData, phone_number: processedPhoneNumber },
        {
          headers: {
            'Content-Type': 'application/json',
            'api-key': API_KEY,
          }
        }
      );

      const { token, customer } = response.data;
      const userId = customer.id;

      Cookies.set('access_token', token, { expires: 1 });
      Cookies.set('user_id', userId, { expires: 1 });
      toast.success("Registration successful");
      navigate('/dashboard');
    } catch (err) {
      const errorMessage = err.response?.data?.message || err.message || err.response?.data?.errors?.join(', ') || 'Error signing up. Please try again.';
      setError(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  const handleGoogleLogin = async (googleResponse) => {
    try {
      const googleToken = googleResponse.credential;

      const response = await axios.post(
        'https://api.milescoop.com/api/v1/auth/social-signin',
        {
          account: 'google',
          token: googleToken,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'api-key': API_KEY,
          }
        }
      );

      const { token, customer } = response.data;
      const userId = customer.id;

      Cookies.set('access_token', token, { expires: 1 });
      Cookies.set('user_id', userId, { expires: 1 });

      toast.success("Google login successful");
      navigate('/dashboard');
    } catch (error) {
      setError('Google login failed. Please try again.');
    }
  };

  const handleFacebookLogin = () => {
    console.log('Facebook Login');
  };

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

  const handleCountrySelect = (country) => {
    setCountryCode(country.code);
    setFormData({
      ...formData,
      country: country.name,
    });
    setIsDropdownOpen(false);
  };

  const filteredCountryCodes = countryCodes.filter(country =>
    country.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    country.code.includes(searchTerm)
  );

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const nextStage = () => {
    if (formStage < 3) {
      setFormStage(formStage + 1);
    }
  };

  const prevStage = () => {
    if (formStage > 1) {
      setFormStage(formStage - 1);
    }
  };

  // Animation variants
  const fadeIn = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.5, ease: [0.22, 1, 0.36, 1] }
    },
    exit: {
      opacity: 0,
      y: -20,
      transition: { duration: 0.3, ease: [0.22, 1, 0.36, 1] }
    }
  };

  const staggerContainer = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  return (
    <GoogleOAuthProvider clientId="71841088397-n30t73grcio6uti847fs86avg0hdh0ak.apps.googleusercontent.com">
      <div className="flex justify-center items-center min-h-screen w-full relative">
        {/* Animated background gradient */}
        <div className="fixed inset-0 z-0 overflow-hidden">
          <motion.div
            animate={{
              rotate: [0, 360],
              scale: [1, 1.2, 1],
              opacity: [0.05, 0.1, 0.05]
            }}
            transition={{
              duration: 25,
              ease: "linear",
              repeat: Infinity,
              repeatType: "reverse"
            }}
            className="absolute top-0 left-0 w-[80vw] h-[80vh] rounded-full bg-gradient-to-r from-primary/30 to-secondary/30 blur-3xl"
          />
          <motion.div
            animate={{
              rotate: [360, 0],
              scale: [1, 1.2, 1],
              opacity: [0.05, 0.08, 0.05]
            }}
            transition={{
              duration: 20,
              ease: "linear",
              repeat: Infinity,
              repeatType: "reverse",
              delay: 5
            }}
            className="absolute bottom-0 right-0 w-[70vw] h-[70vh] rounded-full bg-gradient-to-r from-secondary/30 to-primary/30 blur-3xl"
          />
        </div>

        {/* Main content */}
        <div className="flex justify-center items-stretch min-h-screen w-full relative z-10">
          <motion.div
            initial={{ opacity: 0, scale: 0.98 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.6 }}
            className="flex flex-col h-full w-full md:w-[50%] overflow-y-auto py-8 px-4 md:px-8"
          >
            <div className="relative backdrop-blur-xl bg-white/70 p-8 rounded-3xl border border-white/50 shadow-lg overflow-hidden max-w-xl mx-auto w-full">
              {/* Logo and header */}
              <motion.div
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.2 }}
                className="mb-6 flex flex-col items-center"
              >
                <img src={logo} className='w-16 mb-4' alt="MileScoop Logo" />
                <h1 className="text-2xl font-bold text-center bg-gradient-to-r from-primary to-secondary bg-clip-text text-transparent">
                  Welcome to Milescoop
                </h1>
                <p className='text-center mt-2 text-gray-600 text-sm'>
                  Access exclusive land listings & property recommendations
                </p>
              </motion.div>

              {/* Form progress indicator */}
              <motion.div
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.3 }}
                className="mb-8"
              >
                <div className="flex items-center justify-between w-full">
                  <div className="w-full flex items-center">
                    <div className={`h-8 w-8 rounded-full flex items-center justify-center ${formStage >= 1 ? 'bg-primary text-white' : 'bg-gray-200'}`}>
                      {formStage > 1 ? <FaCheckCircle /> : 1}
                    </div>
                    <div className={`h-1 flex-1 ${formStage >= 2 ? 'bg-primary' : 'bg-gray-200'}`}></div>
                  </div>
                  <div className="w-full flex items-center">
                    <div className={`h-8 w-8 rounded-full flex items-center justify-center ${formStage >= 2 ? 'bg-primary text-white' : 'bg-gray-200'}`}>
                      {formStage > 2 ? <FaCheckCircle /> : 2}
                    </div>
                    <div className={`h-1 flex-1 ${formStage >= 3 ? 'bg-primary' : 'bg-gray-200'}`}></div>
                  </div>
                  <div className={`h-8 w-8 rounded-full flex items-center justify-center ${formStage >= 3 ? 'bg-primary text-white' : 'bg-gray-200'}`}>
                    3
                  </div>
                </div>
                <div className="flex justify-between text-xs text-gray-500 mt-1 px-1">
                  <span>Account</span>
                  <span>Personal Info</span>
                  <span>Location</span>
                </div>
              </motion.div>

              {/* Form with multi-step stages */}
              <form onSubmit={handleSubmit} className="relative">
                <AnimatePresence mode="wait">
                  {formStage === 1 && (
                    <motion.div
                      key="stage1"
                      variants={staggerContainer}
                      initial="hidden"
                      animate="visible"
                      exit="exit"
                      className="space-y-1"
                    >
                      <motion.div variants={fadeIn}>
                        <GlassInput
                          label="Email Address"
                          id="email"
                          name="email"
                          type="email"
                          value={formData.email}
                          placeholder="example@example.com"
                          onChange={handleChange}
                          required
                        />
                      </motion.div>

                      <motion.div variants={fadeIn} className="mb-5">
                        <label htmlFor="password" className="block text-sm font-medium text-gray-700 mb-2">
                          Password (8 or more characters) <span className="text-rose-500">*</span>
                        </label>
                        <div className="relative">
                          <input
                            type={showPassword ? "text" : "password"}
                            id="password"
                            name="password"
                            className="w-full h-12 pl-4 pr-12 rounded-xl bg-white/50 backdrop-blur-md border focus:border-secondary focus:ring-2 focus:ring-secondary/20 outline-none transition-all text-gray-800"
                            value={formData.password}
                            placeholder="••••••••"
                            onChange={handleChange}
                            required
                          />
                          <button
                            type="button"
                            className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-500 hover:text-gray-700"
                            onClick={togglePasswordVisibility}
                          >
                            {showPassword ? <FaRegEyeSlash /> : <FaRegEye />}
                          </button>
                        </div>

                        {formData.password && (
                          <div className="mt-2 grid grid-cols-2 gap-2">
                            <div className={`text-xs flex items-center ${formData.password.length >= 8 ? 'text-green-600' : 'text-gray-500'}`}>
                              <span className="w-3 h-3 mr-1 inline-block">
                                {formData.password.length >= 8 ? <FaCheckCircle className="w-full h-full" /> : '•'}
                              </span>
                              At least 8 characters
                            </div>
                            <div className={`text-xs flex items-center ${/[A-Z]/.test(formData.password) ? 'text-green-600' : 'text-gray-500'}`}>
                              <span className="w-3 h-3 mr-1 inline-block">
                                {/[A-Z]/.test(formData.password) ? <FaCheckCircle className="w-full h-full" /> : '•'}
                              </span>
                              1 uppercase letter
                            </div>
                            <div className={`text-xs flex items-center ${/[0-9]/.test(formData.password) ? 'text-green-600' : 'text-gray-500'}`}>
                              <span className="w-3 h-3 mr-1 inline-block">
                                {/[0-9]/.test(formData.password) ? <FaCheckCircle className="w-full h-full" /> : '•'}
                              </span>
                              1 number
                            </div>
                            <div className={`text-xs flex items-center ${/[!@#$%^&*(),.?":{}|<>]/.test(formData.password) ? 'text-green-600' : 'text-gray-500'}`}>
                              <span className="w-3 h-3 mr-1 inline-block">
                                {/[!@#$%^&*(),.?":{}|<>]/.test(formData.password) ? <FaCheckCircle className="w-full h-full" /> : '•'}
                              </span>
                              1 special character
                            </div>
                          </div>
                        )}
                      </motion.div>

                      <motion.div variants={fadeIn} className="flex justify-between items-center pt-4">
                        <Link to="/accounts/login" className="text-sm text-primary flex items-center gap-1">
                          <IoIosArrowBack size={16} />
                          Back to login
                        </Link>
                        <motion.button
                          whileHover={{ scale: 1.03 }}
                          whileTap={{ scale: 0.98 }}
                          type="button"
                          onClick={nextStage}
                          className="px-6 py-2 text-[14px] rounded-xl bg-secondary backdrop-blur-sm border border-white/10 shadow-lg hover:shadow-xl transition-all text-white font-medium"
                        >
                          Continue
                        </motion.button>
                      </motion.div>
                    </motion.div>
                  )}

                  {formStage === 2 && (
                    <motion.div
                      key="stage2"
                      variants={staggerContainer}
                      initial="hidden"
                      animate="visible"
                      exit="exit"
                      className="space-y-1"
                    >
                      <div className="grid grid-cols-2 gap-4">
                        <motion.div variants={fadeIn}>
                          <GlassInput
                            label="First Name"
                            id="first_name"
                            name="first_name"
                            type="text"
                            value={formData.first_name}
                            placeholder="John"
                            onChange={handleChange}
                            required
                          />
                        </motion.div>
                        <motion.div variants={fadeIn}>
                          <GlassInput
                            label="Last Name"
                            id="last_name"
                            name="last_name"
                            type="text"
                            value={formData.last_name}
                            placeholder="Doe"
                            onChange={handleChange}
                            required
                          />
                        </motion.div>
                      </div>

                      <motion.div variants={fadeIn} className="mb-5">
                        <label htmlFor="phone_number" className="block text-sm font-medium text-gray-700 mb-2">
                          Phone Number <span className="text-rose-500">*</span>
                        </label>
                        <div className="flex gap-2 items-center">
                          <div className="relative w-[35%]">
                            <button
                              type="button"
                              className="w-full h-12 pl-4 pr-4 rounded-xl bg-white/50 backdrop-blur-md border border-white/50 focus:border-secondary focus:ring-2 focus:ring-secondary/20 outline-none transition-all text-gray-800 flex items-center justify-between"
                              onClick={toggleDropdown}
                            >
                              <div className="flex items-center">
                                {countryCodes.find(country => country.code === countryCode)?.flag && (
                                  <img
                                    src={countryCodes.find(country => country.code === countryCode)?.flag}
                                    alt="flag"
                                    className="inline-block w-5 h-5 mr-2"
                                  />
                                )}
                                <span>{countryCode}</span>
                              </div>
                              <HiOutlineChevronDown className={`transition-transform ${isDropdownOpen ? 'rotate-180' : ''}`} />
                            </button>

                            <AnimatePresence>
                              {isDropdownOpen && (
                                <motion.div
                                  initial={{ opacity: 0, y: 10, height: 0 }}
                                  animate={{ opacity: 1, y: 0, height: 'auto' }}
                                  exit={{ opacity: 0, y: 10, height: 0 }}
                                  transition={{ duration: 0.3 }}
                                  className="absolute z-10 mt-1 w-[300px] max-h-[300px] overflow-y-auto rounded-xl backdrop-blur-xl bg-white/80 border border-white/50 shadow-lg"
                                >
                                  <div className="p-2 sticky top-0 bg-white/90 backdrop-blur-xl border-b border-gray-200/80">
                                    <input
                                      type="text"
                                      placeholder="Search country..."
                                      className="w-full p-2 rounded-lg border border-gray-200 text-sm"
                                      value={searchTerm}
                                      onChange={(e) => setSearchTerm(e.target.value)}
                                    />
                                  </div>
                                  <div className="py-1">
                                    {filteredCountryCodes.map(country => (
                                      <div
                                        key={country.code}
                                        className="flex items-center p-2 px-3 cursor-pointer hover:bg-gray-100/80 transition-colors"
                                        onClick={() => handleCountrySelect(country)}
                                      >
                                        {country.flag && (
                                          <img
                                            src={country.flag}
                                            alt={`${country.name} flag`}
                                            className="w-5 h-5 mr-2 rounded-sm object-cover"
                                          />
                                        )}
                                        <span className="text-sm">{country.code}</span>
                                        <span className="text-sm text-gray-600 ml-2 truncate">{country.name}</span>
                                      </div>
                                    ))}
                                  </div>
                                </motion.div>
                              )}
                            </AnimatePresence>
                          </div>

                          <div className="flex-1">
                            <input
                              type="text"
                              id="phone_number"
                              name="phone_number"
                              className="w-full h-12 pl-4 pr-4 rounded-xl bg-white/50 backdrop-blur-md border border-white/50 focus:border-secondary focus:ring-2 focus:ring-secondary/20 outline-none transition-all text-gray-800"
                              value={formData.phone_number}
                              placeholder="712345678"
                              onChange={handleChange}
                              required
                            />
                          </div>
                        </div>
                      </motion.div>

                      <div className="grid grid-cols-2 gap-4">
                        <motion.div variants={fadeIn}>
                          <GlassInput
                            label="ID Number"
                            id="id_number"
                            name="id_number"
                            type="text"
                            value={formData.id_number}
                            placeholder="12345678"
                            onChange={handleChange}
                            required
                          />
                        </motion.div>

                        <motion.div variants={fadeIn} className="mb-5">
                          <label htmlFor="dob" className="block text-sm font-medium text-gray-700 mb-2">
                            Date of Birth <span className="text-rose-500">*</span>
                          </label>
                          <div className="relative">
                            <input
                              type="date"
                              id="dob"
                              name="dob"
                              className="w-full h-12 pl-4 pr-4 rounded-xl bg-white/50 backdrop-blur-md border border-white/50 focus:border-secondary focus:ring-2 focus:ring-secondary/20 outline-none transition-all text-gray-800"
                              value={formData.dob}
                              onChange={handleChange}
                              required
                            />
                          </div>
                        </motion.div>
                      </div>

                      <motion.div variants={fadeIn} className="mb-5">
                        <label htmlFor="gender" className="block text-sm font-medium text-gray-700 mb-2">
                          Gender <span className="text-rose-500">*</span>
                        </label>
                        <div className="relative">
                          <select
                            id="gender"
                            name="gender"
                            className="w-full h-12 pl-4 pr-10 rounded-xl bg-white/50 backdrop-blur-md border border-white/50 focus:border-secondary focus:ring-2 focus:ring-secondary/20 outline-none transition-all text-gray-800 appearance-none"
                            value={formData.gender}
                            onChange={handleChange}
                            required
                          >
                            <option value="">Select Gender</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                            <option value="Other">Other</option>
                          </select>
                          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-4">
                            <HiOutlineChevronDown className="h-4 w-4 text-gray-500" />
                          </div>
                        </div>
                      </motion.div>

                      <motion.div variants={fadeIn} className="flex justify-between items-center pt-4">
                        <motion.button
                          whileHover={{ scale: 1.03 }}
                          whileTap={{ scale: 0.98 }}
                          type="button"
                          onClick={prevStage}
                          className="px-6 py-2 text-[14px] rounded-md bg-white/80 backdrop-blur-sm border border-gray-200 shadow-md hover:bg-white/90 transition-all text-gray-700 font-medium"
                        >
                          Back
                        </motion.button>
                        <motion.button
                          whileHover={{ scale: 1.03 }}
                          whileTap={{ scale: 0.98 }}
                          type="button"
                          onClick={nextStage}
                          className="px-6 py-2 text-[14px] rounded-md bg-secondary backdrop-blur-sm border border-white/10 shadow-lg hover:shadow-xl transition-all text-white font-medium"
                        >
                          Continue
                        </motion.button>
                      </motion.div>
                    </motion.div>
                  )}

                  {formStage === 3 && (
                    <motion.div
                      key="stage3"
                      variants={staggerContainer}
                      initial="hidden"
                      animate="visible"
                      exit="exit"
                      className="space-y-1"
                    >
                      <div className="grid grid-cols-2 gap-4">
                        <motion.div variants={fadeIn}>
                          <GlassInput
                            label="Nationality"
                            id="nationality"
                            name="nationality"
                            type="text"
                            value={formData.nationality}
                            placeholder="Kenyan"
                            onChange={handleChange}
                            required
                          />
                        </motion.div>
                        <motion.div variants={fadeIn}>
                          <GlassInput
                            label="City"
                            id="city"
                            name="city"
                            type="text"
                            value={formData.city}
                            placeholder="Nairobi"
                            onChange={handleChange}
                            required
                          />
                        </motion.div>
                      </div>

                      <div className="grid grid-cols-2 gap-4">
                        <motion.div variants={fadeIn}>
                          <GlassInput
                            label="State/Province"
                            id="state"
                            name="state"
                            type="text"
                            value={formData.state}
                            placeholder="Nairobi"
                            onChange={handleChange}
                            required
                          />
                        </motion.div>
                        <motion.div variants={fadeIn}>
                          <GlassInput
                            label="Country"
                            id="country"
                            name="country"
                            type="text"
                            value={formData.country}
                            placeholder="Kenya"
                            onChange={handleChange}
                            required
                          />
                        </motion.div>
                      </div>

                      <motion.div variants={fadeIn} className="mt-6 flex items-start gap-3">
                        <div className="pt-1">
                          <input
                            type="checkbox"
                            id="agreeToTerms"
                            checked={agreeToTerms}
                            required
                            onChange={() => setAgreeToTerms(!agreeToTerms)}
                            className="w-4 h-4 rounded border-gray-300 text-secondary focus:ring-secondary/20"
                          />
                        </div>
                        <label htmlFor="agreeToTerms" className="text-sm text-gray-700">
                          I agree to the
                          <Link to="/terms" className="text-secondary mx-1 hover:underline">
                            Terms & Conditions
                          </Link>
                          and
                          <Link to="/terms" className="text-secondary mx-1 hover:underline">
                            Privacy Policy
                          </Link>.
                        </label>
                      </motion.div>

                      {error && (
                        <motion.div
                          variants={fadeIn}
                          className="mt-4 p-3 bg-red-50 border border-red-200 rounded-lg text-red-600 text-sm"
                        >
                          {error}
                        </motion.div>
                      )}

                      <motion.div variants={fadeIn} className="flex justify-between items-center pt-4">
                        <motion.button
                          whileHover={{ scale: 1.03 }}
                          whileTap={{ scale: 0.98 }}
                          type="button"
                          onClick={prevStage}
                          className="px-6 py-3 rounded-xl bg-white/80 backdrop-blur-sm border border-gray-200 shadow-md hover:bg-white/90 transition-all text-gray-700 font-medium"
                        >
                          Back
                        </motion.button>
                        <motion.button
                          whileHover={{ scale: 1.03 }}
                          whileTap={{ scale: 0.98 }}
                          type="submit"
                          disabled={loading}
                          className="px-6 py-3 rounded-xl bg-gradient-to-r from-primary to-secondary backdrop-blur-sm border border-white/10 shadow-lg hover:shadow-xl transition-all text-white font-medium flex items-center gap-2"
                        >
                          {loading ? 'Creating Account...' : 'Create Account'}
                          {!loading && (
                            <HiOutlineShieldCheck className="text-white" />
                          )}
                        </motion.button>
                      </motion.div>

                      <motion.div variants={fadeIn} className="text-center mt-6">
                        <p className="text-sm text-gray-600">
                          Already have an account?{' '}
                          <Link className='text-secondary font-medium hover:underline' to='/accounts/login'>
                            Login
                          </Link>
                        </p>
                      </motion.div>
                    </motion.div>
                  )}
                </AnimatePresence>
              </form>
            </div>
          </motion.div>

          {/* Image side */}
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
            className="w-1/2 h-screen hidden md:block relative overflow-hidden"
          >
            <div className="absolute inset-0 bg-black/20 backdrop-blur-sm z-10"></div>
            <img src={image} className='object-cover h-full w-full' alt="Side banner" />

            {/* Fintech-inspired overlay elements */}
            <div className="absolute inset-x-0 bottom-0 z-20 p-10">
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.7, delay: 0.5 }}
                className="backdrop-blur-xl bg-white/20 border border-white/30 rounded-2xl p-6 shadow-xl"
              >
                <h2 className="text-white text-xl font-bold">Premium Land Investments</h2>
                <p className="text-white/90 mt-2">Secure your future with exclusive property opportunities</p>

                <div className="mt-4 grid grid-cols-3 gap-4">
                  <div className="bg-white/20 backdrop-blur-md rounded-xl p-3 text-center">
                    <p className="text-white/80 text-xs">Return Rate</p>
                    <p className="text-white text-xl font-bold">12.5%</p>
                  </div>
                  <div className="bg-white/20 backdrop-blur-md rounded-xl p-3 text-center">
                    <p className="text-white/80 text-xs">Properties</p>
                    <p className="text-white text-xl font-bold">500+</p>
                  </div>
                  <div className="bg-white/20 backdrop-blur-md rounded-xl p-3 text-center">
                    <p className="text-white/80 text-xs">Satisfaction</p>
                    <p className="text-white text-xl font-bold">98%</p>
                  </div>
                </div>
              </motion.div>
            </div>
          </motion.div>
        </div>
      </div>
    </GoogleOAuthProvider>
  );
};

const GlassInput = ({ label, id, name, type, value, placeholder, onChange, required }) => (
  <div className="mb-5">
    <label htmlFor={id} className="block text-sm font-medium text-gray-700 mb-2">
      {label} {required && <span className="text-rose-500">*</span>}
    </label>
    <div className="relative">
      <input
        type={type}
        id={id}
        name={name}
        className="w-full border h-12 pl-4 pr-4 rounded-xl bg-white/50 backdrop-blur-md focus:border-secondary focus:ring-2 focus:ring-secondary/20 outline-none transition-all text-gray-800"
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        required={required}
      />
    </div>
  </div>
);

export default SignUp;