import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence, LayoutGroup } from 'framer-motion';
import Layout from '../elements/Layout';
import moment from 'moment';
import { FaRegEye, FaSearch, FaFileContract, FaCalendarDay, FaCalendarCheck, FaChartPie, FaRegClock, FaSignature } from "react-icons/fa";
import { IoFilterOutline, IoTimeOutline, IoDocumentTextOutline, IoCheckmarkCircle, IoCloseCircle, IoHourglassOutline, IoAddOutline, IoChevronForward, IoStatsChart, IoCheckbox, IoCalendarOutline } from "react-icons/io5";
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { fetchCustomerContracts } from '../utils/api/api';
import toast from 'react-hot-toast';

const Contracts = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const id = Cookies.get('user_id');
  const [contracts, setContracts] = useState([]);
  const [statusFilter, setStatusFilter] = useState('');
  const [typeFilter, setTypeFilter] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [contractsPerPage] = useState(100);
  const [totalPages, setTotalPages] = useState(1);
  const [showFilters, setShowFilters] = useState(false);
  const [filteredContracts, setFilteredContracts] = useState([]);
  const [activeView, setActiveView] = useState('timeline'); // 'timeline', 'grid', or 'list'
  const [hoveredContract, setHoveredContract] = useState(null);
  const [expandedContract, setExpandedContract] = useState(null);

  // Calculate contract statistics
  const [stats, setStats] = useState({
    total: 0,
    approved: 0,
    pending: 0,
    cancelled: 0,
    activeValue: 0,
    nearingExpiry: 0,
  });

  const getContracts = async (page, size, id) => {
    setLoading(true);
    try {
      const filters = {};
      if (statusFilter) filters.status = statusFilter;
      if (typeFilter) filters.type = typeFilter;

      const data = await fetchCustomerContracts({
        page,
        customer_id: id,
        pageSize: size,
        ...filters,
      });

      setContracts(data.contracts);
      setFilteredContracts(data.contracts);
      setTotalPages(data.meta.totalPages);

      // Calculate statistics
      const stats = calculateStats(data.contracts);
      setStats(stats);

    } catch (err) {
      toast.error('Failed to fetch contracts.', {
        style: {
          borderRadius: '12px',
          background: '#333',
          color: '#fff',
        }
      });
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const calculateStats = (contracts) => {
    // Count by status
    const approved = contracts.filter(c => c.status.toLowerCase() === 'approved').length;
    const pending = contracts.filter(c => c.status.toLowerCase() === 'pending').length;
    const cancelled = contracts.filter(c => c.status.toLowerCase() === 'cancelled').length;

    // Calculate contracts nearing expiry (within 30 days)
    const now = moment();
    const nearingExpiry = contracts.filter(c => {
      const endDate = moment(c.end_date);
      const daysToExpiry = endDate.diff(now, 'days');
      return daysToExpiry > 0 && daysToExpiry <= 30 && c.status.toLowerCase() === 'approved';
    }).length;

    // For demonstration - assume each contract has a value
    // In a real app, you'd have a property like contract.value
    const activeValue = approved * 10000; // Just a placeholder calculation

    return {
      total: contracts.length,
      approved,
      pending,
      cancelled,
      activeValue,
      nearingExpiry
    };
  };

  useEffect(() => {
    getContracts(currentPage, contractsPerPage, id);
  }, [currentPage, statusFilter, typeFilter]);

  // Filter contracts based on search query
  useEffect(() => {
    if (searchQuery.trim() === '') {
      setFilteredContracts(contracts);
    } else {
      const lowercaseQuery = searchQuery.toLowerCase();
      const filtered = contracts.filter(contract =>
        contract.title.toLowerCase().includes(lowercaseQuery) ||
        contract.type.toLowerCase().includes(lowercaseQuery) ||
        contract.status.toLowerCase().includes(lowercaseQuery)
      );
      setFilteredContracts(filtered);
    }
  }, [searchQuery, contracts]);

  const getStatusStyle = (status) => {
    switch (status.toLowerCase()) {
      case 'approved':
        return {
          bg: 'bg-green-100',
          text: 'text-green-600',
          gradient: 'from-green-500 to-emerald-600',
          icon: <IoCheckmarkCircle className="mr-1" />,
          iconClass: 'text-green-500'
        };
      case 'pending':
        return {
          bg: 'bg-yellow-100',
          text: 'text-yellow-600',
          gradient: 'from-yellow-500 to-amber-600',
          icon: <IoHourglassOutline className="mr-1" />,
          iconClass: 'text-yellow-500'
        };
      case 'cancelled':
        return {
          bg: 'bg-red-100',
          text: 'text-red-600',
          gradient: 'from-red-500 to-rose-600',
          icon: <IoCloseCircle className="mr-1" />,
          iconClass: 'text-red-500'
        };
      default:
        return {
          bg: 'bg-gray-100',
          text: 'text-gray-600',
          gradient: 'from-gray-500 to-slate-600',
          icon: <IoDocumentTextOutline className="mr-1" />,
          iconClass: 'text-gray-500'
        };
    }
  };

  const getContractTypeIcon = (type) => {
    switch (type.toLowerCase()) {
      case 'sale':
        return <FaFileContract className="text-blue-500" />;
      case 'lease':
        return <FaCalendarDay className="text-purple-500" />;
      case 'service':
        return <FaCalendarCheck className="text-teal-500" />;
      default:
        return <IoDocumentTextOutline className="text-gray-500" />;
    }
  };

  // Calculate contract duration in days and percentage complete
  const getContractProgress = (startDate, endDate) => {
    const start = moment(startDate);
    const end = moment(endDate);
    const today = moment();

    const totalDuration = end.diff(start, 'days');
    const elapsed = today.diff(start, 'days');

    let percentComplete = (elapsed / totalDuration) * 100;
    percentComplete = Math.max(0, Math.min(100, percentComplete));

    const daysRemaining = end.diff(today, 'days');

    return {
      percentComplete,
      daysRemaining,
      totalDuration,
      elapsed,
      isExpired: false
    };
  };

  // Animation variants
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        when: "beforeChildren",
        staggerChildren: 0.1
      }
    }
  };

  const statCardVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        type: "spring",
        damping: 20,
        stiffness: 100
      }
    },
    hover: {
      y: -5,
      boxShadow: "0 10px 25px rgba(0, 0, 0, 0.08)",
      transition: { duration: 0.2 }
    }
  };

  const timelineItemVariants = {
    hidden: { opacity: 0, x: -20 },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        type: "spring",
        damping: 20,
        stiffness: 100
      }
    },
    hover: {
      scale: 1.02,
      boxShadow: "0 8px 20px rgba(0, 0, 0, 0.1)",
      backgroundColor: "#f8fafc",
      transition: { duration: 0.2 }
    }
  };

  const filterVariants = {
    hidden: { opacity: 0, height: 0, overflow: 'hidden' },
    visible: {
      opacity: 1,
      height: 'auto',
      transition: {
        duration: 0.3,
        ease: "easeInOut"
      }
    },
    exit: {
      opacity: 0,
      height: 0,
      transition: {
        duration: 0.3,
        ease: "easeInOut"
      }
    }
  };

  // Skeleton loaders for different sections
  const StatSkeletons = () => (
    <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
      {[...Array(4)].map((_, index) => (
        <div key={index} className="bg-white rounded-xl p-4 shadow-sm animate-pulse">
          <div className="h-5 w-20 bg-gray-200 rounded mb-2"></div>
          <div className="h-8 w-16 bg-gray-200 rounded mb-3"></div>
          <div className="h-2 w-24 bg-gray-200 rounded"></div>
        </div>
      ))}
    </div>
  );

  const TimelineSkeletons = () => (
    <div className="space-y-4">
      {[...Array(5)].map((_, index) => (
        <div key={index} className="flex animate-pulse">
          <div className="w-20 flex-shrink-0">
            <div className="h-5 w-16 bg-gray-200 rounded mb-2 mx-auto"></div>
          </div>
          <div className="w-8 flex items-center justify-center flex-shrink-0">
            <div className="w-3 h-3 bg-gray-200 rounded-full"></div>
            <div className="w-0.5 h-full bg-gray-200 absolute"></div>
          </div>
          <div className="flex-grow bg-white rounded-xl p-4 shadow-sm">
            <div className="flex justify-between items-center mb-3">
              <div className="h-5 w-32 bg-gray-200 rounded"></div>
              <div className="h-5 w-5 bg-gray-200 rounded-full"></div>
            </div>
            <div className="h-4 w-24 bg-gray-200 rounded mb-3"></div>
            <div className="h-3 w-full bg-gray-200 rounded mb-2"></div>
            <div className="h-3 w-2/3 bg-gray-200 rounded"></div>
          </div>
        </div>
      ))}
    </div>
  );

  // Empty state component
  const EmptyState = () => (
    <motion.div
      className="col-span-full flex flex-col items-center justify-center py-16"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <div className="w-20 h-20 bg-gray-100 rounded-full flex items-center justify-center mb-6">
        <IoDocumentTextOutline className="text-gray-400 text-4xl" />
      </div>
      <h3 className="text-xl font-medium text-gray-700 mb-2">No contracts found</h3>
      <p className="text-gray-500 text-center max-w-md mb-8">
        {searchQuery || statusFilter || typeFilter
          ? "No contracts match your search criteria. Try adjusting your filters."
          : "You don't have any contracts yet. They'll appear here once available."}
      </p>
      {(searchQuery || statusFilter || typeFilter) && (
        <motion.button
          className="px-6 py-2.5 bg-secondary text-white rounded-full font-medium"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={() => {
            setSearchQuery('');
            setStatusFilter('');
            setTypeFilter('');
          }}
        >
          Clear Filters
        </motion.button>
      )}
    </motion.div>
  );

  return (
    <Layout backgroundColor='text-white' text="Contracts">
      <motion.div
        className="max-w-7xl mx-auto"
        initial="hidden"
        animate="visible"
        variants={containerVariants}
      >
        {/* Header with Search and Filters */}
        <motion.div
          className="bg-white rounded-2xl shadow-sm border border-gray-100 p-6 mb-8"
          variants={statCardVariants}
        >
          <div className="flex flex-col md:flex-row md:items-center justify-between gap-4 mb-4">
            <div>
              <h2 className="text-xl font-semibold text-gray-800">Your Contracts</h2>
              <p className="text-gray-500 text-sm">Review and manage your active and past contracts</p>
            </div>

            <div className="flex flex-col sm:flex-row gap-3">
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <FaSearch className="text-gray-400" />
                </div>
                <input
                  type="text"
                  placeholder="Search contracts..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  className="pl-10 pr-4 py-2 bg-gray-50 border border-gray-200 rounded-xl text-sm focus:outline-none focus:ring-2 focus:ring-secondary/20 focus:border-secondary w-full sm:w-64"
                />
              </div>

              <motion.button
                className="flex items-center justify-center gap-2 px-4 py-2 bg-gray-50 border border-gray-200 rounded-xl hover:bg-gray-100 transition-colors"
                onClick={() => setShowFilters(!showFilters)}
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
              >
                <IoFilterOutline className="text-gray-600" />
                <span className="text-sm text-gray-700">Filters</span>
              </motion.button>
            </div>
          </div>

          <AnimatePresence>
            {showFilters && (
              <motion.div
                className="border-t border-gray-100 pt-4"
                variants={filterVariants}
                initial="hidden"
                animate="visible"
                exit="exit"
              >
                <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
                  <div>
                    <label className="block text-sm text-gray-600 mb-1">Contract Status</label>
                    <select
                      value={statusFilter}
                      onChange={(e) => setStatusFilter(e.target.value)}
                      className="w-full p-2 bg-gray-50 border border-gray-200 rounded-xl text-sm focus:outline-none focus:ring-2 focus:ring-secondary/20 focus:border-secondary"
                    >
                      <option value="">All Statuses</option>
                      <option value="approved">Approved</option>
                      <option value="pending">Pending</option>
                      <option value="cancelled">Cancelled</option>
                    </select>
                  </div>

                  <div>
                    <label className="block text-sm text-gray-600 mb-1">Contract Type</label>
                    <select
                      value={typeFilter}
                      onChange={(e) => setTypeFilter(e.target.value)}
                      className="w-full p-2 bg-gray-50 border border-gray-200 rounded-xl text-sm focus:outline-none focus:ring-2 focus:ring-secondary/20 focus:border-secondary"
                    >
                      <option value="">All Types</option>
                      <option value="sale">Sale</option>
                      <option value="lease">Lease</option>
                      <option value="service">Service</option>
                    </select>
                  </div>

                  <div>
                    <label className="block text-sm text-gray-600 mb-1">View Type</label>
                    <div className="flex bg-gray-50 rounded-xl p-1 border border-gray-200">
                      <button
                        className={`flex-1 py-1.5 rounded-lg text-sm font-medium transition-colors ${activeView === 'timeline' ? 'bg-white shadow-sm text-primary' : 'text-gray-600'}`}
                        onClick={() => setActiveView('timeline')}
                      >
                        Timeline
                      </button>
                      <button
                        className={`flex-1 py-1.5 rounded-lg text-sm font-medium transition-colors ${activeView === 'grid' ? 'bg-white shadow-sm text-primary' : 'text-gray-600'}`}
                        onClick={() => setActiveView('grid')}
                      >
                        Grid
                      </button>
                      <button
                        className={`flex-1 py-1.5 rounded-lg text-sm font-medium transition-colors ${activeView === 'list' ? 'bg-white shadow-sm text-primary' : 'text-gray-600'}`}
                        onClick={() => setActiveView('list')}
                      >
                        List
                      </button>
                    </div>
                  </div>
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </motion.div>

        {/* Stats Overview */}
        {loading ? (
          <StatSkeletons />
        ) : (
          <motion.div
            className="grid grid-cols-2 md:grid-cols-4 gap-4 mb-8"
            variants={containerVariants}
          >
            <motion.div
              className="bg-gradient-to-br from-blue-50 to-indigo-50 rounded-xl p-5 border border-blue-100 shadow-sm"
              variants={statCardVariants}
              whileHover="hover"
            >
              <div className="flex justify-between items-start">
                <div>
                  <p className="text-sm text-blue-600 font-medium mb-1">Total Contracts</p>
                  <h3 className="text-2xl font-bold text-blue-800">{stats.total}</h3>
                </div>
                <div className="p-2 bg-blue-100 rounded-lg">
                  <IoDocumentTextOutline className="text-blue-600 text-xl" />
                </div>
              </div>
              <p className="text-xs text-blue-600 mt-2">Active and inactive contracts</p>
            </motion.div>

            <motion.div
              className="bg-gradient-to-br from-green-50 to-emerald-50 rounded-xl p-5 border border-green-100 shadow-sm"
              variants={statCardVariants}
              whileHover="hover"
            >
              <div className="flex justify-between items-start">
                <div>
                  <p className="text-sm text-green-600 font-medium mb-1">Active Contracts</p>
                  <h3 className="text-2xl font-bold text-green-800">{stats.approved}</h3>
                </div>
                <div className="p-2 bg-green-100 rounded-lg">
                  <IoCheckmarkCircle className="text-green-600 text-xl" />
                </div>
              </div>
              <p className="text-xs text-green-600 mt-2">Currently approved contracts</p>
            </motion.div>

            <motion.div
              className="bg-gradient-to-br from-amber-50 to-yellow-50 rounded-xl p-5 border border-amber-100 shadow-sm"
              variants={statCardVariants}
              whileHover="hover"
            >
              <div className="flex justify-between items-start">
                <div>
                  <p className="text-sm text-amber-600 font-medium mb-1">Pending Review</p>
                  <h3 className="text-2xl font-bold text-amber-800">{stats.pending}</h3>
                </div>
                <div className="p-2 bg-amber-100 rounded-lg">
                  <IoHourglassOutline className="text-amber-600 text-xl" />
                </div>
              </div>
              <p className="text-xs text-amber-600 mt-2">Awaiting approval</p>
            </motion.div>

            <motion.div
              className="bg-gradient-to-br from-purple-50 to-violet-50 rounded-xl p-5 border border-purple-100 shadow-sm"
              variants={statCardVariants}
              whileHover="hover"
            >
              <div className="flex justify-between items-start">
                <div>
                  <p className="text-sm text-purple-600 font-medium mb-1">Expiring Soon</p>
                  <h3 className="text-2xl font-bold text-purple-800">{stats.nearingExpiry}</h3>
                </div>
                <div className="p-2 bg-purple-100 rounded-lg">
                  <FaRegClock className="text-purple-600 text-xl" />
                </div>
              </div>
              <p className="text-xs text-purple-600 mt-2">Within 30 days</p>
            </motion.div>
          </motion.div>
        )}

        {/* Contracts Display - Conditional based on view type */}
        {loading ? (
          activeView === 'timeline' ? <TimelineSkeletons /> : <StatSkeletons />
        ) : filteredContracts.length > 0 ? (
          <AnimatePresence mode="wait">
            {activeView === 'timeline' && (
              <motion.div
                key="timeline-view"
                className="bg-gray-50 rounded-2xl p-6 border border-gray-100"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.3 }}
              >
                <div className="relative mb-3 flex items-center">
                  <div className="ml-8 mr-4 h-px flex-grow bg-gray-200"></div>
                  <h3 className="text-sm font-medium text-gray-500">CONTRACT TIMELINE</h3>
                  <div className="ml-4 flex-grow h-px bg-gray-200"></div>
                </div>

                <div className="space-y-4">
                  {filteredContracts.map((contract, index) => {
                    const statusStyle = getStatusStyle(contract.status);
                    const progress = getContractProgress(contract.start_date, contract.end_date);

                    return (
                      <motion.div
                        key={contract.id}
                        className="flex group"
                        variants={timelineItemVariants}
                        whileHover="hover"
                      >
                        {/* Timeline month label */}
                        <div className="w-20 flex-shrink-0 text-right pr-2">
                          <span className="text-xs font-medium text-gray-500">
                            {moment(contract.start_date).format('MMM YYYY')}
                          </span>
                        </div>

                        {/* Timeline bullet and line */}
                        <div className="w-8 flex items-center justify-center flex-shrink-0 relative">
                          <div className={`w-3 h-3 ${statusStyle.bg} border-2 border-white rounded-full shadow-sm group-hover:scale-150 transition-transform z-10`}></div>
                          <div className="w-0.5 h-full bg-gray-200 absolute"></div>
                        </div>

                        {/* Contract card */}
                        <motion.div
                          className="flex-grow bg-white rounded-xl p-4 border border-gray-100 shadow-sm cursor-pointer"
                          onClick={() => navigate(`/dashboard/contracts/${contract.id}/view-details`)}
                          layoutId={`contract-${contract.id}`}
                        >
                          <div className="flex justify-between items-start mb-2">
                            <h3 className="font-semibold text-gray-800">{contract.title}</h3>
                            <motion.div
                              className="text-secondary"
                              whileHover={{ scale: 1.2, rotate: 15 }}
                            >
                              <FaRegEye size={16} />
                            </motion.div>
                          </div>

                          <div className="flex items-center gap-2 mb-3">
                            {getContractTypeIcon(contract.type)}
                            <span className="text-sm text-gray-600 capitalize">{contract.type.replace('_', ' ')}</span>
                          </div>

                          {/* Contract timeline progress */}
                          <div className="mb-3">
                            <div className="flex justify-between text-xs text-gray-500 mb-1.5">
                              <span>{moment(contract.start_date).format('MMM D, YYYY')}</span>
                              <span>{moment(contract.end_date).format('MMM D, YYYY')}</span>
                            </div>
                            <div className="h-1.5 bg-gray-100 rounded-full overflow-hidden">
                              <div
                                className={`h-full ${progress.isExpired ? 'bg-red-500' : `bg-gradient-to-r ${statusStyle.gradient}`}`}
                                style={{ width: `${progress.percentComplete}%` }}
                              ></div>
                            </div>
                          </div>

                          <div className="flex justify-between items-center">
                            <div className={`flex items-center px-2 py-0.5 rounded-full text-xs font-medium ${statusStyle.bg} ${statusStyle.text}`}>
                              {statusStyle.icon}
                              {contract.status}
                            </div>

                            <div className="flex items-center text-xs text-gray-500">
                              <IoTimeOutline className="mr-1" />
                              Updated {moment(contract.updatedAt || contract.createdAt).fromNow()}
                            </div>
                          </div>
                        </motion.div>
                      </motion.div>
                    );
                  })}
                </div>

                {/* Timeline End */}
                <div className="flex items-center mt-4 pl-28">
                  <div className="w-3 h-3 bg-gray-300 rounded-full z-10"></div>
                  <div className="ml-4 text-sm text-gray-500">End of timeline</div>
                </div>
              </motion.div>
            )}

            {activeView === 'grid' && (
              <motion.div
                key="grid-view"
                className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.3 }}
              >
                {filteredContracts.map((contract, index) => {
                  const statusStyle = getStatusStyle(contract.status);
                  const progress = getContractProgress(contract.start_date, contract.end_date);

                  return (
                    <motion.div
                      key={contract.id}
                      className="relative overflow-hidden bg-white rounded-xl border border-gray-100 shadow-sm group"
                      variants={statCardVariants}
                      whileHover="hover"
                      whileTap={{ scale: 0.98 }}
                      onClick={() => navigate(`/dashboard/contracts/${contract.id}/view-details`)}
                      onMouseEnter={() => setHoveredContract(contract.id)}
                      onMouseLeave={() => setHoveredContract(null)}
                    >
                      {/* Status indicator bar at top */}
                      <div className={`h-1 w-full bg-gradient-to-r ${statusStyle.gradient}`}></div>

                      <div className="p-5">
                        <div className="flex justify-between items-start mb-3">
                          <h3 className="font-semibold text-gray-800 text-lg group-hover:text-primary transition-colors">
                            {contract.title}
                          </h3>
                          <div className={`p-2 ${statusStyle.bg} rounded-lg ${statusStyle.iconClass}`}>
                            {statusStyle.icon}
                          </div>
                        </div>

                        <div className="flex items-center gap-2 mb-4">
                          {getContractTypeIcon(contract.type)}
                          <span className="text-sm text-gray-600 capitalize">{contract.type.replace('_', ' ')}</span>
                        </div>

                        <div className="space-y-2 mb-4">
                          <div className="flex justify-between items-center">
                            <span className="text-xs text-gray-500">Start Date</span>
                            <span className="text-sm font-medium text-gray-800">
                              {moment(contract.start_date).format('MMM D, YYYY')}
                            </span>
                          </div>
                          <div className="flex justify-between items-center">
                            <span className="text-xs text-gray-500">End Date</span>
                            <span className="text-sm font-medium text-gray-800">
                              {moment(contract.end_date).format('MMM D, YYYY')}
                            </span>
                          </div>
                        </div>

                        {/* Progress bar */}
                        <div className="mb-4">
                          <div className="flex justify-between text-xs text-gray-500 mb-1">
                            <span>Progress</span>
                            <span>{Math.round(progress.percentComplete)}%</span>
                          </div>
                          <div className="h-1.5 bg-gray-100 rounded-full overflow-hidden">
                            <div
                              className={`h-full ${progress.isExpired ? 'bg-red-500' : `bg-gradient-to-r ${statusStyle.gradient}`}`}
                              style={{ width: `${progress.percentComplete}%` }}
                            ></div>
                          </div>
                          <div className="mt-1 text-xs text-gray-500">
                            {progress.isExpired
                              ? <span className="text-red-600">Expired</span>
                              : `${progress.daysRemaining} days remaining`
                            }
                          </div>
                        </div>

                        <div className="flex justify-between items-center pt-2 border-t border-gray-100">
                          <div className={`flex items-center px-3 py-1 rounded-full text-xs font-medium ${statusStyle.bg} ${statusStyle.text}`}>
                            {contract.status}
                          </div>
                          <div className="flex items-center text-xs text-secondary font-medium">
                            View Details
                            <IoChevronForward className="ml-1" />
                          </div>
                        </div>
                      </div>

                      {/* Hover overlay with action buttons */}
                      <AnimatePresence>
                        {hoveredContract === contract.id && (
                          <motion.div
                            className="absolute inset-0 bg-black bg-opacity-5 flex items-center justify-center"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            transition={{ duration: 0.2 }}
                          >
                            <motion.div
                              className="bg-white rounded-full p-3 shadow-lg"
                              initial={{ scale: 0.8 }}
                              animate={{ scale: 1 }}
                              transition={{ type: "spring", stiffness: 200, damping: 15 }}
                            >
                              <FaRegEye size={18} className="text-secondary" />
                            </motion.div>
                          </motion.div>
                        )}
                      </AnimatePresence>
                    </motion.div>
                  );
                })}
              </motion.div>
            )}

            {activeView === 'list' && (
              <motion.div
                key="list-view"
                className="bg-white rounded-2xl border border-gray-100 shadow-sm overflow-hidden"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.3 }}
              >
                <div className="bg-gray-50 px-6 py-3 border-b border-gray-100">
                  <div className="grid grid-cols-12 gap-4">
                    <div className="col-span-4 text-xs font-medium text-gray-500">CONTRACT DETAILS</div>
                    <div className="col-span-2 text-xs font-medium text-gray-500">TYPE</div>
                    <div className="col-span-2 text-xs font-medium text-gray-500">DATE RANGE</div>
                    <div className="col-span-2 text-xs font-medium text-gray-500">STATUS</div>
                    <div className="col-span-2 text-xs font-medium text-gray-500">ACTIONS</div>
                  </div>
                </div>

                <div className="divide-y divide-gray-100">
                  {filteredContracts.map((contract, index) => {
                    const statusStyle = getStatusStyle(contract.status);
                    const progress = getContractProgress(contract.start_date, contract.end_date);

                    return (
                      <motion.div
                        key={contract.id}
                        className="px-6 py-4 hover:bg-gray-50 transition-colors cursor-pointer"
                        variants={timelineItemVariants}
                        whileHover="hover"
                        onClick={() => navigate(`/dashboard/contracts/${contract.id}/view-details`)}
                      >
                        <div className="grid grid-cols-12 gap-4 items-center">
                          <div className="col-span-4">
                            <h3 className="font-medium text-gray-800">{contract.title}</h3>
                            <div className="mt-1 text-xs text-gray-500">
                              {progress.isExpired
                                ? <span className="text-red-600">Expired</span>
                                : `${progress.daysRemaining} days remaining`
                              }
                            </div>
                          </div>

                          <div className="col-span-2">
                            <div className="flex items-center gap-2">
                              {getContractTypeIcon(contract.type)}
                              <span className="text-sm text-gray-600 capitalize">{contract.type.replace('_', ' ')}</span>
                            </div>
                          </div>

                          <div className="col-span-2 text-sm text-gray-600">
                            <div className="flex flex-col">
                              <span>{moment(contract.start_date).format('MMM D, YYYY')}</span>
                              <span className="text-xs text-gray-500">to</span>
                              <span>{moment(contract.end_date).format('MMM D, YYYY')}</span>
                            </div>
                          </div>

                          <div className="col-span-2">
                            <div className={`inline-flex items-center px-3 py-1 rounded-full text-xs font-medium ${statusStyle.bg} ${statusStyle.text}`}>
                              {statusStyle.icon}
                              {contract.status}
                            </div>
                          </div>

                          <div className="col-span-2 flex justify-end">
                            <motion.button
                              className="p-2 text-secondary bg-secondary bg-opacity-10 rounded-full"
                              whileHover={{ scale: 1.1, rotate: 15 }}
                              whileTap={{ scale: 0.9 }}
                            >
                              <FaRegEye size={16} />
                            </motion.button>
                          </div>
                        </div>
                      </motion.div>
                    );
                  })}
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        ) : (
          <EmptyState />
        )}
      </motion.div>
    </Layout>
  );
};

export default Contracts;