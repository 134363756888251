import React from 'react'
import { IoIosArrowForward } from 'react-icons/io';

const GlassInput = ({ label, name, type = 'text', value, onChange, required = false, options = [] }) => (
    <div className="mb-5">
        <label className="block text-sm font-medium text-gray-700 mb-2" htmlFor={name}>
            {label} {required && <span className="text-rose-500">*</span>}
        </label>
        <div className="relative">
            {type === 'select' ? (
                <div className="relative">
                    <select
                        id={name}
                        name={name}
                        value={value || ''}
                        onChange={onChange}
                        required={required}
                        className="w-full h-12 pl-4 pr-10 rounded-xl bg-white/50 backdrop-blur-md border border-white/50 focus:border-blue-300 focus:ring-2 focus:ring-blue-100 outline-none transition-all text-gray-800 appearance-none"
                    >
                        <option value="">Select {label}</option>
                        {options.map((option) => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </select>
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-4">
                        <IoIosArrowForward className="h-4 w-4 text-gray-500 transform rotate-90" />
                    </div>
                </div>
            ) : type === 'textarea' ? (
                <textarea
                    id={name}
                    name={name}
                    value={value || ''}
                    onChange={onChange}
                    required={required}
                    rows={4}
                    className="w-full p-4 rounded-xl bg-white/50 backdrop-blur-md border border-white/50 focus:border-blue-300 focus:ring-2 focus:ring-blue-100 outline-none transition-all text-gray-800"
                />
            ) : type === 'checkbox' ? (
                <div className="flex items-center">
                    <input
                        id={name}
                        name={name}
                        type="checkbox"
                        checked={value || false}
                        onChange={onChange}
                        className="w-5 h-5 rounded bg-white/50 border border-white/50 focus:ring-2 focus:ring-blue-100 text-blue-600"
                    />
                    <label htmlFor={name} className="ml-2 text-sm text-gray-700">
                        {label}
                    </label>
                </div>
            ) : (
                <input
                    id={name}
                    type={type}
                    name={name}
                    value={value || ''}
                    onChange={onChange}
                    required={required}
                    className="w-full h-12 pl-4 pr-4 rounded-xl bg-white/50 backdrop-blur-md border border-white/50 focus:border-blue-300 focus:ring-2 focus:ring-blue-100 outline-none transition-all text-gray-800"
                />
            )}
        </div>
    </div>
);

export default GlassInput
