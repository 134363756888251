import React, { useEffect, useState, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import Layout from '../elements/Layout';
import { IoMdSearch, IoIosClose } from 'react-icons/io';
import { IoFilterOutline, IoGridOutline, IoListOutline, IoMapOutline, IoLocationOutline, IoHomeOutline, IoChevronForward, IoCheckmarkCircle, IoTimeOutline, IoWarningOutline, IoInformationCircleOutline } from 'react-icons/io5';
import { FaBuilding, FaRulerCombined, FaTag, FaLayerGroup, FaMapMarkerAlt, FaRegFilePdf } from 'react-icons/fa';
import Cookies from 'js-cookie';
import { fetchCustomerProperties } from '../utils/api/api';
import toast from 'react-hot-toast';

const MyProperties = () => {
    const [properties, setProperties] = useState([]);
    const [filteredProperties, setFilteredProperties] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedProperty, setSelectedProperty] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [activeView, setActiveView] = useState('gallery'); // 'gallery', 'list', or 'map'
    const [categoryFilter, setCategoryFilter] = useState('all'); // 'all', 'residential', 'commercial', etc.
    const [statusFilter, setStatusFilter] = useState('all'); // 'all', 'sold', 'available', etc.
    const [showFilters, setShowFilters] = useState(false);
    const customer_id = Cookies.get('user_id');
    const modalRef = useRef(null);

    // Color palette for property cards
    const colorPalettes = {
        'sold': {
            gradient: 'from-blue-500 to-indigo-600',
            light: 'bg-blue-50',
            border: 'border-blue-200',
            text: 'text-blue-700',
            icon: <IoCheckmarkCircle className="text-blue-500" />,
            shadow: 'shadow-blue-200'
        },
        'available': {
            gradient: 'from-green-500 to-teal-600',
            light: 'bg-green-50',
            border: 'border-green-200',
            text: 'text-green-700',
            icon: <IoHomeOutline className="text-green-500" />,
            shadow: 'shadow-green-200'
        },
        'pending': {
            gradient: 'from-amber-500 to-orange-600',
            light: 'bg-amber-50',
            border: 'border-amber-200',
            text: 'text-amber-700',
            icon: <IoTimeOutline className="text-amber-500" />,
            shadow: 'shadow-amber-200'
        },
        'default': {
            gradient: 'from-gray-500 to-slate-600',
            light: 'bg-gray-50',
            border: 'border-gray-200',
            text: 'text-gray-700',
            icon: <IoInformationCircleOutline className="text-gray-500" />,
            shadow: 'shadow-gray-200'
        }
    };

    const getStatusStyles = (status) => {
        if (!status) return colorPalettes.default;
        const statusKey = status.toLowerCase();
        return colorPalettes[statusKey] || colorPalettes.default;
    };

    // Stats for visualization
    const [stats, setStats] = useState({
        total: 0,
        sold: 0,
        available: 0,
        pending: 0,
        totalValue: 0
    });

    const getProperties = async () => {
        setLoading(true);
        try {
            const response = await fetchCustomerProperties(customer_id);

            const formattedProperties = response.properties.map(property => {
                const orderItem = property.order_items?.[0] || {};
                return {
                    id: property.id,
                    property_id: orderItem.property?.id || null,
                    property_number: orderItem.property?.property_number || 'N/A',
                    title_number: orderItem.property?.title_number || 'N/A',
                    size: orderItem.property?.size || 'N/A',
                    status: orderItem.property?.status || 'N/A',
                    amount: property.amount || 0,
                    payment_type: property.payment_type || 'N/A',
                    payment_status: property.payment_status || 'N/A',
                    // Additional mock data for visualization
                    location: 'Nairobi, Kenya',
                    image: `https://source.unsplash.com/random/300x200?property&sig=${property.id}`,
                    category: ['residential', 'commercial', 'land'][Math.floor(Math.random() * 3)],
                    features: ['3 Beds', '2 Baths', 'Garage', 'Garden'][Math.floor(Math.random() * 4)],
                    documents: Math.floor(Math.random() * 5),
                };
            });

            setProperties(formattedProperties);
            setFilteredProperties(formattedProperties);

            // Calculate stats
            const stats = calculateStats(formattedProperties);
            setStats(stats);

        } catch (error) {
            toast.error("Failed to get properties", {
                style: {
                    borderRadius: '12px',
                    background: '#333',
                    color: '#fff',
                }
            });
        } finally {
            setLoading(false);
        }
    };

    const calculateStats = (properties) => {
        const sold = properties.filter(p => p.status.toLowerCase() === 'sold').length;
        const available = properties.filter(p => p.status.toLowerCase() === 'available').length;
        const pending = properties.filter(p => p.status.toLowerCase() === 'pending').length;
        const totalValue = properties.reduce((sum, p) => sum + Number(p.amount || 0), 0);

        return {
            total: properties.length,
            sold,
            available,
            pending: pending || properties.length - (sold + available), // If no pending, calculate remaining
            totalValue
        };
    };

    const openModal = (property) => {
        setSelectedProperty(property);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedProperty(null);
    };

    useEffect(() => {
        getProperties();
    }, []);

    // Filter properties based on search query and filters
    useEffect(() => {
        let filtered = [...properties];

        // Apply search filter
        if (searchQuery.trim()) {
            const query = searchQuery.toLowerCase();
            filtered = filtered.filter(property =>
                property.property_number.toLowerCase().includes(query) ||
                property.title_number.toLowerCase().includes(query) ||
                property.status.toLowerCase().includes(query) ||
                property.payment_status.toLowerCase().includes(query)
            );
        }

        // Apply category filter
        if (categoryFilter !== 'all') {
            filtered = filtered.filter(property =>
                property.category === categoryFilter
            );
        }

        // Apply status filter
        if (statusFilter !== 'all') {
            filtered = filtered.filter(property =>
                property.status.toLowerCase() === statusFilter.toLowerCase()
            );
        }

        setFilteredProperties(filtered);
    }, [searchQuery, properties, categoryFilter, statusFilter]);

    // Close modal when clicking outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                closeModal();
            }
        };

        if (isModalOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isModalOpen]);

    // Animation variants
    const containerVariants = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                when: "beforeChildren",
                staggerChildren: 0.1
            }
        }
    };

    const cardVariants = {
        hidden: { opacity: 0, y: 20 },
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                type: "spring",
                damping: 20,
                stiffness: 100
            }
        },
        hover: {
            y: -5,
            transition: {
                type: "spring",
                damping: 15,
                stiffness: 400
            }
        },
        tap: { scale: 0.98 }
    };

    const statCardVariants = {
        hidden: { opacity: 0, y: 20 },
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                type: "spring",
                damping: 20,
                stiffness: 100
            }
        },
        hover: {
            y: -5,
            boxShadow: "0 10px 25px rgba(0, 0, 0, 0.1)",
            transition: {
                type: "spring",
                damping: 15,
                stiffness: 400
            }
        }
    };

    const modalVariants = {
        hidden: { opacity: 0, y: 50, scale: 0.9 },
        visible: {
            opacity: 1,
            y: 0,
            scale: 1,
            transition: {
                type: "spring",
                damping: 25,
                stiffness: 200
            }
        },
        exit: {
            opacity: 0,
            y: 50,
            scale: 0.9,
            transition: {
                duration: 0.2
            }
        }
    };

    const backdropVariants = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                duration: 0.3
            }
        },
        exit: {
            opacity: 0,
            transition: {
                duration: 0.2
            }
        }
    };

    const filterVariants = {
        hidden: { opacity: 0, height: 0, overflow: 'hidden' },
        visible: {
            opacity: 1,
            height: 'auto',
            transition: {
                duration: 0.3,
                ease: "easeInOut"
            }
        },
        exit: {
            opacity: 0,
            height: 0,
            transition: {
                duration: 0.2,
                ease: "easeInOut"
            }
        }
    };

    // Skeleton loaders for different sections
    const StatSkeletons = () => (
        <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
            {[...Array(4)].map((_, index) => (
                <div key={index} className="bg-white rounded-xl p-4 shadow-sm animate-pulse">
                    <div className="h-5 w-20 bg-gray-200 rounded mb-2"></div>
                    <div className="h-8 w-16 bg-gray-200 rounded mb-3"></div>
                    <div className="h-2 w-24 bg-gray-200 rounded"></div>
                </div>
            ))}
        </div>
    );

    const PropertySkeletons = () => (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
            {[...Array(6)].map((_, index) => (
                <div key={index} className="bg-white rounded-xl overflow-hidden shadow-sm animate-pulse">
                    <div className="h-40 bg-gray-200"></div>
                    <div className="p-4">
                        <div className="h-5 w-3/4 bg-gray-200 rounded mb-3"></div>
                        <div className="space-y-2">
                            <div className="h-4 w-full bg-gray-200 rounded"></div>
                            <div className="h-4 w-2/3 bg-gray-200 rounded"></div>
                            <div className="h-4 w-1/2 bg-gray-200 rounded"></div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );

    const ListSkeletons = () => (
        <div className="space-y-4">
            {[...Array(5)].map((_, index) => (
                <div key={index} className="bg-white rounded-xl p-4 shadow-sm animate-pulse">
                    <div className="flex justify-between items-center">
                        <div className="h-5 w-1/3 bg-gray-200 rounded mb-3"></div>
                        <div className="h-8 w-20 bg-gray-200 rounded-full"></div>
                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
                        <div className="h-4 w-full bg-gray-200 rounded"></div>
                        <div className="h-4 w-full bg-gray-200 rounded"></div>
                        <div className="h-4 w-full bg-gray-200 rounded"></div>
                        <div className="h-4 w-full bg-gray-200 rounded"></div>
                    </div>
                </div>
            ))}
        </div>
    );

    // Empty state component
    const EmptyState = () => (
        <motion.div
            className="col-span-full flex flex-col items-center justify-center py-16"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
        >
            <div className="w-20 h-20 bg-gray-100 rounded-full flex items-center justify-center mb-6">
                <IoHomeOutline className="text-gray-400 text-4xl" />
            </div>
            <h3 className="text-xl font-medium text-gray-700 mb-2">No properties found</h3>
            <p className="text-gray-500 text-center max-w-md mb-8">
                {searchQuery || categoryFilter !== 'all' || statusFilter !== 'all'
                    ? "No properties match your search criteria. Try adjusting your filters."
                    : "You don't have any properties yet. They'll appear here once available."}
            </p>
            {(searchQuery || categoryFilter !== 'all' || statusFilter !== 'all') && (
                <motion.button
                    className="px-6 py-2.5 bg-secondary text-white rounded-full font-medium"
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    onClick={() => {
                        setSearchQuery('');
                        setCategoryFilter('all');
                        setStatusFilter('all');
                    }}
                >
                    Clear Filters
                </motion.button>
            )}
        </motion.div>
    );

    return (
        <Layout text="My Properties">
            <motion.div
                className="max-w-7xl mx-auto"
                initial="hidden"
                animate="visible"
                variants={containerVariants}
            >
                {/* Header with Search and Filters */}
                <motion.div
                    className="bg-white rounded-2xl shadow-sm border border-gray-100 p-6 mb-8"
                    variants={statCardVariants}
                >
                    <div className="flex flex-col md:flex-row md:items-center justify-between gap-4 mb-4">
                        <div>
                            <h2 className="text-xl font-semibold text-gray-800">Your Properties</h2>
                            <p className="text-gray-500 text-sm">Manage your real estate investments</p>
                        </div>

                        <div className="flex flex-col sm:flex-row gap-3">
                            <div className="relative flex-grow">
                                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <IoMdSearch className="text-gray-400" />
                                </div>
                                <input
                                    type="text"
                                    placeholder="Search properties..."
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                    className="pl-10 pr-4 py-2 bg-gray-50 border border-gray-200 rounded-xl text-sm focus:outline-none focus:ring-2 focus:ring-secondary/20 focus:border-secondary w-full"
                                />
                                {searchQuery && (
                                    <button
                                        className="absolute inset-y-0 right-0 pr-3 flex items-center"
                                        onClick={() => setSearchQuery('')}
                                    >
                                        <IoIosClose className="text-gray-400 text-xl" />
                                    </button>
                                )}
                            </div>

                            <motion.button
                                className="flex items-center justify-center gap-2 px-4 py-2 bg-gray-50 border border-gray-200 rounded-xl hover:bg-gray-100 transition-colors"
                                onClick={() => setShowFilters(!showFilters)}
                                whileHover={{ scale: 1.02 }}
                                whileTap={{ scale: 0.98 }}
                            >
                                <IoFilterOutline className="text-gray-600" />
                                <span className="text-sm text-gray-700">Filters</span>
                            </motion.button>
                        </div>
                    </div>

                    <AnimatePresence>
                        {showFilters && (
                            <motion.div
                                className="border-t border-gray-100 pt-4"
                                variants={filterVariants}
                                initial="hidden"
                                animate="visible"
                                exit="exit"
                            >
                                <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
                                    <div>
                                        <label className="block text-sm text-gray-600 mb-1">Property Status</label>
                                        <select
                                            value={statusFilter}
                                            onChange={(e) => setStatusFilter(e.target.value)}
                                            className="w-full p-2 bg-gray-50 border border-gray-200 rounded-xl text-sm focus:outline-none focus:ring-2 focus:ring-secondary/20 focus:border-secondary"
                                        >
                                            <option value="all">All Statuses</option>
                                            <option value="sold">Sold</option>
                                            <option value="available">Available</option>
                                            <option value="pending">Pending</option>
                                        </select>
                                    </div>

                                    <div>
                                        <label className="block text-sm text-gray-600 mb-1">Property Type</label>
                                        <select
                                            value={categoryFilter}
                                            onChange={(e) => setCategoryFilter(e.target.value)}
                                            className="w-full p-2 bg-gray-50 border border-gray-200 rounded-xl text-sm focus:outline-none focus:ring-2 focus:ring-secondary/20 focus:border-secondary"
                                        >
                                            <option value="all">All Types</option>
                                            <option value="residential">Residential</option>
                                            <option value="commercial">Commercial</option>
                                            <option value="land">Land</option>
                                        </select>
                                    </div>

                                    <div>
                                        <label className="block text-sm text-gray-600 mb-1">View Type</label>
                                        <div className="flex bg-gray-50 rounded-xl p-1 border border-gray-200">
                                            <button
                                                className={`flex-1 py-1.5 rounded-lg text-sm font-medium transition-colors flex items-center justify-center gap-1 ${activeView === 'gallery' ? 'bg-white shadow-sm text-primary' : 'text-gray-600'}`}
                                                onClick={() => setActiveView('gallery')}
                                            >
                                                <IoGridOutline />
                                                Gallery
                                            </button>
                                            <button
                                                className={`flex-1 py-1.5 rounded-lg text-sm font-medium transition-colors flex items-center justify-center gap-1 ${activeView === 'list' ? 'bg-white shadow-sm text-primary' : 'text-gray-600'}`}
                                                onClick={() => setActiveView('list')}
                                            >
                                                <IoListOutline />
                                                List
                                            </button>
                                            <button
                                                className={`flex-1 py-1.5 rounded-lg text-sm font-medium transition-colors flex items-center justify-center gap-1 ${activeView === 'map' ? 'bg-white shadow-sm text-primary' : 'text-gray-600'}`}
                                                onClick={() => setActiveView('map')}
                                            >
                                                <IoMapOutline />
                                                Map
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </motion.div>
                        )}
                    </AnimatePresence>
                </motion.div>

                {/* Stats Overview */}
                {loading ? (
                    <StatSkeletons />
                ) : (
                    <motion.div
                        className="grid grid-cols-2 md:grid-cols-4 gap-4 mb-8"
                        variants={containerVariants}
                    >
                        <motion.div
                            className="bg-gradient-to-br from-blue-50 to-indigo-50 rounded-xl p-5 border border-blue-100 shadow-sm"
                            variants={statCardVariants}
                            whileHover="hover"
                        >
                            <div className="flex justify-between items-start">
                                <div>
                                    <p className="text-sm text-blue-600 font-medium mb-1">Total Properties</p>
                                    <h3 className="text-2xl font-bold text-blue-800">{stats.total}</h3>
                                </div>
                                <div className="p-2 bg-blue-100 rounded-lg">
                                    <IoHomeOutline className="text-blue-600 text-xl" />
                                </div>
                            </div>
                            <p className="text-xs text-blue-600 mt-2">All your property assets</p>
                        </motion.div>

                        <motion.div
                            className="bg-gradient-to-br from-green-50 to-emerald-50 rounded-xl p-5 border border-green-100 shadow-sm"
                            variants={statCardVariants}
                            whileHover="hover"
                        >
                            <div className="flex justify-between items-start">
                                <div>
                                    <p className="text-sm text-green-600 font-medium mb-1">Total Value</p>
                                    <h3 className="text-2xl font-bold text-green-800">KES {stats.totalValue.toLocaleString()}</h3>
                                </div>
                                <div className="p-2 bg-green-100 rounded-lg">
                                    <FaTag className="text-green-600 text-xl" />
                                </div>
                            </div>
                            <p className="text-xs text-green-600 mt-2">Estimated portfolio value</p>
                        </motion.div>

                        <motion.div
                            className="bg-gradient-to-br from-amber-50 to-yellow-50 rounded-xl p-5 border border-amber-100 shadow-sm"
                            variants={statCardVariants}
                            whileHover="hover"
                        >
                            <div className="flex justify-between items-start">
                                <div>
                                    <p className="text-sm text-amber-600 font-medium mb-1">Sold Properties</p>
                                    <h3 className="text-2xl font-bold text-amber-800">{stats.sold}</h3>
                                </div>
                                <div className="p-2 bg-amber-100 rounded-lg">
                                    <IoCheckmarkCircle className="text-amber-600 text-xl" />
                                </div>
                            </div>
                            <p className="text-xs text-amber-600 mt-2">Completed transactions</p>
                        </motion.div>

                        <motion.div
                            className="bg-gradient-to-br from-purple-50 to-violet-50 rounded-xl p-5 border border-purple-100 shadow-sm"
                            variants={statCardVariants}
                            whileHover="hover"
                        >
                            <div className="flex justify-between items-start">
                                <div>
                                    <p className="text-sm text-purple-600 font-medium mb-1">Pending</p>
                                    <h3 className="text-2xl font-bold text-purple-800">{stats.pending}</h3>
                                </div>
                                <div className="p-2 bg-purple-100 rounded-lg">
                                    <IoTimeOutline className="text-purple-600 text-xl" />
                                </div>
                            </div>
                            <p className="text-xs text-purple-600 mt-2">In process properties</p>
                        </motion.div>
                    </motion.div>
                )}

                {/* Properties Display - Conditional based on view type */}
                {loading ? (
                    activeView === 'list' ? <ListSkeletons /> : <PropertySkeletons />
                ) : filteredProperties.length > 0 ? (
                    <AnimatePresence mode="wait">
                        {activeView === 'gallery' && (
                            <motion.div
                                key="gallery-view"
                                className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6"
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                transition={{ duration: 0.3 }}
                                variants={containerVariants}
                            >
                                {filteredProperties.map((property, index) => {
                                    const statusStyles = getStatusStyles(property.status);

                                    return (
                                        <motion.div
                                            key={property.id}
                                            className="bg-white rounded-xl overflow-hidden shadow-sm border border-gray-100"
                                            variants={cardVariants}
                                            whileHover="hover"
                                            whileTap="tap"
                                            onClick={() => openModal(property)}
                                            custom={index}
                                        >
                                            {/* Property Image Section */}
                                            <div className="relative h-48 overflow-hidden">
                                                <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-black/10 z-10"></div>
                                                <img
                                                    src={property.image}
                                                    alt={property.property_number}
                                                    className="w-full h-full object-cover transition-transform duration-300 group-hover:scale-110"
                                                />
                                                <div className="absolute top-3 left-3 z-20">
                                                    <span className={`inline-flex items-center px-3 py-1 rounded-full text-xs font-medium text-white bg-gradient-to-r ${statusStyles.gradient}`}>
                                                        {property.status}
                                                    </span>
                                                </div>
                                                <div className="absolute bottom-3 left-3 right-3 z-20">
                                                    <h3 className="text-white font-semibold truncate">{property.title_number}</h3>
                                                    <div className="flex items-center text-white/80 text-xs mt-1">
                                                        <IoLocationOutline className="mr-1" />
                                                        <span>{property.location}</span>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* Property Details Section */}
                                            <div className="p-4">
                                                <div className="flex items-center text-sm text-gray-600 mb-3 capitalize">
                                                    <FaBuilding className="mr-1.5 text-gray-500" />
                                                    {property.category}
                                                </div>

                                                <div className="grid grid-cols-2 gap-4 mb-4">
                                                    <div>
                                                        <p className="text-xs text-gray-500">Property No.</p>
                                                        <p className="text-sm font-medium text-gray-800">{property.property_number}</p>
                                                    </div>
                                                    {property.size !== 'N/A' && (
                                                        <div>
                                                            <p className="text-xs text-gray-500">Size</p>
                                                            <div className="flex items-center">
                                                                <FaRulerCombined className="text-gray-500 mr-1" />
                                                                <p className="text-sm font-medium text-gray-800">{property.size}</p>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>

                                                <div className="flex justify-between items-center pt-3 border-t border-gray-100">
                                                    <div className="text-secondary font-semibold">
                                                        KES {Number(property.amount).toLocaleString()}
                                                    </div>
                                                    <div className={`px-3 py-1 rounded-full text-xs font-medium ${statusStyles.light} ${statusStyles.text}`}>
                                                        {property.payment_status}
                                                    </div>
                                                </div>
                                            </div>
                                        </motion.div>
                                    );
                                })}
                            </motion.div>
                        )}

                        {activeView === 'list' && (
                            <motion.div
                                key="list-view"
                                className="space-y-4"
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                transition={{ duration: 0.3 }}
                                variants={containerVariants}
                            >
                                {filteredProperties.map((property, index) => {
                                    const statusStyles = getStatusStyles(property.status);

                                    return (
                                        <motion.div
                                            key={property.id}
                                            className="bg-white rounded-xl p-4 border border-gray-100 shadow-sm"
                                            variants={cardVariants}
                                            whileHover="hover"
                                            whileTap="tap"
                                            onClick={() => openModal(property)}
                                            custom={index}
                                        >
                                            <div className="flex justify-between items-start mb-3">
                                                <div>
                                                    <h3 className="font-semibold text-gray-800">{property.title_number}</h3>
                                                    <div className="flex items-center text-gray-500 text-sm mt-1">
                                                        <IoLocationOutline className="mr-1" />
                                                        <span>{property.location}</span>
                                                    </div>
                                                </div>
                                                <div className={`px-3 py-1 rounded-full text-xs font-medium ${statusStyles.light} ${statusStyles.text}`}>
                                                    {property.status}
                                                </div>
                                            </div>

                                            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4 mb-3">
                                                <div>
                                                    <p className="text-xs text-gray-500">Property No.</p>
                                                    <p className="text-sm font-medium text-gray-800">{property.property_number}</p>
                                                </div>
                                                {property.size !== 'N/A' && (
                                                    <div>
                                                        <p className="text-xs text-gray-500">Size</p>
                                                        <div className="flex items-center">
                                                            <FaRulerCombined className="text-gray-500 mr-1" />
                                                            <p className="text-sm font-medium text-gray-800">{property.size}</p>
                                                        </div>
                                                    </div>
                                                )}
                                                <div>
                                                    <p className="text-xs text-gray-500">Payment Status</p>
                                                    <p className="text-sm font-medium text-gray-800">{property.payment_status}</p>
                                                </div>
                                                <div>
                                                    <p className="text-xs text-gray-500">Amount</p>
                                                    <p className="text-sm font-medium text-secondary">
                                                        KES {Number(property.amount).toLocaleString()}
                                                    </p>
                                                </div>
                                            </div>

                                            <div className="flex justify-end">
                                                <motion.button
                                                    className="flex items-center text-xs text-secondary font-medium"
                                                    whileHover={{ x: 3 }}
                                                >
                                                    View Details
                                                    <IoChevronForward className="ml-1" />
                                                </motion.button>
                                            </div>
                                        </motion.div>
                                    );
                                })}
                            </motion.div>
                        )}

                        {activeView === 'map' && (
                            <motion.div
                                key="map-view"
                                className="bg-white rounded-xl overflow-hidden h-[400px] relative"
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                transition={{ duration: 0.3 }}
                            >
                                <div className="absolute inset-0 bg-gray-100 flex items-center justify-center">
                                    <div className="text-center">
                                        <IoMapOutline className="text-gray-400 text-5xl mx-auto mb-3" />
                                        <p className="text-gray-600">Map view coming soon</p>
                                        <p className="text-sm text-gray-500 mt-1">We're working on an interactive map to show your properties</p>
                                    </div>
                                </div>
                            </motion.div>
                        )}
                    </AnimatePresence>
                ) : (
                    <EmptyState />
                )}
            </motion.div>

            {/* Property Detail Modal */}
            <AnimatePresence>
                {isModalOpen && selectedProperty && (
                    <motion.div
                        className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4"
                        variants={backdropVariants}
                        initial="hidden"
                        animate="visible"
                        exit="exit"
                    >
                        <motion.div
                            ref={modalRef}
                            className="bg-white rounded-2xl shadow-xl max-w-xl w-full max-h-[90vh] overflow-hidden flex flex-col"
                            variants={modalVariants}
                            initial="hidden"
                            animate="visible"
                            exit="exit"
                            onClick={(e) => e.stopPropagation()}
                        >
                            {/* Modal Header with Image */}
                            <div className="relative h-48">
                                <div className="absolute inset-0 bg-gradient-to-t from-black/70 to-black/20 z-10"></div>
                                <img
                                    src={selectedProperty.image}
                                    alt={selectedProperty.property_number}
                                    className="w-full h-full object-cover"
                                />
                                <button
                                    className="absolute top-4 right-4 text-white p-2 rounded-full bg-black/30 z-20 hover:bg-black/50 transition-colors"
                                    onClick={closeModal}
                                >
                                    <IoIosClose className="text-xl" />
                                </button>
                                <div className="absolute bottom-4 left-4 z-20">
                                    <h2 className="text-white text-xl font-semibold">{selectedProperty.title_number}</h2>
                                    <div className="flex items-center text-white/80 text-sm mt-1">
                                        <IoLocationOutline className="mr-1" />
                                        <span>{selectedProperty.location}</span>
                                    </div>
                                </div>
                            </div>

                            {/* Modal Content */}
                            <div className="p-6 overflow-y-auto flex-1">
                                {/* Property Information Sections */}
                                <div className="space-y-6">
                                    {/* Key Details Section */}
                                    <div>
                                        <h3 className="text-lg font-semibold text-gray-800 mb-3">Property Details</h3>
                                        <div className="grid grid-cols-2 gap-4">
                                            <div>
                                                <p className="text-sm text-gray-500">Property Number</p>
                                                <p className="text-base font-medium text-gray-800">{selectedProperty.property_number}</p>
                                            </div>
                                            <div>
                                                <p className="text-sm text-gray-500">Title Number</p>
                                                <p className="text-base font-medium text-gray-800">{selectedProperty.title_number}</p>
                                            </div>
                                            <div>
                                                <p className="text-sm text-gray-500">Size</p>
                                                <p className="text-base font-medium text-gray-800">{selectedProperty.size}</p>
                                            </div>
                                            <div>
                                                <p className="text-sm text-gray-500">Status</p>
                                                <p className="text-base font-medium text-gray-800">{selectedProperty.status}</p>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Financial Details Section */}
                                    <div>
                                        <h3 className="text-lg font-semibold text-gray-800 mb-3">Financial Details</h3>
                                        <div className="bg-gray-50 rounded-xl p-4 border border-gray-100">
                                            <div className="grid grid-cols-2 gap-4">
                                                <div>
                                                    <p className="text-sm text-gray-500">Amount</p>
                                                    <p className="text-lg font-semibold text-secondary">
                                                        KES {Number(selectedProperty.amount).toLocaleString()}
                                                    </p>
                                                </div>
                                                <div>
                                                    <p className="text-sm text-gray-500">Payment Type</p>
                                                    <p className="text-base font-medium text-gray-800 capitalize">
                                                        {selectedProperty.payment_type.replace('_', ' ')}
                                                    </p>
                                                </div>
                                                <div className="col-span-2">
                                                    <p className="text-sm text-gray-500">Payment Status</p>
                                                    <div className="flex items-center mt-1">
                                                        {selectedProperty.payment_status === 'paid' ? (
                                                            <IoCheckmarkCircle className="text-green-500 mr-1.5" />
                                                        ) : (
                                                            <IoWarningOutline className="text-amber-500 mr-1.5" />
                                                        )}
                                                        <p className="text-base font-medium text-gray-800">{selectedProperty.payment_status}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Additional Information */}
                                    <div className="flex items-center gap-3 text-sm text-gray-600">
                                        <div className="flex items-center">
                                            <FaMapMarkerAlt className="text-gray-400 mr-1.5" />
                                            <span>Map Location</span>
                                        </div>
                                        <div className="flex items-center">
                                            <FaRegFilePdf className="text-gray-400 mr-1.5" />
                                            <span>Documents ({selectedProperty.documents})</span>
                                        </div>
                                        <div className="flex items-center">
                                            <FaLayerGroup className="text-gray-400 mr-1.5" />
                                            <span>Related Properties</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Modal Footer */}
                            <div className="p-4 border-t border-gray-100 bg-gray-50">
                                <div className="flex justify-end gap-3">
                                    <motion.button
                                        className="px-4 py-2 text-gray-600 bg-white border border-gray-200 rounded-lg text-sm"
                                        onClick={closeModal}
                                        whileHover={{ scale: 1.02 }}
                                        whileTap={{ scale: 0.98 }}
                                    >
                                        Close
                                    </motion.button>
                                    <motion.button
                                        className="px-5 py-2 text-white bg-secondary rounded-lg text-sm"
                                        whileHover={{ scale: 1.02 }}
                                        whileTap={{ scale: 0.98 }}
                                    >
                                        View Full Details
                                    </motion.button>
                                </div>
                            </div>
                        </motion.div>
                    </motion.div>
                )}
            </AnimatePresence>
        </Layout>
    );
};

export default MyProperties;