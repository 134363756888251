import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { IoIosArrowDown, IoIosCheckmarkCircle } from 'react-icons/io';
import { HiOutlineQuestionMarkCircle, HiOutlineChatAlt2, HiOutlineDocumentText, HiOutlineShieldCheck } from 'react-icons/hi';

const FAQSection = () => {
    const [openFAQ, setOpenFAQ] = useState(0);
    const [hoverIndex, setHoverIndex] = useState(null);

    const toggleFAQ = (index) => {
        setOpenFAQ(openFAQ === index ? null : index);
    };

    const faqs = [
        {
            question: "What documents do I need to buy land with Milescoop?",
            answer: "You'll need a valid national ID, your KRA PIN, and any necessary financial documents depending on your payment plan. Our legal team will guide you through the process to ensure you have everything covered.",
            icon: <HiOutlineDocumentText className="text-primary" size={20} />
        },
        {
            question: "Does Milescoop offer payment plans?",
            answer: "Yes, we offer flexible payment plans. You can pay in installments over a period of time that best suits you, with a minimum deposit required upfront.",
            icon: <HiOutlineShieldCheck className="text-primary" size={20} />
        },
        {
            question: "How do I know the land is genuine?",
            answer: "We conduct thorough due diligence on all properties before listing them. You can also perform an independent search at the land registry for added peace of mind.",
            icon: <HiOutlineQuestionMarkCircle className="text-primary" size={20} />
        },
        {
            question: "Can I transfer ownership to someone else?",
            answer: "Yes, you can transfer ownership once you've completed the payment and received the title deed. Our team will help facilitate the transfer process.",
            icon: <HiOutlineDocumentText className="text-primary" size={20} />
        }
    ];

    // Animation variants
    const containerVariants = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                staggerChildren: 0.1
            }
        }
    };

    const itemVariants = {
        hidden: { opacity: 0, y: 20 },
        visible: {
            opacity: 1,
            y: 0,
            transition: { duration: 0.5, ease: [0.22, 1, 0.36, 1] }
        }
    };

    const contentVariants = {
        hidden: { opacity: 0, height: 0 },
        visible: {
            opacity: 1,
            height: "auto",
            transition: { duration: 0.3, ease: [0.22, 1, 0.36, 1] }
        }
    };

    return (
        <div className="relative py-16 overflow-hidden">
            {/* Decorative background elements */}
            <div className="absolute inset-0 -z-10 overflow-hidden">
                <div className="absolute -top-20 right-0 w-72 h-72 rounded-full bg-primary/10 blur-3xl transform translate-x-1/3"></div>
                <div className="absolute -bottom-20 left-0 w-72 h-72 rounded-full bg-secondary/10 blur-3xl transform -translate-x-1/3"></div>
            </div>

            <motion.div
                variants={containerVariants}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, margin: "-100px" }}
                className="max-w-7xl mx-auto px-6 md:px-8"
            >
                <div className="flex flex-col md:flex-row gap-8 lg:gap-16">
                    {/* Left column - Heading and CTA */}
                    <motion.div
                        variants={containerVariants}
                        className="w-full md:w-5/12 lg:w-4/12"
                    >
                        <motion.div variants={itemVariants} className="flex items-center gap-3 mb-4">
                            <div className="w-[100px] bg-primary h-[2px]" />
                            <p className="text-primary font-medium text-sm">FAQ</p>
                        </motion.div>

                        <motion.h2
                            variants={itemVariants}
                            className="text-3xl md:text-4xl font-bold bg-gradient-to-r from-primary to-secondary bg-clip-text text-transparent leading-tight mb-6"
                        >
                            Common questions about Milescoop Ventures
                        </motion.h2>

                        <motion.p
                            variants={itemVariants}
                            className="text-gray-600 mb-8"
                        >
                            Here are some important questions that are frequently asked by our clients. If you can't find what you're looking for, please reach out to us.
                        </motion.p>

                        <motion.div variants={itemVariants} className="space-y-6">
                            <div className="backdrop-blur-xl bg-white/60 rounded-2xl border border-white/50 shadow-lg p-5">
                                <div className="flex items-start gap-4">
                                    <div className="bg-primary/10 p-3 rounded-full">
                                        <HiOutlineChatAlt2 className="text-primary" size={24} />
                                    </div>
                                    <div>
                                        <h3 className="font-semibold text-gray-900 mb-1">Have a different question?</h3>
                                        <p className="text-sm text-gray-600 mb-3">Our team is ready to answer any questions you might have.</p>
                                        <motion.button
                                            whileHover={{ scale: 1.03 }}
                                            whileTap={{ scale: 0.97 }}
                                            className="px-5 py-2.5 rounded-full bg-gradient-to-r from-primary to-secondary text-white text-sm font-medium shadow-md hover:shadow-lg transition-all"
                                        >
                                            Contact Us
                                        </motion.button>
                                    </div>
                                </div>
                            </div>

                            <div className="backdrop-blur-xl bg-white/60 rounded-2xl border border-white/50 shadow-lg p-5">
                                <div className="flex items-center justify-between gap-4">
                                    <div className="flex items-center gap-3">
                                        <IoIosCheckmarkCircle className="text-green-500" size={24} />
                                        <span className="text-sm font-medium">Verified properties</span>
                                    </div>
                                    <div className="flex items-center gap-3">
                                        <IoIosCheckmarkCircle className="text-green-500" size={24} />
                                        <span className="text-sm font-medium">Legal protection</span>
                                    </div>
                                </div>
                            </div>
                        </motion.div>
                    </motion.div>

                    {/* Right column - FAQ accordion */}
                    <motion.div
                        variants={containerVariants}
                        className="w-full md:w-7/12 lg:w-8/12"
                    >
                        <motion.div
                            variants={itemVariants}
                            className="backdrop-blur-xl bg-white/60 rounded-3xl border border-white/50 shadow-xl overflow-hidden"
                        >
                            <div className="divide-y divide-gray-100/70">
                                {faqs.map((faq, index) => (
                                    <motion.div
                                        key={index}
                                        variants={itemVariants}
                                        onMouseEnter={() => setHoverIndex(index)}
                                        onMouseLeave={() => setHoverIndex(null)}
                                        className={`transition-colors duration-300 ${hoverIndex === index && openFAQ !== index ? 'bg-gray-50/80' : ''}`}
                                    >
                                        <div
                                            className={`flex justify-between items-center p-5 cursor-pointer ${openFAQ === index ? 'bg-primary/5' : ''
                                                }`}
                                            onClick={() => toggleFAQ(index)}
                                        >
                                            <div className="flex items-center gap-4">
                                                <div className={`p-2 rounded-full transition-colors ${openFAQ === index ? 'bg-primary/20' : 'bg-gray-100'
                                                    }`}>
                                                    {faq.icon}
                                                </div>
                                                <h3 className="text-lg font-medium text-gray-800">{faq.question}</h3>
                                            </div>
                                            <motion.div
                                                animate={{ rotate: openFAQ === index ? 180 : 0 }}
                                                transition={{ duration: 0.3 }}
                                                className={`text-primary rounded-full p-1 ${openFAQ === index ? 'bg-primary/10' : ''
                                                    }`}
                                            >
                                                <IoIosArrowDown size={20} />
                                            </motion.div>
                                        </div>

                                        <AnimatePresence>
                                            {openFAQ === index && (
                                                <motion.div
                                                    key={`content-${index}`}
                                                    variants={contentVariants}
                                                    initial="hidden"
                                                    animate="visible"
                                                    exit="hidden"
                                                    className="px-5 pb-5 overflow-hidden"
                                                >
                                                    <div className="pl-14 max-w-3xl">
                                                        <p className="text-gray-600 leading-relaxed">{faq.answer}</p>

                                                        {/* FAQ metadata - gives a fintech feel */}
                                                        <div className="mt-4 pt-4 border-t border-gray-100 flex items-center justify-between">
                                                            <div className="flex items-center gap-6">
                                                                <div className="flex items-center gap-2">
                                                                    <span className="text-xs text-gray-500">Was this helpful?</span>
                                                                    <div className="flex gap-1">
                                                                        <motion.button
                                                                            whileHover={{ scale: 1.1 }}
                                                                            whileTap={{ scale: 0.95 }}
                                                                            className="w-7 h-7 rounded-full bg-gray-100 flex items-center justify-center text-xs"
                                                                        >
                                                                            👍
                                                                        </motion.button>
                                                                        <motion.button
                                                                            whileHover={{ scale: 1.1 }}
                                                                            whileTap={{ scale: 0.95 }}
                                                                            className="w-7 h-7 rounded-full bg-gray-100 flex items-center justify-center text-xs"
                                                                        >
                                                                            👎
                                                                        </motion.button>
                                                                    </div>
                                                                </div>

                                                                <div>
                                                                    <span className="text-xs text-gray-500">Updated: Jan 2023</span>
                                                                </div>
                                                            </div>

                                                            <motion.button
                                                                whileHover={{ scale: 1.05 }}
                                                                whileTap={{ scale: 0.95 }}
                                                                className="text-xs text-primary font-medium"
                                                            >
                                                                Read more
                                                            </motion.button>
                                                        </div>
                                                    </div>
                                                </motion.div>
                                            )}
                                        </AnimatePresence>
                                    </motion.div>
                                ))}
                            </div>

                            {/* FAQ footer */}
                            <div className="p-5 bg-gray-50/80 border-t border-gray-100/70">
                                <div className="flex items-center justify-between">
                                    <p className="text-sm text-gray-500">Can't find what you're looking for?</p>
                                    <motion.button
                                        whileHover={{ scale: 1.03 }}
                                        whileTap={{ scale: 0.97 }}
                                        className="px-4 py-2 rounded-full bg-white border border-primary/30 text-primary text-sm font-medium shadow-sm hover:shadow transition-all"
                                    >
                                        Browse all FAQs
                                    </motion.button>
                                </div>
                            </div>
                        </motion.div>
                    </motion.div>
                </div>
            </motion.div>
        </div>
    );
};

export default FAQSection;