import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import { FaGoogle, FaFacebook, FaApple } from 'react-icons/fa';
import { IoEyeOutline, IoEyeOffOutline } from 'react-icons/io5';
import logo from '../../assets/miles_logo.svg';
import image from '../../assets/login.jpg';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [activeField, setActiveField] = useState(null);
    const navigate = useNavigate();
    const API_KEY = '14CA4D6A5CE4D441CFBFB23CCB699';

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setLoading(true);
            setError('');

            const response = await axios.post('https://api.milescoop.com/api/v1/auth/customer-signin',
                { email, password },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'api-key': API_KEY,
                    }
                }
            );
            const { token, user } = response.data;
            const userId = user.id;

            Cookies.set('access_token', token, { expires: 1 });
            Cookies.set('user_id', userId, { expires: 1 });
            localStorage.setItem('user', JSON.stringify(user));

            navigate('/dashboard');
            window.location.reload();
        } catch (err) {
            setError('Error logging in. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const handleGoogleLogin = () => {
        console.log('Google Login');
    };

    const handleFacebookLogin = () => {
        console.log('Facebook Login');
    };

    const handleAppleLogin = () => {
        console.log('Apple Login');
    };

    return (
        <div className="flex justify-center items-center min-h-screen w-full bg-gray-50">
            <div className="h-screen w-full md:w-1/2 my-auto flex flex-col items-center justify-center overflow-y-auto px-6">
                <div className="bg-white p-8 rounded-3xl mx-auto w-full max-w-md shadow-lg backdrop-blur-xl bg-opacity-80 border border-gray-100">
                    <div className="mb-8">
                        <img src={logo} className="h-12 mx-auto mb-6" alt="MileScoop Logo" />
                        <h1 className="text-3xl font-bold text-center bg-gradient-to-r from-secondary to-indigo-600 bg-clip-text text-transparent">Welcome back</h1>
                        <p className="text-center mt-2 text-gray-500 text-base">Login to your account</p>
                    </div>

                    <form onSubmit={handleSubmit} className="space-y-6">
                        <div className={`bg-gray-50 rounded-2xl p-4 transition-all duration-200 ${activeField === 'email' ? 'ring-2 ring-secondary bg-white' : 'hover:bg-gray-100'}`}>
                            <label htmlFor="email" className="block text-xs font-medium text-gray-400 mb-1">Email Address</label>
                            <input
                                type="email"
                                id="email"
                                className="w-full bg-transparent outline-none text-gray-800 text-lg"
                                value={email}
                                placeholder="you@example.com"
                                onChange={(e) => setEmail(e.target.value)}
                                onFocus={() => setActiveField('email')}
                                onBlur={() => setActiveField(null)}
                                required
                            />
                        </div>

                        <div className={`bg-gray-50 rounded-2xl p-4 transition-all duration-200 ${activeField === 'password' ? 'ring-2 ring-secondary bg-white' : 'hover:bg-gray-100'}`}>
                            <label htmlFor="password" className="block text-xs font-medium text-gray-400 mb-1">Password</label>
                            <div className="relative flex items-center">
                                <input
                                    type={showPassword ? 'text' : 'password'}
                                    id="password"
                                    className="w-full bg-transparent outline-none text-gray-800 text-lg"
                                    value={password}
                                    placeholder="••••••••"
                                    onChange={(e) => setPassword(e.target.value)}
                                    onFocus={() => setActiveField('password')}
                                    onBlur={() => setActiveField(null)}
                                    required
                                />
                                <button
                                    type="button"
                                    className="text-gray-400 hover:text-gray-600 transition-colors"
                                    onClick={() => setShowPassword((prev) => !prev)}
                                >
                                    {showPassword ? <IoEyeOffOutline className="text-xl" /> : <IoEyeOutline className="text-xl" />}
                                </button>
                            </div>
                        </div>

                        <div className="text-right">
                            <Link to="/accounts/forgot-password" className="text-sm text-secondary hover:text-secondary font-medium">
                                Forgot password?
                            </Link>
                        </div>

                        <button
                            type="submit"
                            className={`w-full py-4 px-6 text-base font-medium rounded-2xl text-white bg-gradient-to-r from-secondary to-secondary  hover:shadow-lg transition-all duration-200 flex justify-center items-center ${loading ? 'opacity-90' : ''}`}
                            disabled={loading}
                        >
                            {loading ? (
                                <div className="h-5 w-5 rounded-full border-2 border-white border-t-transparent animate-spin mr-2"></div>
                            ) : null}
                            {loading ? 'Signing in...' : 'Sign in'}
                        </button>

                        {error && (
                            <div className="p-3 bg-red-50 border border-red-100 text-red-600 rounded-xl text-sm">
                                {error}
                            </div>
                        )}
                    </form>

                    <div className="my-8 text-center">
                        <div className="flex items-center gap-3">
                            <div className="h-px bg-gray-200 flex-grow" />
                            <p className="text-gray-400 text-sm font-medium">or continue with</p>
                            <div className="h-px bg-gray-200 flex-grow" />
                        </div>

                        <div className="flex justify-center gap-4 mt-6">
                            <button
                                onClick={handleGoogleLogin}
                                className="flex items-center justify-center w-14 h-14 rounded-full bg-white border border-gray-100 shadow-sm hover:shadow transition-all duration-200"
                            >
                                <FaGoogle className="text-red-500 text-xl" />
                            </button>
                            <button
                                onClick={handleFacebookLogin}
                                className="flex items-center justify-center w-14 h-14 rounded-full bg-white border border-gray-100 shadow-sm hover:shadow transition-all duration-200"
                            >
                                <FaFacebook className="text-secondary text-xl" />
                            </button>
                            <button
                                onClick={handleAppleLogin}
                                className="flex items-center justify-center w-14 h-14 rounded-full bg-white border border-gray-100 shadow-sm hover:shadow transition-all duration-200"
                            >
                                <FaApple className="text-black text-xl" />
                            </button>
                        </div>
                    </div>

                    <div className="text-center mt-6">
                        <p className="text-gray-500">
                            Don't have an account?{' '}
                            <Link to="/accounts/sign-up" className="text-secondary font-medium hover:text-blue-800">
                                Create account
                            </Link>
                        </p>
                    </div>
                </div>
            </div>
            <div className="w-1/2 h-screen hidden md:block overflow-hidden">
                <div className="h-full w-full relative">
                    <img
                        src={image}
                        className="object-cover h-full w-full"
                        alt="Financial dashboard"
                    />
                    <div className="absolute inset-0 bg-gradient-to-r from-indigo-600/30 to-secondary/30 backdrop-blur-sm"></div>
                </div>
            </div>
        </div>
    );
};

export default Login;