import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import Layout from '../elements/Layout';
import { IoMdSearch, IoIosClose } from 'react-icons/io';
import { IoFilterOutline, IoGridOutline, IoListOutline, IoDocumentText, IoCalendarOutline, IoCheckmarkCircle, IoTimeOutline, IoWarningOutline, IoReceiptOutline, IoChevronForward, IoArrowForward, IoHourglassOutline, IoCheckbox } from 'react-icons/io5';
import { FaReceipt, FaFileInvoiceDollar, FaClock, FaSort, FaSortUp, FaSortDown, FaChevronLeft, FaChevronRight, FaRegFileAlt, FaDownload, FaPrint } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import { fetchCustomerInvoices } from '../utils/api/api';
import toast from 'react-hot-toast';
import Cookies from 'js-cookie';
import moment from 'moment';

const Invoices = () => {
  const navigate = useNavigate();
  const customer_id = Cookies.get('user_id');
  const [invoices, setInvoices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [invoicesPerPage] = useState(20);
  const [totalPages, setTotalPages] = useState(1);
  const [filteredInvoices, setFilteredInvoices] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [showFilters, setShowFilters] = useState(false);
  const [activeView, setActiveView] = useState('table');
  const [statusFilter, setStatusFilter] = useState('all');
  const [dateFilter, setDateFilter] = useState('all');
  const [dateRange, setDateRange] = useState({ start: '', end: '' });
  const [sortField, setSortField] = useState('date');
  const [sortDirection, setSortDirection] = useState('desc');

  // Stats for visualization
  const [stats, setStats] = useState({
    total: 0,
    paid: 0,
    pending: 0,
    overdue: 0,
    totalAmount: 0,
    paidAmount: 0,
    pendingAmount: 0
  });

  const getInvoices = async (page, size) => {
    setLoading(true);
    try {
      const response = await fetchCustomerInvoices({ customer_id, page, pageSize: size });
      const receivedInvoices = response.invoices || [];
      setInvoices(receivedInvoices);
      setFilteredInvoices(receivedInvoices);
      setTotalPages(response.meta.totalPages || 1);

      // Calculate stats
      calculateStats(receivedInvoices);

    } catch (error) {
      toast.error('Failed to get invoices', {
        style: {
          borderRadius: '12px',
          background: '#333',
          color: '#fff',
        }
      });
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const calculateStats = (invoiceList) => {
    const now = new Date();
    const paid = invoiceList.filter(invoice => invoice.payment_status === 'paid').length;
    const pending = invoiceList.filter(invoice => invoice.payment_status !== 'paid').length;
    const overdue = invoiceList.filter(invoice =>
      new Date(invoice.payment_due_date) < now && invoice.payment_status !== 'paid'
    ).length;

    const totalAmount = invoiceList.reduce((sum, invoice) => sum + Number(invoice.total_amount || 0), 0);
    const paidAmount = invoiceList
      .filter(invoice => invoice.payment_status === 'paid')
      .reduce((sum, invoice) => sum + Number(invoice.total_amount || 0), 0);
    const pendingAmount = totalAmount - paidAmount;

    setStats({
      total: invoiceList.length,
      paid,
      pending,
      overdue,
      totalAmount,
      paidAmount,
      pendingAmount
    });
  };

  const formatInvoiceNumber = (invoiceNumber) => {
    const yearSuffix = moment().format('YY');
    return `MVL-${invoiceNumber}-${yearSuffix}`;
  };

  useEffect(() => {
    getInvoices(currentPage, invoicesPerPage);
  }, [currentPage, invoicesPerPage]);

  useEffect(() => {
    filterInvoices();
  }, [searchTerm, statusFilter, dateFilter, dateRange, invoices, sortField, sortDirection]);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const filterInvoices = () => {
    let filtered = [...invoices];

    // Apply search filter
    if (searchTerm.trim()) {
      const term = searchTerm.toLowerCase();
      filtered = filtered.filter((invoice) => {
        return (
          (invoice.id && invoice.id.toString().toLowerCase().includes(term)) ||
          (invoice?.order?.property?.title_number && invoice.order.property.title_number.toLowerCase().includes(term)) ||
          (invoice.serial_no && invoice.serial_no.toLowerCase().includes(term))
        );
      });
    }

    // Apply status filter
    if (statusFilter !== 'all') {
      const now = new Date();
      if (statusFilter === 'overdue') {
        filtered = filtered.filter(invoice =>
          new Date(invoice.payment_due_date) < now && invoice.payment_status !== 'paid'
        );
      } else {
        filtered = filtered.filter(invoice => invoice.payment_status === statusFilter);
      }
    }

    // Apply date filter
    if (dateFilter !== 'all') {
      const now = new Date();
      const thirtyDaysAgo = new Date(now);
      thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
      const ninetyDaysAgo = new Date(now);
      ninetyDaysAgo.setDate(ninetyDaysAgo.getDate() - 90);

      switch (dateFilter) {
        case 'last30days':
          filtered = filtered.filter(invoice => new Date(invoice.payment_due_date) >= thirtyDaysAgo);
          break;
        case 'last90days':
          filtered = filtered.filter(invoice => new Date(invoice.payment_due_date) >= ninetyDaysAgo);
          break;
        case 'custom':
          if (dateRange.start && dateRange.end) {
            const startDate = new Date(dateRange.start);
            const endDate = new Date(dateRange.end);
            endDate.setHours(23, 59, 59, 999); // Include the entire end day

            filtered = filtered.filter(invoice => {
              const invoiceDate = new Date(invoice.payment_due_date);
              return invoiceDate >= startDate && invoiceDate <= endDate;
            });
          }
          break;
        default:
          break;
      }
    }

    // Apply sorting
    filtered.sort((a, b) => {
      let compareA, compareB;

      switch (sortField) {
        case 'date':
          compareA = new Date(a.payment_due_date).getTime();
          compareB = new Date(b.payment_due_date).getTime();
          break;
        case 'amount':
          compareA = Number(a.total_amount);
          compareB = Number(b.total_amount);
          break;
        case 'invoice':
          compareA = a.id;
          compareB = b.id;
          break;
        default:
          compareA = new Date(a.payment_due_date).getTime();
          compareB = new Date(b.payment_due_date).getTime();
      }

      return sortDirection === 'asc' ? compareA - compareB : compareB - compareA;
    });

    setFilteredInvoices(filtered);
  };

  const truncateText = (text, limit) => {
    if (!text) return '';
    const words = text.split(' ');
    return words.length > limit ? `${words.slice(0, limit).join(' ')}...` : text;
  };

  const paginate = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const handleSort = (field) => {
    if (sortField === field) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortDirection('asc');
    }
  };

  const getInvoiceStatusInfo = (invoice) => {
    const now = new Date();
    const dueDate = new Date(invoice.payment_due_date);
    const isOverdue = dueDate < now && invoice.payment_status !== 'paid';
    const daysOverdue = isOverdue ? Math.floor((now - dueDate) / (1000 * 60 * 60 * 24)) : 0;
    const daysUntilDue = !isOverdue && invoice.payment_status !== 'paid' ? Math.floor((dueDate - now) / (1000 * 60 * 60 * 24)) : 0;

    if (invoice.payment_status === 'paid') {
      return {
        status: 'Paid',
        icon: <IoCheckmarkCircle className="text-green-500" />,
        bgColor: 'bg-green-100',
        textColor: 'text-green-700',
        borderColor: 'border-green-200',
        message: 'Payment complete'
      };
    } else if (isOverdue) {
      return {
        status: 'Overdue',
        icon: <IoWarningOutline className="text-red-500" />,
        bgColor: 'bg-red-100',
        textColor: 'text-red-700',
        borderColor: 'border-red-200',
        message: `${daysOverdue} day${daysOverdue !== 1 ? 's' : ''} overdue`
      };
    } else {
      return {
        status: 'Pending',
        icon: <IoTimeOutline className="text-yellow-500" />,
        bgColor: 'bg-yellow-100',
        textColor: 'text-yellow-700',
        borderColor: 'border-yellow-200',
        message: `Due in ${daysUntilDue} day${daysUntilDue !== 1 ? 's' : ''}`
      };
    }
  };

  // Animation variants
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        when: "beforeChildren",
        staggerChildren: 0.1
      }
    }
  };

  const statCardVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        type: "spring",
        damping: 20,
        stiffness: 100
      }
    },
    hover: {
      y: -5,
      boxShadow: "0 10px 25px rgba(0, 0, 0, 0.1)",
      transition: {
        type: "spring",
        damping: 15,
        stiffness: 400
      }
    }
  };

  const tableRowVariants = {
    hidden: { opacity: 0, x: -20 },
    visible: i => ({
      opacity: 1,
      x: 0,
      transition: {
        delay: i * 0.05,
        duration: 0.3,
        ease: "easeOut"
      }
    }),
    hover: {
      backgroundColor: "#f9fafb",
      transition: { duration: 0.2 }
    }
  };

  const cardVariants = {
    hidden: { opacity: 0, y: 20, scale: 0.95 },
    visible: i => ({
      opacity: 1,
      y: 0,
      scale: 1,
      transition: {
        delay: i * 0.05,
        duration: 0.3,
        type: "spring",
        stiffness: 100,
        damping: 15
      }
    }),
    hover: {
      y: -5,
      boxShadow: "0 10px 25px rgba(0, 0, 0, 0.08)",
      transition: { type: "spring", stiffness: 400, damping: 10 }
    },
    tap: { scale: 0.98 }
  };

  const filterVariants = {
    hidden: { opacity: 0, height: 0, overflow: 'hidden' },
    visible: {
      opacity: 1,
      height: 'auto',
      transition: {
        duration: 0.3,
        ease: "easeInOut"
      }
    },
    exit: {
      opacity: 0,
      height: 0,
      transition: {
        duration: 0.3,
        ease: "easeInOut"
      }
    }
  };

  // Skeleton loaders for different components
  const StatSkeletons = () => (
    <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
      {[...Array(4)].map((_, index) => (
        <div key={index} className="bg-white rounded-xl p-4 shadow-sm animate-pulse">
          <div className="h-5 w-20 bg-gray-200 rounded mb-2"></div>
          <div className="h-8 w-16 bg-gray-200 rounded mb-3"></div>
          <div className="h-2 w-24 bg-gray-200 rounded"></div>
        </div>
      ))}
    </div>
  );

  const TableSkeleton = () => (
    <div className="bg-white rounded-xl border border-gray-100 shadow-sm overflow-hidden">
      <div className="p-4 border-b border-gray-100 animate-pulse">
        <div className="h-6 w-1/4 bg-gray-200 rounded"></div>
      </div>
      <div className="overflow-x-auto">
        <table className="min-w-full">
          <thead className="bg-gray-50 border-b border-gray-100">
            <tr>
              {[...Array(6)].map((_, i) => (
                <th key={i} className="py-3 px-6">
                  <div className="h-4 bg-gray-200 rounded w-full"></div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {[...Array(5)].map((_, rowIndex) => (
              <tr key={rowIndex} className="border-b border-gray-100">
                {[...Array(6)].map((_, colIndex) => (
                  <td key={colIndex} className="py-4 px-6">
                    <div className="h-4 bg-gray-200 rounded w-full"></div>
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );

  const CardsSkeleton = () => (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
      {[...Array(6)].map((_, index) => (
        <div key={index} className="bg-white rounded-xl p-4 border border-gray-100 shadow-sm animate-pulse">
          <div className="flex justify-between mb-4">
            <div className="h-6 w-1/3 bg-gray-200 rounded"></div>
            <div className="h-6 w-16 bg-gray-200 rounded-full"></div>
          </div>
          <div className="space-y-3 mb-4">
            <div className="h-4 w-full bg-gray-200 rounded"></div>
            <div className="h-4 w-2/3 bg-gray-200 rounded"></div>
          </div>
          <div className="h-6 w-1/2 bg-gray-200 rounded mt-6"></div>
        </div>
      ))}
    </div>
  );

  // Empty state component
  const EmptyState = () => (
    <motion.div
      className="col-span-full flex flex-col items-center justify-center py-16"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <div className="w-20 h-20 bg-gray-100 rounded-full flex items-center justify-center mb-6">
        <FaFileInvoiceDollar className="text-gray-400 text-4xl" />
      </div>
      <h3 className="text-xl font-medium text-gray-700 mb-2">No invoices found</h3>
      <p className="text-gray-500 text-center max-w-md mb-8">
        {searchTerm || statusFilter !== 'all' || dateFilter !== 'all'
          ? "No invoices match your search criteria. Try adjusting your filters."
          : "You don't have any invoices yet. They'll appear here once available."}
      </p>
      {(searchTerm || statusFilter !== 'all' || dateFilter !== 'all') && (
        <motion.button
          className="px-6 py-2.5 bg-secondary text-white rounded-full font-medium"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={() => {
            setSearchTerm('');
            setStatusFilter('all');
            setDateFilter('all');
            setDateRange({ start: '', end: '' });
          }}
        >
          Clear Filters
        </motion.button>
      )}
    </motion.div>
  );

  return (
    <Layout text="Invoices" backgroundColor="bg-gray-50">
      <motion.div
        className="max-w-7xl mx-auto"
        initial="hidden"
        animate="visible"
        variants={containerVariants}
      >
        {/* Header with Search and Filters */}
        <motion.div
          className="bg-white rounded-2xl shadow-sm border border-gray-100 p-6 mb-8"
          variants={statCardVariants}
        >
          <div className="flex flex-col md:flex-row md:items-center justify-between gap-4 mb-4">
            <div>
              <h2 className="text-xl font-semibold text-gray-800">Invoice Management</h2>
              <p className="text-gray-500 text-sm">View and track all your payment invoices</p>
            </div>

            <div className="flex flex-col sm:flex-row gap-3">
              <div className="relative flex-grow">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <IoMdSearch className="text-gray-400" />
                </div>
                <input
                  type="text"
                  placeholder="Search invoices..."
                  value={searchTerm}
                  onChange={handleSearch}
                  className="pl-10 pr-4 py-2 bg-gray-50 border border-gray-200 rounded-xl text-sm focus:outline-none focus:ring-2 focus:ring-secondary/20 focus:border-secondary w-full"
                />
                {searchTerm && (
                  <button
                    className="absolute inset-y-0 right-0 pr-3 flex items-center"
                    onClick={() => setSearchTerm('')}
                  >
                    <IoIosClose className="text-gray-400 text-xl" />
                  </button>
                )}
              </div>

              <motion.button
                className="flex items-center justify-center gap-2 px-4 py-2 bg-gray-50 border border-gray-200 rounded-xl hover:bg-gray-100 transition-colors"
                onClick={() => setShowFilters(!showFilters)}
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
              >
                <IoFilterOutline className="text-gray-600" />
                <span className="text-sm text-gray-700">Filters</span>
              </motion.button>
            </div>
          </div>

          <AnimatePresence>
            {showFilters && (
              <motion.div
                className="border-t border-gray-100 pt-4"
                variants={filterVariants}
                initial="hidden"
                animate="visible"
                exit="exit"
              >
                <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
                  <div>
                    <label className="block text-sm text-gray-600 mb-1">Invoice Status</label>
                    <select
                      value={statusFilter}
                      onChange={(e) => setStatusFilter(e.target.value)}
                      className="w-full p-2 bg-gray-50 border border-gray-200 rounded-xl text-sm focus:outline-none focus:ring-2 focus:ring-secondary/20 focus:border-secondary"
                    >
                      <option value="all">All Statuses</option>
                      <option value="paid">Paid</option>
                      <option value="pending">Pending</option>
                      <option value="overdue">Overdue</option>
                    </select>
                  </div>

                  <div>
                    <label className="block text-sm text-gray-600 mb-1">Due Date</label>
                    <select
                      value={dateFilter}
                      onChange={(e) => setDateFilter(e.target.value)}
                      className="w-full p-2 bg-gray-50 border border-gray-200 rounded-xl text-sm focus:outline-none focus:ring-2 focus:ring-secondary/20 focus:border-secondary"
                    >
                      <option value="all">All Time</option>
                      <option value="last30days">Last 30 Days</option>
                      <option value="last90days">Last 90 Days</option>
                      <option value="custom">Custom Range</option>
                    </select>
                  </div>

                  <div>
                    <label className="block text-sm text-gray-600 mb-1">View Type</label>
                    <div className="flex bg-gray-50 rounded-xl p-1 border border-gray-200">
                      <button
                        className={`flex-1 py-1.5 rounded-lg text-sm font-medium transition-colors flex items-center justify-center gap-1 ${activeView === 'table' ? 'bg-white shadow-sm text-primary' : 'text-gray-600'}`}
                        onClick={() => setActiveView('table')}
                      >
                        <IoGridOutline />
                        Table
                      </button>
                      <button
                        className={`flex-1 py-1.5 rounded-lg text-sm font-medium transition-colors flex items-center justify-center gap-1 ${activeView === 'cards' ? 'bg-white shadow-sm text-primary' : 'text-gray-600'}`}
                        onClick={() => setActiveView('cards')}
                      >
                        <IoListOutline />
                        Cards
                      </button>
                    </div>
                  </div>
                </div>

                {/* Custom date range */}
                {dateFilter === 'custom' && (
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-4">
                    <div>
                      <label className="block text-sm text-gray-600 mb-1">Start Date</label>
                      <div className="relative">
                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                          <IoCalendarOutline className="text-gray-400" />
                        </div>
                        <input
                          type="date"
                          value={dateRange.start}
                          onChange={(e) => setDateRange({ ...dateRange, start: e.target.value })}
                          className="w-full pl-10 pr-4 py-2 bg-gray-50 border border-gray-200 rounded-xl text-sm focus:outline-none focus:ring-2 focus:ring-secondary/20 focus:border-secondary"
                        />
                      </div>
                    </div>

                    <div>
                      <label className="block text-sm text-gray-600 mb-1">End Date</label>
                      <div className="relative">
                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                          <IoCalendarOutline className="text-gray-400" />
                        </div>
                        <input
                          type="date"
                          value={dateRange.end}
                          onChange={(e) => setDateRange({ ...dateRange, end: e.target.value })}
                          className="w-full pl-10 pr-4 py-2 bg-gray-50 border border-gray-200 rounded-xl text-sm focus:outline-none focus:ring-2 focus:ring-secondary/20 focus:border-secondary"
                        />
                      </div>
                    </div>
                  </div>
                )}
              </motion.div>
            )}
          </AnimatePresence>
        </motion.div>

        {/* Stats Overview */}
        {loading ? (
          <StatSkeletons />
        ) : (
          <motion.div
            className="grid grid-cols-2 md:grid-cols-4 gap-4 mb-8"
            variants={containerVariants}
          >
            <motion.div
              className="bg-gradient-to-br from-blue-50 to-indigo-50 rounded-xl p-5 border border-blue-100 shadow-sm"
              variants={statCardVariants}
              whileHover="hover"
            >
              <div className="flex justify-between items-start">
                <div>
                  <p className="text-sm text-blue-600 font-medium mb-1">Total Invoices</p>
                  <h3 className="text-2xl font-bold text-blue-800">{stats.total}</h3>
                </div>
                <div className="p-2 bg-blue-100 rounded-lg">
                  <FaFileInvoiceDollar className="text-blue-600 text-xl" />
                </div>
              </div>
              <p className="text-xs text-blue-600 mt-2">All invoices in your account</p>
            </motion.div>

            <motion.div
              className="bg-gradient-to-br from-green-50 to-emerald-50 rounded-xl p-5 border border-green-100 shadow-sm"
              variants={statCardVariants}
              whileHover="hover"
            >
              <div className="flex justify-between items-start">
                <div>
                  <p className="text-sm text-green-600 font-medium mb-1">Paid Invoices</p>
                  <h3 className="text-2xl font-bold text-green-800">{stats.paid}</h3>
                </div>
                <div className="p-2 bg-green-100 rounded-lg">
                  <IoCheckmarkCircle className="text-green-600 text-xl" />
                </div>
              </div>
              <p className="text-xs text-green-600 mt-2">
                KES {stats.paidAmount.toLocaleString()} total paid
              </p>
            </motion.div>

            <motion.div
              className="bg-gradient-to-br from-amber-50 to-yellow-50 rounded-xl p-5 border border-amber-100 shadow-sm"
              variants={statCardVariants}
              whileHover="hover"
            >
              <div className="flex justify-between items-start">
                <div>
                  <p className="text-sm text-amber-600 font-medium mb-1">Pending</p>
                  <h3 className="text-2xl font-bold text-amber-800">{stats.pending}</h3>
                </div>
                <div className="p-2 bg-amber-100 rounded-lg">
                  <IoTimeOutline className="text-amber-600 text-xl" />
                </div>
              </div>
              <p className="text-xs text-amber-600 mt-2">
                KES {stats.pendingAmount.toLocaleString()} to be paid
              </p>
            </motion.div>

            <motion.div
              className="bg-gradient-to-br from-red-50 to-rose-50 rounded-xl p-5 border border-red-100 shadow-sm"
              variants={statCardVariants}
              whileHover="hover"
            >
              <div className="flex justify-between items-start">
                <div>
                  <p className="text-sm text-red-600 font-medium mb-1">Overdue</p>
                  <h3 className="text-2xl font-bold text-red-800">{stats.overdue}</h3>
                </div>
                <div className="p-2 bg-red-100 rounded-lg">
                  <IoWarningOutline className="text-red-600 text-xl" />
                </div>
              </div>
              <p className="text-xs text-red-600 mt-2">Requires immediate attention</p>
            </motion.div>
          </motion.div>
        )}

        {/* Invoice Display - Conditional based on view type */}
        {loading ? (
          activeView === 'table' ? <TableSkeleton /> : <CardsSkeleton />
        ) : filteredInvoices.length > 0 ? (
          <AnimatePresence mode="wait">
            {activeView === 'table' && (
              <motion.div
                key="table-view"
                className="bg-white rounded-2xl shadow-sm border border-gray-100 overflow-hidden"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.3 }}
              >
                <div className="overflow-x-auto">
                  <table className="min-w-full">
                    <thead className="bg-gray-50 border-b border-gray-100">
                      <tr className="text-xs font-medium text-gray-500 uppercase tracking-wider">
                        <th className="py-3 px-6 text-left cursor-pointer hover:bg-gray-100" onClick={() => handleSort('invoice')}>
                          <div className="flex items-center">
                            <span>Invoice No.</span>
                            {sortField === 'invoice' && (
                              <span className="ml-1">
                                {sortDirection === 'asc' ? <FaSortUp /> : <FaSortDown />}
                              </span>
                            )}
                          </div>
                        </th>
                        <th className="py-3 px-6 text-left">Title Number</th>
                        <th className="py-3 px-6 text-left">Serial No.</th>
                        <th className="py-3 px-6 text-left">Status</th>
                        <th className="py-3 px-6 text-left cursor-pointer hover:bg-gray-100" onClick={() => handleSort('date')}>
                          <div className="flex items-center">
                            <span>Due Date</span>
                            {sortField === 'date' && (
                              <span className="ml-1">
                                {sortDirection === 'asc' ? <FaSortUp /> : <FaSortDown />}
                              </span>
                            )}
                          </div>
                        </th>
                        <th className="py-3 px-6 text-left cursor-pointer hover:bg-gray-100" onClick={() => handleSort('amount')}>
                          <div className="flex items-center">
                            <span>Amount</span>
                            {sortField === 'amount' && (
                              <span className="ml-1">
                                {sortDirection === 'asc' ? <FaSortUp /> : <FaSortDown />}
                              </span>
                            )}
                          </div>
                        </th>
                        <th className="py-3 px-6 text-center">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredInvoices.map((invoice, index) => {
                        const statusInfo = getInvoiceStatusInfo(invoice);

                        return (
                          <motion.tr
                            key={invoice.id}
                            custom={index}
                            variants={tableRowVariants}
                            whileHover="hover"
                            className="border-b border-gray-100 hover:bg-gray-50 cursor-pointer"
                            onClick={() => navigate(`/dashboard/invoices/${invoice.id}/view`)}
                          >
                            <td className="py-4 px-6 text-sm font-medium text-gray-800">
                              {formatInvoiceNumber(invoice.id)}
                            </td>
                            <td className="py-4 px-6 text-sm text-gray-600">
                              {invoice?.order?.property?.title_number || 'N/A'}
                            </td>
                            <td className="py-4 px-6 text-sm text-gray-600">
                              {invoice.serial_no}
                            </td>
                            <td className="py-4 px-6">
                              <div className={`inline-flex items-center px-2.5 py-1 rounded-full text-xs font-medium ${statusInfo.bgColor} ${statusInfo.textColor}`}>
                                {statusInfo.icon}
                                <span className="ml-1">{statusInfo.status}</span>
                              </div>
                            </td>
                            <td className="py-4 px-6 text-sm text-gray-600">
                              {moment(invoice.payment_due_date).format('MMM DD, YYYY')}
                            </td>
                            <td className="py-4 px-6 text-sm font-semibold text-gray-800">
                              KES {Number(invoice.total_amount).toLocaleString()}
                            </td>
                            <td className="py-4 px-6 text-center">
                              <div className="flex items-center justify-center space-x-2">
                                <motion.button
                                  className="p-1.5 text-secondary bg-secondary bg-opacity-10 rounded-full"
                                  whileHover={{ scale: 1.1 }}
                                  whileTap={{ scale: 0.9 }}
                                >
                                  <FaRegFileAlt className="text-sm" />
                                </motion.button>
                              </div>
                            </td>
                          </motion.tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </motion.div>
            )}

            {activeView === 'cards' && (
              <motion.div
                key="cards-view"
                className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.3 }}
                variants={containerVariants}
              >
                {filteredInvoices.map((invoice, index) => {
                  const statusInfo = getInvoiceStatusInfo(invoice);

                  return (
                    <motion.div
                      key={invoice.id}
                      className="bg-white rounded-xl overflow-hidden shadow-sm border border-gray-100"
                      variants={cardVariants}
                      whileHover="hover"
                      whileTap="tap"
                      onClick={() => navigate(`/dashboard/invoices/${invoice.id}/view`)}
                      custom={index}
                    >
                      {/* Card Header with gradient based on status */}
                      <div className={`h-2 w-full ${statusInfo.bgColor}`}></div>

                      <div className="p-5">
                        <div className="flex justify-between items-start mb-4">
                          <div>
                            <h3 className="text-lg font-semibold text-gray-800">
                              {formatInvoiceNumber(invoice.id)}
                            </h3>
                            <p className="text-sm text-gray-500">
                              {invoice?.order?.property?.title_number || 'No title number'}
                            </p>
                          </div>
                          <div className={`flex items-center px-3 py-1 rounded-full text-xs font-medium ${statusInfo.bgColor} ${statusInfo.textColor}`}>
                            {statusInfo.icon}
                            <span className="ml-1">{statusInfo.status}</span>
                          </div>
                        </div>

                        <div className="space-y-3 mb-5">
                          <div className="flex justify-between items-center">
                            <span className="text-sm text-gray-500">Serial No:</span>
                            <span className="text-sm font-medium text-gray-700">{invoice.serial_no}</span>
                          </div>
                          <div className="flex justify-between items-center">
                            <span className="text-sm text-gray-500">Due Date:</span>
                            <span className="text-sm font-medium text-gray-700">
                              {moment(invoice.payment_due_date).format('MMM DD, YYYY')}
                            </span>
                          </div>
                          <div className="flex justify-between items-center">
                            <span className="text-sm text-gray-500">{statusInfo.message}</span>
                            <span className="text-sm font-semibold text-gray-800">
                              KES {Number(invoice.total_amount).toLocaleString()}
                            </span>
                          </div>
                        </div>

                        <div className="flex justify-between items-center pt-3 border-t border-gray-100">
                          <motion.div
                            className="flex items-center text-xs text-secondary font-medium"
                            whileHover={{ x: 3 }}
                          >
                            View Details
                            <IoChevronForward className="ml-1" />
                          </motion.div>
                        </div>
                      </div>
                    </motion.div>
                  );
                })}
              </motion.div>
            )}
          </AnimatePresence>
        ) : (
          <EmptyState />
        )}

        {/* Pagination Controls */}
        {!loading && filteredInvoices.length > 0 && (
          <motion.div
            className="flex justify-between items-center mt-8"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3 }}
          >
            <p className="text-sm text-gray-600">
              Showing {filteredInvoices.length} of {invoices.length} invoices
            </p>

            <div className="flex items-center space-x-2">
              <motion.button
                className={`w-10 h-10 rounded-full flex items-center justify-center ${currentPage === 1
                  ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                  : 'bg-white border border-gray-200 text-gray-600 hover:bg-gray-50'
                  }`}
                onClick={() => paginate(1)}
                disabled={currentPage === 1}
                whileHover={currentPage !== 1 ? { scale: 1.1 } : {}}
                whileTap={currentPage !== 1 ? { scale: 0.9 } : {}}
              >
                <FaChevronLeft className="text-xs mr-1" />
                <FaChevronLeft className="text-xs" />
              </motion.button>

              <motion.button
                className={`w-10 h-10 rounded-full flex items-center justify-center ${currentPage === 1
                  ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                  : 'bg-white border border-gray-200 text-gray-600 hover:bg-gray-50'
                  }`}
                onClick={() => paginate(currentPage - 1)}
                disabled={currentPage === 1}
                whileHover={currentPage !== 1 ? { scale: 1.1 } : {}}
                whileTap={currentPage !== 1 ? { scale: 0.9 } : {}}
              >
                <FaChevronLeft className="text-xs" />
              </motion.button>

              <div className="px-3 py-1 bg-secondary text-white rounded-full text-sm min-w-[40px] text-center font-medium">
                {currentPage}
              </div>

              <motion.button
                className={`w-10 h-10 rounded-full flex items-center justify-center ${currentPage === totalPages
                  ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                  : 'bg-white border border-gray-200 text-gray-600 hover:bg-gray-50'
                  }`}
                onClick={() => paginate(currentPage + 1)}
                disabled={currentPage === totalPages}
                whileHover={currentPage !== totalPages ? { scale: 1.1 } : {}}
                whileTap={currentPage !== totalPages ? { scale: 0.9 } : {}}
              >
                <FaChevronRight className="text-xs" />
              </motion.button>

              <motion.button
                className={`w-10 h-10 rounded-full flex items-center justify-center ${currentPage === totalPages
                  ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                  : 'bg-white border border-gray-200 text-gray-600 hover:bg-gray-50'
                  }`}
                onClick={() => paginate(totalPages)}
                disabled={currentPage === totalPages}
                whileHover={currentPage !== totalPages ? { scale: 1.1 } : {}}
                whileTap={currentPage !== totalPages ? { scale: 0.9 } : {}}
              >
                <FaChevronRight className="text-xs" />
                <FaChevronRight className="text-xs ml-1" />
              </motion.button>
            </div>
          </motion.div>
        )}
      </motion.div>
    </Layout>
  );
};

export default Invoices;