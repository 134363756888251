import React, { useEffect, useState, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import Layout from '../elements/Layout';
import logo from '../assets/miles_logo.svg';
import { FaPrint, FaShareAlt, FaDownload, FaFileInvoice, FaInfoCircle, FaBuilding, FaMapMarkerAlt } from 'react-icons/fa';
import { IoIosGlobe } from 'react-icons/io';
import { MdOutlineMailOutline, MdPayment, MdAccessTime } from 'react-icons/md';
import { IoCallSharp, IoCheckmarkCircle, IoTimeOutline, IoWarningOutline, IoDocumentText, IoCalendarOutline, IoWalletOutline, IoChevronDown, IoChevronUp, IoShareSocialOutline } from 'react-icons/io5';
import { useParams, useNavigate } from 'react-router-dom';
import { fetchInvoice } from '../utils/api/api';
import toast from 'react-hot-toast';
import moment from 'moment'
import Barcode from 'react-barcode';
import QRCode from 'react-qr-code';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

const ViewInvoice = () => {
  const [invoice, setInvoice] = useState(null);
  const [loading, setLoading] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [showTransactions, setShowTransactions] = useState(true);
  const [downloadSuccess, setDownloadSuccess] = useState(false);
  const { id } = useParams();
  const receiptRef = useRef();
  const navigate = useNavigate();

  const getInvoice = async () => {
    setLoading(true);
    try {
      const response = await fetchInvoice(id);
      setInvoice(response.invoice);
    } catch (error) {
      toast.error("Failed to fetch invoice", {
        style: {
          borderRadius: '12px',
          background: '#333',
          color: '#fff',
        }
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getInvoice();
  }, []);

  useEffect(() => {
    if (downloadSuccess) {
      const timer = setTimeout(() => {
        setDownloadSuccess(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [downloadSuccess]);

  const isPaid = invoice?.payment_status === 'paid';
  const isOverdue = invoice?.payment_due_date && new Date(invoice.payment_due_date) < new Date() && !isPaid;

  const formatInvoiceNumber = (id) => {
    const yearSuffix = moment().format('YYYY').slice(-2);
    return `MVL-${id}-${yearSuffix}`;
  };

  const handleDownloadPdf = () => {
    const input = receiptRef.current;

    if (!input) {
      console.error("Receipt reference not found.");
      return;
    }

    setDownloading(true);
    const filename = `receipt_${invoice.id}.pdf`;

    // Notify Flutter about the download initiation
    if (window.flutter_inappwebview) {
      window.flutter_inappwebview.callHandler("onDownloadStarted", filename);
    }

    html2canvas(input, { scale: 2 }) // Increase scale for higher quality
      .then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("p", "mm", "a4");

        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (canvas.height * pdfWidth) / canvas.width; // Maintain aspect ratio

        pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
        const pdfBlob = pdf.output("blob");

        pdf.save(filename);

        // Convert Blob to Base64
        const reader = new FileReader();
        reader.onload = () => {
          const base64data = reader.result.split(",")[1];

          // Notify Flutter with Base64 data
          if (window.flutter_inappwebview) {
            window.flutter_inappwebview.callHandler("onDownloadCompleted", {
              filename: filename,
              base64: base64data,
            });
          }

          setDownloading(false);
          setDownloadSuccess(true);
        };

        reader.readAsDataURL(pdfBlob);
      })
      .catch((error) => {
        console.error("Error generating PDF:", error);
        setDownloading(false);

        // Notify Flutter about the failure
        if (window.flutter_inappwebview) {
          window.flutter_inappwebview.callHandler("onDownloadFailed", error.message);
        }

        toast.error("Failed to generate PDF", {
          style: {
            borderRadius: '12px',
            background: '#333',
            color: '#fff',
          }
        });
      });
  };

  // Animation variants
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        when: "beforeChildren",
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        type: "spring",
        damping: 25,
        stiffness: 100
      }
    }
  };

  const statusVariants = {
    hidden: { opacity: 0, scale: 0.9 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        type: "spring",
        damping: 20,
        stiffness: 200
      }
    }
  };

  const getInvoiceStatusInfo = () => {
    if (!invoice) return null;

    if (isPaid) {
      return {
        label: 'Paid',
        icon: <IoCheckmarkCircle className="text-white" />,
        bgColor: 'bg-green-500',
        textColor: 'text-green-700',
        message: 'Payment complete'
      };
    } else if (isOverdue) {
      return {
        label: 'Overdue',
        icon: <IoWarningOutline className="text-white" />,
        bgColor: 'bg-red-500',
        textColor: 'text-red-700',
        message: 'Payment required immediately'
      };
    } else {
      return {
        label: 'Pending',
        icon: <IoTimeOutline className="text-white" />,
        bgColor: 'bg-yellow-500',
        textColor: 'text-yellow-700',
        message: 'Awaiting payment'
      };
    }
  };

  // Loading skeleton
  if (loading) {
    return (
      <Layout>
        <div className="max-w-4xl mx-auto p-6">
          <div className="animate-pulse">
            <div className="flex justify-between items-center mb-8">
              <div className="flex items-center">
                <div className="w-16 h-16 bg-gray-200 rounded-lg mr-4"></div>
                <div>
                  <div className="h-6 w-48 bg-gray-200 rounded mb-2"></div>
                  <div className="h-4 w-32 bg-gray-200 rounded"></div>
                </div>
              </div>
              <div className="h-10 w-32 bg-gray-200 rounded"></div>
            </div>

            <div className="w-full h-0.5 bg-gray-200 my-8"></div>

            <div className="grid grid-cols-2 gap-8 mb-6">
              <div>
                <div className="h-5 w-32 bg-gray-200 rounded mb-4"></div>
                <div className="space-y-2">
                  <div className="h-4 w-full bg-gray-200 rounded"></div>
                  <div className="h-4 w-2/3 bg-gray-200 rounded"></div>
                  <div className="h-4 w-3/4 bg-gray-200 rounded"></div>
                </div>
              </div>
              <div>
                <div className="h-5 w-32 bg-gray-200 rounded mb-4"></div>
                <div className="space-y-2">
                  <div className="h-4 w-full bg-gray-200 rounded"></div>
                  <div className="h-4 w-2/3 bg-gray-200 rounded"></div>
                  <div className="h-4 w-3/4 bg-gray-200 rounded"></div>
                </div>
              </div>
            </div>

            <div className="h-80 bg-gray-200 rounded-lg mb-8"></div>

            <div className="flex justify-between mb-8">
              <div className="h-24 w-1/3 bg-gray-200 rounded"></div>
              <div className="h-24 w-1/3 bg-gray-200 rounded"></div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }

  if (!invoice) {
    return (
      <Layout>
        <div className="max-w-4xl mx-auto p-6 text-center">
          <div className="w-20 h-20 bg-gray-100 rounded-full flex items-center justify-center mx-auto mb-6">
            <FaFileInvoice className="text-gray-400 text-4xl" />
          </div>
          <h3 className="text-xl font-medium text-gray-700 mb-2">Invoice Not Found</h3>
          <p className="text-gray-500 mb-6">The invoice you're looking for doesn't exist or has been removed.</p>
          <button
            className="px-6 py-2 bg-secondary text-white rounded-xl"
            onClick={() => navigate('/dashboard/invoices')}
          >
            Back to Invoices
          </button>
        </div>
      </Layout>
    );
  }

  const statusInfo = getInvoiceStatusInfo();

  return (
    <Layout>
      <motion.div
        className="max-w-4xl mx-auto"
        initial="hidden"
        animate="visible"
        variants={containerVariants}
      >
        {/* Action Bar */}
        <motion.div
          className="bg-white rounded-xl shadow-sm mb-6 p-4 flex justify-between items-center"
          variants={itemVariants}
        >
          <div className="flex items-center">
            <button
              onClick={() => navigate('/dashboard/invoices')}
              className="mr-4 px-4 py-2 bg-gray-50 hover:bg-gray-100 text-gray-700 rounded-lg text-sm flex items-center"
            >
              <span>◀ Back</span>
            </button>
            <h2 className="text-lg font-semibold text-gray-800">
              {isPaid ? 'Receipt' : 'Invoice'} #{formatInvoiceNumber(invoice.id)}
            </h2>
          </div>
        </motion.div>

        <AnimatePresence>
          {downloadSuccess && (
            <motion.div
              className="fixed top-4 right-4 bg-green-50 border border-green-200 text-green-800 px-4 py-3 rounded-xl shadow-lg flex items-center gap-2 z-50"
              initial={{ opacity: 0, y: -20, scale: 0.9 }}
              animate={{ opacity: 1, y: 0, scale: 1 }}
              exit={{ opacity: 0, y: -20, scale: 0.9 }}
              transition={{ duration: 0.3 }}
            >
              <IoCheckmarkCircle className="text-green-600 text-xl" />
              <span>PDF downloaded successfully!</span>
            </motion.div>
          )}
        </AnimatePresence>

        <div ref={receiptRef} className="bg-white rounded-xl shadow-sm overflow-hidden">
          {/* Status Banner */}
          <motion.div
            className={`w-full py-2 px-6 ${statusInfo.bgColor} text-white flex justify-between items-center`}
            variants={statusVariants}
          >
            <div className="flex items-center gap-2">
              {statusInfo.icon}
              <span className="font-medium">{isPaid ? 'Payment Completed' : isOverdue ? 'Payment Overdue' : 'Payment Pending'}</span>
            </div>
            {!isPaid && (
              <div className="text-sm">
                <span>Due date: {moment(invoice.payment_due_date).format('MMM DD, YYYY')}</span>
              </div>
            )}
          </motion.div>

          {/* Invoice Header */}
          <motion.div className="p-6 md:p-8" variants={itemVariants}>
            <div className="flex flex-col md:flex-row justify-between items-start md:items-center mb-8">
              <div className="flex items-center mb-4 md:mb-0">
                <img src={logo} alt="Milescoop Ventures" className="h-16 w-16 mr-4" />
                <div>
                  <h2 className="text-xl font-semibold text-gray-800">Milescoop Ventures Ltd</h2>
                  <p className="text-gray-500 text-sm">www.milescoop.com</p>
                </div>
              </div>

              <div className="flex flex-col items-end">
                <div className={`uppercase text-lg font-bold ${isPaid ? 'text-green-600' : isOverdue ? 'text-red-600' : 'text-yellow-600'}`}>
                  {isPaid ? 'Receipt' : 'Invoice'}
                </div>
                <div className="text-gray-500 text-sm mt-1">Serial: {invoice.serial_no}</div>
                <div className="text-gray-500 text-sm">Date: {moment(invoice.invoice_date).format('MMM DD, YYYY')}</div>
              </div>
            </div>

            <div className="h-px bg-gray-200 my-6"></div>

            {/* Invoice Details Grid */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-8">
              {/* Customer Information */}
              <div>
                <h3 className="text-gray-800 font-semibold mb-3 flex items-center">
                  <span className="mr-2">Billed To</span>
                </h3>
                <div className="bg-gray-50 p-4 rounded-xl border border-gray-100">
                  <p className="text-gray-800 font-medium">{`${invoice.customer.first_name} ${invoice.customer.last_name}`}</p>
                  <p className="text-gray-600 text-sm mt-2">Customer ID: {invoice.customer.unique_number}</p>
                  <p className="text-gray-600 text-sm">{invoice.customer.email}</p>
                  <p className="text-gray-600 text-sm">{invoice.customer.phone_number}</p>
                </div>
              </div>

              {/* Payment Information */}
              <div>
                <h3 className="text-gray-800 font-semibold mb-3">Payment Information</h3>
                <div className="bg-gray-50 p-4 rounded-xl border border-gray-100">
                  <div className="flex justify-between mb-2">
                    <span className="text-gray-600 text-sm">Invoice Number:</span>
                    <span className="text-gray-800 font-medium">{formatInvoiceNumber(invoice.id)}</span>
                  </div>
                  <div className="flex justify-between mb-2">
                    <span className="text-gray-600 text-sm">Issue Date:</span>
                    <span className="text-gray-800">{moment(invoice.invoice_date).format('MMM DD, YYYY')}</span>
                  </div>
                  <div className="flex justify-between mb-2">
                    <span className="text-gray-600 text-sm">Due Date:</span>
                    <span className={`${isOverdue ? 'text-red-600 font-medium' : 'text-gray-800'}`}>
                      {moment(invoice.payment_due_date).format('MMM DD, YYYY')}
                    </span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-gray-600 text-sm">Status:</span>
                    <span className={`${isPaid
                      ? 'text-green-600'
                      : isOverdue
                        ? 'text-red-600'
                        : 'text-yellow-600'
                      } font-medium`}>
                      {statusInfo.label}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            {/* Order Details */}
            <motion.div className="mb-8" variants={itemVariants}>
              <h3 className="text-gray-800 font-semibold mb-3">Order Details</h3>
              <div className="overflow-x-auto rounded-xl border border-gray-100">
                <table className="min-w-full">
                  <thead className="bg-gray-50 border-b border-gray-100">
                    <tr className="text-gray-500 text-xs uppercase tracking-wider">
                      <th className="py-3 px-6 text-left">Title No.</th>
                      <th className="py-3 px-6 text-left">Quantity</th>
                      <th className="py-3 px-6 text-left">Type</th>
                      <th className="py-3 px-6 text-left">Balance</th>
                      <th className="py-3 px-6 text-right">Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="border-b border-gray-100">
                      <td className="py-4 px-6 text-sm font-medium text-gray-800">
                        {invoice?.order?.property?.title_number || 'N/A'}
                      </td>
                      <td className="py-4 px-6 text-sm text-gray-600">1</td>
                      <td className="py-4 px-6 text-sm text-gray-600 capitalize">
                        {invoice?.installment?.type || 'One-time'}
                      </td>
                      <td className="py-4 px-6 text-sm text-gray-600">
                        {invoice.remaining_balance || 'N/A'}
                      </td>
                      <td className="py-4 px-6 text-sm font-semibold text-gray-800 text-right">
                        KES {Number(invoice.total_amount).toLocaleString()}
                      </td>
                    </tr>

                    <tr>
                      <td colSpan="5" className="py-3 px-6 text-sm text-gray-600 bg-gray-50">
                        <div className="flex items-center gap-2">
                          <FaBuilding className="text-gray-400" />
                          <span className="font-medium">Project:</span>
                          <span>{invoice.order?.property?.project?.title || 'N/A'}</span>
                          <span className="mx-1">|</span>
                          <FaMapMarkerAlt className="text-gray-400" />
                          <span className="font-medium">Location:</span>
                          <span>{invoice?.order?.property?.project?.location || 'N/A'}</span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </motion.div>

            {/* Payment Summary */}
            <motion.div className="w-full flex items-center justify-between mb-8" variants={itemVariants}>
              <div className="flex w-full justify-between items-center space-x-4">
                <div className="hidden md:block">
                  <Barcode
                    value={invoice?.serial_no}
                    width={1.5}
                    height={60}
                    displayValue={true}
                    background="#ffffff"
                    lineColor="#000000"
                    margin={0}
                    fontSize={12}
                    textAlign="center"
                  />
                </div>
                <div>
                  <QRCode
                    value={invoice?.serial_no}
                    size={100}
                    bgColor="#ffffff"
                    fgColor="#000000"
                    level="H"
                  />
                </div>
              </div>
            </motion.div>
            <div className="bg-gray-50 p-4 rounded-xl border border-gray-100">
              <h3 className="text-gray-800 font-semibold mb-3">Payment Summary</h3>
              <div className="flex justify-between mb-2">
                <span className="text-gray-600 text-sm">Subtotal:</span>
                <span className="text-gray-800">KES {Number(invoice.sub_total || 0).toLocaleString()}</span>
              </div>
              <div className="flex justify-between mb-2">
                <span className="text-gray-600 text-sm">Discount:</span>
                <span className="text-gray-800">{invoice.discount || 0}%</span>
              </div>
              <div className="flex justify-between mb-2">
                <span className="text-gray-600 text-sm">Paid Amount:</span>
                <span className="text-green-600">KES {Number(invoice.paid_amount || 0).toLocaleString()}</span>
              </div>
              <div className="h-px bg-gray-200 my-2"></div>
              <div className="flex justify-between font-semibold">
                <span className="text-gray-800">Total:</span>
                <span className="text-primary text-lg">KES {Number(invoice.total_amount).toLocaleString()}</span>
              </div>
            </div>
            {/* Notes */}
            {invoice.notes && (
              <motion.div className="mb-8 bg-blue-50 p-4 rounded-xl border border-blue-100" variants={itemVariants}>
                <h3 className="text-gray-800 font-semibold mb-2 flex items-center">
                  <FaInfoCircle className="text-blue-500 mr-2" />
                  Payment Notes
                </h3>
                <p className="text-gray-600 text-sm">{invoice.notes}</p>
              </motion.div>
            )}

            {/* Transactions Section */}
            {invoice?.transactions && invoice.transactions.length > 0 && (
              <motion.div className="mb-6" variants={itemVariants}>
                <div className="flex justify-between items-center mb-3">
                  <h3 className="text-gray-800 font-semibold flex items-center">
                    <MdPayment className="text-primary mr-2" />
                    Transaction History
                  </h3>
                  <button
                    className="text-gray-500 text-sm flex items-center"
                    onClick={() => setShowTransactions(!showTransactions)}
                  >
                    {showTransactions ? (
                      <>
                        <span>Hide</span>
                        <IoChevronUp className="ml-1" />
                      </>
                    ) : (
                      <>
                        <span>Show</span>
                        <IoChevronDown className="ml-1" />
                      </>
                    )}
                  </button>
                </div>

                <AnimatePresence>
                  {showTransactions && (
                    <motion.div
                      initial={{ opacity: 0, height: 0 }}
                      animate={{ opacity: 1, height: "auto" }}
                      exit={{ opacity: 0, height: 0 }}
                      transition={{ duration: 0.3 }}
                      className="overflow-hidden"
                    >
                      <div className="overflow-x-auto rounded-xl border border-gray-100">
                        <table className="min-w-full">
                          <thead className="bg-gray-50 border-b border-gray-100">
                            <tr className="text-gray-500 text-xs uppercase tracking-wider">
                              <th className="py-3 px-6 text-left">Transaction ID</th>
                              <th className="py-3 px-6 text-left">Amount</th>
                              <th className="py-3 px-6 text-left">Type</th>
                              <th className="py-3 px-6 text-left">Date & Time</th>
                            </tr>
                          </thead>
                          <tbody>
                            {invoice.transactions.map((transaction) => {
                              const year = moment(transaction.createdAt).format('YYYY').slice(-2);
                              const formattedTransactionId = `MVL-${transaction.id}-${year}`;

                              return (
                                <tr key={transaction.id} className="border-b border-gray-100 hover:bg-gray-50">
                                  <td className="py-3 px-6 text-sm font-medium text-gray-800">
                                    {formattedTransactionId}
                                  </td>
                                  <td className="py-3 px-6 text-sm text-gray-600">
                                    KES {Number(transaction.amount).toLocaleString()}
                                  </td>
                                  <td className="py-3 px-6 text-sm text-gray-600 capitalize">
                                    {transaction.type}
                                  </td>
                                  <td className="py-3 px-6 text-sm text-gray-600">
                                    {moment(transaction.createdAt).format('MMM DD, YYYY, h:mm a')}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </motion.div>
                  )}
                </AnimatePresence>
              </motion.div>
            )}

            {/* Footer */}
            <motion.footer className="mt-12 pt-6 border-t border-gray-200" variants={itemVariants}>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-6 text-gray-600 text-sm">
                <div className="space-y-2">
                  <p className="flex items-center">
                    <span className="bg-secondary text-white rounded-full p-1.5 w-6 h-6 flex items-center justify-center mr-2">
                      <IoCallSharp />
                    </span>
                    +254 (0) 737 900 424
                  </p>
                  <p className="flex items-center">
                    <span className="bg-secondary text-white rounded-full p-1.5 w-6 h-6 flex items-center justify-center mr-2">
                      <MdOutlineMailOutline />
                    </span>
                    info@milescoop.com
                  </p>
                </div>

                <div className="space-y-2">
                  <p className="flex items-center">
                    <span className="bg-secondary text-white rounded-full p-1.5 w-6 h-6 flex items-center justify-center mr-2">
                      <IoIosGlobe />
                    </span>
                    www.milescoop.com
                  </p>
                </div>

                <div className="space-y-2">
                  <p className="flex items-center">
                    <span className="bg-secondary text-white rounded-full p-1.5 w-6 h-6 flex items-center justify-center mr-2">
                      <IoIosGlobe />
                    </span>
                    Design Centre, 4th floor, Room 4A
                  </p>
                  <p className="flex items-center">
                    <span className="bg-secondary text-white rounded-full p-1.5 w-6 h-6 flex items-center justify-center mr-2">
                      <IoIosGlobe />
                    </span>
                    PIN NUMBER: 052203487E
                  </p>
                </div>
              </div>

              <div className="mt-6 text-center text-xs text-gray-500">
                This is {isPaid ? 'a receipt' : 'an invoice'} from Milescoop Ventures Ltd. Thank you for your business!
              </div>
            </motion.footer>
          </motion.div>
        </div>
      </motion.div>
    </Layout>
  );
};

export default ViewInvoice;