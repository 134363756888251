import React, { useEffect, useState, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import Cookies from 'js-cookie';
import moment from 'moment';
import { fetchCustomerOrders, fetchCustomerWallet, getCustomerPayments, getCustomerStatements, processWalletPayment } from '../utils/api/api';
import toast from 'react-hot-toast';
import Layout from '../elements/Layout';
import logo from '../assets/miles_logo.svg';
import Loading from '../elements/Loading';
import { Link } from 'react-router-dom';
import { HiOutlineCash, HiOutlineDocumentText, HiOutlineCalendar, HiOutlineClock, HiOutlineCreditCard, HiOutlineExclamationCircle, HiOutlineCheckCircle, HiOutlineX } from "react-icons/hi";

const WalletStatement = () => {
    const customer_id = Cookies.get('user_id');
    const statementRef = useRef(null);
    const [wallet, setWallet] = useState(null);
    const [loading, setLoading] = useState(true);
    const [statements, setStatements] = useState([]);
    const [payments, setPayments] = useState([]);
    const [orders, setOrders] = useState([]);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredPayments, setFilteredPayments] = useState([]);

    // Animation variants
    const containerVariants = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                staggerChildren: 0.1
            }
        }
    };

    const itemVariants = {
        hidden: { opacity: 0, y: 20 },
        visible: {
            opacity: 1,
            y: 0,
            transition: { duration: 0.5, ease: [0.22, 1, 0.36, 1] }
        }
    };

    const buttonVariants = {
        hover: {
            scale: 1.02,
            boxShadow: "0 10px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)",
            transition: { duration: 0.2 }
        },
        tap: { scale: 0.98 }
    };

    const getPayments = async () => {
        try {
            const response = await getCustomerPayments({
                customer_id,
                page: 1,
                pageSize: 1000,
            });
            setPayments(response.payments);
            setFilteredPayments(response.payments);
        } catch (error) {
            toast.error(error.message || "Failed to get payments");
        }
    };

    useEffect(() => {
        const getOrders = async () => {
            try {
                const response = await fetchCustomerOrders({
                    customer_id: customer_id,
                    page: 1,
                    pageSize: 1000,
                });
                setOrders(response.orders);
            } catch (error) {
                console.error("Failed to fetch orders", error);
            }
        };
        getOrders();
    }, []);

    const fetchCustomerStatements = async () => {
        try {
            const response = await getCustomerStatements(customer_id);
            setStatements(response);
        } catch (error) {
            toast.error("Failed to get statements");
        }
    };

    const getWallet = async () => {
        try {
            const response = await fetchCustomerWallet(customer_id);
            setWallet(response.wallet);
            setLoading(false);
        } catch (error) {
            toast.error(error.message || "Request failed");
            setLoading(false);
        }
    };

    const handleWalletPayment = async () => {
        if (!selectedOrder) {
            toast.error("Please select an order to pay from the wallet");
            return;
        }
        try {
            setLoading(true);
            await processWalletPayment({
                customer_id,
                order_id: selectedOrder.id,
            });
            toast.success("Payment processed successfully from wallet");
            await Promise.all([getPayments(), getWallet(), fetchCustomerStatements()]);
            setShowModal(false);
            setLoading(false);
        } catch (error) {
            toast.error(error.message || "Payment failed");
            setLoading(false);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                await Promise.all([getWallet(), getPayments(), fetchCustomerStatements()]);
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        if (searchTerm.trim()) {
            const filtered = payments.filter(payment =>
                payment.transaction_id.toLowerCase().includes(searchTerm.toLowerCase()) ||
                payment.method.toLowerCase().includes(searchTerm.toLowerCase()) ||
                moment(payment.createdAt).format('MMM Do, YYYY').toLowerCase().includes(searchTerm.toLowerCase())
            );
            setFilteredPayments(filtered);
        } else {
            setFilteredPayments(payments);
        }
    }, [searchTerm, payments]);

    const handleOrderSelection = (orderId) => {
        const order = orders.find((order) => order.id === Number(orderId));
        setSelectedOrder(order);
    };

    const formatCurrency = (amount) => {
        return new Intl.NumberFormat('en-KE', {
            style: 'currency',
            currency: 'KES',
        }).format(amount);
    };

    if (loading) return <Loading />;

    const StatementCard = ({ label, value, color, icon, date }) => (
        <motion.div
            variants={itemVariants}
            whileHover={{ y: -5, transition: { duration: 0.2 } }}
            className={`p-5 rounded-2xl backdrop-blur-xl ${color} border border-white/50 shadow-md flex flex-col justify-between h-full`}
        >
            <div className="flex justify-between items-start">
                <div className="p-2 rounded-full bg-white/30 backdrop-blur-sm">
                    {icon}
                </div>
                <div className="text-right">
                    <p className="text-sm font-medium opacity-80">{label}</p>
                    {date && (
                        <p className="text-xs opacity-70">{date}</p>
                    )}
                </div>
            </div>
            <div className="mt-4">
                <p className="text-xl md:text-2xl font-bold">{value}</p>
            </div>
        </motion.div>
    );

    const statementItems = [
        {
            label: 'Upcoming Payment',
            value: statements?.next_due !== null ? formatCurrency(statements?.next_due?.payment_status === 'partially_paid' ? statements?.next_due?.remaining_balance : statements?.next_due?.total_amount) : '--',
            color: 'bg-yellow-50/80 text-yellow-800',
            icon: <HiOutlineCalendar className="text-yellow-600" size={20} />,
            date: statements?.next_due?.payment_due_date ? moment(statements?.next_due?.payment_due_date).format('MMM DD, YYYY') : ''
        },
        {
            label: 'Total Arrears',
            value: statements?.total_overdue !== null ? formatCurrency(statements?.total_overdue) : '--',
            color: 'bg-red-50/80 text-red-800',
            icon: <HiOutlineExclamationCircle className="text-red-600" size={20} />
        },
        {
            label: 'Total Paid',
            value: statements?.total_paid !== null ? formatCurrency(statements?.total_paid) : '--',
            color: 'bg-green-50/80 text-green-800',
            icon: <HiOutlineCheckCircle className="text-green-600" size={20} />
        },
        {
            label: 'Outstanding Balance',
            value: statements?.total_due !== null ? formatCurrency(statements?.total_due) : '--',
            color: 'bg-indigo-50/80 text-indigo-800',
            icon: <HiOutlineCreditCard className="text-indigo-600" size={20} />
        }
    ];

    const GlassModal = ({ title, onClose, children }) => (
        <AnimatePresence>
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-black/20 backdrop-blur-sm"
                onClick={(e) => {
                    if (e.target === e.currentTarget) onClose();
                }}
            >
                <motion.div
                    initial={{ scale: 0.9, opacity: 0 }}
                    animate={{ scale: 1, opacity: 1 }}
                    exit={{ scale: 0.9, opacity: 0 }}
                    transition={{ type: "spring", stiffness: 300, damping: 30 }}
                    className="w-full max-w-md rounded-3xl overflow-hidden backdrop-blur-xl bg-white/80 border border-white/50 shadow-xl"
                >
                    <div className="p-5 border-b border-gray-100/80 flex justify-between items-center">
                        <h3 className="text-xl font-semibold bg-clip-text text-transparent bg-gradient-to-r from-primary to-secondary">
                            {title}
                        </h3>
                        <button
                            onClick={onClose}
                            className="w-8 h-8 rounded-full bg-gray-100/80 flex items-center justify-center text-gray-600 hover:bg-gray-200/80 transition-colors"
                        >
                            <HiOutlineX size={18} />
                        </button>
                    </div>
                    <div className="p-5">
                        {children}
                    </div>
                </motion.div>
            </motion.div>
        </AnimatePresence>
    );

    return (
        <Layout backgroundColor="bg-gray-50/50">
            {loading && <Loading />}

            <div className="fixed inset-0 -z-10 pointer-events-none overflow-hidden">
                <div className="absolute top-40 left-20 w-64 h-64 rounded-full bg-primary/5 blur-3xl"></div>
                <div className="absolute bottom-20 right-20 w-80 h-80 rounded-full bg-secondary/5 blur-3xl"></div>
            </div>

            <motion.div
                variants={containerVariants}
                initial="hidden"
                animate="visible"
                className="w-full max-w-7xl mx-auto"
                ref={statementRef}
            >
                <motion.div
                    variants={itemVariants}
                    className="rounded-2xl backdrop-blur-xl bg-white/60 border border-white/50 shadow-lg p-6 mb-6"
                >
                    <div className="flex flex-col md:flex-row justify-between items-center gap-6">
                        <div className="flex items-center gap-4">
                            <img src={logo} className="w-[80px]" alt="Logo" />
                            <div>
                                <h1 className="text-2xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-primary to-secondary">
                                    Wallet Statement
                                </h1>
                                <p className="text-gray-600 text-sm">
                                    Complete financial overview for your account
                                </p>
                            </div>
                        </div>

                        <div className="text-right text-gray-600 text-sm">
                            <p className="text-lg font-semibold text-gray-800">Milescoop Ventures Ltd</p>
                            <p>P.O. Box 2760 - 00100, Nairobi, Kenya</p>
                            <p>+254 (0) 727 900 424 • info@milescoop.com</p>
                            <p>www.milescoop.com</p>
                        </div>
                    </div>
                </motion.div>

                {/* Statement summary cards */}
                <motion.div
                    variants={itemVariants}
                    className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-6"
                >
                    {statementItems.map((item, index) => (
                        <StatementCard
                            key={index}
                            label={item.label}
                            value={item.value}
                            color={item.color}
                            icon={item.icon}
                            date={item.date}
                        />
                    ))}
                </motion.div>

                {/* Wallet balance card */}
                {wallet?.balance > 0 && (
                    <motion.div
                        variants={itemVariants}
                        className="mb-6 overflow-hidden rounded-2xl relative"
                    >
                        <div className="absolute inset-0 bg-gradient-to-r from-blue-500/60 to-indigo-600/60 backdrop-blur-md -z-10"></div>
                        <div className="absolute inset-0 bg-pattern opacity-10"></div>

                        <div className="relative p-6 flex flex-col md:flex-row items-center justify-between">
                            <div className="mb-4 md:mb-0 text-white">
                                <div className="flex items-center gap-3 mb-2">
                                    <div className="w-12 h-12 rounded-full bg-white/20 backdrop-blur-md flex items-center justify-center">
                                        <HiOutlineCash className="text-white" size={24} />
                                    </div>
                                    <div>
                                        <h3 className="text-xl font-bold">Available Wallet Balance</h3>
                                        <p className="text-white/80">Use your balance to pay for upcoming invoices</p>
                                    </div>
                                </div>
                            </div>

                            <div className="flex flex-col md:flex-row items-center gap-4">
                                <p className="text-2xl font-bold text-white">
                                    {formatCurrency(wallet.balance)}
                                </p>
                                <motion.button
                                    onClick={() => setShowModal(true)}
                                    variants={buttonVariants}
                                    whileHover="hover"
                                    whileTap="tap"
                                    className="px-6 py-3 bg-white/20 hover:bg-white/30 backdrop-blur-md text-white font-medium rounded-xl border border-white/30 shadow-lg flex items-center gap-2"
                                >
                                    <HiOutlineCreditCard size={20} />
                                    <span>Pay from Wallet</span>
                                </motion.button>
                            </div>
                        </div>
                    </motion.div>
                )}

                {/* Payments history section */}
                <motion.div
                    variants={itemVariants}
                    className="rounded-2xl backdrop-blur-xl bg-white/60 border border-white/50 shadow-lg overflow-hidden"
                >
                    <div className="p-5 border-b border-gray-100/80">
                        <div className="flex flex-col md:flex-row justify-between gap-4">
                            <div className="flex items-center gap-3">
                                <div className="w-10 h-10 rounded-full bg-primary/10 flex items-center justify-center">
                                    <HiOutlineDocumentText className="text-primary" size={20} />
                                </div>
                                <h2 className="text-xl font-semibold text-gray-800">
                                    Payment History
                                </h2>
                            </div>

                            <div className="relative">
                                <input
                                    type="text"
                                    placeholder="Search transactions..."
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                    className="w-full pl-4 pr-10 py-2 rounded-xl bg-white/70 backdrop-blur-sm border border-white/50 focus:outline-none focus:ring-2 focus:ring-primary/20"
                                />
                                <div className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500">
                                    <HiOutlineSearch size={18} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="divide-y divide-gray-100/80 max-h-[550px] overflow-y-auto p-1">
                        <AnimatePresence>
                            {filteredPayments.length > 0 ? (
                                filteredPayments.map((payment, index) => (
                                    <motion.div
                                        key={payment.id}
                                        variants={itemVariants}
                                        initial={{ opacity: 0, y: 10 }}
                                        animate={{ opacity: 1, y: 0 }}
                                        exit={{ opacity: 0, y: -10 }}
                                        transition={{ duration: 0.2 }}
                                        whileHover={{
                                            backgroundColor: "rgba(255, 255, 255, 0.5)",
                                            transition: { duration: 0.2 }
                                        }}
                                    >
                                        <Link
                                            to={`/dashboard/payments/${payment.id}/view-details`}
                                            className="flex items-center justify-between gap-4 p-4 rounded-xl transition-all"
                                        >
                                            <div className="flex items-center gap-3">
                                                <div className="w-10 h-10 rounded-full bg-green-100 flex items-center justify-center text-green-600">
                                                    <HiOutlineCash size={20} />
                                                </div>
                                                <div>
                                                    <p className="font-medium text-gray-800">{payment.transaction_id}</p>
                                                    <div className="flex items-center gap-2 text-sm text-gray-500">
                                                        <span className="capitalize">{payment.method.replace('_', ' ')}</span>
                                                        <span className="w-1 h-1 rounded-full bg-gray-300"></span>
                                                        <span>{moment(payment.createdAt).format('MMM Do, YYYY')}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex flex-col items-end">
                                                <p className="font-semibold text-primary">
                                                    {formatCurrency(payment.amount)}
                                                </p>
                                                <p className="text-xs text-gray-500">
                                                    {moment(payment.createdAt).format('hh:mm A')}
                                                </p>
                                            </div>
                                        </Link>
                                    </motion.div>
                                ))
                            ) : (
                                <motion.div
                                    variants={itemVariants}
                                    className="py-12 text-center text-gray-500"
                                >
                                    <div className="w-16 h-16 mx-auto rounded-full bg-gray-100 flex items-center justify-center mb-4">
                                        <HiOutlineDocumentText size={28} className="text-gray-400" />
                                    </div>
                                    <p>No payment records found</p>
                                    {searchTerm && (
                                        <button
                                            onClick={() => setSearchTerm('')}
                                            className="mt-3 text-primary text-sm hover:underline"
                                        >
                                            Clear search
                                        </button>
                                    )}
                                </motion.div>
                            )}
                        </AnimatePresence>
                    </div>
                </motion.div>

                {/* Modal for payment selection */}
                {showModal && (
                    <GlassModal title="Pay for Invoice" onClose={() => setShowModal(false)}>
                        <div className="mb-6 p-4 rounded-xl bg-blue-50/80 border border-blue-100/80 text-blue-800">
                            <div className="flex items-center gap-3 mb-2">
                                <div className="w-8 h-8 rounded-full bg-blue-100 flex items-center justify-center">
                                    <HiOutlineCash size={16} className="text-blue-500" />
                                </div>
                                <p className="font-medium">Available Balance</p>
                            </div>
                            <p className="text-xl font-bold ml-8">{formatCurrency(wallet.balance)}</p>
                        </div>

                        <div className="mb-5">
                            <label className="block text-gray-700 text-sm font-medium mb-2">
                                Select an Order to Pay:
                            </label>
                            <div className="relative">
                                <select
                                    onChange={(e) => handleOrderSelection(e.target.value)}
                                    className="w-full h-12 pl-4 pr-10 rounded-xl bg-white/80 backdrop-blur-sm border border-gray-200/80 focus:outline-none focus:ring-2 focus:ring-primary/20 appearance-none"
                                    value={selectedOrder?.id || ''}
                                >
                                    <option value="" disabled>Select an order</option>
                                    {orders
                                        .filter(order => order.payment_status === 'pending' || order.payment_status === 'partially_paid')
                                        .map((order) => {
                                            const projectTitle = order.project?.title || 'Unknown Project';
                                            const propertyDetails = order.order_items
                                                .map((item) => `(${item.property.property_number}) - ${order.payment_status.replace('_', ' ')}`)
                                                .join(', ');

                                            return (
                                                <option key={order.id} value={order.id}>
                                                    {`${projectTitle} - ${propertyDetails}`}
                                                </option>
                                            );
                                        })}
                                </select>
                                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-4">
                                    <HiOutlineChevronDown className="h-4 w-4 text-gray-500" />
                                </div>
                            </div>
                        </div>

                        {selectedOrder && (
                            <div className="mb-6 p-4 rounded-xl bg-gray-50/80 border border-gray-200/80">
                                <div className="flex justify-between mb-2">
                                    <span className="text-gray-600">Order Amount:</span>
                                    <span className="font-medium text-gray-800">
                                        {formatCurrency(selectedOrder.total_amount || 0)}
                                    </span>
                                </div>
                                {selectedOrder.payment_status === 'partially_paid' && (
                                    <div className="flex justify-between">
                                        <span className="text-gray-600">Remaining Balance:</span>
                                        <span className="font-medium text-gray-800">
                                            {formatCurrency(selectedOrder.remaining_balance || 0)}
                                        </span>
                                    </div>
                                )}
                            </div>
                        )}

                        <div className="flex justify-between gap-4 mt-8">
                            <motion.button
                                onClick={() => setShowModal(false)}
                                variants={buttonVariants}
                                whileHover="hover"
                                whileTap="tap"
                                className="flex-1 py-3 rounded-xl bg-white border border-gray-200 text-gray-700 font-medium"
                            >
                                Cancel
                            </motion.button>
                            <motion.button
                                onClick={handleWalletPayment}
                                disabled={!selectedOrder || loading}
                                variants={buttonVariants}
                                whileHover={selectedOrder && !loading ? "hover" : {}}
                                whileTap={selectedOrder && !loading ? "tap" : {}}
                                className={`flex-1 py-3 rounded-xl text-white font-medium flex items-center justify-center gap-2 ${!selectedOrder || loading ? 'bg-gray-400' : 'bg-gradient-to-r from-primary to-secondary'
                                    }`}
                            >
                                {loading ? (
                                    <>
                                        <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                        </svg>
                                        <span>Processing...</span>
                                    </>
                                ) : (
                                    <>
                                        <HiOutlineCash size={20} />
                                        <span>Confirm Payment</span>
                                    </>
                                )}
                            </motion.button>
                        </div>
                    </GlassModal>
                )}
            </motion.div>
        </Layout>
    );
};

const HiOutlineSearch = ({ size, className }) => (
    <svg xmlns="http://www.w3.org/2000/svg" className={className} width={size} height={size} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
        <circle cx="11" cy="11" r="8"></circle>
        <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
    </svg>
);

const HiOutlineChevronDown = ({ size, className }) => (
    <svg xmlns="http://www.w3.org/2000/svg" className={className} width={size} height={size} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
        <polyline points="6 9 12 15 18 9"></polyline>
    </svg>
);

export default WalletStatement;