import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import Layout from '../elements/Layout';
import { useNavigate, useParams } from 'react-router-dom';
import { checkPaymentStatus, fetchOrder, processCardPayment, processMpesaPayment } from '../utils/api/api';
import moment from 'moment';
import toast from 'react-hot-toast';
import Cookies from 'js-cookie';
import { FaCreditCard, FaCheckCircle, FaClock, FaExclamationCircle } from 'react-icons/fa';
import { IoCheckmarkCircle, IoCloseCircle, IoShieldCheckmark, IoPhonePortrait, IoCalendarOutline, IoLockClosed } from 'react-icons/io5';

const PaymentPage = () => {
    const [order, setOrder] = useState(null);
    const [loading, setLoading] = useState(false);
    const [processingPayment, setProcessingPayment] = useState(false);
    const navigate = useNavigate();
    const [paymentMethod, setPaymentMethod] = useState('mpesa');
    const [amount, setAmount] = useState('');
    const [phone, setPhone] = useState('');
    const [formErrors, setFormErrors] = useState({});
    const [cardDetails, setCardDetails] = useState({
        cardNumber: '',
        expiryDate: '',
        cvv: '',
        cardholderName: '',
    });
    const [focusedField, setFocusedField] = useState(null);

    const { id } = useParams();
    const cu_id = Cookies.get('user_id');
    const mpesa_logo = 'https://imgs.search.brave.com/ksKqVibKAMDeEPrAJiYsygtSjr-is_IBO44A08W82Ts/rs:fit:500:0:0:0/g:ce/aHR0cHM6Ly91cGxv/YWQud2lraW1lZGlh/Lm9yZy93aWtpcGVk/aWEvY29tbW9ucy8w/LzBiL00tUEVTQS5w/bmc';
    const visa_logo = 'https://imgs.search.brave.com/bNsCAqw-xAbHOBLJ8nQRkVsPfIQJGxTG2tnzaQY6ytk/rs:fit:500:0:0:0/g:ce/aHR0cHM6Ly9zZWVr/bG9nby5jb20vaW1h/Z2VzL1YvVklTQS1s/b2dvLTE2RjRGODJE/MTMtc2Vla2xvZ28u/Y29tLnBuZw';
    const mastercard_logo = 'https://imgs.search.brave.com/P3S-4nzSLPOsVBu50WqTJkvji1ahb56zJ-22cbFeeHI/rs:fit:500:0:0:0/g:ce/aHR0cHM6Ly9hc3Nl/dHMuc3RpY2twbmcu/Y29tL2ltYWdlcy81/ODQ4MjM1NGNlZjEw/MTRjMGI1ZTQ5YzAu/cG5n';

    const paymentOptions = [
        { id: 'mpesa', title: 'M-Pesa', logo: mpesa_logo, description: 'Pay using M-Pesa mobile money' },
        { id: 'card', title: 'Card Payment', logo: visa_logo, secondaryLogo: mastercard_logo, description: 'Pay with debit or credit card' }
    ];

    // Animation variants
    const containerVariants = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                staggerChildren: 0.1
            }
        }
    };

    const itemVariants = {
        hidden: { opacity: 0, y: 20 },
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                type: "spring",
                stiffness: 100,
                damping: 15
            }
        }
    };

    const cardVariants = {
        hidden: { opacity: 0, y: 20 },
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                type: "spring",
                stiffness: 100,
                damping: 15
            }
        },
        hover: {
            y: -5,
            boxShadow: "0 10px 25px rgba(0, 0, 0, 0.1)",
            transition: {
                type: "spring",
                stiffness: 400,
                damping: 15
            }
        },
        tap: { scale: 0.98 }
    };

    const getOrder = async () => {
        setLoading(true);
        try {
            const response = await fetchOrder(id);
            const fetchedOrder = response.order;
            setOrder(fetchedOrder);

            if (fetchedOrder?.payment_type === 'one_time') {
                setAmount(Number(fetchedOrder.amount));
            } else if (fetchedOrder?.payment_type === 'installments') {
                const firstPendingInstallment = fetchedOrder.installments.find(inst => inst.payment_status === 'pending');
                if (firstPendingInstallment) {
                    setAmount(Number(firstPendingInstallment.amount));
                }
            }

            // Auto-populate the phone number if available in the customer data
            if (fetchedOrder?.customer?.phone) {
                setPhone(fetchedOrder.customer.phone);
            }

        } catch (error) {
            toast.error('Failed to get order details', {
                style: {
                    borderRadius: '12px',
                    background: '#333',
                    color: '#fff',
                }
            });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getOrder();
    }, [id]);

    const parseExpiryDate = (expiryDate) => {
        const [month, year] = expiryDate.split('/');
        return { month, year };
    };

    const validateForm = () => {
        const errors = {};

        if (paymentMethod === 'mpesa') {
            if (!phone) {
                errors.phone = 'Phone number is required';
            } else if (!/^(07|01|2547|2541)[0-9]{8}$/.test(phone.replace(/\s/g, ''))) {
                errors.phone = 'Enter a valid Kenyan phone number';
            }
        } else if (paymentMethod === 'card') {
            if (!cardDetails.cardNumber) {
                errors.cardNumber = 'Card number is required';
            } else if (!/^\d{16}$/.test(cardDetails.cardNumber.replace(/\s/g, ''))) {
                errors.cardNumber = 'Enter a valid 16-digit card number';
            }

            if (!cardDetails.expiryDate) {
                errors.expiryDate = 'Expiry date is required';
            } else if (!/^\d{2}\/\d{2}$/.test(cardDetails.expiryDate)) {
                errors.expiryDate = 'Enter a valid expiry date (MM/YY)';
            }

            if (!cardDetails.cvv) {
                errors.cvv = 'CVV is required';
            } else if (!/^\d{3}$/.test(cardDetails.cvv)) {
                errors.cvv = 'Enter a valid 3-digit CVV';
            }

            if (!cardDetails.cardholderName) {
                errors.cardholderName = 'Cardholder name is required';
            }
        }

        if (!amount || amount <= 0) {
            errors.amount = 'Please enter a valid amount';
        }

        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const formatCardNumber = (value) => {
        const input = value.replace(/\D/g, '');
        const formattedValue = input.replace(/(\d{4})(?=\d)/g, '$1 ');
        return formattedValue.substring(0, 19); // 16 digits + 3 spaces
    };

    const formatExpiryDate = (value) => {
        const input = value.replace(/\D/g, '');
        if (input.length > 2) {
            return `${input.substring(0, 2)}/${input.substring(2, 4)}`;
        }
        return input;
    };

    const handleCardNumberChange = (e) => {
        const formattedValue = formatCardNumber(e.target.value);
        setCardDetails({ ...cardDetails, cardNumber: formattedValue });
    };

    const handleExpiryDateChange = (e) => {
        const formattedValue = formatExpiryDate(e.target.value);
        setCardDetails({ ...cardDetails, expiryDate: formattedValue });
    };

    const handlePaymentSubmit = async (e) => {
        e.preventDefault();

        if (!validateForm()) {
            // Form has errors, don't proceed
            toast.error('Please correct the errors in the form', {
                style: {
                    borderRadius: '12px',
                    background: '#333',
                    color: '#fff',
                }
            });
            return;
        }

        setProcessingPayment(true);

        const customerId = cu_id;
        const orderId = id;

        if (paymentMethod === 'mpesa') {
            const mpesaPayload = {
                order_id: orderId,
                customer_id: customerId,
                amount: amount,
                phone_number: phone.replace(/\s/g, ''),
            };

            try {
                const response = await processMpesaPayment(mpesaPayload);
                const { message, payment_id } = response;

                toast.success(message, {
                    style: {
                        borderRadius: '12px',
                        background: '#333',
                        color: '#fff',
                    },
                    icon: '✅',
                });

                pollPaymentStatus(payment_id);
            } catch (error) {
                toast.error('M-Pesa payment failed. Please try again.', {
                    style: {
                        borderRadius: '12px',
                        background: '#333',
                        color: '#fff',
                    },
                    icon: '❌',
                });
                setProcessingPayment(false);
            }
        } else if (paymentMethod === 'card') {
            const { month, year } = parseExpiryDate(cardDetails.expiryDate);
            const cardPayload = {
                order_id: orderId,
                customer_id: customerId,
                amount: amount,
                card_number: cardDetails.cardNumber.replace(/\s/g, ''),
                card_expiry_month: month,
                card_expiry_year: `20${year}`,
                cvc: cardDetails.cvv,
                cardholder_name: cardDetails.cardholderName
            };

            try {
                await processCardPayment(cardPayload);
                toast.success('Card payment successfully processed', {
                    style: {
                        borderRadius: '12px',
                        background: '#333',
                        color: '#fff',
                    },
                    icon: '✅',
                });

                // Simulate payment confirmation for card payments
                setTimeout(() => {
                    setProcessingPayment(false);
                    navigate('/dashboard/payments');
                }, 3000);
            } catch (error) {
                toast.error('Card payment failed. Please check your details and try again.', {
                    style: {
                        borderRadius: '12px',
                        background: '#333',
                        color: '#fff',
                    },
                    icon: '❌',
                });
                setProcessingPayment(false);
            }
        }
    };

    const pollPaymentStatus = (paymentId) => {
        const interval = setInterval(async () => {
            try {
                const response = await checkPaymentStatus(paymentId.toString());
                const { status } = response.payment;

                if (status === 'completed') {
                    clearInterval(interval);
                    toast.success('Payment successfully completed', {
                        style: {
                            borderRadius: '12px',
                            background: '#333',
                            color: '#fff',
                        },
                        icon: '✅',
                    });
                    setProcessingPayment(false);
                    navigate('/dashboard/payments');
                } else if (status === 'failed') {
                    clearInterval(interval);
                    toast.error('Payment failed', {
                        style: {
                            borderRadius: '12px',
                            background: '#333',
                            color: '#fff',
                        },
                        icon: '❌',
                    });
                    setProcessingPayment(false);
                }
            } catch (error) {
                clearInterval(interval);
                setProcessingPayment(false);

                if (error.response && error.response.status === 400) {
                    toast.error('Payment failed due to client error', {
                        style: {
                            borderRadius: '12px',
                            background: '#333',
                            color: '#fff',
                        },
                        icon: '❌',
                    });
                } else if (error.response && error.response.status === 500) {
                    toast.error('Server error. Please try again later.', {
                        style: {
                            borderRadius: '12px',
                            background: '#333',
                            color: '#fff',
                        },
                        icon: '❌',
                    });
                } else {
                    toast.error('Error checking payment status', {
                        style: {
                            borderRadius: '12px',
                            background: '#333',
                            color: '#fff',
                        },
                        icon: '❌',
                    });
                }
            }
        }, 4000);
    };

    const renderPaymentProcessingModal = () => (
        <AnimatePresence>
            {processingPayment && (
                <motion.div
                    className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm z-50 flex items-center justify-center"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                >
                    <motion.div
                        className="bg-white rounded-2xl shadow-xl p-6 max-w-md w-full mx-4"
                        initial={{ scale: 0.9, opacity: 0 }}
                        animate={{ scale: 1, opacity: 1 }}
                        exit={{ scale: 0.9, opacity: 0 }}
                        transition={{ type: "spring", damping: 25 }}
                    >
                        <div className="text-center">
                            <motion.div
                                className="w-16 h-16 bg-blue-50 rounded-full mx-auto mb-4 flex items-center justify-center"
                                initial={{ rotate: 0 }}
                                animate={{ rotate: 360 }}
                                transition={{ duration: 2, repeat: Infinity, ease: "linear" }}
                            >
                                <motion.div className="w-12 h-12 border-4 border-secondary rounded-full border-t-transparent">
                                </motion.div>
                            </motion.div>

                            <h2 className="text-xl font-semibold text-gray-800 mb-2">
                                {paymentMethod === 'mpesa' ? 'Check Your Phone' : 'Processing Payment'}
                            </h2>

                            <p className="text-gray-600 mb-6">
                                {paymentMethod === 'mpesa'
                                    ? 'Please enter your M-Pesa PIN on your phone to complete the transaction.'
                                    : 'Your card payment is being processed. Please wait...'
                                }
                            </p>

                            <div className="space-y-4">
                                <div className="flex items-center bg-gray-50 p-3 rounded-lg">
                                    <div className="w-8 h-8 rounded-full bg-blue-100 flex items-center justify-center mr-3">
                                        <FaClock className="text-secondary" />
                                    </div>
                                    <div className="flex-1">
                                        <p className="text-sm text-gray-600">Transaction in progress</p>
                                        <motion.div
                                            className="h-1 bg-gray-200 rounded-full mt-1 overflow-hidden"
                                            initial={{ width: "100%" }}
                                        >
                                            <motion.div
                                                className="h-full bg-secondary"
                                                initial={{ width: "0%" }}
                                                animate={{ width: "100%" }}
                                                transition={{ duration: 30, ease: "linear" }}
                                            />
                                        </motion.div>
                                    </div>
                                </div>

                                <div className="bg-yellow-50 p-3 rounded-lg text-sm text-yellow-800">
                                    <div className="flex items-start">
                                        <FaExclamationCircle className="text-yellow-500 mt-1 mr-2 flex-shrink-0" />
                                        <p>
                                            {paymentMethod === 'mpesa'
                                                ? 'Please do not close this page while the transaction is in progress.'
                                                : 'Please wait while we verify your payment details.'
                                            }
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </motion.div>
                </motion.div>
            )}
        </AnimatePresence>
    );

    return (
        <Layout text="Payment" backgroundColor='bg-gray-50'>
            <motion.div
                className="max-w-6xl mx-auto"
                initial="hidden"
                animate="visible"
                variants={containerVariants}
            >

                {loading ? (
                    <motion.div
                        className="flex justify-center items-center py-20"
                        variants={itemVariants}
                    >
                        <motion.div
                            className="w-12 h-12 border-4 border-primary rounded-full border-t-transparent"
                            animate={{ rotate: 360 }}
                            transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
                        />
                    </motion.div>
                ) : (
                    <div className="flex w-full">
                        {/* Left Column - Payment Form */}
                        <motion.div
                            className="w-full"
                            variants={itemVariants}
                        >
                            <motion.div
                                className="bg-white w-full rounded-2xl shadow-sm border border-gray-100 overflow-hidden"
                                variants={cardVariants}
                            >
                                <div className="p-6 border-b border-gray-100">
                                    <h3 className="text-xl font-semibold text-gray-800 mb-1">Payment Method</h3>
                                    <p className="text-gray-500 text-sm">Select your preferred payment method</p>
                                </div>

                                <form onSubmit={handlePaymentSubmit} className="p-6">
                                    {/* Payment Method Selection */}
                                    <div className="grid grid-cols-2 gap-4 mb-6">
                                        {paymentOptions.map((option) => (
                                            <motion.div
                                                key={option.id}
                                                className={`relative rounded-xl border-2 p-4 cursor-pointer ${paymentMethod === option.id
                                                    ? 'border-secondary bg-secondary bg-opacity-5'
                                                    : 'border-gray-200 hover:border-gray-300'
                                                    }`}
                                                onClick={() => setPaymentMethod(option.id)}
                                                whileHover={{ y: -5, boxShadow: "0 10px 25px rgba(0, 0, 0, 0.08)" }}
                                                whileTap={{ scale: 0.98 }}
                                            >
                                                <div className="flex items-center mb-2">
                                                    <img
                                                        src={option.logo}
                                                        alt={option.title}
                                                        className="h-8 w-auto object-contain"
                                                    />
                                                    {option.secondaryLogo && (
                                                        <img
                                                            src={option.secondaryLogo}
                                                            alt={`${option.title} additional`}
                                                            className="h-8 w-auto object-contain ml-2"
                                                        />
                                                    )}
                                                </div>
                                                <p className="text-sm font-medium text-gray-800">{option.title}</p>
                                                <p className="text-xs text-gray-500 mt-1">{option.description}</p>

                                                {paymentMethod === option.id && (
                                                    <motion.div
                                                        className="absolute top-3 right-3 w-5 h-5 bg-secondary rounded-full flex items-center justify-center"
                                                        initial={{ scale: 0 }}
                                                        animate={{ scale: 1 }}
                                                        transition={{ type: "spring", stiffness: 500, damping: 15 }}
                                                    >
                                                        <div className="w-2.5 h-2.5 bg-white rounded-full" />
                                                    </motion.div>
                                                )}
                                            </motion.div>
                                        ))}
                                    </div>

                                    {/* Amount Field (Common for both payment methods) */}
                                    <div className="mb-6">
                                        <label className="block text-sm font-medium text-gray-700 mb-2">Amount (KES)</label>
                                        <div className={`relative rounded-xl bg-gray-50 border ${focusedField === 'amount'
                                            ? 'border-secondary ring-2 ring-secondary ring-opacity-10'
                                            : formErrors.amount
                                                ? 'border-red-300'
                                                : 'border-gray-200'
                                            } transition-all duration-200`}>
                                            <input
                                                type="number"
                                                value={amount}
                                                onChange={(e) => setAmount(e.target.value)}
                                                onFocus={() => setFocusedField('amount')}
                                                onBlur={() => setFocusedField(null)}
                                                className="w-full py-3 px-4 rounded-xl bg-transparent outline-none text-gray-800"
                                                placeholder="Enter amount"
                                            />
                                            <div className="absolute inset-y-0 right-4 flex items-center pointer-events-none">
                                                <span className="text-gray-500 text-sm">KES</span>
                                            </div>
                                        </div>
                                        {formErrors.amount && (
                                            <p className="mt-1 text-xs text-red-500">{formErrors.amount}</p>
                                        )}
                                    </div>

                                    {/* Conditional Form Fields Based on Payment Method */}
                                    <AnimatePresence mode="wait">
                                        {paymentMethod === 'mpesa' ? (
                                            <motion.div
                                                key="mpesa-form"
                                                initial={{ opacity: 0, y: 20 }}
                                                animate={{ opacity: 1, y: 0 }}
                                                exit={{ opacity: 0, y: -20 }}
                                                transition={{ duration: 0.3 }}
                                            >
                                                <div className="mb-6">
                                                    <label className="block text-sm font-medium text-gray-700 mb-2">M-Pesa Phone Number</label>
                                                    <div className={`relative rounded-xl bg-gray-50 border ${focusedField === 'phone'
                                                        ? 'border-secondary ring-2 ring-secondary ring-opacity-10'
                                                        : formErrors.phone
                                                            ? 'border-red-300'
                                                            : 'border-gray-200'
                                                        } transition-all duration-200`}>
                                                        <div className="absolute inset-y-0 left-4 flex items-center pointer-events-none">
                                                            <IoPhonePortrait className="text-gray-400" />
                                                        </div>
                                                        <input
                                                            type="text"
                                                            value={phone}
                                                            onChange={(e) => setPhone(e.target.value)}
                                                            onFocus={() => setFocusedField('phone')}
                                                            onBlur={() => setFocusedField(null)}
                                                            className="w-full py-3 pl-10 pr-4 rounded-xl bg-transparent outline-none text-gray-800"
                                                            placeholder="254XXXXXXXXX"
                                                        />
                                                    </div>
                                                    {formErrors.phone && (
                                                        <p className="mt-1 text-xs text-red-500">{formErrors.phone}</p>
                                                    )}
                                                    <p className="mt-2 text-xs text-gray-500">
                                                        You will receive a prompt on your phone to complete the payment.
                                                    </p>
                                                </div>

                                                <div className="bg-blue-50 rounded-xl p-4 mb-6">
                                                    <div className="flex items-start">
                                                        <div className="flex-shrink-0">
                                                            <IoShieldCheckmark className="text-secondary text-xl" />
                                                        </div>
                                                        <div className="ml-3">
                                                            <h4 className="text-sm font-medium text-gray-800">Secure M-Pesa Transaction</h4>
                                                            <p className="text-xs text-gray-600 mt-1">
                                                                Your payment is processed securely through Safaricom's M-Pesa service.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </motion.div>
                                        ) : (
                                            <motion.div
                                                key="card-form"
                                                initial={{ opacity: 0, y: 20 }}
                                                animate={{ opacity: 1, y: 0 }}
                                                exit={{ opacity: 0, y: -20 }}
                                                transition={{ duration: 0.3 }}
                                            >
                                                <div className="mb-6">
                                                    <label className="block text-sm font-medium text-gray-700 mb-2">Cardholder Name</label>
                                                    <div className={`relative rounded-xl bg-gray-50 border ${focusedField === 'cardholderName'
                                                        ? 'border-secondary ring-2 ring-secondary ring-opacity-10'
                                                        : formErrors.cardholderName
                                                            ? 'border-red-300'
                                                            : 'border-gray-200'
                                                        } transition-all duration-200`}>
                                                        <input
                                                            type="text"
                                                            value={cardDetails.cardholderName}
                                                            onChange={(e) => setCardDetails({ ...cardDetails, cardholderName: e.target.value })}
                                                            onFocus={() => setFocusedField('cardholderName')}
                                                            onBlur={() => setFocusedField(null)}
                                                            className="w-full py-3 px-4 rounded-xl bg-transparent outline-none text-gray-800"
                                                            placeholder="Name as it appears on card"
                                                        />
                                                    </div>
                                                    {formErrors.cardholderName && (
                                                        <p className="mt-1 text-xs text-red-500">{formErrors.cardholderName}</p>
                                                    )}
                                                </div>

                                                <div className="mb-6">
                                                    <label className="block text-sm font-medium text-gray-700 mb-2">Card Number</label>
                                                    <div className={`relative rounded-xl bg-gray-50 border ${focusedField === 'cardNumber'
                                                        ? 'border-secondary ring-2 ring-secondary ring-opacity-10'
                                                        : formErrors.cardNumber
                                                            ? 'border-red-300'
                                                            : 'border-gray-200'
                                                        } transition-all duration-200`}>
                                                        <div className="absolute inset-y-0 left-4 flex items-center pointer-events-none">
                                                            <FaCreditCard className="text-gray-400" />
                                                        </div>
                                                        <input
                                                            type="text"
                                                            value={cardDetails.cardNumber}
                                                            onChange={handleCardNumberChange}
                                                            onFocus={() => setFocusedField('cardNumber')}
                                                            onBlur={() => setFocusedField(null)}
                                                            className="w-full py-3 pl-10 pr-4 rounded-xl bg-transparent outline-none text-gray-800"
                                                            placeholder="xxxx xxxx xxxx xxxx"
                                                            maxLength="19" // 16 digits + 3 spaces
                                                        />
                                                    </div>
                                                    {formErrors.cardNumber && (
                                                        <p className="mt-1 text-xs text-red-500">{formErrors.cardNumber}</p>
                                                    )}
                                                </div>

                                                <div className="grid grid-cols-2 gap-4 mb-6">
                                                    <div>
                                                        <label className="block text-sm font-medium text-gray-700 mb-2">Expiry Date</label>
                                                        <div className={`relative rounded-xl bg-gray-50 border ${focusedField === 'expiryDate'
                                                            ? 'border-secondary ring-2 ring-secondary ring-opacity-10'
                                                            : formErrors.expiryDate
                                                                ? 'border-red-300'
                                                                : 'border-gray-200'
                                                            } transition-all duration-200`}>
                                                            <div className="absolute inset-y-0 left-4 flex items-center pointer-events-none">
                                                                <IoCalendarOutline className="text-gray-400" />
                                                            </div>
                                                            <input
                                                                type="text"
                                                                value={cardDetails.expiryDate}
                                                                onChange={handleExpiryDateChange}
                                                                onFocus={() => setFocusedField('expiryDate')}
                                                                onBlur={() => setFocusedField(null)}
                                                                className="w-full py-3 pl-10 pr-4 rounded-xl bg-transparent outline-none text-gray-800"
                                                                placeholder="MM/YY"
                                                                maxLength="5"
                                                            />
                                                        </div>
                                                        {formErrors.expiryDate && (
                                                            <p className="mt-1 text-xs text-red-500">{formErrors.expiryDate}</p>
                                                        )}
                                                    </div>

                                                    <div>
                                                        <label className="block text-sm font-medium text-gray-700 mb-2">CVV</label>
                                                        <div className={`relative rounded-xl bg-gray-50 border ${focusedField === 'cvv'
                                                            ? 'border-secondary ring-2 ring-secondary ring-opacity-10'
                                                            : formErrors.cvv
                                                                ? 'border-red-300'
                                                                : 'border-gray-200'
                                                            } transition-all duration-200`}>
                                                            <div className="absolute inset-y-0 left-4 flex items-center pointer-events-none">
                                                                <IoLockClosed className="text-gray-400" />
                                                            </div>
                                                            <input
                                                                type="text"
                                                                value={cardDetails.cvv}
                                                                onChange={(e) => setCardDetails({ ...cardDetails, cvv: e.target.value.replace(/\D/g, '').substring(0, 3) })}
                                                                onFocus={() => setFocusedField('cvv')}
                                                                onBlur={() => setFocusedField(null)}
                                                                className="w-full py-3 pl-10 pr-4 rounded-xl bg-transparent outline-none text-gray-800"
                                                                placeholder="XXX"
                                                                maxLength="3"
                                                            />
                                                        </div>
                                                        {formErrors.cvv && (
                                                            <p className="mt-1 text-xs text-red-500">{formErrors.cvv}</p>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="bg-blue-50 rounded-xl p-4 mb-6">
                                                    <div className="flex items-start">
                                                        <div className="flex-shrink-0">
                                                            <IoShieldCheckmark className="text-secondary text-xl" />
                                                        </div>
                                                        <div className="ml-3">
                                                            <h4 className="text-sm font-medium text-gray-800">Secure Card Transaction</h4>
                                                            <p className="text-xs text-gray-600 mt-1">
                                                                Your card details are encrypted and processed securely. We never store your full card details.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </motion.div>
                                        )}
                                    </AnimatePresence>

                                    <motion.button
                                        type="submit"
                                        className="w-full py-3 px-6 bg-secondary text-white font-medium rounded-xl shadow-md"
                                        whileHover={{ scale: 1.02, boxShadow: "0 5px 15px rgba(0, 0, 0, 0.1)" }}
                                        whileTap={{ scale: 0.98 }}
                                        disabled={processingPayment}
                                    >
                                        {processingPayment ? (
                                            <div className="flex items-center justify-center">
                                                <div className="w-5 h-5 border-2 border-white border-t-transparent rounded-full animate-spin mr-2"></div>
                                                Processing...
                                            </div>
                                        ) : (
                                            <div className="flex items-center justify-center">
                                                Pay {amount ? `KES ${Number(amount).toLocaleString()}` : 'Now'}
                                            </div>
                                        )}
                                    </motion.button>
                                </form>
                            </motion.div>

                            {/* Installment Section */}
                            {order?.installments && order?.installments?.length > 0 && (
                                <motion.div
                                    className="mt-6 bg-white rounded-2xl shadow-sm border border-gray-100 overflow-hidden"
                                    variants={cardVariants}
                                >
                                    <div className="p-6 border-b border-gray-100">
                                        <h3 className="text-xl font-semibold text-gray-800">Installment Schedule</h3>
                                    </div>

                                    <div className="p-4 space-y-3">
                                        {order?.installments
                                            ?.sort((a, b) => a.id - b.id)
                                            .map((installment, index) => (
                                                <motion.div
                                                    key={installment.id}
                                                    className={`p-4 rounded-xl ${index % 2 === 0 ? 'bg-gray-50' : 'bg-white border border-gray-100'
                                                        }`}
                                                    whileHover={{
                                                        backgroundColor: "#f9fafb",
                                                        boxShadow: "0 4px 12px rgba(0, 0, 0, 0.05)"
                                                    }}
                                                    variants={itemVariants}
                                                >
                                                    <div className="flex items-center justify-between">
                                                        <div className="flex items-center">
                                                            <motion.div
                                                                className={`flex items-center justify-center rounded-full w-10 h-10 text-xs text-white ${installment?.invoice?.payment_status === 'paid'
                                                                    ? 'bg-green-500'
                                                                    : installment?.invoice?.payment_status === 'pending'
                                                                        ? 'bg-primary'
                                                                        : installment?.invoice?.payment_status === 'partially_paid'
                                                                            ? 'bg-secondary'
                                                                            : 'bg-red-500'
                                                                    }`}
                                                                whileHover={{ scale: 1.1 }}
                                                            >
                                                                {index + 1}
                                                            </motion.div>
                                                            <div className="ml-4">
                                                                <p className="capitalize font-medium text-gray-800">
                                                                    {installment.type}
                                                                </p>
                                                                <p className="text-sm text-gray-600">
                                                                    KES {Number(installment.amount).toLocaleString()}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="text-right">
                                                            <p className="text-sm text-gray-600 flex items-center justify-end">
                                                                <IoCalendarOutline className="mr-1 text-gray-400" />
                                                                {moment(installment.due_date).format('DD MMM, YYYY')}
                                                            </p>
                                                            <div className="flex items-center mt-1">
                                                                <span className={`inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium ${installment.payment_status === 'complete'
                                                                    ? 'bg-green-100 text-green-800'
                                                                    : installment.payment_status === 'pending'
                                                                        ? 'bg-yellow-100 text-yellow-800'
                                                                        : 'bg-red-100 text-red-800'
                                                                    }`}>
                                                                    {installment.payment_status === 'complete' ? <IoCheckmarkCircle className="mr-1" /> : null}
                                                                    {installment.payment_status}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {/* Progress bar for partially paid installments */}
                                                    {installment.invoice.payment_status === 'partially_paid' && (
                                                        <div className="mt-3">
                                                            <div className="h-1.5 w-full bg-gray-200 rounded-full overflow-hidden">
                                                                <motion.div
                                                                    className="h-full bg-secondary"
                                                                    style={{
                                                                        width: `${(installment.invoice.paid_amount / installment.amount) * 100}%`
                                                                    }}
                                                                    initial={{ width: 0 }}
                                                                    animate={{
                                                                        width: `${(installment.invoice.paid_amount / installment.amount) * 100}%`
                                                                    }}
                                                                    transition={{ duration: 1, delay: 0.5 }}
                                                                />
                                                            </div>
                                                            <div className="flex justify-between mt-1 text-xs text-gray-500">
                                                                <span>Paid: KES {Number(installment.invoice.paid_amount).toLocaleString()}</span>
                                                                <span>Remaining: KES {Number(installment.invoice.remaining_balance).toLocaleString()}</span>
                                                            </div>
                                                        </div>
                                                    )}
                                                </motion.div>
                                            ))}
                                    </div>
                                </motion.div>
                            )}
                        </motion.div>
                    </div>
                )}
            </motion.div>

            {/* Render the payment processing modal */}
            {renderPaymentProcessingModal()}
        </Layout>
    );
};

export default PaymentPage;